import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, notification, Modal } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  CalendarOutlined,
  RedoOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import $ from 'jquery';
import axios from 'axios';
import { io } from 'socket.io-client';

import { RemoveDoctorDeleteAppointments } from '../modals';
import DoctorProfileForm from '../forms/DoctorProfileForm';
import { SERVER_URL, SOCKET_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem('user'));
const userType = user?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};
let searchInput;

const DoctorsTable = ({
  data,
  activate,
  deactivate,
  columnKeys,
  columnKeysTranslated,
  editPath,
  calendarPath,
  viewPath,
  tableKey,
  setReset,
}) => {
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [doctorData, setDoctorData] = useState(undefined);
  const [ordination, setOrdination] = useState();
  const [removeWorkDay, setRemoveWorkDay] = useState({ open: false, data: [], doc: '' });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder='Pretraži'
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={(e) => {
              if (e.target.value === '') {
                setReset((reset) => reset.filter((r) => r !== dataIndex));
                handleReset(clearFilters);
              } else {
                setReset((prev) => [...prev, dataIndex]);
                handleSearch(selectedKeys, confirm, dataIndex);
              }
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            style={{
              width: 195,
              marginBottom: 8,
              display: 'block',
              border: '1px solid #4dc1be',
              borderRadius: 20,
              paddingLeft: 10,
            }}
          />
          <Button
            onClick={() => {
              setReset((prev) => [...prev, dataIndex]);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size='small'
            style={{ width: 90, marginRight: 15 }}
          >
            Pretraži
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setReset((reset) => reset.filter((r) => r !== dataIndex));
              handleReset(clearFilters);
            }}
            size='small'
            style={{ width: 90 }}
          >
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <svg
        fill={filtered ? 'red' : 'white'}
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 24 24'
      >
        {' '}
        <path d='M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z' />
      </svg>
    ),
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    $(document).ready(function () {
      $('.ant-table-filter-trigger.active').parent().parent().attr('style', 'background-color: #4dc1be !important');
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const toggleModal = (as) => {
    setIsOpenModal(true);
    setDoctorData(as);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  const getOrdination = useCallback(async () => {
    try {
      const call = await axios.get(`${SERVER_URL}/ordination/${user?.ordination[0]}`, headers);
      setOrdination(call.data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const beforeDelete = async (id) => {
    try {
      const { data } = await axios.get(
        `${SERVER_URL}/appointments/day?doc=${id}&ord=${ordination._id}&remove=true`,
        headers,
      );
      if (data.length) {
        setRemoveWorkDay({ open: true, data, doc: id });
      } else {
        deactivate(id);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      sendDeleteNotification(removeWorkDay);
      deactivate(removeWorkDay.doc);
      setRemoveWorkDay({ open: false, data: [], doc: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendDeleteNotification = async (data) => {
    // for doctor notifications
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: 'ordination',
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    // for ordination notifications
    const ordNotificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: 'doctor',
      forWho: 'ordination'
    }));
    await axios.post(`${SERVER_URL}/notifications`, ordNotificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-doctor-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });

      socket.emit('new-notification-ordination-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });
    }
  };

  useEffect(() => {
    if (user?.ordination && user?.ordination[0]) getOrdination();
  }, [getOrdination]);

  const setupCategory = (record) => {
    const categories = record.category.filter((cat) =>
      ordination.category.some((ordCat) => ordCat.name.sr === cat.name.sr),
    );
    if (categories.length) {
      sessionStorage.setItem('openedSpecializationId', categories[0]._id);
      sessionStorage.setItem('currentDoctorFullName', `${record.firstName}-${record.lastName}`.toLowerCase());
      sessionStorage.setItem('currentDoctorId', record._id);
      history.push(`${calendarPath}${record.firstName.toLowerCase()}-${record.lastName.toLowerCase()}-${record._id}`);
    } else {
      notification.info({
        message: 'Ne pripada opsegu specijalizacija.',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  columns?.push({
    title: 'Postupak',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {userType === 'ordination' ? (
          <>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <span onClick={() => setupCategory(record)}>
                <CalendarOutlined title='Kalendar lekara' />
              </span>
            </div>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={`/admin/doctor/hours/` + record._id}>
                <ClockCircleOutlined title='Radno vreme' />
              </Link>
            </div>
          </>
        ) : (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={editPath + record._id}>
              <EditOutlined title='Izmeni lekara' />
            </Link>
          </div>
        )}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => toggleModal(record)}>
            <EyeOutlined title='Pregled doktora' style={{ textDecoration: 'none', color: 'black' }} />
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          {record.userReference?.status === 'ACTIVE' ? (
            <Popconfirm
              disabled={record._id === user?.id}
              placement='left'
              title={
                userType === 'admin'
                  ? 'Da li ste sigurni da želite da deaktivirate nalog?'
                  : 'Da li ste sigurni da želite da uklonite doktora?'
              }
              onConfirm={() =>
                userType === 'admin' ? deactivate(record.userReference?.email) : beforeDelete(record._id)
              }
              okText='Da'
              cancelText='Ne'
            >
              <DeleteOutlined title={userType === 'admin' ? 'Deaktivirajte nalog' : 'Ukloni doktora iz ordinacije'} />
            </Popconfirm>
          ) : (
            <Popconfirm
              disabled={record._id === user?.id}
              placement='left'
              title={'Da li ste sigurni da želite da aktivirate nalog?'}
              onConfirm={() => activate(record.userReference?.email)}
              okText='Da'
              cancelText='Ne'
            >
              <RedoOutlined title={'Aktivirajte nalog'} />
            </Popconfirm>
          )}
        </div>
      </div>
    ),
  });

  if (userType === 'admin') {
    columns.push({
      title: 'Status',
      render: (item) => (
        <>
          {item.status === 'ACTIVE' && <span style={{ color: 'green' }}>active</span>}
          {item.status === 'INACTIVE' && <span style={{ color: 'crimson' }}>inactive</span>}
          {item.status === 'DELETED' && <span style={{ color: 'black' }}>deleted</span>}
          {item.status === 'SUSPENDED' && <span style={{ color: 'yellow' }}>suspended</span>}
          {item.status === 'WAITING_FOR_ACTIVATION' && <span style={{ color: 'dodgerblue' }}>pending</span>}
        </>
      ),
    });
  }

  return (
    <>
      <div>
        <Table
          key={tableKey}
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 50,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: (
              <span>
                <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
                <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema doktora</p>
              </span>
            ),
          }}
        />
      </div>
      <RemoveDoctorDeleteAppointments
        mode='remove_doctor'
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        deleteAppointments={deleteAppointments}
        doctor={false}
      />
      <Modal
        className='doctor-modal patient'
        visible={isOpenModal}
        onCancel={closeModal}
        footer={null}
        width={700}
        centered
      >
        <DoctorProfileForm data={doctorData} isView={true} readonly={true} />
      </Modal>
    </>
  );
};

export default DoctorsTable;
