import React from 'react';
import { Modal, Card, Form, Radio, Input } from 'antd';

const ServiceModal = ({ openModal, setOpenModal, isEdit, submitService, form, isCreatedByDoctor }) => {
  return (
    <Modal
      className='doctor-modal'
      visible={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
      width={500}
      centered
    >
      <Card
        title={`${isEdit ? 'Ažuriraj' : 'Kreiraj'} uslugu`}
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <div className='date-change-container'>
          <Form onFinish={(values) => submitService(values)} layout='vertical' form={form}>
            <Form.Item
              style={{ margin: '0 8px' }}
              label='NAZIV'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Obavezno upišite naziv!',
                },
              ]}
            >
              {isCreatedByDoctor ? <Input /> : <Input readOnly />}
            </Form.Item>
            <Form.Item
              label='INTERVAL'
              name='duration'
              style={{ margin: '12px 8px' }}
              rules={[
                {
                  required: true,
                  message: 'Izaberite dužinu trajanja!',
                },
              ]}
            >
              <Radio.Group>
                <Radio value={'15'}>15 minuta</Radio>
                <Radio value={'30'}>30 minuta</Radio>
                <Radio value={'45'}>45 minuta</Radio>
                <Radio value={'60'}>60 minuta</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label='CENA (RSD)' name='price' style={{ margin: '12px 8px' }}>
              <Input type={'number'} />
            </Form.Item>
            <button className='action-button' htmltype='submit'>
              <img src='/images/save.svg' alt='accept' />
              <span>Potvrdi</span>
            </button>
          </Form>
        </div>
      </Card>
    </Modal>
  );
};

export default ServiceModal;