import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { io } from 'socket.io-client';
import DoctorProfileForm from '../../components/forms/DoctorProfileForm';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const DoctorProfile = () => {
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [formCategories, setCategories] = useState(undefined);
  const [singleDoctor, setSingleDoctor] = useState(undefined);

  if (currentuser?.userType === 'ordination') {
    history.push('/admin/doctors');
  }

  const getDoctorProfile = useCallback(async (id) => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/doctors/${id}`, headers);
      setSingleDoctor(data);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    const url = history.location.pathname;
    const lastSegment = url.split('/').pop();
    let id = lastSegment.split('-').pop();
    sessionStorage.setItem('currentDoctorId', id);
    if (id === 'profile') {
      id = currentuser?.doctor;
    }
    getDoctorProfile(id);
  }, [history, getDoctorProfile]);

  const updateDoctorProfile = async (updateData, id) => {
    try {
      const doctor = await Axios.put(`${SERVER_URL}/doctors/${id}`, { ...updateData }, headers);
      // const adminResponse = await Axios.get(`${SERVER_URL}/user-admin`, headers);
      // const adminId = adminResponse.data._id;
      // let socket = io(SOCKET_URL, { path: '/socket.io' });
      // socket.emit('new-notification-admin-created', { adminId });
      if (doctor.status === 200) {
        notification.success({
          message: doctor.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
        setTimeout(() => window.location.reload(), 500);
      }

      if (doctor.data.added.length || doctor.data.removed.length) {
        let socket = io(SOCKET_URL, { path: '/socket.io' });
        socket.emit('ordination-changed', {
          added: doctor.data.added,
          removed: doctor.data.removed,
          doctor: doctor.data.doctor
        });
      }

      if (doctor.status === 202) {
        notification.info({
          message: doctor.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
        notification.success({
          message: 'Uspešno je sačuvan profil!',
          placement: 'bottomRight',
          duration: 2.5
        });
        setTimeout(() => window.location.reload(), 500);
      }
    } catch (err) {
      if (err.response.status === 406) {
        notification.info({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      } else {
        notification.error({
          message:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
          placement: 'bottomRight',
          duration: 2.5
        });
      }
    }
  };

  const deleteDoctorProfile = async (email) => {
    try {
      const doctor = await Axios.get(`${SERVER_URL}/doctor/deactivate/${email}?admin=true`, headers);
      if (doctor.status === 200) {
        notification.success({
          message: doctor.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  useEffect(() => {
    fetchFormCategories();
  }, []);

  const fetchFormCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className='profile-container'>
      <div className='ordination-form-container'>
        <div className='ordination-form-wrapper wide'>
          {formCategories && (
            <DoctorProfileForm
              data={singleDoctor ? singleDoctor : null}
              updateDoctorProfileHandler={updateDoctorProfile}
              deleteDoctorProfileHandler={deleteDoctorProfile}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              categories={formCategories}
              language={'sr'}
              token={currentuser}
              readonly={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
