import React, { useState, useEffect, useContext } from 'react';
import { SOCKET_URL } from '../../../config';
import { SidebarContext } from '../../../App';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';

const currentuser = JSON.parse(sessionStorage.getItem('user'));

const AdminSidebarNarrow = (props) => {
  const { collapsedNav } = props;
  const { sidebarContext, unreadNotification } = useContext(SidebarContext);
  const [sidebarLinks, setSidebarLinks] = useState([
    {
      link: '/admin/concord/ordinations',
      linkText: 'Ordinacije',
      activeLink: false,
      id: 1,
    },
    {
      link: '/admin/concord/patients',
      linkText: 'Pacijenti',
      activeLink: false,
      id: 2,
    },
    {
      link: '/admin/concord/doctors',
      linkText: 'Lekari',
      activeLink: false,
      id: 3,
    },
    {
      link: '/admin/concord/categories',
      linkText: 'Oblasti rada',
      activeLink: false,
      id: 4,
    },
    {
      link: '/admin/concord/services',
      linkText: 'Usluge',
      activeLink: false,
      id: 5,
    },
    {
      link: '/admin/concord/cities',
      linkText: 'Gradovi',
      activeLink: false,
      id: 6,
    },
    {
      link: '/admin/concord/notifications',
      linkText: `Poruke`,
      activeLink: false,
      id: 7,
    },
    {
      link: '/admin/concord/banners',
      linkText: `Baneri`,
      activeLink: false,
      id: 8,
    },
  ]);

  useEffect(() => {
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    socket.emit('user-connected', currentuser.id);
    socket.on('new-notification-admin-received', async () => {
      await sidebarContext.getNumberOfUnreadNotifications();
    });
  }, [sidebarContext]);

  const markCurrentLink = (linkId) => {
    let newSidebarLinks = sidebarLinks;

    newSidebarLinks.forEach((link) => {
      if (link.id === linkId) {
        link.activeLink = true;
      } else {
        link.activeLink = false;
      }
    });
    setSidebarLinks(newSidebarLinks);
  };

  return (
    <aside className={collapsedNav ? 'sidebar-narrow-admin-active' : 'sidebar-narrow-admin'}>
      <ul>
        {sidebarLinks.map((item) => {
          return (
            <li key={item.id} onClick={() => markCurrentLink(item.id)} style={{ position: 'relative' }}>
              <Link to={item.link} className={item.activeLink ? 'sidebar-link current' : 'sidebar-link'}>
                {item.linkText}
                {item.linkText === 'Poruke' && unreadNotification ? (
                  <small
                    style={{
                      position: 'absolute',
                      left: '48px',
                      top: '-7px',
                      background: 'crimson',
                      padding: '0 0.25rem',
                      borderRadius: '.3rem',
                      zIndex: '10',
                    }}
                  >
                    <span style={{ color: 'white' }}>{unreadNotification}</span>
                  </small>
                ) : (
                  ''
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default AdminSidebarNarrow;
