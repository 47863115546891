import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Select, Row, Col, Popconfirm, notification, Checkbox, Upload, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import Axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { PlusOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { DownOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const MAX_IMAGE = 2;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const ordId = currentuser?.ordination;
let ordination;

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const OrdinationForm = ({
  data,
  updateOrdinationHandler,
  categories,
  createOrdinationHandler,
  deleteOrdinationHandler,
  result,
  token,
  readonly,
}) => {
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [gallery, setGallery] = useState([]);
  const [cities, setCities] = useState();
  const [phone, setPhone] = useState();
  const [cords, setCords] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [timerID, setTimerID] = useState(null);
  const [visibility, setVisibility] = useState();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    const divs = document.querySelectorAll('.ant-upload-list-item-card-actions-btn');
    if (divs.length) {
      divs.forEach((div) => {
        div.classList.remove('ant-btn');
        div.classList.remove('ant-btn-sm');
        div.classList.remove('ant-btn-text');
        div.classList.remove('ant-btn-icon-only');
      });
    }
  }, [fileList]);

  const uploadImage = (val) => {
    setFileList(val.fileList);
    try {
      if (val.file.response) {
        if (val.file.status === 'done') {
          setGallery([...gallery, val.file.response.image]);
          notification.info({
            message: 'Slika je sačuvana',
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa čuvanjem slika.',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const removeImage = async (file) => {
    try {
      await Axios.delete(`${SERVER_URL}/remove-local-image/${file.response.image._id}`, {
        headers: { Authorization: `Bearer ${currentuser?.token}` },
      });
      const newGallery = gallery.filter((image) => image._id !== file.response.image._id);
      setGallery(newGallery);
      notification.info({
        message: 'Slika je uklonjena',
        placement: 'bottomRight',
        duration: 2.5,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const setupAddress = (search, cb) => {
    clearTimeout(timerID);
    const timeout = setTimeout(() => geolocation(search, cb), 700);
    setTimerID(timeout);
  };

  useEffect(() => {
    setVisibility(data?.visibility);
  }, [data]);

  const geolocation = async (input, callback) => {
    try {
      if (input !== '') {
        const { data } = await Axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=${process.env.REACT_APP_MAP_KEY}`,
        );

        const formatData = data.results.map((r) => ({
          label: r.formatted_address,
          value: `${r.geometry.location.lat}, ${r.geometry.location.lng}`,
        }));

        setRecommendations(formatData);
        callback(formatData);
      }
    } catch (error) {
      notification.info({
        message: 'Precizirajte adresu bolje. Unesite grad ili broj ulice.',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const getCitites = async () => {
    try {
      const cityCall = await Axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  if (data.name) {
    ordination = {
      ...data,
      email: data.userReference?.email,
      phone: data.phone.length && data.phone[0],
      category: data.category.map((item) => item._id),
    };
  }

  useEffect(() => {
    if (data && data.gallery) {
      const loadGallery = data.gallery.map((image) => ({
        response: { image: { _id: image._id } },
        name: image.originalname,
        status: 'done',
        url: `${SERVER_URL}/${image.url}`,
      }));
      setFileList(loadGallery);
      setGallery(data.gallery);
    }
  }, [data]);

  const isNew = data ? false : true;

  useEffect(() => {
    if (data && data.avatar) {
      setImage(data.avatar);
    } else {
      setImage(null);
    }
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    if (userType !== 'admin') getCitites();
  }, [data]);

  const onFinish = async (values) => {
    const ordDoctors = await Axios.post(
      `${SERVER_URL}/doctors-by-ordination`,
      { ordination: ordId },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser?.token}` },
      },
    );
    const session = JSON.parse(sessionStorage.getItem('user'));
    session.name = values.name;
    session.email = values.email;
    sessionStorage.setItem('user', JSON.stringify(session));

    let cats = [];
    ordDoctors.data.forEach((doc) => doc.category.forEach((cat) => cats.push(cat._id)));
    cats = [...new Set(cats)];

    const selectedCategory = cats.find((cat) => values.category.find((ordCat) => cat === ordCat));
    const selectedDoctor = ordDoctors.data.find((doc) => doc.category.find((cat) => cat._id === selectedCategory));

    if (selectedCategory && selectedDoctor) {
      sessionStorage.setItem(
        'currentDoctor',
        `${selectedDoctor?.lastName} ${selectedDoctor?.firstName.slice(0, 1) + '.'}`,
      );
      sessionStorage.setItem(
        'currentDoctorFullName',
        `${selectedDoctor?.firstName}-${selectedDoctor?.lastName}`.toLowerCase(),
      );
      sessionStorage.setItem('openedSpecializationId', selectedCategory);
      sessionStorage.setItem('currentDoctorId', selectedDoctor?._id);
    }

    if (cords) {
      values.cords = cords;
    }
    if (visibility) {
      values.visibility = visibility;
    } else {
      values.visibility = false;
    }
    values.address = values.address.label;
    values.gallery = gallery;
    if (isNew) {
      createOrdinationHandler(values);
    } else {
      values.avatar = image;
      updateOrdinationHandler(values, data._id);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed in OrdinationForm:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ avatar: values });
    setImage(values);
  };

  const deactivateOrdination = () => {
    deleteOrdinationHandler(data._id);
  };

  const forgotPassword = async () => {
    try {
      const sendPasswordLink = await Axios.post(
        `${SERVER_URL}/forgot-password`,
        { email: currentuser?.email },
        { withCredentials: false },
      );
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const confirm = (e) => {
    deactivateOrdination();
    sessionStorage.clear();
    setTimeout(() => (window.location.href = '/login'), 700);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const resetData = () => {
    form.resetFields();
    notification.info({
      message: 'Promene su poništene',
      placement: 'bottomRight',
      duration: 2.5,
    });
  };

  const removeGoogleAuth = async () => {
    try {
      const { data } = await Axios.delete(
        `${SERVER_URL}/calendar/auth-google?ord=${currentuser?.ordination}&type=ordination`,
        headers,
      );
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      setTimeout(() => window.location.reload(), 800);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='klinika-klinika'>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`Klinika ${ordination ? ordination.name : ''}`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                initialValues={ordination}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
                style={{ borderRadius: '13px' }}
              >
                <Row className='card-form'>
                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                    <Form.Item
                      label='NAZIV'
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: 'Obavezno upišite naziv!',
                        },
                      ]}
                    >
                      {userType === 'ordination' ? (
                        <Input style={{ paddingLeft: '0px' }} />
                      ) : (
                        <Input readOnly style={{ cursor: 'context-menu' }} />
                      )}
                    </Form.Item>

                    <Form.Item label='ADRESA' name='address'>
                      {userType === 'ordination' ? (
                        <AsyncSelect
                          loadOptions={setupAddress}
                          defaultOptions={recommendations}
                          placeholder='Ulica broj, grad'
                          onChange={(e) => setCords(e.value)}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: 30,
                              border: 0,
                              boxShadow: 'none',
                              borderRadius: 0,
                              borderBottom: '1px solid #e5e5e5',
                            }),
                            input: (base) => ({
                              ...base,
                              color: '#627b90',
                              fontWeight: 'bold',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#627b90',
                              fontWeight: 'bold',
                            }),
                            menu: (base) => ({
                              ...base,
                              marginTop: '0rem',
                              borderRadius: 0,
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: (props) => (
                              <components.DropdownIndicator {...props}>
                                <DownOutlined />
                              </components.DropdownIndicator>
                            ),
                          }}
                        />
                      ) : (
                        <Input />
                      )}
                    </Form.Item>

                    <Form.Item label='GRAD' name='city'>
                      {userType === 'ordination' ? (
                        <Select
                          showArrow
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .replace(/ć/g, 'c')
                              .replace(/č/g, 'c')
                              .replace(/đ/g, 'dj')
                              .replace(/š/g, 's')
                              .replace(/ž/g, 'z')
                              .replace(/dž/g, 'dz')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz'),
                              ) >= 0
                          }
                        >
                          {cities?.map((city) => (
                            <Option key={city._id} value={city.title}>
                              {city.title}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Input readOnly style={{ cursor: 'context-menu' }} />
                      )}
                    </Form.Item>

                    <Form.Item label='DRŽAVA' name='country'>
                      {userType === 'ordination' ? (
                        <Input style={{ paddingLeft: '0px' }} />
                      ) : (
                        <Input readOnly style={{ cursor: 'context-menu' }} />
                      )}
                    </Form.Item>

                    <Form.Item label='TELEFON' name='phone'>
                      {userType === 'ordination' ? (
                        <PhoneInput
                          style={{ border: 'none', borderBottom: '1px solid #e5e5e5', paddingLeft: '2px' }}
                          international
                          defaultCountry='RS'
                          countryCallingCodeEditable={false}
                          value={phone}
                          onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                        />
                      ) : (
                        <PhoneInput
                          international
                          defaultCountry='RS'
                          countryCallingCodeEditable={false}
                          value={phone}
                          onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                          readOnly
                        />
                      )}
                    </Form.Item>

                    <Form.Item
                      label='EMAIL'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Obavezno upišite naziv!',
                        },
                      ]}
                    >
                      {userType === 'ordination' ? <Input /> : <Input readOnly style={{ cursor: 'context-menu' }} />}
                    </Form.Item>

                    <Form.Item label='WEBSAJT' name='website'>
                      {userType === 'ordination' ? <Input /> : <Input readOnly style={{ cursor: 'context-menu' }} />}
                    </Form.Item>

                    <Form.Item
                      label='OBLAST RADA'
                      name='category'
                      className='panel-body'
                      rules={[{ required: true, message: 'Obavezno odaberite specijalizaciju!' }]}
                    >
                      {userType === 'ordination' ? (
                        <Select
                          showArrow
                          showSearch
                          mode='multiple'
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .replace(/ć/g, 'c')
                              .replace(/č/g, 'c')
                              .replace(/đ/g, 'dj')
                              .replace(/š/g, 's')
                              .replace(/ž/g, 'z')
                              .replace(/dž/g, 'dz')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz'),
                              ) >= 0
                          }
                        >
                          {categories?.map((cat) => (
                            <Option key={cat._id} value={cat._id}>
                              {cat.name.sr}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          showArrow
                          showSearch
                          mode='multiple'
                          bordered={false}
                          removeIcon={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .replace(/ć/g, 'c')
                              .replace(/č/g, 'c')
                              .replace(/đ/g, 'dj')
                              .replace(/š/g, 's')
                              .replace(/ž/g, 'z')
                              .replace(/dž/g, 'dz')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz'),
                              ) >= 0
                          }
                        >
                          {categories?.map((cat) => (
                            <Option key={cat._id} value={cat._id}>
                              {cat.name.sr}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item label='O KLINICI' name='description'>
                      {userType === 'ordination' ? (
                        <TextArea rows={4} />
                      ) : (
                        <TextArea rows={4} readOnly style={{ cursor: 'context-menu' }} />
                      )}
                    </Form.Item>

                    {userType === 'ordination' && (
                      <Form.Item label='GALERIJA'>
                        <Upload
                          action={`${SERVER_URL}/upload-local-image`}
                          name='image'
                          listType='picture-card'
                          maxCount={MAX_IMAGE}
                          multiple
                          onPreview={handlePreview}
                          headers={{
                            Authorization: currentuser?.token,
                            uri: 'public/images/gallery/',
                          }}
                          fileList={fileList}
                          onChange={uploadImage}
                          onRemove={removeImage}
                        >
                          {fileList.length >= MAX_IMAGE ? null : (
                            <div>
                              <PlusOutlined style={{ fontSize: '2rem', color: 'gray' }} />
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Otpremi sliku
                              </div>
                            </div>
                          )}
                        </Upload>
                        <Modal visible={previewVisible} title={previewTitle} footer={' '} onCancel={handleCancel}>
                          <img
                            alt='plus'
                            style={{
                              width: '100%',
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    )}
                    {userType === 'admin' && (
                      <Form.Item label='VIDLJIVOST' name='visibility'>
                        <Checkbox disabled checked={visibility} />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                    <Form.Item className='upload-wrapper right' name='avatar'>
                      {userType === 'ordination' ? (
                        <UploadBox
                          editHandler={avatarHandler}
                          deleteHandler={deleteAvatarHandler}
                          image={image}
                          index={0}
                          name='avatar'
                        />
                      ) : (
                        <UploadBox
                          editHandler={() => console.log('LOL')}
                          deleteHandler={() => console.log('DOUBLE LOL')}
                          image={image}
                          index={0}
                          name='avatar'
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                {readonly && userType !== 'ordinationDoctor' && (
                  <Row className='doctor-profile-buttons-row'>
                    <Col style={{ marginRight: 'auto' }}>
                      {!data?.google ? (
                        <a
                          href={`${SERVER_URL}/calendar/google?email=${currentuser?.email}&type=ordination`}
                          className='action-button border-dark sync'
                        >
                          <img src='/images/googleLogo.png' alt='google' />
                          <span> Sinhronizujte se sa Google kalendarom</span>
                        </a>
                      ) : (
                        <span
                          style={{ cursor: 'pointer' }}
                          className='action-button border-dark'
                          onClick={removeGoogleAuth}
                        >
                          <img src='/images/save.svg' alt='save' />
                          <span>Sinhronizovano</span>
                        </span>
                      )}
                      <Form.Item>
                        {!isNew && (
                          <Popconfirm
                            title='Da li stvarno želite da deaktivirate nalog?'
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText='Da'
                            cancelText='Ne'
                          >
                            <button className='delete-button top-space deactivate' style={{ cursor: 'pointer' }}>
                              <img src='/images/delete.svg' alt='delete' style={{ color: '#ff7373' }} />
                              <span>Deaktivirajte nalog</span>
                            </button>
                          </Popconfirm>
                        )}
                      </Form.Item>
                      <span
                        className='action-button border-dark change-password'
                        onClick={forgotPassword}
                        style={{ cursor: 'pointer' }}
                      >
                        <span style={{ width: '7rem' }}>
                          <span>Promenite šifru</span>
                        </span>
                      </span>
                    </Col>
                    <Col>
                      <Form.Item>
                        <button className='action-button border-dark' htmltype='submit'>
                          <img src='/images/save.svg' alt='save' />
                          <span>Potvrdi</span>
                        </button>
                      </Form.Item>
                      <div className='delete-button top-space' onClick={resetData} style={{ cursor: 'pointer' }}>
                        <img src='/images/delete.svg' alt='delete' />
                        <span>Otkaži promene</span>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token.token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdinationForm;
