import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Card, Form, Col } from 'antd';
import ReactToPrint from 'react-to-print';
import PrintReport from '../../pages/patients/PrintReport';
import PrintRecepie from '../../pages/patients/PrintRecepie';
import PrintDocument from '../../pages/patients/PrintDocument';
import $ from 'jquery';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem("user"));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const DocumentsTable = ({ data, columnKeys, printRef, columnKeysTranslated }) => {
  const [doctor, setDoctor] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [document, setDocument] = useState([]);
  const [type, setType] = useState('');
  const [documentName, setDocumentName] = useState('');
  let searchInput;

  const getDoctor = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/doctors/${user?.doctor}`, headers);
      setDoctor(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (user.doctor) {
      getDoctor();
    }
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder='Pretraži'
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 195,
              marginBottom: 8,
              display: 'block',
              border: '1px solid #4dc1be',
              borderRadius: 20,
              paddingLeft: 10,
            }}
          />
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 15 }}
          >
            Pretraži
          </Button>
          <Button type='primary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <svg fill={filtered ? 'red' : 'white'} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" > <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" /></svg>,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    $(document).ready(function () {
      $('.ant-table-filter-trigger.active').parent().parent().attr('style', 'background-color: #4dc1be !important');
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const toggleModal = (result) => {
    setDocumentName(result.name);
    setType(result.type);
    setIsOpenModal(true);
    setDocument(result);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));
  columns?.push({
    title: 'Postupak',
    render: (text, record) => (
      <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => toggleModal(record)}>
        <EyeOutlined title={`Pregled ${record.type}a`}></EyeOutlined>
      </div>
    ),
  });

  return (
    <>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <span>
              <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
              <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>
                Za ovog pacijenta jos uvek nije napravljen nijedan pregled
              </p>
            </span>
          ),
        }}
      />
      <Modal
        className='doctor-modal report'
        visible={isOpenModal}
        onCancel={closeModal}
        footer={null}
        width={700}
        centered
      >
        <div>
          <Card bordered={false}>
            <Form name='basic' layout='horizontal' className='modal-form' size='small' disabled={true}>
              {type === 'izveštaj' && <PrintReport singleReport={document} signature={doctor.signature} ref={printRef} />}

              {type === 'recept' && <PrintRecepie singleRecepie={document} signature={doctor.signature} ref={printRef} />}

              {type === 'dokument' && <PrintDocument singleDocument={document} ref={printRef} />}

              <Col xs={{ span: 24, offset: 0 }} className='report-form-button-col'>
                <Form.Item>
                  <ReactToPrint
                    trigger={() => (
                      <button className='action-button border-dark' style={{ margin: '1rem 0' }}>
                        <span>Štampanje</span>
                      </button>
                    )}
                    content={() => printRef.current}
                    documentTitle={documentName}
                  />
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default DocumentsTable;
