import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const PrintRecepie = React.forwardRef(({ singleRecepie, signature }, ref) => {
  const canvas = useRef({});

  useEffect(() => {
    canvas.current.fromDataURL(signature);
  }, [signature]);

  return (
    <div className='recepie' ref={ref}>
      <h1 className='recepie-title'>RECEPT</h1>
      <h2 style={{ textTransform: 'uppercase' }}>Zdrastvena ustanova/privatna praksa</h2>
      <div className='recepie-input'>
        <label>Naziv: </label>
        <input defaultValue={singleRecepie.ordination?.name} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Adresa: </label>
        <input defaultValue={singleRecepie.ordination?.address} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Telefon: </label>
        <input defaultValue={singleRecepie.ordination?.phone} readOnly />
      </div>

      <h2 style={{ textTransform: 'uppercase' }}>Pacijent</h2>
      <div className='recepie-input'>
        <label>Vrsta pregleda: </label>
        <input defaultValue={singleRecepie.examinationType} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Ime i prezime: </label>
        <input defaultValue={`${singleRecepie.patient.firstName} ${singleRecepie.patient.lastName}`} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Godina rodjenja: </label>
        <input type='date' defaultValue={singleRecepie.patient.dateOfBirth} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Telefon: </label>
        <input defaultValue={singleRecepie.patient.phone} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Adresa: </label>
        <input defaultValue={singleRecepie.patient?.address} readOnly />
      </div>

      <div className='recepie-input diagnose'>
        <label className='diagnose-modal-label'>NAZIV ILI ŠIFRA DIJAGNOZE: </label>
        <input className='recepie-input' defaultValue={singleRecepie.diagnoseId} readOnly />
      </div>

      <h2 style={{ textTransform: 'uppercase', marginTop: '10px' }}>Vrsta recepta (označiti):</h2>
      <div className='recepie-form'>
        <input
          checked={singleRecepie.recepieType === 'non-renewable' && true}
          type='radio'
          name='recepieType'
          readOnly
          value='non-renewable'
        />
        <label>Neobnovljiv</label>
        <input
          checked={singleRecepie.recepieType === 'renewable' && true}
          type='radio'
          name='recepieType'
          readOnly
          value='renewable'
        />
        <label>Obnovljiv</label>
        <input
          checked={singleRecepie.recepieType === 'unique' && true}
          type='radio'
          name='recepieType'
          readOnly
          value='unique'
        />
        <label>Poseban</label>
        <input
          checked={singleRecepie.recepieType === 'limited' && true}
          type='radio'
          name='recepieType'
          readOnly
          value='limited'
        />
        <label>Ograničen</label>
      </div>

      <h2 style={{ textTransform: 'uppercase' }}>RP.</h2>
      <textarea className='recepie-text' rows={4} readOnly>
        {singleRecepie.recepie}
      </textarea>

      <h2 style={{ textTransform: 'uppercase' }}>Napomene:</h2>
      <textarea className='recepie-text note' rows={2} readOnly>
        {singleRecepie.prescriptionNote}
      </textarea>
      <div className='recepie-input'>
        <label className='pharmacy-modal-label'>Broj potvrde apoteke za preuzimanje leka:</label>
        <input value={singleRecepie.drugID} readOnly />
      </div>
      <div className='recepie-input'>
        <label className='modal-label-wider'>Datum propisivanja:</label>
        <input value={singleRecepie.date} readOnly />
      </div>
      <div className='recepie-input'>
        <label className='modal-label-wider'>Ime i prezime lekara:</label>
        <input value={singleRecepie.doctorName} readOnly />
      </div>
      <div className='recepie-input'>
        <label>Broj licence:</label>
        <input value={singleRecepie.doctor[0].license} readOnly />
      </div>
      <div>
        <span style={{ color: "#627B90" }}>Potpis i faksimil lekara:</span>
        <SignatureCanvas
          onBegin={() => canvas.current.off()}
          ref={canvas}
          canvasProps={{ width: 556, height: 130 }}
        />
      </div>
    </div>
  );
});

export default PrintRecepie;
