import React, { useState, useEffect } from 'react';
import { Input, Form, Upload, Modal, notification, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GalleryModal } from './';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const MAX_IMAGE = 2;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const ServiceForm = ({ onSubmit, service, token, language, setEdit }) => {
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });

  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete service[key]);

  useEffect(() => {
    if (Object.keys(service).length === 0) {
      form.resetFields();
      setFileList([]);
      setGallery([]);
    }
  }, [service, form]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    const divs = document.querySelectorAll('.ant-upload-list-item-card-actions-btn');
    if (divs.length) {
      divs.forEach((div) => {
        div.classList.remove('ant-btn');
        div.classList.remove('ant-btn-sm');
        div.classList.remove('ant-btn-text');
        div.classList.remove('ant-btn-icon-only');
      });
    }
  }, [fileList]);

  const uploadImage = (val) => {
    setFileList(val.fileList);
    try {
      if (val.file.response) {
        if (val.file.status === 'done') {
          setGallery([...gallery, val.file.response.image]);
          notification.info({
            message: 'Slika je sačuvana',
            placement: 'bottomRight',
            duration: 2.5
          });
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa čuvanjem slika.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const removeImage = async (file) => {
    try {
      await Axios.delete(`${SERVER_URL}/remove-local-image/${file.response.image._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newGallery = gallery.filter((image) => image._id !== file.response.image._id);
      setGallery(newGallery);
      notification.info({
        message: 'Slika je uklonjena',
        placement: 'bottomRight',
        duration: 2.5
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
  };

  useEffect(() => {
    if (service && service.gallery) {
      const loadGallery = service.gallery.map((image) => ({
        response: { image: { _id: image._id } },
        name: image.originalname,
        status: 'done',
        url: `${SERVER_URL}/${image.url}`,
      }));
      setFileList(loadGallery);
      setGallery(service.gallery);
    }
    if (service) {
      form.setFieldsValue({ ...service });
    }
  }, [service, form]);

  const onFinish = async (values) => {
    const isNew = true;
    values.gallery = gallery;
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{!service.price ? 'Kreiraj' : 'Izmeni'}</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, service: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>

      <div className='panel-body'>
        <Form onFinish={(values) => onFinish(values)} layout='vertical' form={form} initialValues={service}>
          <Form.Item label='GALERIJA'>
            <Upload
              action={`${SERVER_URL}/upload-local-image`}
              name='image'
              listType='picture-card'
              maxCount={MAX_IMAGE}
              multiple
              onPreview={handlePreview}
              headers={{
                Authorization: token,
                uri: 'public/images/gallery/',
              }}
              fileList={fileList}
              onChange={uploadImage}
              onRemove={removeImage}
            >
              {fileList.length >= MAX_IMAGE ? null : (
                <div>
                  <PlusOutlined style={{ fontSize: '2rem', color: 'gray' }} />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {fileList.length === 0 ? 'Bela pozadina' : 'Zelena pozadina'}
                  </div>
                </div>
              )}
            </Upload>
            <Modal visible={previewVisible} title={previewTitle} footer={' '} onCancel={handleCancel}>
              <img
                alt='plus'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Form.Item>
          <Form.Item
            style={{ margin: '0 8px' }}
            label='NAZIV'
            name='title'
            rules={[
              {
                required: true,
                message: 'Obavezno upišite naziv!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='INTERVAL'
            name='duration'
            style={{ margin: '12px 8px' }}
            rules={[
              {
                required: true,
                message: 'Obavezno unesite trajanje usluge!',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={'15'}>15 minuta</Radio>
              <Radio value={'30'}>30 minuta</Radio>
              <Radio value={'45'}>45 minuta</Radio>
              <Radio value={'60'}>60 minuta</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='CENA (RSD)'
            name='price'
            style={{ margin: '12px 8px' }}
            rules={[
              {
                required: true,
                message: 'Obavezno unesite cenu!',
              },
            ]}
          >
            <Input type={'number'} />
          </Form.Item>
          <div
            className='text-right'
            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: '10px' }}
          >
            <button className='add-specialization border-dark' htmltype='submit'>
              <img src='/images/save.svg' alt='save' />
              <span>Potvrdi</span>
            </button>
          </div>
        </Form>
        {modal.visible && (
          <GalleryModal
            width={700}
            centered
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/users/'
            imageType='data'
            imageHeight={200}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsert(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceForm;
