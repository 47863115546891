import React, { useState, useEffect } from 'react';
import { Modal, Card } from 'antd';
import { useHistory } from 'react-router-dom';

const user = JSON.parse(sessionStorage.getItem("user"));

const AddOrdinationPrompt = ({ workDayOverlap, setWorkDayOverlap, removeOrdination }) => {
  const history = useHistory();
  const [accomodateOrdination, setAccomodateOrdination] = useState([]);

  useEffect(() => {
    const ords = [];
    if (user.userType === 'doctor') {
      if (workDayOverlap.data) {
        workDayOverlap.data.workDayOverlap?.forEach(day => day.days.forEach(yad => ords.push(yad.current.ordination.name)));
        workDayOverlap.data.singleDayOverlap?.forEach(day => day.days.forEach(yad => ords.push(yad.current.ordination.name)));
      }
    } else {
      if (workDayOverlap.data) {
        workDayOverlap.data.workDayOverlap?.forEach(day => day.days.forEach(yad => ords.push(yad.added.ordination.name)));
        workDayOverlap.data.singleDayOverlap?.forEach(day => day.days.forEach(yad => ords.push(yad.added.ordination.name)));
      }
    }
    setAccomodateOrdination([...new Set(ords)]);
  }, [workDayOverlap]);

  const workDay = workDayOverlap.data.workDayOverlap;
  const singleDay = workDayOverlap.data.singleDayOverlap;

  const revertChanges = () => {
    user.userType === 'doctor' && removeOrdination(workDayOverlap.data.added);
    setWorkDayOverlap({ open: false, data: [], added: '' });
  };

  return (
    <Modal
      className='doctor-modal'
      visible={workDayOverlap.open}
      onCancel={revertChanges}
      footer={null}
      width={500}
      centered
    >
      <Card
        title="Uspostavljanje radnih dana"
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <p>Preklapaju se {workDay?.length ? 'radna vremana' : ''}{singleDay?.length ? ' i jednokratni dani' : ''}. Molim da prilagodite vreme za {accomodateOrdination?.length === 1 ? 'ordinaciju' : 'ordinacije'} {accomodateOrdination?.length === 1 ? <b>{accomodateOrdination[0]}</b> : accomodateOrdination.map((ord, i) => <><b>{ord}</b>{i < accomodateOrdination.length - 2 ? ', ' : i < accomodateOrdination.length - 1 ? ' i ' : ''}</>)}.</p>
        {workDay?.length ?
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '1.3rem' }}>Radno vreme</h2>
            {workDay.map((day, i) =>
              <div key={i}>
                <p><b>{day.day}</b></p>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    <p><b>Trenutno</b></p>
                    {day.days.map((yad, j) =>
                      <div key={j}>
                        <span>{yad.current.ordination.name}: {yad.current.start} - {yad.current.end}</span><br />
                      </div>
                    )}
                  </div>
                  <div>
                    <p><b>Dodato</b></p>
                    {day.days.map((yad, j) =>
                      <div key={j}>
                        <span>{yad.added.ordination.name}: {yad.added.start} - {yad.added.end}</span><br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div> : null
        }
        {singleDay?.length ?
          <div style={{ textAlign: 'center' }}>
            <br /><br />
            <h2 style={{ fontSize: '1.3rem' }}>Jednokratno radno vreme</h2>
            {singleDay.map((day, i) =>
              <div key={i}>
                <p><b>{new Date(day.date).toLocaleDateString('sr-Latn-RS', { year: 'numeric', month: 'long', day: 'numeric' })}</b></p>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    <p><b>Trenutno</b></p>
                    {day.days.map((yad, j) =>
                      <div key={j}>
                        <span>{yad.current.ordination.name}: {yad.current.start} - {yad.current.end}</span><br />
                      </div>
                    )}
                  </div>
                  <div>
                    <p><b>Dodato</b></p>
                    {day.days.map((yad, j) =>
                      <div key={j}>
                        <span>{yad.added.ordination.name}: {yad.added.start} - {yad.added.end}</span><br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div> : null
        }
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
          <button
            className='action-button'
            onClick={() => {
              if (user.userType === 'doctor') {
                revertChanges();
                history.push('/admin/doctor/workdays');
              } else {
                history.push('/admin/ordination/workdays');
              }
            }}
          >
            <span>Radno vreme</span>
          </button>
          <button className='action-button delete-button' onClick={revertChanges}>
            <img src='/images/delete.svg' alt='delete' />
            <span>Otkaži</span>
          </button>
        </div>
      </Card>
    </Modal>
  );
};

export default AddOrdinationPrompt;