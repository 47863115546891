import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { notification, Button } from 'antd';
import moment from 'moment';
import PatientsTable from '../../../components/tables/PatientsTable';
import { SERVER_URL } from '../../../config';
import axios from 'axios';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [reset, setReset] = useState([]);
  const [tableKey, setTableKey] = useState(0);

  const getPatients = async () => {
    try {
      const data = await axios.get(`${SERVER_URL}/user/patients`, headers);
      setPatients(data);
    } catch (error) {
      notification.error({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  const deactivatePatient = async (id) => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/patient/deactivate/${id}?admin=true`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getPatients();
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const activatePatient = async (id) => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/patient/activate/${id}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getPatients();
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const columnKeysTranslated = [
    'Ime',
    'Prezime',
    'Datum Rodjenja',
    'Grad',
    'Telefon',
    'Email',
    'Doktori',
    'Ordinacije',
    'Kreiran'
  ];
  const columnKeys = ['firstName', 'lastName', 'convertedDate', 'city', 'phone', 'email', 'doctor', 'ordination', 'createdAt'];

  let tableData = [];
  if (patients?.data?.length > 0) {
    tableData = patients.data?.map((item) => {
      return {
        ...item,
        ordination: item.ordinations?.map((ord, i) => `${ord.name}${i < item.ordinations.length - 1 ? `,` : ''} `),
        convertedDate: item.dateOfBirth ? moment(item.dateOfBirth).locale('sr').format('DD. MMMM YYYY.') : '',
        doctor: item.doctors?.map(
          (doc, i) => `${doc.firstName} ${doc.lastName}${i < item.doctors.length - 1 ? `,` : ''} `,
        ),
        status: item.userReference?.status,
        email: item.userReference?.email,
        createdAt: !item.createdAt ? "" : new Date(item.createdAt).toLocaleString('sr-Latn-RS', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: '2-digit',
          minute: "2-digit",
        })
      };
    });
  }

  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    setReset([]);
  };

  return (
    <div className='patients-table' style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/concord/new-patient'>
          <Button type='primary'>Novi Pacijent</Button>
        </Link>
        {reset.length ? (
          <Button style={{ marginLeft: '0.5rem' }} type='primary' onClick={() => resetFilters()}>
            Reset filtere
          </Button>
        ) : (
          ''
        )}
      </div>

      {patients?.data?.length > 0 && (
        <div className='table-wrapper'>
          <PatientsTable
            key={tableKey}
            setReset={setReset}
            data={tableData}
            deactivate={deactivatePatient}
            activate={activatePatient}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            title='User'
            editPath='/admin/concord/edit-patient/'
            viewPath='/admin/view-user/'
          />
        </div>
      )}
      {patients?.data?.length === 0 && (
        <h2 style={{ color: '#4dc1be', marginTop: '2rem', textAlign: 'center' }}>NEMA PACIJENATA</h2>
      )}
    </div>
  );
};

export default Patients;
