import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Button, Col, notification, Row } from 'antd';
import { CityTree, CityForm } from '../../components/forms';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { UserContext } from '../../App';
import { LoadingOutlined } from '@ant-design/icons';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Cities = (props) => {
  const { language } = useContext(UserContext);
  const [cities, setCities] = useState([]);
  const [edit, setEdit] = useState({ visible: false, city: {}, parent: {} });
  const [treeData, setTreeData] = useState([]);
  const { serviceId } = props.match.params;

  const getCities = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/cities`, headers);
      setCities(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (serviceId && cities.data.length > 0) {
      const selectedCity = cities.data.find((c) => c._id === serviceId);
      setEdit({
        visible: true,
        city: selectedCity || {},
        parent: selectedCity ? selectedCity.parent : {},
      });
    }
  }, [serviceId, cities.data]);

  useEffect(() => {
    let data = [];
    data = getTreeFromFlatData({
      flatData: cities?.data?.map((node) => ({ ...node, title: node.title })),
      getKey: (node) => node._id, // resolve a node's key
      getParentKey: (node) => node.parent, // resolve a node's parent's key
      rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
    });
    data =
      data.length > 0
        ? data.map((data) => ({
          ...data,
          expanded:
            data.children && data.children.length > 0 && data.children.some((child) => child._id === serviceId),
        }))
        : [];
    setTreeData(data);
  }, [cities, serviceId]);

  const deleteHandler = async (id) => {
    try {
      const { data: { message } } = await Axios.delete(`${SERVER_URL}/cities/${id}`, headers);
      getCities();
      setEdit({ visible: false, city: {}, parent: {} });
      notification.success({ message, placement: 'bottomRight' });
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const editHandler = (_, id) => {
    if (id) {
      setEdit({ visible: true, city: cities.data.find((c) => c._id === id), parentNode: id });
    } else {
      setEdit({ visible: true, city: cities.data.find((c) => c._id === id), parentNode: id });
    }
  };

  const onSubmit = async (data, isNew) => {
    if (edit.parentNode.length) {
      isNew = false;
    }

    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/cities` : `${SERVER_URL}/cities/${data._id}`;

    try {
      const { data: { message } } = await Axios[method](route, data, headers);
      getCities();
      setEdit({ visible: false, city: {}, parent: {} });
      notification.success({ message, placement: 'bottomRight' });
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  return (
    <>
      <div className='category-action-container'>
        <Row type='flex' gutter={8}>
          <Col xs={24} md={24}>
            <div className='actions-block'>
              <Button
                className='add-specialization'
                onClick={() => setEdit({ visible: true, city: {}, parentNode: {} })}
              >
                Novi grad
              </Button>
            </div>
          </Col>
        </Row>

        <Row type='flex' gutter={8}>
          <Col xs={24} md={24}>
            <div className='panel panel-primary' style={{ textAlign: 'center', padding: '8px', marginBottom: '10px' }}>
              {cities.isLoading && <div style={{ justifyContent: "center", display: 'flex', margin: "5rem" }}>
                <LoadingOutlined spin style={{ fontSize: '3rem', }} />
              </div>}

              {!cities.isLoading && cities.data && cities.data.length > 0 && (
                <CityTree
                  treeData={treeData}
                  setTreeData={setTreeData}
                  token={currentuser?.token}
                  language={language && language.selected.code}
                  setEdit={setEdit}
                  editHandler={editHandler}
                  deleteHandler={deleteHandler}
                />
              )}

              {!cities.isLoading && cities.data && cities.data.length === 0 && <h2>NEMA GRADOVA</h2>}
            </div>
          </Col>
        </Row>
      </div>
      <div className={edit.visible && language ? 'create-new-item-form active' : 'create-new-item-form'}>
        <CityForm
          onSubmit={onSubmit}
          city={edit.city}
          token={currentuser?.token}
          language={language}
          setEdit={setEdit}
          SERVER_URL={SERVER_URL}
          fetchCities={cities}
        />
      </div>
    </>
  );
};

export default Cities;
