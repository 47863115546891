import React, { useState } from 'react';
import { Modal, Card, notification, Form, Input } from 'antd';
import axios from 'axios';
import { io } from "socket.io-client";

import { SERVER_URL, SOCKET_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem("user"));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const RemoveDoctorDeleteAppointments = ({ removeWorkDay, setRemoveWorkDay, deleteAppointments, doctor, revertChanges, mode }) => {
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState('');

  const accept = async () => {
    if (openPassword) {
      try {
        const { data } = await axios.post(`${SERVER_URL}/password-check/${user?.id}`, { password }, headers);
        if (data) {
          if (mode === 'remove_doctor') {
            let socket = io(SOCKET_URL, { path: '/socket.io' });
            socket.emit('remove-doctor-created', { doctor: removeWorkDay.doc, ordination: user.ordination[0] });
          }
          deleteAppointments();
        } else {
          notification.warn({
            message: "Pogrešna šifra",
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
        setPassword('');
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setOpenPassword(true);
    }
  };

  const dataLength = removeWorkDay.data.length;

  return (
    <Modal
      className='doctor-modal'
      visible={removeWorkDay.open}
      onCancel={() => {
        doctor && revertChanges(removeWorkDay.ordId);
        setRemoveWorkDay({ open: false, data: [], doc: '' });
        setOpenPassword(false);
        setPassword('');
      }}
      footer={null}
      width={500}
      centered
    >
      <Card
        title={`Brisanje ${dataLength} ${dataLength === 1 ? 'zakazanog' : dataLength < 5 ? 'zakazana' : 'zakazanih'} termina`}
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <p>{dataLength === 1 ? 'Nadjen je' : 'Nadjena su'} <b>{dataLength}</b> {dataLength === 1 ? 'zakazan termina' : 'zakazana termina'}. Ukoliko uklonite {doctor ? 'ordinaciju' : 'doktora iz ordinacije'}, izbrisaćete {dataLength === 1 ? 'termin' : 'termine'}. Da li želite da potvrdite akciju?</p>
        {removeWorkDay.data?.map(day => (
          <div key={day._id}>
            <p style={{ margin: '0' }}><b>{day.firstName} {day.lastName}</b> {new Date(day.startDate).toLocaleString('sr', 'RS')}</p>
          </div>
        ))}
        <br />
        {openPassword &&
          <div>
            <p>Kako biste potvrdili trajno brisanje svih termina, molim da ukucate Vašu šifru:</p>
            <Form.Item label='LOZINKA'>
              <Input type='password' onChange={e => setPassword(e.target.value)} />
            </Form.Item>
          </div>
        }
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button className='action-button' htmltype='submit' onClick={accept}>
            <img src='/images/save.svg' alt='accept' />
            <span>Potvrdi</span>
          </button>
          <button
            className='action-button delete-button'
            onClick={() => {
              doctor && revertChanges(removeWorkDay.ordId);
              setOpenPassword(false);
              setPassword('');
              setRemoveWorkDay({ open: false, data: [], doc: '' });
            }}
          >
            <img src='/images/delete.svg' alt='delete' />
            <span>Otkaži</span>
          </button>
        </div>
      </Card>
    </Modal>
  );
};

export default RemoveDoctorDeleteAppointments;