import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Row, Col, notification, Select, DatePicker } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
let upperBP = '',
  lowerBP = '';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

export default function EditPatient(props) {
  const [form] = Form.useForm();
  const { patientId } = props.match.params;
  const [patient, setPatient] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [ordinations, setOrdinations] = useState([]);
  const [cities, setCities] = useState([]);
  const [child, setChild] = useState(false);
  const [patientExists, setPatientExists] = useState(false);

  if (patient.bloodPressure) {
    upperBP = patient.bloodPressure.split('/')[0];
    lowerBP = patient.bloodPressure.split('/')[1];
  }

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  const getDoctors = useCallback(async () => {
    try {
      const doctorCall = await axios.get(`${SERVER_URL}/doctors`, headers);
      setDoctors(doctorCall.data.items);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, []);

  const getCities = async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const getPatient = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/patient/${patientId}`, headers);
      setPatient(data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, [patientId]);

  const getOrdinations = useCallback(async () => {
    try {
      const ordinationCall = await axios.get(`${SERVER_URL}/ordination`, headers);
      setOrdinations(ordinationCall.data.items);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, []);

  useEffect(() => {
    getPatient();
    getOrdinations();
    getDoctors();
    getCities();
  }, [getDoctors, getOrdinations, getPatient]);

  useEffect(() => {
    if (form.getFieldValue('dateOfBirth')) {
      isChild(form.getFieldValue('dateOfBirth'));
    }
  }, [form]);

  if (patient.patient && patient.patient.firstName) {
    patient.ordinations = patient.ordinations?.filter((ord) => ord.status === 'ACTIVE');
    patient.doctors = patient.doctors?.filter((doc) => doc.status === 'ACTIVE');
    form.setFieldsValue({
      ...patient.patient,
      dateOfBirth: patient.patient.dateOfBirth && moment(patient.patient.dateOfBirth),
      email: patient.patient.userReference?.email,
      ordination: patient.patient.ordinations?.map((ord) => ord._id),
      doctor: patient.patient.doctors.map((doc) => doc._id),
      parentFirstName: patient.patient.parent?.firstName,
      parentLastName: patient.patient.parent?.lastName,
      parentEmail: patient.patient.parent?.email,
      parentPhone: patient.patient.parent?.phone,
    });
  }

  const onFinish = async (values) => {
    const inputValues = {};
    Object.keys(values).forEach((val, key) => {
      inputValues[val] = values[val];
    });
    if (upperBP && lowerBP) {
      inputValues.bloodPressure = `${upperBP}/${lowerBP}`;
    } else {
      inputValues.bloodPressure = '';
    }
    inputValues.patientId = patientId;
    inputValues.child = child;
    inputValues.doctors = inputValues.doctor;
    delete inputValues.doctor;

    try {
      const { data } = await axios.put(`${SERVER_URL}/patient/${patientId}`, inputValues, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      getPatient();
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const checkEmail = async (e) => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/patient/email/${patient._id}`, { email: e }, headers);
      if (data.length) {
        if (e !== '') {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5
          });
          setPatientExists(true);
        }
      } else {
        setPatientExists(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='edit-pacijenta-profil'>
      <div className='patient-profile'>
        <div className='card-container'>
          <div className='card-content'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                <Card title={`Pacijent ${patient.patient?.firstName} ${patient.patient?.lastName}`} bordered={false}>
                  <Form onFinish={(values) => onFinish(values)} onFinishFailed={onFinishFailed} form={form}>
                    <Row className='card-form patient-edit-form'>
                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                        <Form.Item
                          label='IME'
                          name='firstName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo Vas, unesite ime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label='PREZIME'
                          name='lastName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite prezime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='DATUM ROĐENJA'
                          name='dateOfBirth'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite datum rođenja!',
                            },
                          ]}
                        >
                          <DatePicker
                            showToday={false}
                            placeholder=''
                            style={{ width: '100%', border: 'none', borderBottom: '1px solid #e5e5e5' }}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => isChild(e)}
                          />
                        </Form.Item>

                        {child ? (
                          <>
                            <Form.Item
                              label='IME RODITELJA'
                              name='parentFirstName'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite ime!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label='PREZIME RODITELJA'
                              name='parentLastName'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite prezime!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label='EMAIL RODITELJA'
                              name='parentEmail'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite email!',
                                },
                              ]}
                              style={{ display: 'block' }}
                            >
                              <Input type='email' onChange={(e) => checkEmail(e.currentTarget.value)} />
                            </Form.Item>
                            <Form.Item
                              label='TELEFON RODITELJA'
                              name='parentPhone'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite telefon!',
                                },
                              ]}
                            >
                              <PhoneInput
                                style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              label='EMAIL'
                              name='email'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite email!',
                                },
                              ]}
                              style={{ display: 'block' }}
                            >
                              <Input type='email' />
                            </Form.Item>
                            <Form.Item
                              label='TELEFON'
                              name='phone'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite telefon!',
                                },
                              ]}
                            >
                              <PhoneInput
                                style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                              />
                            </Form.Item>
                          </>
                        )}
                        {/* <Form.Item label='ADRESA' name='address'>
                        <Input />
                      </Form.Item> */}
                        <Form.Item label='GRAD' name='city'>
                          <Select
                            showArrow
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city._id} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                        <Form.Item label='ORDINACIJE' name='ordination' className='panel-body'>
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {ordinations?.map((ord) => (
                              <Option key={ord._id} value={ord._id}>
                                {ord.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='DOKTORI' name='doctor' className='panel-body'>
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .join('')
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {doctors?.map((doc) => (
                              <Option key={doc._id} value={doc._id}>
                                {doc.title} {doc.firstName} {doc.lastName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='POL' name='gender'>
                          <Select>
                            <Option value='muški'>muški</Option>
                            <Option value='ženski'>ženski</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label='TELESNA TEŽINA' name='bodyWeight'>
                          <Input type='number' min={0} />
                        </Form.Item>
                        <Form.Item label='VISINA' name='height'>
                          <Input type='number' min={0} />
                        </Form.Item>
                        <Form.Item label='KRVNA GRUPA' name='bloodType'>
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value='0+'>0+</Option>
                            <Option value='0-'>0-</Option>
                            <Option value='A+'>A+</Option>
                            <Option value='A-'>A-</Option>
                            <Option value='B+'>B+</Option>
                            <Option value='B-'>B-</Option>
                            <Option value='AB+'>AB-</Option>
                            <Option value='AB-'>AB+</Option>
                          </Select>
                        </Form.Item>
                        {/* <Form.Item label='KRVNI PRITISAK' name='bloodPressure'>
                        <Input
                          type='number'
                          min={0}
                          style={{ width: '4.5rem' }}
                          value={upperBP}
                          onChange={(e) => (upperBP = e.currentTarget.value)}
                        />{' '}
                        /
                        <Input
                          type='number'
                          min={0}
                          style={{ width: '4.5rem' }}
                          value={lowerBP}
                          onChange={(e) => (lowerBP = e.currentTarget.value)}
                        />
                      </Form.Item>
                      <Form.Item label='ŠEĆER U KRVI' name='bloodSugar'>
                        <Input type={'number'} />
                      </Form.Item> */}
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 1 }} className='dz'>
                        <Form.Item style={{ display: 'block' }} label='ALERGIJE' name='allergiesNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item style={{ display: 'block' }} label='NASLEDNE BOLESTI' name='inheritedDiseaseNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                        {/* <Form.Item style={{ display: 'block' }} label='IMUNIZACIJE' name='immunizationsNote'>
                        <TextArea rows={4} />
                      </Form.Item> */}
                      </Col>

                      {/* <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 0 }}>
                      <Form.Item style={{ display: 'block' }} label='TERAPIJE U TOKU' name='therapiesNote'>
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col> */}
                    </Row>

                    <Row className='new-patient-button-row'>
                      <Col xs={{ span: 12, offset: 12 }}>
                        <Form.Item>
                          {!patientExists && (
                            <button style={{ float: 'right' }} htmltype='submit' className='action-button border-dark'>
                              <img src='/images/save.svg' alt='save' />
                              <span>Potvrdi</span>
                            </button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
