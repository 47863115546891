import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { message } from 'antd';
import { SERVER_URL } from '../config';
import Footer from '../components/Footer';

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Register = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleRegister(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleRegister = async (data) => {
    try {
      const ordination = await Axios.post(`${SERVER_URL}/register?type=ordination`, { ...data });
      if (ordination) {
        form.resetFields();
        message.info(ordination.data.message, 3);
      }
    } catch (err) {
      console.log(err);
      console.log('\n Login error = ', err.response.data.message);
      return message.warning(err.response.data.message, 5);
    }
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img src='/images/logo-mojlekar.svg' alt='Aceso Logo' className='logo-image' />
        <h1 className='welcome'>Dobrodošli !</h1>
        <h3 className='registration-title'>Registracija</h3>
        <Card className='login-header' bordered={false}>
          <Form
            form={form}
            {...layout}
            name='basic'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className='error-message'
          >
            <Form.Item name='name' rules={[{ required: true, message: 'Molimo Vas unesite Vaše ime!' }]}>
              <Input
                placeholder='Naziv ordinacije'
                prefix={<img className='input-icon' src='./images/user-icon.svg' alt='letter icon' />}
              />
            </Form.Item>
            <Form.Item name='website' rules={[{ required: true, message: 'Molimo Vas unesite Vaš websajt!' }]}>
              <Input
                placeholder='Websajt'
                prefix={<img className='input-icon' src='./images/user-icon.svg' alt='letter icon' />}
              />
            </Form.Item>
            <Form.Item name='email' rules={[{ required: true, message: 'Molimo Vas unesite Vašu email adresu!' }]}>
              <Input
                placeholder='Email'
                prefix={<img className='input-icon' src='./images/letter.png' alt='letter icon' />}
              />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Molimo Vas unesite Vašu lozinku!' }]}>
              <Input.Password
                placeholder='Lozinka'
                prefix={<img className='input-icon' src='./images/padlock.png' alt='padlock icon' />}
              />
            </Form.Item>

            <Form.Item name='city' rules={[{ required: true, message: 'Molimo Vas unesite Vaš grad!' }]}>
              <Input
                placeholder='Grad'
                prefix={<img className='input-icon' src='./images/city-icon.svg' alt='letter icon' />}
              />
            </Form.Item>

            <Form.Item name='country' rules={[{ required: true, message: 'Molimo Vas unesite naziv države!' }]}>
              <Input
                placeholder='Država'
                prefix={<img className='input-icon' src='./images/flag-icon.svg' alt='letter icon' />}
              />
            </Form.Item>

            <div className='user-link'>
              <Link to='/login'>
                <span>Već imate nalog? Prijavite se</span>
              </Link>
            </div>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className='login-button' htmlType='submit'>
                Registruj se
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
