import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Button, notification } from 'antd';
import Footer from '../components/Footer';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const layout = {
  wrapperCol: { span: 24 },
};

const ResetPassword = (props) => {
  const history = useHistory();
  const [userType, setUserType] = useState('');
  const token = props.match.params.token;

  const getUser = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/user/token/${token}`);
      if (data) {
        setUserType(data.userType);
      } else {
        setUserType('none');
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [token]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (userType === 'none') {
      notification.info({
        message: 'Link je nevažeći. Molimo Vas da ponovo pošaljete zahtev za restart šifre.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, [userType]);

  const checkPassword = (str) => str.length >= 4;

  const resetPassword = async (values) => {
    try {
      const message = <span>Lozinka mora imati najmanje 4 karaktera</span>;
      if (!checkPassword(values.password)) {
        notification.info({
          message,
          placement: 'bottomRight',
          duration: 2.5
        });
      } else if (values.password === values.confirmPassword) {
        const { data } = await Axios.post(`${SERVER_URL}/password-reset/${token}`, {
          password: values.password,
        });
        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
        setTimeout(() => history.push('/login'), 1000);
      } else {
        notification.warn({
          message: 'Lozinke moraju biti iste. Molim da ih proverite',
          placement: 'bottomRight',
          duration: 2.5
        });
      }
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img src='/images/logo-mojlekar.svg' alt='moj lekar logo' className='logo-image' />
        <h1 className='welcome'>{userType === 'doctor' ? 'Postavite Šifru' : 'Promena Šifre'}</h1>
        <h3 className='login-with'>
          {userType !== 'none'
            ? 'Unesite novu šifru i potvrdite je.'
            : 'Link je nevažeći. Molimo Vas da ponovo pošaljete zahtev za restart šifre.'}
        </h3>
        <Card className='login-header' bordered={false}>
          {userType !== 'none' ? (
            <Form
              {...layout}
              name='basic'
              onFinish={resetPassword}
              onFinishFailed={onFinishFailed}
              className='error-message'
            >
              <Form.Item name='password' rules={[{ required: true, message: 'Molimo Vas unesite Vašu lozinku!' }]}>
                <Input.Password
                  placeholder='Lozinka'
                  prefix={<img className='input-icon' src='/images/padlock.png' alt='padlock icon' />}
                />
              </Form.Item>

              <Form.Item
                name='confirmPassword'
                rules={[{ required: true, message: 'Molimo Vas unesite Vašu lozinku!' }]}
              >
                <Input.Password
                  placeholder='Potvrdite lozinka'
                  prefix={<img className='input-icon' src='/images/padlock.png' alt='padlock icon' />}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button className='login-button' htmlType='submit'>
                  {userType === 'doctor' ? 'Postavite šifru' : 'Restartuj šifru'}
                </Button>
              </Form.Item>

              <Link className='user-link' style={{ color: 'whitesmoke' }} to='/login'>
                Ulogujte se
              </Link>
            </Form>
          ) : (
            <Button className='login-button'>
              <Link style={{ textDecoration: 'none' }} to='/forgot-password'>
                Restart šifre
              </Link>
            </Button>
          )}
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
