import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Card, Row, Col, notification, Select, Checkbox } from 'antd';
import UploadBox from '../../components/base/UploadBox';
import { GalleryModal } from '../../components/forms';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { DownOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const NewOrdination = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [image, setImage] = useState();
  const [categories, setCategories] = useState();
  const [cities, setCities] = useState();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const currentuser = useContext(UserContext);
  const [ordinationExists, setOrdinationExists] = useState(false);
  const [phone, setPhone] = useState();
  const [cords, setCords] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [timerID, setTimerID] = useState(null);
  const [visibility, setVisibility] = useState();

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ avatar: values });
    setImage(values);
  };

  const setupAddress = (search, cb) => {
    clearTimeout(timerID);
    const timeout = setTimeout(() => geolocation(search, cb), 700);
    setTimerID(timeout);
  };

  const fetchFormCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCitites = async () => {
    try {
      const cityCall = await Axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCitites();
    fetchFormCategories();
  }, []);

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    form.setFieldsValue({ avatar: null });
    setImage(null);
  };

  const checkPassword = (str) => {
    return str.length >= 4;
  };

  const onFinish = async (values) => {
    try {
      if (cords) {
        values.cords = cords;
      }
      if (visibility) {
        values.visibility = visibility;
      }
      if (values.address) values.address = values.address.label;
      const message = <span>Lozinka mora imati najmanje 4 karaktera</span>;
      if (!checkPassword(values.password)) {
        notification.info({
          message,
          placement: 'bottomRight',
          duration: 2.5
        });
      } else {
        const ordination = await Axios.post(`${SERVER_URL}/register?type=ordination`, values);
        if (ordination.status === 201) {
          notification.success({
            message: 'Ordinacija i korisnik su kreirani.',
            placement: 'bottomRight',
            duration: 2.5
          });
          form.resetFields();
          setTimeout(() => history.push('/admin/concord/ordinations'), 500);
          setImage();
        }
      }
    } catch (err) {
      if (err.reponse) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      console.log(err.message);
    }
  };

  const onFinishFailed = (err) => {
    console.log(err);
  };

  const checkEmail = async (e) => {
    try {
      const check = await Axios.get(`${SERVER_URL}/ordination/email/${e}`, headers);
      if (check.data.length) {
        if (e !== '') {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5
          });
          setOrdinationExists(true);
        }
      } else {
        setOrdinationExists(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const geolocation = async (input, callback) => {
    try {
      if (input !== '') {
        const { data } = await Axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=${process.env.REACT_APP_MAP_KEY}`,
        );

        const formatData = data.results.map(r => ({
          label: r.formatted_address,
          value: `${r.geometry.location.lat}, ${r.geometry.location.lng}`
        }));

        setRecommendations(formatData);
        callback(formatData);
      }
    } catch (error) {
      notification.info({
        message: "Precizirajte adresu bolje. Unesite grad ili broj ulice.",
        placement: "bottomRight",
        duration: 2.5
      });
    }
  };

  return (
    <div className='kreiranje-klinike'>
      <div className='profile-container'>
        <div className='ordination-form-container'>
          <div className='ordination-form-wrapper wide'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                {/* eslint-disable-next-line no-useless-concat */}
                <Card title='Kreiraj novu kliniku' bordered={false}>
                  <Form
                    name='basic'
                    onFinish={(values) => onFinish(values)}
                    onFinishFailed={onFinishFailed}
                    layout='horizontal'
                    form={form}
                    style={{ borderRadius: '13px' }}
                  >
                    <Row className='card-form'>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                        <Form.Item
                          style={{ marginLeft: '2px' }}
                          label='NAZIV'
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite naziv!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item label='ADRESA' name='address'>
                          <AsyncSelect
                            loadOptions={setupAddress}
                            defaultOptions={recommendations}
                            placeholder='Ulica broj, grad'
                            onChange={e => setCords(e.value)}
                            styles={{
                              control: base => ({
                                ...base,
                                minHeight: 30,
                                border: 0,
                                boxShadow: "none",
                                borderRadius: 0,
                                borderBottom: '1px solid #e5e5e5',
                              }),
                              input: base => ({
                                ...base,
                                color: '#627b90',
                                fontWeight: 'bold'
                              }),
                              singleValue: provided => ({
                                ...provided,
                                color: '#627b90',
                                fontWeight: 'bold'
                              }),
                              menu: base => ({
                                ...base,
                                marginTop: '0rem',
                                borderRadius: 0
                              })
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: (props) =>
                                <components.DropdownIndicator {...props}>
                                  <DownOutlined />
                                </components.DropdownIndicator>
                            }}
                          />
                        </Form.Item>

                        <Form.Item label='GRAD' name='city' className='panel-body'>
                          <Select
                            showArrow
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city._id} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='DRŽAVA' name='country'>
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='TELEFON'
                          name='phone'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite telefon!',
                            },
                          ]}
                        >
                          <PhoneInput
                            style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}

                            international
                            defaultCountry='RS'
                            countryCallingCodeEditable={false}
                            value={phone}
                            onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                          />
                        </Form.Item>

                        <Form.Item
                          label='EMAIL'
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite email!',
                            },
                          ]}
                        >
                          <Input type='email' onChange={(e) => checkEmail(e.currentTarget.value)} />
                        </Form.Item>

                        <Form.Item label='WEBSAJT' name='website'>
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='LOZINKA'
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite lozinku!',
                            },
                          ]}
                        >
                          <Input type='password' />
                        </Form.Item>

                        <Form.Item
                          label='OBLAST RADA'
                          name='category'
                          className='panel-body'
                          rules={[{ required: true, message: 'Obavezno odaberite specijalizaciju!' }]}
                        >
                          <Select
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {categories?.map((cat) => (
                              <Option key={cat._id} value={cat._id}>
                                {cat.name.sr}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='O KLINICI' name='description'>
                          <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item label='VIDLJIVOST' name='visibility'>
                          <Checkbox onChange={() => setVisibility(!visibility)} />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                        <Form.Item className='upload-wrapper right' name='avatar'>
                          <UploadBox
                            editHandler={avatarHandler}
                            deleteHandler={deleteAvatarHandler}
                            image={image}
                            index={0}
                            name='avatar'
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className='ordination-buttons' style={{ justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          {!ordinationExists && (
                            <button className='action-button border-dark' htmltype='submit' style={{ float: 'right' }}>
                              <img src='/images/save.svg' alt='save' />
                              <span>Kreiraj</span>
                            </button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>

                {modal.visible && (
                  <GalleryModal
                    visible={modal.visible}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageSavePath='public/images/users/'
                    imageType='data'
                    imageHeight={200}
                    SERVER_URL={SERVER_URL}
                    token={currentuser.data.token}
                    form={form}
                    onCancel={() => setModal({ ...modal, visible: false })}
                    onInsert={(values) => onInsert(values, modal.formKey)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrdination;
