import React, { useContext, useEffect, useState, useCallback } from 'react';
import { NotificationTable } from '../../components/tables';
import Axios from 'axios';
import { SidebarContext } from '../../App';
import { SERVER_URL, SOCKET_URL } from '../../config';
import moment from 'moment';
import { io } from 'socket.io-client';
import { Card, Modal } from 'antd';

let id, type;
const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

export default function Notifications({ notificationEvents }) {
  const [notifications, setNotifications] = useState([]);
  const [message, setMessage] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const { sidebarContext } = useContext(SidebarContext);
  let [index, setIndex] = useState(0);

  if (user?.userType === 'doctor') {
    id = user?.doctor;
    type = 'doctor';
  } else if (user?.userType === 'ordination') {
    id = user?.ordination[0];
    type = 'ordination';
  }

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/notifications-doctor-ordination?id=${id}&type=${type}`, headers);
      setNotifications(data);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, notificationEvents]);

  useEffect(() => {
    let tableContent = document.querySelector(`.scroll-row-${index}`);
    let observer = new IntersectionObserver(intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (tableContent) observer.observe(tableContent);
  });

  useEffect(() => {
    let readType = `read${type.charAt(0).toUpperCase() + type.slice(1)}`;
    let initialIndex = notifications.findIndex((item) => item[readType] === false);
    setIndex(initialIndex);
  }, [notifications]);

  const intersectionCallback = async (entries) => {
    const offset = 15;
    let readType = `read${type.charAt(0).toUpperCase() + type.slice(1)}`;

    if (entries[0].isIntersecting && notifications.findIndex((item) => item[readType] === false) !== -1) {
      const call = await Axios.get(`${SERVER_URL}/notifications-doctor-ordination?id=${id}&type=${type}`, headers);
      let notifications = call.data;
      let startIndex = notifications.findIndex((item) => item[readType] === false);
      let ids = notifications.slice(startIndex, startIndex + offset).map((item) => item._id);
      let numberOfUnread = await Axios.put(
        `${SERVER_URL}/notifications-set-read?readType=${readType}&id=${id}&type=${type}`,
        ids,
        headers,
      );
      if (numberOfUnread.data.length < offset) {
        await Axios.put(
          `${SERVER_URL}/notifications-set-read`,
          numberOfUnread.data.map((item) => item._id),
          headers,
        );
      } else {
        setIndex(index + offset);
      }
      await sidebarContext.getNumberOfUnreadNotifications();
    }
  };

  const newNotification = useCallback(async () => {
    let readType = `read${type.charAt(0).toUpperCase() + type.slice(1)}`;
    const notifications = await Axios.get(
      `${SERVER_URL}/notifications-doctor-ordination?id=${id}&type=${type}`,
      headers,
    );
    setNotifications(notifications.data);
    let startIndex = [...notifications.data].findIndex((item) => item[readType] === false);
    setIndex(startIndex);
    await sidebarContext.getNumberOfUnreadNotifications();
  }, [sidebarContext]);

  useEffect(() => {
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    if (type === 'doctor') {
      socket.emit('user-connected', user?.doctor);
      socket.on('new-notification-doctor-received', async (data) => {
        const { doctor, patient, ordination, startDate, requestedBy } = data;
        const dateOfBirth = patient.dateOfBirth ? `(${new Date(patient.dateOfBirth).toUTCString().substring(5, 16)})` : '';
        const messageType = data.message;
        setVisibleModal(true);
        switch (messageType) {
          case 'CHANGED_TERM':
            setMessage(
              `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je pomeren termin ${type === 'doctor'
                ? `u ordinaciji ${ordination?.name}`
                : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
              } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
            );
            break;
          case 'SCHEDULE_TERM':
            setMessage(
              `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazao termin ${type === 'doctor'
                ? `u ordinaciji ${ordination?.name}`
                : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
              } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
            );
            break;
          case 'CANCEL_TERM':
            if (requestedBy === 'patient') {
              setMessage(
                `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazao termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {

              let notificationOwner;
              if (requestedBy === 'doctor') {
                notificationOwner = doctor?.firstName + ' ' + doctor?.lastName;
              } else if (requestedBy === 'ordination') {
                notificationOwner = ordination?.name;
              }

              setMessage(
                `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazan termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName} od strane ${notificationOwner}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            }
            break;
          default:
            break;
        }
        await newNotification();
      });
    } else if (type === 'ordination') {
      socket.emit('user-connected', user?.ordination[0]);
      socket.on('new-notification-ordination-received', async (data) => {
        const { doctor, patient, ordination, startDate, requestedBy } = data;
        const dateOfBirth = patient.dateOfBirth ? `(${new Date(patient.dateOfBirth).toUTCString().substring(5, 16)})` : '';
        const messageType = data.message;
        setVisibleModal(true);
        switch (messageType) {
          case 'CHANGED_TERM':
            if (requestedBy === 'patient') {
              setMessage(
                `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je promenio termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
              setMessage(
                `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je pomeren termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            }
            break;
          case 'SCHEDULE_TERM':
            if (requestedBy === 'patient') {
              setMessage(
                `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazao termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
              setMessage(
                `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazan termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            }
            break;
          case 'CANCEL_TERM':
            if (requestedBy === 'patient') {
              setMessage(
                `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazao termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            } else {
              let notificationOwner;
              if (requestedBy === 'doctor') {
                notificationOwner = doctor?.firstName + ' ' + doctor?.lastName;
              } else if (requestedBy === 'ordination') {
                notificationOwner = ordination?.name;
              }

              setMessage(
                `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazan termin ${type === 'doctor'
                  ? `u ordinaciji ${ordination?.name}`
                  : `kod doktora ${doctor?.firstName} ${doctor?.lastName} od strane ${requestedBy !== 'admin' ? notificationOwner : 'admina'}`
                } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
              );
            }
            break;
          default:
            break;
        }
        await newNotification();
      });
    }
  }, [newNotification]);

  const columnKeysTranslated = ['Poruka', 'Kreirano', 'Ažurirano'];
  const columnKeys = ['message', 'created', 'updated'];

  let tableData = [];
  if (notifications && notifications.length > 0) {
    tableData = notifications.map((item, i) => {
      const dateOfBirth = item.patient?.dateOfBirth ? `(${new Date(item.patient?.dateOfBirth).toUTCString().substring(5, 16)})` : '';
      let message;
      switch (item.message) {
        case 'ADD_DOCTOR_TO_ORDINATION':
          message = <div key={i}>Ordinacija {item.ordination?.name} želi da doda doktora {item.doctor?.firstName} {item.doctor?.lastName}</div>;
          break;
        case 'ADD_ORDINATION_TO_DOCTOR':
          message = <div key={i}>Doktor {item.doctor?.firstName} {item.doctor?.lastName} želi da doda ordinaciju {item.ordination?.name}</div>;
          break;
        case "OVERLAP_SHEDULE_TERM":
          message = <div key={i}>
            {item.requestedBy !== 'patient' ? "Pacijentu" : "Pacijent"} {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je {item.requestedBy !== 'patient' ? "zakazan" : "zakazao"} termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}
            {item.requestedBy === 'doctor' || item.requestedBy === 'patient' ? <b> Duplirani termin</b> : ''}
          </div>;
          break;
        case "OVERLAP_CHANGED_TERM":
        case "OVERLAP_PROLONGUE_TERM":
          message = <div key={i}>
            Pacijentu {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je pomeren termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}
            {item.requestedBy === 'doctor' ? <b> Duplirani termin</b> : ''}
          </div>;
          break;
        case "CHANGED_TERM":
        case "PROLONGUE":
          if (item.requestedBy === 'patient') {
            message = <div key={i}>Pacijent {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je promenio termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } za {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}</div>;
          } else if (item.requestedBy === 'doctor' || item.requestedBy === 'ordination') {
            message = <div key={i}>Pacijentu {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je pomeren termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}</div>;
          }
          break;
        case 'SCHEDULE_TERM':
          if (item.requestedBy === 'patient') {
            message = <div key={i}>Pacijent {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je zakazao termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}</div>;
          } else if (item.requestedBy === 'doctor' || item.requestedBy === 'ordination') {
            message = <div key={i}>Pacijentu {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je zakazan termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}</div>;
          }
          break;
        case 'CANCEL_TERM':
          if (item.requestedBy === 'patient') {
            message = <div key={i}>Pacijent {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je otkazao termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')}</div>;
          } else {
            let notificationOwner;
            if (item.requestedBy === 'doctor') {
              notificationOwner = item.doctor?.firstName + ' ' + item.doctor?.lastName;
            } else if (item.requestedBy === 'ordination') {
              notificationOwner = item.ordination?.name;
            }

            message = <div key={i}>Pacijentu {item.patient?.firstName} {item.patient?.lastName} {dateOfBirth} je otkazan termin {type === 'doctor'
              ? `u ordinaciji ${item.ordination?.name}`
              : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } {moment(item.startDate).format('DD MMMM YYYY, HH:mm')} od strane {notificationOwner}</div>;
          }
          break;
        default:
          break;
      }

      return {
        message,
        created: moment(item.createdAt).format('DD/MM/YY, HH:mm'),
        updated: moment(item.updatedAt).format('DD/MM/YY, HH:mm'),
      };
    });
  }

  return (
    <div className='doctors-table'>
      <div className='doctors-container'>
        {notifications && (
          <NotificationTable
            data={tableData}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            readonly={false}
          />
        )}
        <Modal
          className='doctor-modal'
          visible={visibleModal}
          onCancel={() => setVisibleModal(false)}
          footer={null}
          width={500}
          he
          centered
        >
          <Card title='Nova notifikacija' bordered={false} className='calendar-data-form-card date-change'>
            <div className='date-change-container'>
              <p>{message}</p>
              <button className='action-button' onClick={() => setVisibleModal(false)}>
                <img src='/images/save.svg' alt='accept' />
                <span>Potvrdi</span>
              </button>
            </div>
          </Card>
        </Modal>
      </div>
    </div>
  );
}
