import { Modal, Card } from 'antd';
import React from 'react';

const RemoveWorkDayModal = ({ deleteAppointments, removeAppointments, setRemoveAppointments, appointments }) => {
  const formatDay = appointments.day === 'sreda' || appointments.day === 'subota' || appointments.day === 'nedelja' ? appointments.day?.slice(0, -1) + 'u' : appointments.day;

  const dataLength = appointments.data?.length;

  return (
    <Modal
      className='doctor-modal'
      visible={removeAppointments}
      onCancel={() => setRemoveAppointments(false)}
      footer={null}
      width={500}
      centered
    >
      <Card
        title={`Brisanje zakazanih termina za ${formatDay}`}
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <p>{dataLength === 1 ? 'Nadjen je' : 'Nadjena su'} <b>{dataLength}</b> {dataLength === 1 ? 'zakazan termina' : 'zakazana termina'} za <b>{formatDay}</b>. Ukoliko uklonite radno vreme, izbrisaćete {dataLength > 1 ? 'termin' : 'termine'}. Da li želite da potvrdite akciju?</p>
        {appointments.data?.map(day => (
          <div key={day._id}>
            <p style={{ margin: '0' }}><b>{day.firstName} {day.lastName}</b> {new Date(day.startDate).toLocaleString('sr', 'RS')}</p>
          </div>
        ))}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button className='action-button' htmltype='submit' onClick={deleteAppointments}>
            <img src='/images/save.svg' alt='accept' />
            <span>Potvrdi</span>
          </button>
          <button className='action-button delete-button' onClick={() => setRemoveAppointments(false)}>
            <img src='/images/delete.svg' alt='delete' />
            <span>Otkaži</span>
          </button>
        </div>
      </Card>
    </Modal>
  );
};

export default RemoveWorkDayModal;