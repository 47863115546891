import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Input, Button, Card, Result, Select, Row, Col, notification, Popconfirm } from 'antd';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import SignatureCanvas from 'react-signature-canvas';
import { io } from 'socket.io-client';

import { GalleryModal } from './';
import UploadBox from '../base/UploadBox';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { RemoveDoctorDeleteAppointments, AddOrdinationPrompt } from '../modals';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

let doctorProfile;
const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const DoctorProfileForm = ({
  data,
  updateDoctorProfileHandler,
  categories,
  deleteDoctorProfileHandler,
  result,
  setResult,
  token,
  language,
  readonly,
}) => {
  const canvas = useRef({});
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [ordinations, setOrdinations] = useState([]);
  const [cities, setCities] = useState([]);
  const [doctorExists, setDoctorExists] = useState(false);
  const [removeWorkDay, setRemoveWorkDay] = useState({ open: false, data: [] });
  const [workDayOverlap, setWorkDayOverlap] = useState({
    open: false, data: [], added: ''
  });

  if (data) {
    doctorProfile = {
      firstName: data.firstName,
      lastName: data.lastName,
      name: `${data.title || 'dr'} ${data.firstName} ${data.lastName}`,
      title: data.title,
      city: data.city.map((c) => c),
      category: data.category.map((cat) => cat._id),
      categoryName: data.category.map((cat) => cat.name.sr),
      about: data.about,
      ordination: data.ordination.map((ord) => ord._id),
      viewServices: data.services.map((service) => service.title),
      services: data.services.map((service) => service._id),
      phone: data.phone[0],
      email: data.userReference?.email,
      typeOfWork: data.typeOfWork,
      workingHours: data.workingHours,
      password: data.password,
      avatar: data.avatar,
      license: data.license,
    };
  }

  useEffect(() => {
    if (data) {
      canvas.current.fromDataURL(data.signature);
    }
  }, [data]);

  const isNew = data ? false : true;

  const getCities = async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const getOrdinations = useCallback(async () => {
    try {
      const ordinations = await axios.get(`${SERVER_URL}/ordination`, headers);
      setOrdinations(ordinations.data.items);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    if (data && data.avatar) {
      setImage(data.avatar);
    } else {
      setImage(null);
    }
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    getOrdinations();
    getCities();
  }, [getOrdinations]);

  const onFinish = async (values) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (userType === 'doctor') {
      const newUser = {
        ...user,
        ordination: values.ordination,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      sessionStorage.setItem('user', JSON.stringify(newUser));
    }

    try {
      values.avatar = image;
      values.signature = canvas.current.toDataURL();
      updateDoctorProfileHandler(values, data._id);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed in DoctorProfileForm:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ avatar: values });
    setImage(values);
  };

  const deactivateDoctor = () => {
    deleteDoctorProfileHandler(doctorProfile.email);
    sessionStorage.clear();
    setTimeout(() => (window.location.href = '/login'), 700);
  };

  const removeGoogleAuth = async () => {
    try {
      const { data } = await axios.delete(
        `${SERVER_URL}/calendar/auth-google?doc=${currentuser?.doctor}&type=doctor`,
        headers,
      );
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      setTimeout(() => window.location.reload(), 800);
    } catch (error) {
      console.log(error.message);
    }
  };

  const checkEmail = async (e) => {
    try {
      const check = await axios.get(`${SERVER_URL}/doctor/email/${e}`, headers);
      if (check.data.length) {
        if (e !== '') {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5,
          });
          setDoctorExists(true);
        }
      } else {
        setDoctorExists(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetData = () => {
    form.resetFields();
    canvas.current.fromDataURL(data.signature);
    notification.info({
      message: 'Promene su poništene',
      placement: 'bottomRight',
      duration: 2.5,
    });
  };

  const forgotPassword = async () => {
    try {
      const sendPasswordLink = await axios.post(
        `${SERVER_URL}/forgot-password`,
        { email: currentuser?.email },
        { withCredentials: false },
      );
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const handleSignatureImage = async (e) => {
    const base64 = await getBase64(e.target.files[0]);
    canvas.current.fromDataURL(base64);
  };

  // before removing ordination check whether it has appointments
  const beforeRemove = async (ordId) => {
    try {
      const appointments = await axios.get(`${SERVER_URL}/appointments/day?doc=${data._id}&ord=${ordId}&remove=true`, headers);
      if (appointments.data.length) {
        setRemoveWorkDay({ open: true, data: appointments.data, ordId });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // if doctor cancels the prompt, revert the changes
  const revertChanges = (ordId) => {
    const ordIds = form.getFieldValue('ordination');
    ordIds.push(ordId);
    form.setFieldsValue({ ordination: ordIds });
  };

  // if doctor accepts the prompt, delete appointments + notification
  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      sendDeleteNotification(removeWorkDay);
      setRemoveWorkDay({ open: false, data: [], doc: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: 'doctor',
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-ordination-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });
    }
  };

  // before adding an ordination, check his work time with other ordinations
  // to prevent time overlap
  const beforeSelection = async (ordId) => {
    try {
      const postData = {
        current: form.getFieldValue('ordination').filter(ord => ord !== ordId),
        added: ordId,
        docId: currentuser.doctor
      };
      const { data } = await axios.post(`${SERVER_URL}/check/workingDay`, postData, headers);
      if (data.workDayOverlap.length || data.singleDayOverlap.length) {
        setWorkDayOverlap({ open: true, data, added: ordId });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // if doctor cancels the prompt, remove the added ordination
  const removeOrdination = (ordId) => {
    const ordIds = form.getFieldValue('ordination');
    ordIds.splice(ordIds.findIndex(ord => ord === ordId), 1);
    form.setFieldsValue({ ordination: ordIds });
  };

  return (
    <div className='doktor-profil-forma'>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title={`${doctorProfile ? doctorProfile.name : ''}`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={doctorProfile}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
                style={{ borderRadius: '13px' }}
              >
                <Row className='card-form'>
                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                    <Form.Item
                      label='IME'
                      name='firstName'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesti ime!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label='PREZIME'
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesti prezime!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label='EMAIL'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Obavezno upišite email!',
                        },
                      ]}
                    >
                      <Input type='email' onChange={(e) => checkEmail(e.currentTarget.value)} />
                    </Form.Item>

                    <Form.Item label='TELEFON' name='phone'>
                      <PhoneInput
                        style={{ display: 'flex', alignItems: 'center', padding: '0px', paddingLeft: '6px' }}
                        className='ant-input'
                        international
                        defaultCountry='RS'
                        countryCallingCodeEditable={false}
                        value={doctorProfile && doctorProfile.phone}
                      />
                    </Form.Item>

                    <Form.Item label='TITULA' name='title' placeholeder='Lekar'>
                      <Input />
                    </Form.Item>

                    {userType === 'ordination' ? (
                      <></>
                    ) : (
                      <Form.Item label='ORDINACIJA' name='ordination' className='panel-body'>
                        {userType === 'doctor' ? (
                          <Select
                            onDeselect={beforeRemove}
                            onSelect={beforeSelection}
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz'),
                                ) >= 0
                            }
                          >
                            {ordinations?.map((ord) => (
                              <Option key={ord._id} value={ord._id}>
                                {ord.name}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Select mode='multiple' open={false} bordered={false} removeIcon={false}>
                            {ordinations?.map((ord) => {
                              return (
                                <Option key={ord._id} value={ord._id}>
                                  {ord.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    )}

                    <Form.Item
                      label='GRAD'
                      name='city'
                      rules={[
                        {
                          required: true,
                          message: 'Obavezno upišite grad!',
                        },
                      ]}
                    >
                      {/* CHECK BORDER */}
                      {userType === 'doctor' ? (
                        <Select
                          mode='multiple'
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .replace(/ć/g, 'c')
                              .replace(/č/g, 'c')
                              .replace(/đ/g, 'dj')
                              .replace(/š/g, 's')
                              .replace(/ž/g, 'z')
                              .replace(/dž/g, 'dz')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz'),
                              ) >= 0
                          }
                        >
                          {cities?.map((city) => (
                            <Option key={city._id} value={city.title}>
                              {city.title}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Select bordered={false} open={false} mode='multiple' removeIcon={false}>
                          {cities?.map((city) => (
                            <Option disabled key={city._id} value={city.title}>
                              {city.title}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    {!readonly ? (
                      <Form.Item
                        label='OBLAST RADA'
                        name='category'
                        className='panel-body'
                        rules={[{ required: true, message: 'Obavezno odaberite specijalizaciju!' }]}
                      >
                        <Select
                          showArrow
                          showSearch
                          mode='multiple'
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .replace(/ć/g, 'c')
                              .replace(/č/g, 'c')
                              .replace(/đ/g, 'dj')
                              .replace(/š/g, 's')
                              .replace(/ž/g, 'z')
                              .replace(/dž/g, 'dz')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz'),
                              ) >= 0
                          }
                        >
                          {categories?.map((cat) => (
                            <Option key={cat._id} value={cat._id}>
                              {cat.name.sr}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item label='OBLAST RADA' name='categoryName' className='panel-body'>
                        <Select mode='multiple' bordered={false} open={false} removeIcon={false} />
                      </Form.Item>
                    )}

                    {userType === 'doctor' ? (
                      <Form.Item label='NAČIN RADA' name='typeOfWork' className='panel-body'>
                        <Select mode='multiple' showArrow={true}>
                          <Option value='Rad iz klinike'>Rad iz klinike</Option>
                          {/* <Option value='Izlazak na teren'>Izlazak na teren</Option> */}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item label='NAČIN RADA' name='typeOfWork' className='panel-body'>
                        <Select mode='multiple' bordered={false} open={false} removeIcon={false} />
                      </Form.Item>
                    )}

                    <Form.Item
                      label='BROJ LICENCE'
                      name='license'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite broj licence!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item label='PREDSTAVITE SE' name='about'>
                      <TextArea rows={3} />
                    </Form.Item>

                    <Form.Item label='POTPIS'>
                      <SignatureCanvas
                        onBegin={() => readonly && canvas.current.off()}
                        ref={canvas}
                        style={{ cursor: 'pointer' }}
                        canvasProps={{ width: 304, height: 100, className: 'signature-canvas' }}
                      />

                      {!readonly && (
                        <>
                          <p
                            onClick={() => canvas.current.fromDataURL(data.signature)}
                            style={{ float: 'right', cursor: 'pointer', userSelect: 'none', fontSize: '0.7rem' }}
                          >
                            Otkaži
                          </p>
                          <label
                            htmlFor='files'
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              userSelect: 'none',
                              margin: '0 0.5rem',
                              fontSize: '0.7rem',
                            }}
                          >
                            Uvezi
                          </label>
                          <input onChange={handleSignatureImage} id='files' style={{ display: 'none' }} type='file' />
                          <p
                            onClick={() => canvas.current.clear()}
                            style={{ float: 'right', cursor: 'pointer', userSelect: 'none', fontSize: '0.7rem' }}
                          >
                            Očisti
                          </p>
                        </>
                      )}
                    </Form.Item>
                  </Col>

                  <Col
                    //  className='right-profile-col'
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 4, offset: 2 }}
                  >
                    <Form.Item className='upload-wrapper right' name='avatar'>
                      {!readonly ? (
                        <UploadBox
                          editHandler={avatarHandler}
                          deleteHandler={deleteAvatarHandler}
                          image={image}
                          index={0}
                          name='avatar'
                        />
                      ) : (
                        <UploadBox
                          editHandler={() => console.log('LOL')}
                          deleteHandler={() => console.log('DOUBLE LOL')}
                          image={image}
                          index={0}
                          name='avatar'
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                {!readonly && (
                  <Row className='doctor-profile-buttons-row'>
                    <Col style={{ marginRight: 'auto' }}>
                      {!data?.google ? (
                        <a
                          href={`${SERVER_URL}/calendar/google?email=${currentuser?.email}&type=doctor`}
                          className='action-button border-dark sync'
                        >
                          <img src='/images/googleLogo.png' alt='google' />
                          <span> Sinhronizujte se sa Google kalendarom</span>
                        </a>
                      ) : (
                        <span
                          style={{ cursor: 'pointer' }}
                          className='action-button border-dark'
                          onClick={removeGoogleAuth}
                        >
                          <img src='/images/save.svg' alt='save' />
                          <span>Sinhronizovano</span>
                        </span>
                      )}
                      <Form.Item>
                        {!isNew && (
                          <Popconfirm
                            title='Da li stvarno želite da deaktivirate nalog?'
                            onConfirm={deactivateDoctor}
                            okText='Da'
                            cancelText='Ne'
                          >
                            <button className='delete-button top-space deactivate' style={{ cursor: 'pointer' }}>
                              <img src='/images/delete.svg' alt='delete' style={{ color: '#ff7373' }} />
                              <span>Deaktivirajte nalog</span>
                            </button>
                          </Popconfirm>
                        )}
                      </Form.Item>
                      <span
                        className='action-button border-dark change-password'
                        onClick={forgotPassword}
                        style={{ cursor: 'pointer' }}
                      >
                        <span style={{ width: '7rem' }}>
                          <span>Promenite šifru</span>
                        </span>
                      </span>
                    </Col>
                    <Col>
                      <Form.Item>
                        {!doctorExists && (
                          <button className='action-button border-dark' htmltype='submit'>
                            <img src='/images/save.svg' alt='save' />
                            <span>Potvrdi</span>
                          </button>
                        )}
                      </Form.Item>
                      <div className='delete-button top-space' onClick={resetData} style={{ cursor: 'pointer' }}>
                        <img src='/images/delete.svg' alt='delete' />
                        <span>Otkaži promene</span>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
            {result && (
              <Result
                title='Kreiran je profil doktora!'
                extra={
                  <Button type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token?.token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
      <RemoveDoctorDeleteAppointments
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        revertChanges={revertChanges}
        deleteAppointments={deleteAppointments}
        doctor={true}
      />
      <AddOrdinationPrompt
        workDayOverlap={workDayOverlap}
        setWorkDayOverlap={setWorkDayOverlap}
        removeOrdination={removeOrdination}
      />
    </div>
  );
};

export default DoctorProfileForm;
