import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import OrdinationForm from '../../components/forms/OrdinationForm';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { notification } from 'antd';
import useAxios from '../../hooks/useAxios';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const OrdinationProfile = (props) => {
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [formCategories, setCategories] = useState(undefined);
  const [showAddCategory, setShowAddCategory] = useState(false);

  //added from Category component
  const [ordination, setOrdination] = useState({});
  const [categories, fetchCategories] = useAxios('', [], currentuser?.token, 'get');
  const { categoryId } = props.match.params;

  const getOrdination = useCallback(async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/ordination/${currentuser?.ordination}`, headers);
      setOrdination(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    setShowAddCategory(false);
    fetchCategories(`${SERVER_URL}/categories`, []);
  }, [fetchCategories, categoryId, categories.data]);

  useEffect(() => {
    getOrdination();
  }, [getOrdination]);

  const createOrdination = async (data) => {
    try {
      let isError = false;
      if (isError === false) {
        const ordination = await Axios.post(`${SERVER_URL}/ordination`, { ...data }, headers);
        if (ordination.status === 201) {
          notification.success({
            message: 'Kreiran je profil ordinacije.',
            placement: 'bottomRight',
            duration: 2.5
          });
          setResult(true);
          history.push('/admin');
        }
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const updateOrdination = async (updateData, id) => {
    try {
      const ordination = await Axios.put(`${SERVER_URL}/ordination/${id}`, { ...updateData }, headers);
      if (ordination.status === 200) {
        notification.success({
          message: ordination.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
        // reload mandatory for session storage set
        setTimeout(() => window.location.reload(), 700);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const deactivateOrdination = async (id) => {
    try {
      const ordination = await Axios.get(`${SERVER_URL}/ordination/deactivate/${id}`, headers);
      if (ordination.status === 200) {
        notification.success({
          message: ordination.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  useEffect(() => {
    fetchFormCategories();
  }, []);

  const fetchFormCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className='profile-container'>
      <div
        className={!showAddCategory ? 'ordination-form-container mobile-top-space' : 'ordination-form-container wide'}
      >
        <div className={!showAddCategory ? 'ordination-form-wrapper wide' : 'ordination-form-wrapper'}>
          {formCategories && ordination.data && (
            <OrdinationForm
              data={ordination.data}
              createOrdinationHandler={createOrdination}
              updateOrdinationHandler={updateOrdination}
              deleteOrdinationHandler={deactivateOrdination}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              categories={formCategories}
              language={'sr'}
              showAddCategory={showAddCategory}
              setShowAddCategory={setShowAddCategory}
              token={currentuser}
              readonly={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdinationProfile;
