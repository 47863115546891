import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Card, Row, Col, Select, DatePicker } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const { Option } = Select;

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const PatientForm = ({ handleCreate }) => {
  const [form] = Form.useForm();
  const [ordination, setOrdination] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [child, setChild] = useState(false);
  const [phone, setPhone] = useState();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  useEffect(() => {
    isChild(form.getFieldValue('dateOfBirth'));
  }, [form]);

  const getOrdination = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordination/${currentuser?.ordination}`, headers);
      setOrdination(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getDoctors = useCallback(async () => {
    axios
      .post(`${SERVER_URL}/doctors-by-ordination`, { ordination: currentuser?.ordination }, headers)
      .then((res) => setDoctors(res.data))
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getDoctors();
    getOrdination();
  }, [getDoctors, getOrdination]);

  useEffect(() => {
    form.setFieldsValue({
      ordinations: ordination.name,
    });
  }, [form, ordination]);

  const onFinish = async (values) => {
    const inputValues = {};
    Object.keys(values).forEach((val, key) => {
      inputValues[val] = values[val];
    });
    if (currentuser?.userType === 'ordination') {
      inputValues.ordinations = [ordination._id];
    }
    inputValues.child = child;

    if (currentuser?.userType === 'doctor') inputValues.doctors = currentuser?.doctor;

    handleCreate(inputValues);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='novi-pacijent-form'>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='Novi pacijent' bordered={false}>
            <Form
              {...layout}
              name='basic'
              layout='horizontal'
              onFinish={(values) => onFinish(values)}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Row className='card-form'>
                <Col xs={{ span: 24, offset: 0 }}>
                  <Form.Item
                    label='IME'
                    name='firstName'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas, unesite ime!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='PREZIME'
                    name='lastName'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo, unesite prezime!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='DATUM ROĐENJA'
                    name='dateOfBirth'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo, unesite datum rođenja!',
                      },
                    ]}
                    title='Upišite mesec, dan i godinu rođenja'
                  >
                    <DatePicker
                      showToday={false}
                      placeholder=''
                      style={{ width: '100%', border: 'none', borderBottom: '1px solid #e5e5e5' }}
                      format={'DD/MM/YYYY'}
                      onChange={(e) => isChild(e)}
                    />
                  </Form.Item>

                  {child ? (
                    <>
                      <h2 style={{ color: '#4dc1be' }}>Roditelj</h2>
                      <p style={{ color: '#627b90' }}>Kod maloletnika unesite podatke staratelja/roditelja</p>
                      <Form.Item
                        label='IME'
                        name='parentFirstName'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite ime!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label='PREZIME'
                        name='parentLastName'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite prezime!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label='EMAIL'
                        name='parentEmail'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite email!',
                          },
                        ]}
                      >
                        <Input type='email' />
                      </Form.Item>
                      <Form.Item
                        label='TELEFON'
                        name='parentPhone'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite telefon!',
                          },
                        ]}
                      >
                        <PhoneInput
                          style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                          international
                          defaultCountry='RS'
                          countryCallingCodeEditable={false}
                          value={phone}
                          onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        label='TELEFON'
                        name='phone'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite telefon!',
                          },
                        ]}
                      >
                        <PhoneInput
                          style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                          international
                          defaultCountry='RS'
                          countryCallingCodeEditable={false}
                          value={phone}
                          onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                        />
                      </Form.Item>
                      <Form.Item
                        label='EMAIL'
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo, unesite email!',
                          },
                        ]}
                      >
                        <Input type='email' />
                      </Form.Item>
                    </>
                  )}

                  {currentuser?.userType !== 'doctor' && (
                    <Form.Item
                      label='DOKTOR'
                      name='doctors'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite doktora!',
                        },
                      ]}
                    >
                      <Select
                        showArrow={true}
                        mode='multiple'
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .join('')
                            .toLowerCase()
                            .replace(/ć/g, 'c')
                            .replace(/č/g, 'c')
                            .replace(/đ/g, 'dj')
                            .replace(/š/g, 's')
                            .replace(/ž/g, 'z')
                            .replace(/dž/g, 'dz')
                            .indexOf(
                              input
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz'),
                            ) >= 0
                        }
                      >
                        {doctors &&
                          doctors.map((doc) => (
                            <Option key={doc._id} value={doc._id}>
                              {doc.title || 'dr'} {doc.firstName} {doc.lastName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Row className='new-patient-button-row'>
                <Col xs={{ span: 12, offset: 12 }}>
                  <Form.Item>
                    <button style={{ float: 'right' }} htmltype='submit' className='action-button border-dark'>
                      <img src='/images/save.svg' alt='save' />
                      <span>Dodaj</span>
                    </button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
