import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Input, Card, Row, Col, notification, Radio } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import SignatureCanvas from 'react-signature-canvas';

const { TextArea } = Input;
const appointmentId = sessionStorage.getItem('appointment');
const dayNow = moment().locale('sr').format('DD. MMMM YYYY.');

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const PrescriptionForm = ({ patient }) => {
  const canvas = useRef({});
  const [disabled, setDisabled] = useState(false);
  const [ordPhone, setOrdPhone] = useState();
  const [patPhone, setPatPhone] = useState();
  const [form] = Form.useForm();
  const [appointment, setAppointment] = useState({});

  useEffect(() => {
    canvas.current.fromDataURL(appointment?.doctor?.signature);
  }, [appointment, canvas]);

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setTimeout(() => setDisabled(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [disabled]);

  const getAppointment = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/calendar/${appointmentId}`, headers);
      setAppointment(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    getAppointment();
  }, [getAppointment]);

  useEffect(() => {
    if (patient && dayNow && appointment) {
      form.setFieldsValue({
        examinationType: appointment?.examinationType?.title,
        prescribingDate: dayNow,
        ordinationName: appointment?.ordinations?.name,
        ordinationAddress: appointment?.ordinations?.address,
        ordinationPhone: appointment?.ordinations?.phone[0],
        patientName: `${patient?.firstName} ${patient?.lastName}`,
        dateOfBirth: patient?.dateOfBirth,
        patientPhone: patient?.phone,
        patientAddress: patient?.address,
        doctorName: `${appointment?.doctor?.firstName} ${appointment?.doctor?.lastName}`,
        doctorLicense: appointment?.doctor?.license,
      });
    }
  }, [patient, form, appointment]);

  const createHandler = async (data) => {
    data.patient = patient?._id;
    data.doctor = appointment?.doctor?._id;
    data.ordination = appointment?.ordinations?._id;
    try {
      const recepie = await Axios.post(`${SERVER_URL}/recepie`, { ...data }, headers);
      notification.success({
        message: recepie.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      setDisabled(true);
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  return (
    <Card bordered={false}>
      <Form
        name='basic'
        layout='horizontal'
        onFinish={(values) => createHandler(values)}
        form={form}
        className='prescription-form'
      >
        <Row>
          <Col xs={{ span: 24, offset: 0 }}>
            <h2 style={{ textTransform: 'uppercase' }}>Zdrastvena ustanova/privatna praksa</h2>
            <Form.Item name='ordinationName' label='Naziv'>
              <Input readOnly />
            </Form.Item>
            <Form.Item name='ordinationAddress' label='Adresa'>
              <Input readOnly />
            </Form.Item>
            <Form.Item name='ordinationPhone' label='Telefon'>
              <PhoneInput
                style={{ borderBottom: '1px solid #e5e5e5' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                value={ordPhone}
                onChange={setOrdPhone}
                readOnly
              />
            </Form.Item>

            <h2 style={{ textTransform: 'uppercase' }}>Pacijent</h2>
            <Form.Item name='examinationType' label='Vrsta pregleda'>
              <Input readOnly />
            </Form.Item>
            <Form.Item name='patientName' label='Ime i prezime'>
              <Input readOnly />
            </Form.Item>
            <Form.Item label='Godina rodjenja' name='dateOfBirth'>
              <Input type='date' readOnly />
            </Form.Item>
            <Form.Item name='patientPhone' label='Telefon'>
              <PhoneInput
                style={{ borderBottom: '1px solid #e5e5e5' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                value={patPhone}
                onChange={(num) => num === undefined ? setPatPhone('') : setPatPhone(num)}
                readOnly
              />
            </Form.Item>
            <Form.Item name='patientAddress' label='Adresa'>
              <Input readOnly />
            </Form.Item>

            <Form.Item name='diagnoseId' label='NAZIV ILI ŠIFRA DIJAGNOZE' className='label-diagnose'>
              <Input />
            </Form.Item>

            <h2 style={{ textTransform: 'uppercase', marginTop: '10px' }}>Vrsta recepta (označiti):</h2>
            <Form.Item name='recepieType'>
              <Radio.Group>
                <Radio value='non-renewable'>Neobnovljiv</Radio>
                <Radio value='renewable'>Obnovljiv</Radio>
                <Radio value='unique'>Poseban</Radio>
                <Radio value='limited'>Ograničen</Radio>
              </Radio.Group>
            </Form.Item>

            <h2 style={{ textTransform: 'uppercase' }}>RP.</h2>
            <Form.Item name='recepie'>
              <TextArea rows={4} />
            </Form.Item>

            <h2 style={{ textTransform: 'uppercase' }}>Napomene:</h2>
            <Form.Item name='prescriptionNote'>
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item name='drugID' label='Broj potvrde apoteke za preuzimanje leka'>
              <Input />
            </Form.Item>
            <Form.Item name='prescribingDate' label='Datum propisivanja'>
              <Input readOnly />
            </Form.Item>
            <Form.Item name='doctorName' label='Ime i prezime lekara'>
              <Input readOnly />
            </Form.Item>
            <Form.Item name='doctorLicense' label='Broj licence'>
              <Input readOnly />
            </Form.Item>
            <div className='report-box-text info'>
              <div className='doctor-signature'>
                <span>Potpis i faksimil lekara:</span>
                <SignatureCanvas
                  onBegin={() => canvas.current.off()}
                  ref={canvas}
                  canvasProps={{ width: 556, height: 130 }}
                />
              </div>
            </div>
          </Col>
        </Row>

        {!disabled &&
          <button className='action-button border-dark' htmltype='submit' style={{ marginRight: 0, marginTop: '1rem' }}>
            <img src='/images/save.svg' alt='save' />
            <span style={{ color: 'white' }}>Potvrdi</span>
          </button>
        }
      </Form>
    </Card>
  );
};

export default PrescriptionForm;
