import React, { useEffect, useState, useRef } from 'react';
import ReportsForm from '../../components/forms/ReportsForm';
import moment from 'moment';
import 'moment/locale/sr';
import { notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useLocation } from 'react-router-dom';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Reports = () => {
  const canvas = useRef({});
  moment.locale('sr');
  const [patientData, setPatientData] = useState(undefined);
  const [disabled, setDisabled] = useState(false);

  const location = useLocation();
  const pathName = location.pathname;
  const patientUrlData = pathName.split('/');
  const currentPatientUrlData = patientUrlData[3];
  const patientId = currentPatientUrlData.split('-').pop();

  useEffect(() => {
    fetchPatientData(patientId);
  }, [patientId]);

  const fetchPatientData = async (patientId) => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/patient/${patientId}`, headers);
      setPatientData(data.patient);
      return data.patient;
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setTimeout(() => setDisabled(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [disabled]);

  const createHandler = async (data, ordination, doctor) => {
    data.patient = patientId;
    data.doctor = doctor;
    data.ordination = ordination;
    if (canvas.current.isEmpty()) {
      notification.info({
        message: "Molim, da unesete potpis!",
        placement: 'bottomRight',
        duration: 2.5
      });
      return;
    }
    data.signature = canvas.current.toDataURL();
    try {
      const report = await Axios.post(`${SERVER_URL}/report`, { ...data }, headers);
      if (report.status === 200) {
        notification.success({
          message: 'Izveštaj je sačuvan.',
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      setDisabled(true);
    } catch (err) {
      console.log(err.message);
      notification.error({
        message: 'Problem sa bazom podataka.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  return (
    <div className='patient'>
      <div className='patient-profile-form-container create-report'>
        <div className={userType === 'doctor' ? 'profile-form-wrapper mobile-top-space-big' : 'profile-form-wrapper'}>
          <div className='cards-border'>
            <div>
              <div className='dashboard'>
                <div className='card-wrapper'>
                  <ReportsForm
                    patient={patientData}
                    createHandler={createHandler}
                    canvas={canvas}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
