import React, { useEffect, useState } from 'react';
import { PatientsTable } from '../../components/tables';
import { notification } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SERVER_URL } from '../../config';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Patients = ({ notificationEvents }) => {
  const history = useHistory();
  const [reset, setReset] = useState([]);
  const [tableKey, setTableKey] = useState(0);
  const [search, setSearch] = useState('');
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    let timer;
    if (search.trim() !== '') {
      if (userType === 'ordination') {
        timer = setTimeout(() => {
          Axios.post(
            `${SERVER_URL}/patient/search/${search}?type=ordination`,
            {
              ordinations: currentuser?.ordination,
            },
            headers,
          )
            .then((res) => setPatients(res.data.patients))
            .catch((err) => console.log(err));
        }, 500);
      } else {
        timer = setTimeout(() => {
          Axios.post(`${SERVER_URL}/patient/search/${search}?type=doctor&id=${currentuser?.doctor}`, null, headers)
            .then((res) => setPatients(res.data.patients))
            .catch((err) => console.log(err));
        }, 500);
      }
    } else {
      getPatients();
    }

    return () => clearTimeout(timer);
  }, [search, notificationEvents]);

  const removePatient = async (id) => {
    try {
      let patient;
      if (userType === 'doctor') {
        patient = await Axios.delete(
          `${SERVER_URL}/doctor/remove-patient/${id}?doc=${currentuser?.doctor}`,
          headers,
        );
      } else {
        patient = await Axios.delete(
          `${SERVER_URL}/remove/patient/${id}?ordination=${currentuser?.ordination}`,
          headers,
        );
      }
      notification.success({
        message: patient.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      setPatients(prevState => prevState.filter(patient => patient._id !== id));
    } catch (err) {
      notification.error({
        message: 'Problem sa uklanjenjem. Pokušajte ponovo.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const updatePatient = async (id, values) => {
    try {
      const { data } = await Axios.put(`${SERVER_URL}/patient/${id}`, values, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      getPatients();
    } catch (error) {
      if (error.response.data.message) {
        notification.error({
          message: error.response.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      console.log(error.message);
    }
  };

  const getPatients = async () => {
    try {
      let patientsResponse;
      if (userType === 'doctor') {
        patientsResponse = await Axios.get(`${SERVER_URL}/patient/doctor/${currentuser?.doctor}`, headers);
      } else if (userType === 'ordination') {
        patientsResponse = await Axios.post(
          `${SERVER_URL}/patient/ordination`,
          {
            ordinations: currentuser?.ordination,
          },
          headers,
        );
      }

      setPatients(patientsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const showPatientModal = () => {
    setTimeout(() => history.push('/admin/new-patient'), 500);
  };

  const columnKeysTranslated = ['Ime', 'Prezime', 'Datum Rodjenja', 'Grad', 'Telefon', 'Email'];
  const columnKeys = ['firstName', 'lastName', 'convertedDate', 'city', 'phone', 'email'];
  if (userType === 'ordination') {
    columnKeysTranslated.push('Doktori');
    columnKeys.push('doctor');
  }

  let tableData = [];
  if (patients?.length > 0) {
    tableData = patients?.map((item) => {
      return {
        ...item,
        email: item.userReference?.email,
        ordination: item.ordinations?.map((ord, i) => `${ord?.name}${i < item.ordinations.length - 1 ? `,` : ''} `),
        convertedDate: item.dateOfBirth && moment(item.dateOfBirth).locale('sr').format('DD. MMMM YYYY.'),
        doctor: item.doctors?.map(
          (doc, i) => `${doc.firstName} ${doc.lastName}${i < item.doctors.length - 1 ? `,` : ''} `,
        ),
      };
    });
  }

  const resetFilters = () => {
    setTableKey(tableKey => tableKey + 1);
    setReset([]);
  };

  return (
    <div className='patients-table'>
      <div className='above-table-content'>
        {userType !== 'ordinationDoctor' && (
          <button className='action-button' onClick={showPatientModal}>
            <span>Dodaj pacijenta</span>
          </button>
        )}
        {reset.length ? <button className='action-button' onClick={() => resetFilters()}><span>Resetuj filtere</span></button> : ''}

        <div className='search-input' style={{ marginLeft: 'auto' }}>
          <div className='form-content'>
            <input
              type='text'
              id='searchInput'
              className='table-search'
              placeholder='Pretraži'
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className='search-button'>
              <img src='/images/loupe.svg' alt='loupe' />
            </button>
          </div>
        </div>
      </div>

      <div className='patients-container no-space'>
        {patients && (
          <div className='table-wrapper'>
            <PatientsTable
              key={tableKey}
              setReset={setReset}
              data={tableData}
              updatePatient={updatePatient}
              columnKeys={columnKeys}
              deactivate={removePatient}
              columnKeysTranslated={columnKeysTranslated}
              isView={true}
              title='Patients'
              editPath='/admin/patients/'
              viewPath='/admin/view-user/'
              readonly={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Patients;
