import React, { useContext, useEffect, useState, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { SERVER_URL, SOCKET_URL } from '../../config';
import Axios from 'axios';
import { SidebarContext } from '../../App';
import { io } from 'socket.io-client';
import { Card, Modal, notification } from 'antd';
import moment from 'moment';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;

const now = new Date();

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const SidebarNarrow = ({ collapsedNav, setEventToUpdate }) => {
  const history = useHistory();
  const currentPage = useHistory()?.location?.pathname;
  let { unreadNotification, sidebarContext } = useContext(SidebarContext);

  const [currentDoctor, setCurrentDoctor] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [doctorOrdination, setDoctorOrdination] = useState();
  const [message, setMessage] = useState();

  const getDoctor = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/doctors/${currentuser?.doctor}`, headers);
      setDoctorOrdination(data.ordination);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (currentuser?.doctor) getDoctor();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      if (userType === 'doctor') {
        const { data } = await Axios.get(`${SERVER_URL}/doctors/${currentuser?.doctor}`, headers);
        setCurrentDoctor(`${data.firstName}-${data.lastName}-${data._id}`.toLowerCase());
        sessionStorage.setItem('currentDoctor', `${data.lastName} ${data.firstName[0]}.`);
        sessionStorage.setItem('currentDoctorFullName', `${data.firstName}-${data.lastName}`.toLowerCase());
        sessionStorage.setItem('openedSpecializationId', data.category?._id);
        sessionStorage.setItem('currentDoctorId', data._id);
      } else if (userType === 'ordination') {
        const { data } = await Axios.get(`${SERVER_URL}/ordination/${currentuser?.ordination}`, headers);
        if (data) {
          fetchCategories(data._id).then((cat) => {
            fetchOrdinationsDoctors(data._id).then((doc) => {
              if (doc.length && cat.length) {
                getCurrentDoctor(doc, cat);
              } else {
                setCurrentDoctor('null-null');
              }
            });
          });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    if (history.location.pathname !== '/admin/doctor/notifications') {
      let socket = io(SOCKET_URL, { path: '/socket.io' });
      socket.emit('user-connected', currentuser?.doctor);
      socket.on('receive-single-days', async ({ data, doctor, ordination, mode, requestedBy, ordinationSingleDays }) => {
        console.log('receive-single-days');
        setVisibleModal(true);
        await sidebarContext.getNumberOfUnreadNotifications();
        setEventToUpdate(data);
        switch (mode) {
          case 'CREATE':
            setMessage(
              <div>
                {requestedBy === 'doctor' ? (
                  <p>
                    Doktor {doctor.firstName} {doctor.lastName} je kreirao {ordinationSingleDays && 'ordinacijske'} jednokratne dane:
                  </p>
                ) : (
                  <p>Ordinacija {ordination.name} je kreirala {ordinationSingleDays && 'ordinacijske'} jednokratne dane:</p>
                )}
                {data.map((d) => (
                  <div>
                    <h3 style={{ fontWeight: 'bold' }}>{new Date(d.date).toLocaleDateString()}</h3>
                    <p>
                      {d.from} - {d.to} <i>{d.override && 'Pregažen'}</i>
                    </p>
                  </div>
                ))}
              </div>,
            );
            break;
          case 'UPDATE':
            setMessage(
              <div>
                {requestedBy === 'doctor' ? (
                  <p>
                    Doktor {doctor.firstName} {doctor.lastName} je ažurirao {ordinationSingleDays && 'ordinacijske'} jednokratne dane:
                  </p>
                ) : (
                  <p>Ordinacija {ordination.name} je ažurirala {ordinationSingleDays && 'ordinacijske'} jednokratne dane:</p>
                )}
                {data.add.length ? <h3 style={{ fontWeight: 'bold' }}>Dodat</h3> : ''}
                {data.add.map((a) => (
                  <p>
                    {new Date(a.date).toLocaleDateString()} {a.from} - {a.to} <i>{a.override && 'Pregažen'}</i>
                  </p>
                ))}
                {data.change.length ? <h3 style={{ fontWeight: 'bold' }}>Izmenjen</h3> : ''}
                {data.change.map((a) => (
                  <p>
                    {new Date(a.date).toLocaleDateString()} {a.from} - {a.to} <i>{a.override && 'Pregažen'}</i>
                  </p>
                ))}
                {data.remove.length ? <h3 style={{ fontWeight: 'bold' }}>Uklonjen</h3> : ''}
                {data.remove.map((a) => (
                  <p>
                    {new Date(a.date).toLocaleDateString()} {a.from} - {a.to}
                  </p>
                ))}
              </div>,
            );
            break;
          case 'DELETE':
            setMessage(
              <div>
                {requestedBy === 'doctor' ? (
                  <p>
                    Doktor {doctor.firstName} {doctor.lastName} je izbrisao {ordinationSingleDays && 'ordinacijske'} jednokratne dane
                  </p>
                ) : (
                  <p>Ordinacija {ordination.name} je izbrisala {ordinationSingleDays && 'ordinacijske'} jednokratne dane</p>
                )}
              </div>,
            );
            break;
          default:
            break;
        }
      });
      if (userType === 'doctor') {
        socket.emit('user-connected', currentuser?.doctor);
        socket.on('patient-added', async (data) => {
          console.log('patient-added');
          setEventToUpdate(data);
          notification.info({
            message: data.message,
            placement: 'bottomRight',
            duration: 5
          });
        });
        socket.on('new-notification-doctor-received', async (data) => {
          console.log('new-notification-doctor-received');
          setEventToUpdate(data);
          const { doctor, patient, ordination, startDate, requestedBy } = data;

          if (now < new Date(startDate)) {
            const dateOfBirth = patient.dateOfBirth
              ? `(${new Date(patient.dateOfBirth).toUTCString().substring(5, 16)})`
              : '';
            const messageType = data.message;
            await sidebarContext.getNumberOfUnreadNotifications();
            setVisibleModal(true);
            switch (messageType) {
              case 'CHANGED_TERM':
              case 'OVERLAP_PROLONGUE_TERM':
              case 'PROLONGUE':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je promenio termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je pomeren termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                }
                break;
              case 'SCHEDULE_TERM':
              case 'OVERLAP_SHEDULE_TERM':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazao termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazan termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                }
                break;
              case 'CANCEL_TERM':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazao termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else {
                  let notificationOwner;
                  if (requestedBy === 'doctor') {
                    notificationOwner = doctor.firstName + ' ' + doctor.lastName;
                  } else if (requestedBy === 'ordination') {
                    notificationOwner = ordination.name;
                  }

                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazan termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')} od strane ${requestedBy !== 'admin' ? notificationOwner : 'admina'
                    }`,
                  );
                }
                break;
              default:
                break;
            }
          }
        });
        socket.on('remove-doctor-received', async (data) => {
          console.log('remove-doctor-received');
          currentuser.ordination = data.doctor.ordination;
          sessionStorage.setItem('user', JSON.stringify(currentuser));
          setEventToUpdate(data);
          setVisibleModal(true);
          setMessage(data.message);
          setTimeout(() => window.location.reload(), 5000);
        });
        socket.on('remove-ordination-received', async (data) => {
          console.log('remove-ordination-received');
          currentuser.ordination = data.doctor.ordination;
          sessionStorage.setItem('user', JSON.stringify(currentuser));
          setEventToUpdate(data);
          setVisibleModal(true);
          setMessage(data.message);
          setTimeout(() => window.location.reload(), 5000);
        });
      } else if (userType === 'ordination') {
        socket.emit('user-connected', currentuser?.ordination);
        socket.on('new-notification-ordination-received', async (data) => {
          console.log('new-notification-ordination-received');
          setEventToUpdate(data);
          const { doctor, patient, ordination, startDate, requestedBy } = data;

          if (now < new Date(startDate)) {
            const dateOfBirth = patient.dateOfBirth
              ? `(${new Date(patient.dateOfBirth).toUTCString().substring(5, 16)})`
              : '';
            const messageType = data.message;
            setVisibleModal(true);
            await sidebarContext.getNumberOfUnreadNotifications();
            switch (messageType) {
              case 'OVERLAP_SHEDULE_TERM':
                setMessage(
                  <div>
                    {requestedBy !== 'patient' ? 'Pacijentu' : 'Pacijent'} {patient?.firstName} {patient?.lastName}{' '}
                    {dateOfBirth} je {requestedBy !== 'patient' ? 'zakazan' : 'zakazao'} termin{' '}
                    {userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`}{' '}
                    {moment(startDate).format('DD MMMM YYYY, HH:mm')}. <br />
                    <br />
                    <i>
                      <b>Bitno </b> Duplirani termin za dan {moment(startDate).format('DD MMMM YYYY')} u{' '}
                      {moment(startDate).format('HH:mm')} kod doktora {doctor?.firstName} {doctor?.lastName}
                    </i>
                  </div>,
                );
                break;
              case 'OVERLAP_CHANGED_TERM':
              case 'OVERLAP_PROLONGUE_TERM':
                setMessage(
                  <div>
                    Pacijentu {patient?.firstName} {patient?.lastName} {dateOfBirth} je pomeren termin{' '}
                    {userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`}{' '}
                    {moment(startDate).format('DD MMMM YYYY, HH:mm')}. <br />
                    <br />
                    <i>
                      <b>Bitno </b> Duplirani termin za dan {moment(startDate).format('DD MMMM YYYY')} u{' '}
                      {moment(startDate).format('HH:mm')} kod doktora {doctor?.firstName} {doctor?.lastName}
                    </i>
                  </div>,
                );
                break;
              case 'CHANGED_TERM':
              case 'PROLONGUE':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je promenio termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je pomeren termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } za ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                }
                break;
              case 'SCHEDULE_TERM':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazao termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else if (requestedBy === 'doctor' || requestedBy === 'ordination') {
                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je zakazan termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                }
                break;
              case 'CANCEL_TERM':
                if (requestedBy === 'patient') {
                  setMessage(
                    `Pacijent ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazao termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')}`,
                  );
                } else {
                  let notificationOwner;
                  if (requestedBy === 'doctor') {
                    notificationOwner = doctor.firstName + ' ' + doctor.lastName;
                  } else if (requestedBy === 'ordination') {
                    notificationOwner = ordination.name;
                  }

                  setMessage(
                    `Pacijentu ${patient?.firstName} ${patient?.lastName} ${dateOfBirth} je otkazan termin ${userType === 'doctor'
                      ? `u ordinaciji ${ordination?.name}`
                      : `kod doktora ${doctor?.firstName} ${doctor?.lastName}`
                    } ${moment(startDate).format('DD MMMM YYYY, HH:mm')} od strane ${requestedBy !== 'admin' ? notificationOwner : 'admina'
                    }`,
                  );
                }
                break;
              default:
                break;
            }
          }
        });
        socket.on('remove-ordination-received', async (data) => {
          console.log('remove-ordination-received');
          setEventToUpdate(data);
          sessionStorage.removeItem('currentDoctor');
          sessionStorage.removeItem('currentDoctorId');
          sessionStorage.removeItem('currentDoctorFullName');
          setVisibleModal(true);
          setMessage(data.message);
          setTimeout(() => window.location.href = '/admin/doctors', 5000);
        });
      }
      socket.on('receive-cancel-appointments', ({ data }) => {
        console.log('receive-cancel-appointments');
        setVisibleModal(true);
        setEventToUpdate(data);
        setMessage(
          <div>
            <p>Otkazani su sledeci termini:</p>
            {data.data.map((termin) => (
              <p key={termin._id}>
                Pacijentu{' '}
                <b>
                  {termin.firstName} {termin.lastName}
                </b>{' '}
                kod doktora{' '}
                <i>
                  {termin.doctor.firstName} {termin.doctor.lastName}
                </i>{' '}
                u {new Date(termin.startDate).toLocaleString()}
              </p>
            ))}
          </div>,
        );
      });
      socket.on(
        'receive-work-days',
        async ({ workDay, nonWork, doctor, ordination, message, requestedBy, appointments }) => {
          console.log('receive-work-days');
          await sidebarContext.getNumberOfUnreadNotifications();
          setEventToUpdate({ workDay, nonWork, doctor, ordination, message, requestedBy });
          switch (message) {
            case 'UPDATE_WORK_DAY':
              if (
                workDay.add.length ||
                workDay.remove.length ||
                workDay.change.length ||
                nonWork.addNon.length ||
                nonWork.removeNon.length ||
                nonWork.changeNon.length
              ) {
                setVisibleModal(true);
                setMessage(
                  <div>
                    {requestedBy === 'doctor' ? (
                      <p>
                        Doktor {doctor.firstName} {doctor.lastName} je postavio radne dane:
                      </p>
                    ) : (
                      <p>Ordinacija {ordination.name} je postavila radne dane:</p>
                    )}
                    {workDay.add.length ? <h2>Dodat</h2> : ''}
                    {workDay.add.map((a) => (
                      <p>
                        <strong>{a.day}</strong> {a.from} - {a.to}
                      </p>
                    ))}
                    {workDay.remove.length ? <h2>Uklonjen</h2> : ''}
                    {workDay.remove.map((a) => (
                      <p>
                        <strong>{a.day}</strong> {a.from} - {a.to}
                      </p>
                    ))}
                    {workDay.change.length ? <h2>Izmenjen</h2> : ''}
                    {workDay.change.map((a) => (
                      <p>
                        <strong>{a.day}</strong> {a.from} - {a.to}
                      </p>
                    ))}
                    {nonWork.addNon.length || nonWork.removeNon.length || nonWork.changeNon.length ? (
                      <>
                        <h2>Neradni dani</h2>
                        {nonWork.addNon.length ? <h2>Dodat</h2> : ''}
                        {nonWork.addNon.map((a) => (
                          <p>
                            <strong>{new Date(a.date).toLocaleDateString()}</strong> {a.from} - {a.to}
                          </p>
                        ))}
                        {nonWork.removeNon.length ? <h2>Uklonjen</h2> : ''}
                        {nonWork.removeNon.map((a) => (
                          <p>
                            <strong>{new Date(a.date).toLocaleDateString()}</strong> {a.from} - {a.to}
                          </p>
                        ))}
                        {nonWork.changeNon.length ? <h2>Izmenjen</h2> : ''}
                        {nonWork.changeNon.map((a) => (
                          <p>
                            <strong>{new Date(a.date).toLocaleDateString()}</strong> {a.from} - {a.to}
                          </p>
                        ))}
                      </>
                    ) : (
                      ''
                    )}
                  </div>,
                );
              }
              break;
            case 'DELETE_WORK_DAY':
              setVisibleModal(true);
              setMessage(
                <div>
                  {requestedBy === 'doctor' ? (
                    <p>
                      Doktor {doctor.firstName} {doctor.lastName} je izbrisao radno vreme.
                    </p>
                  ) : (
                    <div>
                      <p>Ordinacija {ordination.name} je izbrisala radno vreme.</p>
                      {appointments?.length ? <h3>Izbrisani termini</h3> : ''}
                      {appointments?.map((termin) => (
                        <p>
                          <strong>
                            {termin.firstName} {termin.lastName}
                          </strong>{' '}
                          ({termin.dateOfBirth})
                        </p>
                      ))}
                    </div>
                  )}
                </div>,
              );
              break;
            default:
              break;
          }
        },
      );
    }
  });

  const fetchCategories = async (id) => {
    try {
      const categoriesResponse = await Axios.get(`${SERVER_URL}/categories-by-ordination/${id}`, headers);
      return categoriesResponse.data;
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchOrdinationsDoctors = async (id) => {
    try {
      const doctorsResponse = await Axios.post(`${SERVER_URL}/doctors-by-ordination`, { ordination: id }, headers);
      return doctorsResponse.data;
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCurrentDoctor = (doctors, category) => {
    let cats = [];
    doctors.forEach((doc) => doc.category.forEach((cat) => cats.push(cat.name.sr)));
    cats = [...new Set(cats)];
    cats.sort((a, b) => a.localeCompare(b));

    const selectedCategory = cats.find((cat) => category.find((ordCat) => cat === ordCat.name.sr));
    const selectedDoctor = doctors.find((doc) => doc.category.find((cat) => cat.name.sr === selectedCategory));

    setCurrentDoctor(`${selectedDoctor?.firstName}-${selectedDoctor?.lastName}-${selectedDoctor?._id}`.toLowerCase());

    sessionStorage.setItem(
      'currentDoctor',
      `${selectedDoctor?.lastName} ${selectedDoctor?.firstName.slice(0, 1) + '.'}`,
    );
    sessionStorage.setItem(
      'currentDoctorFullName',
      `${selectedDoctor?.firstName}-${selectedDoctor?.lastName}`.toLowerCase(),
    );
    sessionStorage.setItem(
      'openedSpecializationId',
      selectedDoctor?.category.find((cat) => cat.name.sr === selectedCategory)._id,
    );
    sessionStorage.setItem('currentDoctorId', selectedDoctor?._id);
  };

  useEffect(() => {
    if (currentuser) fetchData();
  }, [fetchData]);

  return (
    <aside className={collapsedNav ? 'sidebar-narrow-active' : 'sidebar-narrow'}>
      <ul>
        <li className={currentPage === '/admin' ? 'active-li-element' : ''}>
          <NavLink to={'/admin'} title='Početna'>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img src='/images/home-icon.svg' alt='home' width='33' />
              <span>Početna stranica</span>
            </p>
          </NavLink>
        </li>

        <li className={currentPage === `/admin/calendar/${currentDoctor}` ? 'active-li-element' : ''}>
          <NavLink
            to={{
              pathname: `/admin/calendar/${currentDoctor}`,
              state: { fromSidebarNarrow: true },
            }}
            title='Moj organizator'
          >
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img src='/images/calendar-icon.svg' alt='calendar' />
              <span>Moj Organizator</span>
            </p>
          </NavLink>
        </li>

        <li className={currentPage === '/admin/patients' ? 'active-li-element' : ''}>
          <NavLink to={'/admin/patients'} title='Pacijenti'>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img src='/images/patient-icon.svg' alt='patient' />
              <span>Pacijenti</span>
            </p>
          </NavLink>
        </li>

        {userType !== 'doctor' && (
          <li className={currentPage === '/admin/doctors' ? 'active-li-element' : ''}>
            <NavLink to={'/admin/doctors'} title='Doktori'>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/doctor-icon.svg' alt='doctor' />
                <span>Doktori</span>
              </p>
            </NavLink>
          </li>
        )}
        {userType !== 'doctor' ? (
          <li className={currentPage === '/admin/ordination/profile' ? 'active-li-element' : ''}>
            <NavLink to={'/admin/ordination/profile'} title='Profil'>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/ordination-icon.svg' alt='ordination' />
                <span>Profil</span>
              </p>
            </NavLink>
          </li>
        ) : (
          <li className={currentPage === '/admin/doctor/profile' ? 'active-li-element' : ''}>
            <NavLink to={'/admin/doctor/profile'} title='Profil'>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/ordination-icon.svg' alt='profile' />
                <span>Profil</span>
              </p>
            </NavLink>
          </li>
        )}
        {userType === 'doctor' ? (
          <li
            className={
              ['/admin/doctor/workdays', `/admin/doctor/hours/${currentuser?.doctor}`].includes(currentPage)
                ? 'active-li-element'
                : ''
            }
          >
            <NavLink
              to={doctorOrdination?.length ? '/admin/doctor/workdays' : `/admin/doctor/hours/${currentuser?.doctor}`}
              title='Radno Vreme'
            >
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/group.svg' alt='workDay' />
                <span>Radno vreme</span>
              </p>
            </NavLink>
          </li>
        ) : (
          <li className={currentPage === '/admin/ordination/workdays' ? 'active-li-element' : ''}>
            <NavLink to='/admin/ordination/workdays' title='Radno Vreme'>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <img src='/images/group.svg' alt='workDay' />
                <span>Radno vreme</span>
              </p>
            </NavLink>
          </li>
        )}

        <li className={currentPage === '/admin/qr-code' ? 'active-li-element' : ''}>
          <NavLink to={'/admin/qr-code'} title='QR kod'>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img src='/images/qr-code.svg' alt='qr-code' />
              <span>QR kod</span>
            </p>
          </NavLink>
        </li>

        {userType === 'doctor' && (
          <li className={currentPage === '/admin/doctor/notifications' ? 'active-li-element' : ''}>
            <NavLink to={`/admin/doctor/notifications`} title='Notifikacije'>
              <div style={{ display: 'flex' }}>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='/images/bell-icon.svg' alt='notification' />
                  <span>Notifikacije</span>
                </p>
                {unreadNotification !== 0 && (
                  <small
                    style={{
                      position: 'absolute',
                      transform: 'translate(8px, -6px)',
                      background: 'crimson',
                      padding: '0 0.25rem',
                      borderRadius: '20%',
                      zIndex: '10',
                    }}
                  >
                    <span style={{ color: 'white' }}>{unreadNotification}</span>
                  </small>
                )}
              </div>
            </NavLink>
          </li>
        )}

        {userType === 'ordination' && (
          <li className={currentPage === '/admin/ordination/notifications' ? 'active-li-element' : ''}>
            <NavLink to={`/admin/ordination/notifications`} title='Notifikacije'>
              <div style={{ display: 'flex' }}>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='/images/bell-icon.svg' alt='notification' />
                  <span>Notifikacije</span>
                </p>
                {unreadNotification !== 0 && (
                  <small
                    style={{
                      position: 'absolute',
                      transform: 'translate(8px, -6px)',
                      background: 'crimson',
                      padding: '0 0.25rem',
                      borderRadius: '.3rem',
                      zIndex: '10',
                    }}
                  >
                    <span style={{ color: 'white' }}>{unreadNotification}</span>
                  </small>
                )}
              </div>
            </NavLink>
          </li>
        )}
      </ul>

      <Modal
        className='doctor-modal'
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={null}
        width={500}
        centered
      >
        <Card title='Nova notifikacija' bordered={false} className='calendar-data-form-card date-change'>
          <div className='date-change-container'>
            {message}
            <button style={{ marginTop: '1.2rem' }} className='action-button' onClick={() => setVisibleModal(false)}>
              <img src='/images/save.svg' alt='accept' />
              <span>Potvrdi</span>
            </button>
          </div>
        </Card>
      </Modal>
    </aside>
  );
};

export default SidebarNarrow;
