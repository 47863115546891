import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';

const user = JSON.parse(sessionStorage.getItem('user'));
const userType = user?.userType;

const body = {
  id: userType === 'doctor' ? user?.doctor : user?.ordination ? user?.ordination[0] : '',
  name: userType === 'doctor' ? `${user?.firstName} ${user?.lastName}` : user?.name,
  type: userType
};

const QRCode = () => {
  const imageRef = useRef(null);
  const [qrImage, setQrImage] = useState('');
  const [qrUrl, setQrUrl] = useState('');

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`Preuzmi MELO Health aplikaciju klikom na link: ${qrUrl}. Registruj se i zakaži pregled već danas.`);
      message.success('Link je kopirana u klipbord', 2);
    } catch (error) {
      console.log(error.message);
    }
  };

  const copyQrToClipboard = async () => {
    try {
      const htmlBlob = new Blob([imageRef.current.outerHTML], { type: 'text/html' });
      const textBlob = new Blob([imageRef.current.innerText], { type: 'text/plain' });
      await navigator.clipboard.write([
        new window.ClipboardItem({ 'text/html': htmlBlob }, { 'text/plain': textBlob }),
      ]);
      message.success('QR kod je kopirana u klipbord', 2);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getQRcode = useCallback(async () => {
    try {
      const { data } = await axios.post('https://codes.melo.rs/generate_qr_code.php', body);
      setQrUrl(data.data.code_url);
      setQrImage(data.data.code_path);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getQRcode();
  }, [getQRcode]);

  return (
    <div className='home-page-container'>
      <div ref={imageRef} style={{ width: '350px' }}>
        <div style={{ border: '2px solid #1EC8DB', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '2rem' }}>
          <img style={{ margin: '2rem' }} width='150' src='https://dashboard.moj-doktor-dev.concordsofttest.com/images/logo-mojlekar-blue.svg' alt='logo' />
          {qrImage ?
            <img src={qrImage} alt='qr-code' width='230' />
            : null
          }
          <p style={{ color: '#1EC8DB', marginTop: '1rem', fontSize: '1.1rem' }}>Skeniraj kod</p>
          <div style={{ marginTop: '1rem', width: '100%', padding: '1.2rem 0', background: '#1EC8DB', color: 'whitesmoke', borderBottomLeftRadius: '1.8rem', borderBottomRightRadius: '1.8rem', textAlign: 'center' }}>
            <p style={{ fontSize: '1rem', margin: 0, paddingBottom: '0.5rem' }}>1. Skeniraj QR kod</p>
            <p style={{ fontSize: '1rem', margin: 0, paddingBottom: '0.5rem' }}>2. Preuzmi aplikaciju</p>
            <p style={{ fontSize: '1rem', margin: 0, paddingBottom: '0.5rem' }}>3. Zakaži svoj pregled</p>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <button
          style={{ marginRight: '2rem', color: '#1EC8DB', cursor: 'pointer' }}
          onClick={copyLinkToClipboard}
        >
          Kopiraj link
        </button>
        <button
          style={{ color: '#1EC8DB', cursor: 'pointer' }}
          onClick={copyQrToClipboard}
        >
          Kopiraj QR kod
        </button>
      </div>
      <h1 style={{ marginTop: '2rem' }}>Preuzmi MELO Health aplikaciju.</h1>
      <p>Registruj se i zakaži pregled već danas!</p>
    </div >
  );
};

export default QRCode;