import React, { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';

const UploadBox = ({ editHandler, deleteHandler, image, index, name }) => {
  const [visible, setVisible] = useState(false);
  const imageUrl = image && image.url ? image.url : image;
  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  useEffect(() => {
    const overlay = document.getElementById(`upload-box-${name}-${index}`);
    if (overlay) {
      overlay.addEventListener('mouseenter', () => setVisible(true));
      overlay.addEventListener('mouseleave', () => setVisible(false));
      return () => {
        overlay.removeEventListener('mouseenter', () => setVisible(true));
        overlay.removeEventListener('mouseleave', () => setVisible(false));
      };
    }
  });

  return (
    <>
      {image ? (
        <div className='user-image' id={`upload-box-${name}-${index}`}>
          <img src={getUrl(imageUrl)} alt='upload' title='Izaberi iz galerije ili otpremi' />
          <div className={visible ? 'action-user-image active' : 'action-user-image'}>
            <EditOutlined style={{ padding: '5px' }} title='Izmeni sliku' onClick={editHandler} />
            <DeleteOutlined style={{ padding: '5px' }} title='Izbriši sliku' onClick={deleteHandler} />
          </div>
        </div>
      ) : (
        <div className='avatar-placeholder' title='Dodaj sliku' onClick={() => editHandler()}>
          <div className='avatar-image'>
            <img src='/images/avatar-doctor.png' alt='avatar doctor' />
          </div>
        </div>
      )}
    </>
  );
};

export default UploadBox;
