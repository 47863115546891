import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Card, Row, Col } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import 'moment/locale/sr';

const { TextArea } = Input;

const user = JSON.parse(sessionStorage.getItem('user'));
const appointmentId = sessionStorage.getItem('appointment');
moment.locale('sr');
const date = moment().locale('sr').format('DD. MMMM YYYY.');
const now = moment().locale('sr').format('HH:mm');

const ReportsForm = ({ patient, createHandler, canvas, disabled }) => {
  const [form] = Form.useForm();
  const [appointment, setAppointment] = useState({});

  useEffect(() => {
    canvas.current.fromDataURL(appointment?.doctor?.signature);
  }, [appointment, canvas]);

  const getAppointment = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/calendar/${appointmentId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      setAppointment(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    getAppointment();
  }, [getAppointment]);

  useEffect(() => {
    if (date && patient && appointment) {
      form.setFieldsValue({
        date: date,
        time: now,
        doctor: `${appointment?.doctor?.firstName} ${appointment?.doctor?.lastName}`,
        patient: `${patient?.firstName} ${patient?.lastName}`,
        examinationType: appointment?.examinationType?.title,
      });
    }
  }, [form, patient, appointment]);

  return (
    <Card bordered={false}>
      <Form
        name='basic'
        layout='horizontal'
        onFinish={(values) => createHandler(values, appointment?.ordinations?._id, appointment?.doctor?._id)}
        form={form}
      >
        <div className='desktop-top-data'>
          <Row className='card-form report'>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} className='report-form-left-col'>
              <Form.Item className='upload-wrapper right' name='avatar'>
                <div className='report-ordination-info'>
                  <p>Klinika: {appointment?.ordinations?.name || 'nema'}</p>
                  <p>
                    {appointment?.ordinations?.address}
                    {appointment?.ordinations?.address?.includes(appointment?.ordinations?.city)
                      ? `, ${appointment?.ordinations?.city}`
                      : ''}
                  </p>
                  <p>{appointment?.ordinations?.phone.map((item) => item)}</p>
                  <p>{appointment?.ordinations?.email}</p>
                </div>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 4 }} className='report-col'>
              <Form.Item label='DATUM' name='date'>
                <Input />
              </Form.Item>
              <Form.Item label='VREME' name='time'>
                <Input />
              </Form.Item>
              <Form.Item label='LEKAR' name='doctor'>
                <Input />
              </Form.Item>
              <Form.Item label='PACIJENT' name='patient'>
                <Input />
              </Form.Item>
              <Form.Item label='VRSTA PREGLEDA' name='examinationType'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='mobile-top-data'>
          <Row className='card-form report'>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} className='report-form-left-col'>
              <Form.Item className='upload-wrapper right' name='avatar'>
                <div className='report-ordination-info'>
                  <p>Klinika: {appointment?.ordinations?.name}</p>
                  <p>
                    {appointment?.ordinations?.address}, {appointment?.ordinations?.city}
                  </p>
                  <p>{appointment?.ordinations?.phone.map((item) => item)}</p>
                  <p>{appointment?.ordinations?.email}</p>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <div className='mobile-report-inputs'>
            <Row>
              <Col>
                <Form.Item label='DATUM:' name='date'>
                  <Input />
                </Form.Item>
                <Form.Item label='VREME:' name='time'>
                  <Input />
                </Form.Item>
                <Form.Item label='LEKAR:' name='doctor'>
                  <Input />
                </Form.Item>
                <Form.Item label='PACIJENT:' name='patient'>
                  <Input />
                </Form.Item>
                <Form.Item label='VRSTA PREGLEDA' name='examinationType'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <h1 className='document-title'>IZVEŠTAJ LEKARA SPECIJALISTE</h1>

        <div className='report-document-content'>
          <div className='report-note'>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item name='dgNote' label='Dg:' labelCol={{ span: 24 }}>
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </div>

          <div className='report-note'>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item name='anamnesisNote' label='Anamneza:' labelCol={{ span: 24 }}>
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </div>

          <div className='report-note'>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item name='therapyNote' label='Terapija:' labelCol={{ span: 24 }}>
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </div>

          <div className='report-box-text info'>
            <p>
              {appointment?.ordinations?.city}, {date}
            </p>
            <div className='doctor-signature'>
              <span>Potpis i faksimil lekara:</span>
              <SignatureCanvas
                onBegin={() => canvas.current.off()}
                ref={canvas}
                canvasProps={{ width: 556, height: 130 }}
              />
            </div>
          </div>

          <p className='report-box-text'>
            Pacijentu ili članu njegove porodice lekar specijalista dao je obaveštenje o nazivu medicinske mere, vrsti i
            načinu lečenja, dijagnostičkoj proceduri i mogućim rizicima od istog <strong>dobio - nije dobio</strong>{' '}
            pristanak za predloženu medicinsku meru (podvući ponuđeni odgovor). Pacijent upoznat sa lečenjem u skladu sa
            čl. 11. i 15. Zakona o pravim Pacijenata.
          </p>
        </div>
        <Row justify='end'>
          {!disabled &&
            <button className='action-button border-dark' htmltype='submit' style={{ marginRight: 0 }}>
              <img src='/images/save.svg' alt='save' />
              <span>Potvrdi</span>
            </button>
          }
        </Row>
      </Form>
    </Card>
  );
};

export default ReportsForm;
