import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Modal, notification, Card } from 'antd';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import allLocales from '@fullcalendar/core/locales-all';
import CalendarDataForm from '../forms/CalendarDataForm';
import Axios from 'axios';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { NavMenuContext } from '../../App';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import { io } from 'socket.io-client';
import ReactDOM from 'react-dom';

const today = new Date();
const dani = ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'];

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const CalendarFull = (props) => {
  const nextBtn = document.querySelector('.fc-next-button');
  const prevBtn = document.querySelector('.fc-prev-button');
  const todayBtn = document.querySelector('.fc-today-button');
  const history = useHistory();
  const { events, setEvents, calendarData, notificationEvents, setIsCalendarModalVisible, form } = props;
  const { isNavMenuOpen, setNavMenuOpen } = useContext(NavMenuContext);
  const doctorId = history.location.pathname.split('-')[2];

  const [realWorkTime, setRealWorkTime] = useState([]);
  const [displayDate, setDisplayDate] = useState([]);
  const [curView, setCurView] = useState('timeGridWeek');
  const [singleDays, setSingleDays] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedDateInfo, setSelectedDateInfo] = useState(undefined);
  const [editCalendarDate, setEditCalendarDate] = useState(undefined);
  const [workingDays, setWorkingDays] = useState([]);
  const [ordinationWorkingDays, setOrdinationWorkingDays] = useState([]);
  const [ordSingleDays, setOrdSingleDays] = useState([]);
  const [startTime, setStartTime] = useState();
  const [ordinations, setOrdinations] = useState([]);
  const [selectedOrdination, setSelectedOrdination] = useState('');
  const [changeDate, setChangeDate] = useState({
    toChange: false,
    event: null,
    patientName: '',
  });
  const calendarComponentRef = useRef();

  const getOrdinationSingleDay = useCallback(async () => {
    try {
      const { data } = await Axios.get(
        `${SERVER_URL}/ordinationSingleDay?doc=${doctorId}&ord=${currentuser.ordination}`,
        headers,
      );
      setOrdSingleDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    currentuser.ordination.length && getOrdinationSingleDay();
  }, [getOrdinationSingleDay, notificationEvents]);

  const getDoctor = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
      setOrdinations(data.ordination);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    if (doctorId) getDoctor();
  }, [getDoctor, doctorId]);

  // Get 15min intervals between two dates
  const fifteenMinIntervals = (startString, endString) => {
    const start = moment(startString, 'HH:mm a');
    const end = moment(endString, 'HH:mm a');

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result = [];
    const current = moment(start);

    while (current <= end) {
      result.push(current.format('HH:mm'));
      current.add(15, 'minutes');
    }

    return result;
  };

  const getSingleDays = useCallback(async () => {
    try {
      let url;
      if (currentuser.ordination.length) {
        url = `${SERVER_URL}/singleDay/${doctorId}?type=ordination&ord=${currentuser.ordination}`;
      } else {
        url = `${SERVER_URL}/singleDay/${doctorId}?type=doctor`;
      }
      const { data } = await Axios.get(url, headers);
      setSingleDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    if (doctorId) getSingleDays();
  }, [getSingleDays, doctorId, notificationEvents]);

  useEffect(() => {
    // appointments
    if (calendarData) {
      const data = calendarData?.map((item) => ({
        title: `${item.ordinations?.name ? item.ordinations.name : ''} ${item.examinationType?.title ? item.examinationType.title : ''}`,
        extendedProps: {
          lastName: item.lastName,
          firstName: item.firstName,
          examinationType: item.examinationType,
          dateOfBirth: item.dateOfBirth,
          phone: item.phone,
          id: item._id,
          note: item.note,
          ordination: item.ordinations,
          patient: item.patient,
          doctor: item.doctor,
          start: item.startDate,
          end: item.endDate,
        },
        start: item.startDate,
        end: item.endDate,
        allDay: false,
      }));

      // display non-working days
      workingDays?.forEach((single) => {
        const ordinationName = single.ordination?.name;
        if (single.nonWorking?.length > 0) {
          single.nonWorking.forEach((day) => {
            // Subract the hours by 2
            const from = +day.from.split(':')[0] - 1;
            const newFrom = `${from < 10 ? `0${from}` : from}:${day.from.split(':')[1]}`;
            const to = +day.to.split(':')[0] - 1;
            const newTo = `${to < 10 ? `0${to}` : to}:${day.to.split(':')[1]}`;

            const start = day.date.substr(0, 11) + newFrom + day.date.substr(day.date.length - 8, day.date.length);
            const end = day.date.substr(0, 11) + newTo + day.date.substr(day.date.length - 8, day.date.length);

            data.push({
              start,
              end,
              display: 'background',
              className: ['new_background'],
              ordination: single.ordination?._id,
              title: `${userType === 'doctor' && ordinationName ? ordinationName : ''}`,
              overlap: false,
            });
          });
        }
      });

      // display non-working days for ordination
      realWorkTime?.forEach((single) => {
        if (single.nonWorking?.length > 0) {
          single.nonWorking.forEach((day) => {
            const [fromHours, fromMinutes] = day.from.split(':');
            const [toHours, toMinutes] = day.to.split(':');

            let start = new Date(day.date).setHours(fromHours, fromMinutes);
            let end = new Date(day.date).setHours(toHours, toMinutes);

            data.push({
              start,
              end,
              display: 'background',
              ordination: single.ordination?._id,
              className: ['new_background'],
              overlap: false,
            });
          });
        }
      });

      // display Ordination name in lower right corner
      businessHours.forEach((day) => {
        const intervals = fifteenMinIntervals(day.startTime, day.endTime);
        intervals.forEach((_, i) => {
          if (i !== intervals.length - 1 && intervals[i + 1]) {
            data.push({
              daysOfWeek: day.daysOfWeek,
              startTime: intervals[i],
              endTime: intervals[i + 1],
              title: userType === 'doctor' && currentuser.ordination.length ? day.ordination?.name : '',
              ordination: day.ordination?._id,
              display: 'background',
              color: 'white',
              extendedProps: {
                date: day.date,
              },
            });
          }
        });
      });

      setEvents(
        !selectedOrdination || selectedOrdination === 'all'
          ? data
          : data.filter(
            (d) =>
              d.extendedProps?.ordination?._id === selectedOrdination ||
              (d.display === 'background' && d.ordination === selectedOrdination),
          ),
      );
      setIsMounted(true);
    }
  }, [realWorkTime, selectedOrdination, businessHours, calendarData, setEvents, workingDays, singleDays, displayDate, notificationEvents]);

  const showCalendarModal = () => {
    setIsCalendarModalVisible(true);
  };

  const handleSelectedDates = (info) => {
    const endDate = info.end;
    const day = new Date(info.start).getDay();
    const startTime = new Date(info.start).toTimeString();
    const ordinations = events.filter((event) => event.daysOfWeek?.length);

    const workDay = ordinations.find(
      (ord) => ord.daysOfWeek[0] === day && ord.startTime < startTime && ord.endTime > startTime,
    );

    let ordination;
    if (businessHours[0].ordination && workDay) {
      ordination = businessHours.find((hour) => hour.ordination._id === workDay.ordination);
    }

    if (ordination && ordination.appointmentDuration) {
      endDate.setMinutes(endDate.getMinutes() + +ordination.appointmentDuration - 15);
      setSelectedDateInfo({ ...info, end: endDate });
    } else {
      setSelectedDateInfo(info);
    }
    setEditCalendarDate(undefined);
    showCalendarModal();
  };

  const handleCalendarModalCancel = () => {
    setIsCalendarModalVisible(false);
  };

  const openEditModal = async (event) => {
    setStartTime(new Date(event.event._def.extendedProps.start).toTimeString().substring(0, 8));
    const calendarId = event.event._def.extendedProps.id;
    if (calendarId) {
      await Axios.get(`${SERVER_URL}/calendar/${calendarId}`, headers)
        .then((res) => {
          setSelectedDateInfo(undefined);
          setEditCalendarDate({ ...res.data, isEdit: true });
          showCalendarModal();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    if (isMounted && !businessHours.length) {
      notification.info({
        message: 'Morate postaviti radno vreme kako biste omogućili rad kalendara',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [isMounted, businessHours.length]);

  const updateCalendarDate = async (eventDropInfo) => {
    const startDate = eventDropInfo.event.start;
    const ordId = eventDropInfo.event.extendedProps.ordination?._id;

    if (ordId) {
      const workingDaysForOrdination = workingDays.find((ord) => ord.ordination?._id === ordId);
      const specificDay =
        workingDaysForOrdination?.nonWorking?.find(
          (day) => new Date(day.date).toLocaleDateString() === new Date(startDate).toLocaleDateString(),
        ) || [];
      const ordinationData = await Axios.get(`${SERVER_URL}/ordination/${ordId}`, headers);
      let workDay;

      switch (startDate.toString().split(' ')[0]) {
        case 'Mon':
          workDay = 1;
          break;
        case 'Tue':
          workDay = 2;
          break;
        case 'Wed':
          workDay = 3;
          break;
        case 'Thu':
          workDay = 4;
          break;
        case 'Fri':
          workDay = 5;
          break;
        case 'Sat':
          workDay = 6;
          break;
        case 'Sun':
          workDay = 0;
          break;
        default:
          break;
      }

      if (businessHours[0].ordination) {
        const allowedDrops = businessHours.filter((allow) => allow.ordination._id === ordId);
        const startTime = startDate.toString().split(' ')[4];

        if (specificDay.length) {
          const isAllowedForNonWorkingDays = allowedDrops.find(
            (hour) =>
              hour.endTime >= startTime &&
              specificDay.to.substring(0, 5) > startTime.substring(0, 5) &&
              hour.startTime < startTime &&
              specificDay.from.substring(0, 5) <= startTime.substring(0, 5) &&
              workDay === hour.daysOfWeek[0],
          );

          if (isAllowedForNonWorkingDays) {
            eventDropInfo.revert();
            return notification.info({
              message: `Vreme ne pripada radu doktora za ordinaciju ${ordinationData.data.name}.`,
              placement: 'bottomRight',
              duration: 2.5,
            });
          }
        }

        const isAllowed = allowedDrops.find(
          (day) => day.daysOfWeek[0] === workDay && day.endTime >= startTime && day.startTime < startTime,
        );

        if (!isAllowed) {
          eventDropInfo.revert();
          return notification.info({
            message: `Vreme ne pripada radu doktora za ordinaciju ${ordinationData.data.name}.`,
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
      }
    }

    const name = `${eventDropInfo.event.extendedProps.patient.firstName} ${eventDropInfo.event.extendedProps.patient.lastName}`;
    setChangeDate({
      toChange: true,
      event: eventDropInfo,
      patientName: name,
    });
  };

  const acceptUpdateDate = async () => {
    const calendarId = changeDate.event.event.extendedProps.id;
    const startDate = changeDate.event.event.start;
    const endDate = changeDate.event.event.end;
    await Axios.put(`${SERVER_URL}/calendar/${calendarId}`, { startDate, endDate, userType }, headers)
      .then(async (res) => {
        let socket = io(SOCKET_URL, { path: '/socket.io' });
        const response = res.data.updatedDate;
        const updatedData = { ...res.data.updatedDate, isEdit: true };
        const patientId = changeDate.event.event.extendedProps.patient._id;
        const ordinationId = changeDate.event.event.extendedProps.ordination?._id;
        const doctorId = changeDate.event.event.extendedProps.doctor;
        const examinationType = changeDate.event.event.extendedProps.examinationType;
        const startDate = response.startDate;
        const endDate = response.endDate;
        let message = 'CHANGED_TERM';

        // does the created appointment overlaps with any other
        // if yes send notification to ordination
        if (ordinations.length && userType === 'doctor') {
          const { data } = await Axios.post(`${SERVER_URL}/calendar-doctor/${doctorId}`, { ordinations }, headers);
          const appointments = data.filter(
            (c) =>
              new Date(c.startDate).toLocaleDateString() === new Date(startDate).toLocaleDateString() &&
              c._id !== calendarId,
          );
          appointments.forEach((termin) => {
            if (
              (termin.startDate > startDate && termin.endDate < endDate) ||
              (termin.startDate <= startDate && termin.endDate >= endDate) ||
              (termin.endDate > startDate && termin.endDate < endDate) ||
              (termin.startDate > startDate && termin.startDate < endDate)
            ) {
              message = 'OVERLAP_CHANGED_TERM';
            }
          });
        }

        if (ordinationId) {
          await Axios.post(
            `${SERVER_URL}/notifications`,
            {
              patient: patientId,
              requestedBy: userType,
              doctor: doctorId,
              ordination: ordinationId,
              appointment: calendarId,
              examinationType,
              message,
              startDate,
            },
            headers,
          );
        }

        notification.success({
          message: 'Datum je promenjen.',
          placement: 'bottomRight',
          duration: 2.5,
        });

        if (ordinationId) {
          try {
            const doctor = businessHours.find((hour) => hour.ordination?._id === ordinationId);
            if (doctor && doctor.doctorEmail && userType === 'doctor') {
              const { data } = await Axios.post(
                `${SERVER_URL}/calendar/email`,
                {
                  email: doctor.doctorEmail,
                  message: 'UPDATE',
                  props: {
                    examinationType: changeDate.event.event.extendedProps.examinationType,
                    patientName: `${changeDate.event.event.extendedProps.firstName} ${changeDate.event.event.extendedProps.lastName}`,
                    startDate: new Date(changeDate.event.event._instance.range.start).toLocaleString(),
                    endDate: new Date(changeDate.event.event._instance.range.end).toLocaleString(),
                    doctorName: `${currentuser.firstName} ${currentuser.lastName}`,
                  },
                },
                headers,
              );
              notification.info({
                message: data.message,
                placement: 'bottomRight',
                duration: 2.5,
              });
            }
          } catch (error) {
            console.log(error.message);
          }

          await Axios.post(
            `${SERVER_URL}/send-push-token/${patientId}`,
            {
              startDate,
              endDate,
              calendarId,
              message: 'CHANGED_TERM',
              doctor: doctorId,
              ordination: ordinationId,
            },
            headers,
          );
          if (userType === 'doctor') {
            socket.emit('new-notification-ordination-created', {
              ordinationId,
              doctorId,
              patientId,
              startDate,
              endDate,
              calendarId,
              message,
              requestedBy: 'doctor',
            });
          } else if (userType === 'ordination') {
            socket.emit('new-notification-doctor-created', {
              doctorId,
              ordinationId,
              patientId,
              startDate,
              endDate,
              calendarId,
              message: 'CHANGED_TERM',
              requestedBy: 'ordination',
            });
          }
        }

        setEditCalendarDate(updatedData);
        setChangeDate({ toChange: false, event: null });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const declineUpdateDate = () => {
    changeDate.event.revert();
    setChangeDate({ toChange: false, event: null });
  };

  const resizeDate = async (eventDropInfo) => {
    const oldStartDate = eventDropInfo.event.start;
    const oldEndDate = eventDropInfo.event.end;
    const calendarId = eventDropInfo.event.extendedProps.id;
    await Axios.put(`${SERVER_URL}/calendar/${calendarId}`, { oldStartDate, oldEndDate, userType }, headers)
      .then(async (res) => {
        let socket = io(SOCKET_URL, { path: '/socket.io' });
        const response = res.data.updatedDate;
        const appointment = response._id;
        const doctorId = response.doctor._id;
        const ordinationId = response.ordinations;
        const patientId = response.patient._id;
        const examinationType = response.examinationType;
        let startDate = response.startDate;
        let endDate = response.endDate;
        let message = 'PROLONGUE';

        await Axios.post(
          `${SERVER_URL}/notifications`,
          {
            patient: patientId,
            requestedBy: userType,
            doctor: doctorId,
            ordination: ordinationId,
            appointment: calendarId,
            examinationType,
            message,
            startDate,
          },
          headers,
        );

        await Axios.post(
          `${SERVER_URL}/send-push-token/${patientId}`,
          {
            startDate,
            endDate,
            calendarId,
            message: 'CHANGED_TERM',
            doctor: doctorId,
            ordination: ordinationId,
          },
          headers,
        );

        // does the prolongued appointment overlaps with any other
        // if yes send notification to ordination
        if (ordinations.length && userType === 'doctor') {
          const { data } = await Axios.post(`${SERVER_URL}/calendar-doctor/${doctorId}`, { ordinations }, headers);
          const appointments = data.filter(
            (c) =>
              new Date(c.startDate).toLocaleDateString() === new Date(startDate).toLocaleDateString() &&
              c._id !== calendarId,
          );
          appointments.forEach((termin) => {
            startDate = new Date(response.startDate).toLocaleString();
            endDate = new Date(response.endDate).toLocaleString();
            const terminStartDate = new Date(termin.startDate).toLocaleString();
            const terminEndDate = new Date(termin.endDate).toLocaleString();
            if (
              (terminStartDate > startDate && terminEndDate < endDate) ||
              (terminStartDate <= startDate && terminEndDate >= endDate) ||
              (terminEndDate > startDate && terminEndDate < endDate) ||
              (terminStartDate > startDate && terminStartDate < endDate)
            ) {
              message = 'OVERLAP_PROLONGUE_TERM';
            }
          });

          socket.emit('new-notification-ordination-created', {
            ordinationId,
            patientId,
            doctorId,
            startDate: oldStartDate,
            endDate: oldEndDate,
            message,
            requestedBy: 'doctor',
            calendarId: appointment,
          });
        } else if (userType === 'ordination') {
          socket.emit('new-notification-doctor-created', {
            ordinationId,
            patientId,
            doctorId,
            startDate,
            endDate,
            message,
            requestedBy: 'ordination',
            calendarId: appointment,
          });
        }

        const doctor = businessHours.find((hour) => hour.ordination?._id === res.data.updatedDate.ordinations);
        if (doctor && doctor.doctorEmail && userType === 'doctor') {
          const { data } = await Axios.post(
            `${SERVER_URL}/calendar/email`,
            {
              email: doctor.doctorEmail,
              message: 'PROLONGUE',
              props: {
                examinationType: res.data.updatedDate.examinationType,
                patientName: `${res.data.updatedDate.patient.firstName} ${res.data.updatedDate.patient.lastName}`,
                startDate: new Date(startDate).toLocaleString(),
                endDate: new Date(endDate).toLocaleString(),
                doctorName: `${currentuser.firstName} ${currentuser.lastName}`,
              },
            },
            headers,
          );
          notification.info({
            message: data.message,
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
        const updatedData = { ...res.data.updatedDate, isEdit: true };
        setEditCalendarDate(updatedData);
        notification.success({
          message: 'Datum je promenjen.',
          placement: 'bottomRight',
          duration: 2.5,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getWorkingDay = useCallback(async () => {
    try {
      if (currentuser.userType === 'doctor') {
        const { data } = await Axios.get(`${SERVER_URL}/doctor/workingDay/${currentuser.doctor}`, headers);
        setWorkingDays(data);
      } else {
        const { data } = await Axios.post(
          `${SERVER_URL}/workingDay/${doctorId}`,
          { ordination: currentuser.ordination },
          headers,
        );
        setWorkingDays(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  const getOrdinationWorkingDay = async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/ordination/workingDay`,
        { ordinations: currentuser.ordination },
        headers,
      );
      setOrdinationWorkingDays(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const createGreenThMark = useCallback(() => {
    const thToday = today.toISOString().substring(0, 10);
    const thElement = document.querySelectorAll(`th [data-date='${thToday}']`);

    for (let i = 0; i < thElement.length; i++) {
      thElement[i].classList.add('active');
    }

    $(document).ready(function () {
      if (thElement) {
        $('.fc-col-header-cell-cushion').addClass('active');
      }
    });
  }, []);

  useEffect(() => {
    if (doctorId !== 'undefined' && doctorId) {
      getWorkingDay();
      getOrdinationWorkingDay();
    }

    createGreenThMark();
  }, [doctorId, createGreenThMark, getWorkingDay, notificationEvents]);

  // format display of non working days
  useEffect(() => {
    const data = [];
    const newNonWorking = [];

    if (ordinationWorkingDays.length && businessHours.length) {
      ordinationWorkingDays.forEach((day) => {
        businessHours.forEach((workHour) => {
          if (day.ordination._id === workHour.ordination._id) {
            day.nonWorking?.forEach((nonDay) => {
              if (new Date(nonDay.date).toDateString() === new Date(workHour.date).toDateString()) {
                const [nonStartHours, nonStartMinutes] = nonDay.from.split(':');
                const [nonEndHours, nonEndMinutes] = nonDay.to.split(':');
                const [workStartHours, workStartMinutes] = workHour.startTime.split(':');
                const [workEndHours, workEndMinutes] = workHour.endTime.split(':');

                let start = new Date(nonDay.date).setHours(nonStartHours, nonStartMinutes);
                let end = new Date(nonDay.date).setHours(nonEndHours, nonEndMinutes);

                if (nonDay.from < workHour.startTime && nonDay.to > workHour.endTime) {
                  start = new Date(workHour.date).setHours(workStartHours, workStartMinutes);
                  end = new Date(workHour.date).setHours(workEndHours, workEndMinutes);
                } else if (nonDay.from < workHour.startTime) {
                  start = new Date(workHour.date).setHours(workStartHours, workStartMinutes);
                } else if (nonDay.to > workHour.endTime) {
                  end = new Date(workHour.date).setHours(workEndHours, workEndMinutes);
                }

                if (!data.find((d) => d._id === day._id)) {
                  newNonWorking.push({
                    date: workHour.date,
                    from: new Date(start).toLocaleTimeString('sr', 'RS').substring(0, 5),
                    to: new Date(end).toLocaleTimeString('sr', 'RS').substring(0, 5),
                  });
                }
              }
            });
          }
        });

        data.push({ ...day, nonWorking: newNonWorking });
      });
    }

    setRealWorkTime(data);
  }, [ordinationWorkingDays, businessHours]);

  useEffect(() => {
    const days = [];

    // add working days
    workingDays?.forEach((ordination) => {
      Object.keys(ordination).forEach((key, _) => {
        if (ordination[key]?.from && ordination[key]?.to) {
          const date = displayDate.find((date) => dani[new Date(date).getDay()] === key);
          const [startHours, startMinutes] = ordination[key].from.split(':');
          const [endHours, endMinutes] = ordination[key].to.split(':');

          const startTime = `${startHours}:${startMinutes < 1 ? `00` : startMinutes <= 15 ? 15 : startMinutes <= 30 ? 30 : 45
            }`;
          const endTime = `${endHours}:${endMinutes < 1 ? `00` : endMinutes <= 15 ? 15 : endMinutes <= 30 ? 30 : 45}`;

          days.push({
            daysOfWeek: [dani.findIndex((a) => a === key)],
            startTime,
            endTime,
            appointmentDuration: ordination.duration,
            ordination: ordination.ordination,
            date,
            doctorEmail: ordination.doctorEmail ? ordination.doctorEmail : null,
          });
        }
      });
    });

    // add ordination single work days
    ordSingleDays?.forEach((yad) => {
      yad.days.forEach((day) => {
        if (displayDate.includes(day.date.substring(0, 10))) {
          if (new Date(day.date).getTime() > new Date().getTime()) {
            const [startHours, startMinutes] = day.from.split(':');
            const [endHours, endMinutes] = day.to.split(':');

            const startTime = `${startHours}:${startMinutes < 1 ? `00` : startMinutes <= 15 ? 15 : startMinutes <= 30 ? 30 : 45
              }`;
            const endTime = `${endHours}:${endMinutes < 1 ? `00` : endMinutes <= 15 ? 15 : endMinutes <= 30 ? 30 : 45}`;

            days.push({
              daysOfWeek: [new Date(day.date).getDay()],
              startTime,
              endTime,
              appointmentDuration: yad.duration,
              ordination: yad.ordination,
              date: day.date,
            });
          }
        }
      });
    });

    // add single work days
    if (singleDays.length) {
      singleDays.forEach((single) =>
        single.days.forEach((yad) => {
          yad.date = new Date(yad.date).toISOString().slice(0, 10);
          displayDate.forEach((date) => {
            if (date === yad.date) {
              days.forEach((day) => {
                const weekday = new Date(yad.date).getDay();
                if (day.daysOfWeek[0] === weekday && day.ordination?._id === single.ordination?._id) {
                  day.startTime = yad.from;
                  day.endTime = yad.to;
                }
              });
            } else {
              if (displayDate.includes(yad.date)) {
                const date = new Date(yad.date);
                const [startHours, startMinutes] = yad.from.split(':');
                const [endHours, endMinutes] = yad.to.split(':');

                const startTime = `${startHours}:${startMinutes < 1 ? `00` : startMinutes <= 15 ? 15 : startMinutes <= 30 ? 30 : 45
                  }`;
                const endTime = `${endHours}:${endMinutes < 1 ? `00` : endMinutes <= 15 ? 15 : endMinutes <= 30 ? 30 : 45
                  }`;

                days.push({
                  daysOfWeek: [dani.findIndex((_, i) => i === date.getDay())],
                  startTime,
                  endTime,
                  appointmentDuration: single.duration,
                  ordination: single.ordination,
                  date: yad.date,
                });
              }
            }
          });
        }),
      );
    }

    setBusinessHours(
      !selectedOrdination || selectedOrdination === 'all'
        ? days
        : days.filter((d) => d.ordination._id === selectedOrdination),
    );
  }, [workingDays, singleDays, displayDate, selectedOrdination, ordSingleDays]);

  const freeDay = (event) => {
    const clickedStartDay = event.start.getDay();
    const clickedStartTime = event.start.toLocaleTimeString();
    const newClickedStartTime = moment(clickedStartTime, 'hh:mm A').format('HH:mm:ss');
    setStartTime(newClickedStartTime);
    const currentDate = new Date(event.start).toISOString().substring(0, 10);

    let docTime = '',
      ordTime = '';
    const doctorOpen = workingDays?.find((ordination) => {
      const ordId = businessHours.find(
        (hour) =>
          hour.ordination === ordination.ordination &&
          ordination[dani[clickedStartDay]]?.from < newClickedStartTime &&
          ordination[dani[clickedStartDay]]?.to > newClickedStartTime,
      );

      if (ordId && ordination.nonWorking?.length) {
        docTime = ordination.nonWorking.find(
          (not) =>
            not.date.includes(currentDate) && not.from < newClickedStartTime && not.to > newClickedStartTime && not,
        );
        const isAppointable = ordination.nonWorking.find(
          (not) =>
            not.date.includes(currentDate) && not.from < newClickedStartTime && not.to > newClickedStartTime && not,
        );
        if (isAppointable) {
          return true;
        }
        return false;
      }
      return false;
    });

    const ordinationOpen = realWorkTime?.find((ordination) => {
      if (ordination.nonWorking?.length) {
        ordTime = ordination.nonWorking.find(
          (not) =>
            not.date.includes(currentDate) && not.from < newClickedStartTime && not.to > newClickedStartTime && not,
        );
        const isAppointable = ordination.nonWorking.find(
          (not) =>
            not.date.includes(currentDate) && not.from < newClickedStartTime && not.to > newClickedStartTime && not,
        );
        if (isAppointable) {
          return true;
        }
        return false;
      }
      return false;
    });

    if (event.start > new Date()) {
      if (doctorOpen) {
        // if you clicked on nonworking day set by doctor
        Axios.get(`${SERVER_URL}/doctors/${doctorOpen.doctor}`, headers)
          .then((doc) => {
            if (doctorOpen.ordination?._id) {
              Axios.get(`${SERVER_URL}/ordination/${doctorOpen.ordination._id}`, headers)
                .then((ord) => {
                  notification.info({
                    message: `Doktor ${doc.data.firstName} ${doc.data.lastName} neće raditi u ordinaciji ${ord.data.name
                      } ${moment(currentDate).locale('sr').format('DD. MMMM YYYY.')} od ${docTime?.from} do ${docTime?.to
                      }.`,
                    placement: 'bottomRight',
                    duration: 2.5,
                  });
                })
                .catch((err) => console.log(err.message));
            } else {
              notification.info({
                message: `Doktor ${doc.data.firstName} ${doc.data.lastName} neće raditi ${moment(currentDate)
                  .locale('sr')
                  .format('DD. MMMM YYYY.')} od ${docTime?.from} do ${docTime?.to}.`,
                placement: 'bottomRight',
                duration: 2.5,
              });
            }
          })
          .catch((err) => console.log(err.message));

        return 0;
      } else if (!doctorOpen && ordinationOpen?.ordination) {
        // if you clicked on nonworking day set by ordination
        notification.info({
          message: `Klinika neće raditi ${moment(currentDate)
            .locale('sr')
            .format('DD. MMMM YYYY.')} u periodu od ${ordTime?.from} do ${ordTime?.to}.`,
          placement: 'bottomRight',
          duration: 2.5,
        });
        return 0;
      }
      return 1;
    }
    return 1;
  };

  const allowClick = () => {
    form.resetFields();

    if (!businessHours.length) {
      notification.info({
        message: 'Morate postaviti radno vreme kako biste omogućili rad kalendara.',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const navMenuToggle = () => {
    setNavMenuOpen(!isNavMenuOpen);
  };

  $(document).on('click', '.fc-next-button', function () {
    createGreenThMark();
  });

  $(document).on('click', '.fc-prev-button', function () {
    createGreenThMark();
  });

  $('.fc-today-button').click(function () {
    setTimeout(() => {
      createGreenThMark();
    }, 50);
  });

  // switch for calendar view
  useEffect(() => {
    if (sessionStorage.getItem('view')) {
      setCurView(sessionStorage.getItem('view'));
    }
  }, [curView]);
  // switch for calendar view

  const setWorkingHours = () => {
    const dates = document.querySelectorAll('[role="columnheader"]');
    setDisplayDate([...dates]?.map((data) => data.dataset.date));
  };

  useEffect(() => {
    const dates = document.querySelectorAll('[role="columnheader"]');
    setDisplayDate([...dates]?.map((data) => data.dataset.date));
  }, []);

  useEffect(() => {
    nextBtn?.addEventListener('click', () => setTimeout(setWorkingHours, 10));
    prevBtn?.addEventListener('click', () => setTimeout(setWorkingHours, 10));
    todayBtn?.addEventListener('click', () => setTimeout(setWorkingHours, 10));

    return () => {
      nextBtn?.removeEventListener('click', setWorkingHours);
      prevBtn?.removeEventListener('click', setWorkingHours);
      todayBtn?.removeEventListener('click', setWorkingHours);
    };
  }, [nextBtn, prevBtn, todayBtn]);

  const viewOrdinationAppointments = useCallback((e) => {
    const ordId = e.target.getAttribute('id');
    setSelectedOrdination(ordId);
  }, []);

  useEffect(() => {
    if (currentuser.userType === 'doctor' && ordinations.length && ordinations?.length > 0) {
      const parent = document.querySelector('.fc-toolbar-chunk:nth-child(2)');
      const elements = ordinations.map((ord) =>
        React.createElement(
          'div',
          {
            key: ord._id,
            id: ord._id,
            onClick: viewOrdinationAppointments,
            className: selectedOrdination === ord._id ? 'single-ord-active' : 'single-ord',
          },
          ord.name,
        ),
      );
      if (ordinations.length && ordinations?.length > 1) {
        elements.unshift(
          React.createElement(
            'div',
            {
              key: '1',
              id: 'all',
              onClick: viewOrdinationAppointments,
              className: selectedOrdination === 'all' ? 'single-ord-active' : 'single-ord',
            },
            'Sve klinike',
          ),
        );
      }
      ReactDOM.render(elements, parent);
    }
  }, [ordinations, viewOrdinationAppointments, selectedOrdination]);

  return (
    <>
      {userType === 'ordination' ? (
        <div className='doctors-nav'>
          <div className='doctors-nav-content'>
            <button className='action-button doctors-nav-button' onClick={navMenuToggle}>
              <span>{isNavMenuOpen ? 'Izaberite lekara' : 'Zatvorite izbor lekara'}</span>
            </button>
          </div>
        </div>
      ) : null}

      <div id='calendar' className={userType === 'ordination' ? 'left-spacing-calendar' : ''}>
        {curView === 'timeGridDay' && (
          <FullCalendar
            initialView='timeGridDay'
            allDaySlot={false}
            selectable={true}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            eventClick={(event) => openEditModal(event)}
            dateClick={(e) => allowClick(e)}
            selectAllow={(e) => freeDay(e)}
            events={events}
            select={handleSelectedDates}
            locales={allLocales}
            locale='sr-Latn-RS'
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
            slotDuration={'00:15:00'}
            slotMinTime='7:00'
            slotMaxTime='22:00'
            ref={calendarComponentRef}
            editable={true}
            titleFormat={{ year: 'numeric', month: 'long', week: 'long', day: '2-digit' }}
            eventDrop={updateCalendarDate}
            eventResize={resizeDate}
            nowIndicator={true}
            selectConstraint={businessHours}
            eventConstraint={businessHours}
            businessHours={businessHours.length ? businessHours : { daysOfWeek: [] }}
            displayEventTime={false}
          />
        )}
        {curView === 'timeGridWeek' && (
          <FullCalendar
            initialView='timeGridWeek'
            allDaySlot={false}
            selectable={true}
            dayHeaders={true}
            dayHeaderFormat={{ weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            eventClick={(event) => openEditModal(event)}
            dateClick={(e) => allowClick(e)}
            selectAllow={(e) => freeDay(e)}
            events={events}
            select={handleSelectedDates}
            locales={allLocales}
            locale='sr-Latn-RS'
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
            slotDuration={'00:15:00'}
            slotMinTime='7:00'
            slotMaxTime='22:00'
            ref={calendarComponentRef}
            editable={true}
            titleFormat={{ year: 'numeric', month: 'long', week: 'long', day: '2-digit' }}
            eventDrop={updateCalendarDate}
            eventResize={resizeDate}
            nowIndicator={true}
            selectConstraint={businessHours}
            eventConstraint={businessHours}
            businessHours={businessHours.length ? businessHours : { daysOfWeek: [] }}
            displayEventTime={false}
          />
        )}
      </div>
      {changeDate?.toChange && (
        <Modal
          className='doctor-modal'
          visible={changeDate}
          onCancel={declineUpdateDate}
          footer={null}
          width={500}
          he
          centered
        >
          <Card title='Potvrdite promenu datuma' bordered={false} className='calendar-data-form-card date-change'>
            <div className='date-change-container'>
              <p>Jeste li sigurni da želite da promenite datum termina za pacijenta {changeDate.patientName}?</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button className='action-button' onClick={acceptUpdateDate}>
                  <img src='/images/save.svg' alt='accept' />
                  <span>Potvrdi</span>
                </button>
                <button style={{ marginRight: 0 }} className='action-button delete-button' onClick={declineUpdateDate}>
                  <img src='/images/delete.svg' alt='delete' />
                  <span>Otkaži</span>
                </button>
              </div>
            </div>
          </Card>
        </Modal>
      )}
      <CalendarDataForm
        props={props}
        events={events}
        setEvents={setEvents}
        startTime={startTime}
        businessHours={businessHours}
        selectedInfoDate={selectedDateInfo}
        handleCalendarModalCancel={handleCalendarModalCancel}
        editCalendarDate={editCalendarDate}
      />
    </>
  );
};

export default CalendarFull;
