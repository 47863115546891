import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';

const Routes = ({ notificationEvents, setEventToUpdate }) => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <ProtectedRoute user={user.data} exact path='/admin' component={Home} allowed={['admin']} />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) =>
        route.children.map((item, i) => (
          <ProtectedRoute
            exact
            user={user.data}
            key={`R_${i}`}
            path={item.path}
            component={item.component}
            allowed={item.allowed}
            notificationEvents={notificationEvents}
            setEventToUpdate={setEventToUpdate}
          />
        )),
      )}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/admin' /> : <Login />)} />
    </Switch>
  );
};

export default Routes;
