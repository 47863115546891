import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const PatientNav = () => {
  const userType = user.userType;
  const location = useLocation();
  const pathName = location.pathname;
  const [appointable, setAppointable] = useState(false);

  const appointment = sessionStorage.getItem('appointment');
  const patientUrlData = pathName.split('/');
  const currentPatientUrlData = patientUrlData[3];
  const [firstName, lastName] = currentPatientUrlData.split('-');
  const patientId = currentPatientUrlData.split('-')[2];

  const getAppointable = useCallback(async () => {
    try {
      const findPatient = await axios.get(`${SERVER_URL}/patient/appointment/${patientId}`, headers);
      setAppointable(findPatient.data);
    } catch (err) {
      console.log(err.message);
    }
  }, [patientId]);

  useEffect(() => {
    getAppointable();
  }, [getAppointable]);

  let paths;
  if (appointable && appointment && userType !== 'ordination') {
    paths = [
      { link: `/admin/patients/${currentPatientUrlData}`, linkText: 'Podaci pacijenta' },
      { link: `/admin/patients/${currentPatientUrlData}/istorija`, linkText: 'Dokumenta' },
      // { link: `/admin/patients/${currentPatientUrlData}/izvestaji`, linkText: 'Kreiraj izveštaj' },
      // { link: `/admin/patients/${currentPatientUrlData}/recept`, linkText: 'Kreiraj recept' },
    ];
  } else {
    paths = [
      { link: `/admin/patients/${currentPatientUrlData}`, linkText: 'Podaci pacijenta' },
      { link: `/admin/patients/${currentPatientUrlData}/istorija`, linkText: 'Dokumenta' },
    ];
  }

  return (
    <div className='patient-nav'>
      <ul>
        {paths.map((path, index) => {
          return (
            <Link key={index} to={path.link}>
              <li className={pathName === path.link ? 'patient-nav-link active' : 'patient-nav-link'}>
                {path.linkText}
              </li>
            </Link>
          );
        })}
        {!appointment && <p style={{ color: 'white', margin: 'auto 1rem' }}>{firstName.charAt(0).toUpperCase() + firstName.slice(1)} {lastName.charAt(0).toUpperCase() + lastName.slice(1)}</p>}
      </ul>
    </div>
  );
};

export default PatientNav;
