import React, { useEffect, useRef, useState, useCallback } from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import 'moment/locale/sr';
import DocumentsTable from '../../components/tables/DocumentsTable';

const user = JSON.parse(sessionStorage.getItem('user'));
const userType = user?.userType;
const appointment = sessionStorage.getItem('appointment');

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const PatientHistory = () => {
  moment.locale('sr');
  const history = useHistory();
  const [patientId, setPatientId] = useState(undefined);
  const [documents, setDocuments] = useState([]);
  const printRef = useRef();

  const location = useLocation();
  const pathName = location.pathname;
  const [appointable, setAppointable] = useState(false);

  const patientUrlData = pathName.split('/');
  const currentPatientUrlData = patientUrlData[3];
  const patientId2 = currentPatientUrlData.split('-')[2];

  useEffect(() => {
    const queryName = history.location.pathname.split('/')[history.location.pathname.split('/').length - 2];
    const id = queryName.split('-').pop();
    setPatientId(id);
  }, [history.location.pathname]);

  const getPatientDocuments = useCallback(async () => {
    try {
      const documents = await Axios.get(
        `${SERVER_URL}/documents/patient/${patientId}?doctor=${user?.doctor}`,
        headers,
      );
      setDocuments(documents.data);
    } catch (error) {
      console.log(error.message);
    }
  }, [patientId]);

  useEffect(() => {
    if (patientId && user?.doctor) {
      getPatientDocuments();
    }
  }, [patientId, getPatientDocuments]);

  const getAppointable = useCallback(async () => {
    try {
      const findPatient = await Axios.get(`${SERVER_URL}/patient/appointment/${patientId2}`, headers);
      setAppointable(findPatient.data);
    } catch (err) {
      console.log(err.message);
    }
  }, [patientId2]);

  useEffect(() => {
    getAppointable();
  }, [getAppointable]);

  const columnKeys = ['name', 'date', 'examinationType', 'doctorName'];
  const columnKeysTranslated = ['Naziv', 'Datum', 'Vrsta Pregleda', 'Doktor'];

  const data = documents?.map(document => {
    if (document.type === 'dokument') {
      return {
        ...document,
        date: moment(document.createdAt).locale('sr').format('DD. MMMM YYYY.')
      };
    }
    return document;
  });

  return (
    <div className='patients-table'>
      <div
        className={
          appointable && appointment && userType !== 'ordination'
            ? 'patients-container top-space'
            : 'patients-container'
        }
      >
        {documents && (
          <div className='table-wrapper'>
            <DocumentsTable
              data={data}
              columnKeys={columnKeys}
              columnKeysTranslated={columnKeysTranslated}
              printRef={printRef}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientHistory;
