import React, { useState, useEffect, useCallback } from 'react';
import { Card, Form, Modal, TimePicker, notification, Popconfirm } from 'antd';
import DayPicker, { DateUtils } from 'react-day-picker';
import { differenceInCalendarDays } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import { io } from 'socket.io-client';

import RemoveWorkDayModal from './RemoveWorkDayModal';
import DeleteWorkDayModal from './DeleteWorkDayModal';
import { SERVER_URL, SOCKET_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const format = 'HH:mm';
const dani = ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'];

const SingleDayModal = ({
  requestedBy,
  singleDayOpen,
  setSingleDayOpen,
  doctor,
  ordination,
  allSingleDays,
  singleDayId,
  setSingleDayId,
  getAllSingleDays,
  workingDays,
  allWorkingDays,
  ordinationWorkDay,
  appointmentDuration,
}) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [doctorSingleDays, setDoctorSingleDays] = useState([]);
  const [removeAppointments, setRemoveAppointments] = useState(false);
  const [appointments, setAppointments] = useState({});
  const [removeWorkDay, setRemoveWorkDay] = useState({
    open: false,
    data: [],
    mode: '',
    time: {},
    nonWork: undefined,
  });

  const disabledDays = (date) => {
    return differenceInCalendarDays(date, new Date()) < 0;
  };

  const getDoctorSingleDays = useCallback(async () => {
    try {
      if (doctor)
        if (ordination) {
          const { data } = await axios.get(
            `${SERVER_URL}/singleDay/${doctor}?type=ordination&ord=${user.ordination}`,
            headers,
          );
          setDoctorSingleDays(data.filter((d) => d.ordination._id !== ordination));
        } else {
          const { data } = await axios.get(`${SERVER_URL}/singleDay/${doctor}?type=doctor`, headers);
          setDoctorSingleDays(data.filter((d) => d.doctor._id !== doctor));
        }
    } catch (error) {
      console.log(error.message);
    }
  }, [doctor, ordination]);

  useEffect(() => {
    getDoctorSingleDays();
  }, [getDoctorSingleDays]);

  useEffect(() => {
    if (singleDayId) {
      const day = allSingleDays.find((yad) => yad._id === singleDayId);
      setSelectedDays(day.days);
    } else {
      setSelectedDays([]);
    }
  }, [allSingleDays, singleDayId]);

  const createSingleDay = async (values) => {
    selectedDays.forEach((day) => {
      const date = new Date(day.date).toLocaleDateString('sr-Latn-RS', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (day.from > day.to) {
        day.error = true;
        notification.warn({
          message: `OD mora biti manje od DO za ${date}`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if ((!day.from && day.to) || (day.from && !day.to) || (!day.from && !day.to)) {
        day.error = true;
        notification.warn({
          message: `Popunite u celosti ${date}`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      if (day.from < day.to) {
        delete day.error;
      }
    });

    let error = selectedDays.find((day) => day.error);
    if (error) return;

    values.ordination = ordination;
    values.doctor = doctor;
    values.days = selectedDays;
    values.duration = appointmentDuration;

    try {
      const socket = io(SOCKET_URL, { path: '/socket.io' });
      if (singleDayId) {
        // update
        const { data } = await axios.put(`${SERVER_URL}/singleDay/${singleDayId}`, values, headers);
        const notificationData = {
          ordination,
          doctor,
          miscellaneousData: { oldDays: data.oldDays.days, newDays: data.newDays.days },
          mode: 'UPDATE',
          requestedBy,
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-single-days', notificationData);

        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else {
        // create
        const { data } = await axios.post(`${SERVER_URL}/singleDay`, values, headers);
        const notificationData = {
          ordination,
          doctor,
          miscellaneousData: data.day.days,
          mode: 'CREATE',
          requestedBy,
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-single-days', notificationData);

        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }

      getAllSingleDays();
      setSingleDayOpen(false);
      setSingleDayId(null);
    } catch (error) {
      console.log(error.message);
    }
  };

  // send notifications to ordination and patients for appointment cancellation
  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy,
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-ordination-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });
    }
  };

  const handleDaySelect = async (day, { selected, disabled }) => {
    const days = selectedDays.concat();

    if (!disabled) {
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(new Date(selectedDay.date), day),
        );
        if (days[selectedIndex].from && days[selectedIndex].to) {
          const { data } = await axios.post(
            `${SERVER_URL}/day?single=true`,
            { day: days[selectedIndex], doctorId: doctor },
            headers,
          );
          if (data.length) {
            setRemoveAppointments(true);
            setAppointments({ data, date: day, day: dani[new Date(day).getDay()] });
          } else {
            days.splice(selectedIndex, 1);
          }
        } else {
          days.splice(selectedIndex, 1);
        }
      } else {
        days.push({ date: day, from: '', to: '' });
      }
    }

    days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    setSelectedDays(days);
  };

  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/delete/day`, { days: appointments.data }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      sendDeleteNotification(appointments);

      const days = selectedDays.concat();
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay.date), appointments.date),
      );
      days.splice(selectedIndex, 1);
      setSelectedDays(days);

      setAppointments({});
      setRemoveAppointments(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateTime = (day, toSet, time) => {
    const copy = [...selectedDays];

    const dayInWeek = dani[new Date(day.date).getDay()];

    const pickedDayIndex = copy.findIndex((yad) => yad === day);
    if (pickedDayIndex !== -1) {
      copy[pickedDayIndex][toSet] = time;
    }

    // if from is removed, remove the to too
    if (toSet === 'from' && time === '') {
      const toRemove = copy.indexOf(day);
      if (toRemove > -1) copy.splice(toRemove, 1);
    }

    // is inserted time in work time, if it is display checkbox
    if (copy[pickedDayIndex]) {
      if (workingDays[dayInWeek] && copy[pickedDayIndex].from && copy[pickedDayIndex].to) {
        const restrictFrom = workingDays[dayInWeek].from;
        const restrictTo = workingDays[dayInWeek].to;
        const workFrom = copy[pickedDayIndex].from;
        const workTo = copy[pickedDayIndex].to;
        if ((restrictFrom < workFrom && workFrom < restrictTo) || (restrictFrom < workTo && workTo < restrictTo)) {
          copy[pickedDayIndex].restrict = true;
        } else {
          copy[pickedDayIndex].restrict = false;
        }
      } else {
        delete copy[pickedDayIndex].restrict;
      }
    }

    setSelectedDays(copy);
  };

  const setupHours = async (momentTime, day, toSet) => {
    const time = momentTime !== null ? moment(momentTime).format(format) : '';
    const fromatedDay = dani[new Date(day.date).getDay()];

    // if time is removed from FROM, get all booked appointemnts
    if (time === '' && toSet === 'from') {
      try {
        const { data } = await axios.post(
          `${SERVER_URL}/day?single=true`,
          { day, doctorId: doctor, ordination },
          headers,
        );
        if (data.length) {
          setRemoveAppointments(true);
          setAppointments({ data, day: fromatedDay, toSet, time });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      // otherwise find the day and check if FROM or TO is set
      // if yes, find appointments in that reach
      // if not, update time
      try {
        const { data } = await axios.post(
          `${SERVER_URL}/appointments/reach`,
          { time, day, toSet, doc: doctor, ord: ordination, isDate: true },
          headers,
        );
        if (data.length) {
          setRemoveWorkDay({ open: true, data, mode: 'edit', time: { day, toSet, time } });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const deleteSingleDay = async () => {
    try {
      const socket = io(SOCKET_URL, { path: '/socket.io' });
      const { data } = await axios.delete(`${SERVER_URL}/singleDay/${singleDayId}`, headers);
      const notificationData = {
        ordination,
        doctor,
        miscellaneousData: {},
        mode: 'DELETE',
        requestedBy,
      };
      await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
      socket.emit('send-single-days', notificationData);

      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getAllSingleDays();
      setSingleDayOpen(false);
      setSingleDayId(null);
    } catch (error) {
      console.log(error.message);
    }
  };

  // before deleting all working days, open popup
  const beforeDelete = async () => {
    try {
      let url;
      if (user.ordination.length) {
        url = `${SERVER_URL}/remove/singleDay/${doctor}?type=ordination&ord=${ordination}`;
      } else {
        url = `${SERVER_URL}/remove/singleDay/${doctor}?type=doctor`;
      }
      const { data } = await axios.get(url, headers);
      if (data.length) {
        setRemoveWorkDay({ open: true, data, edit: 'delete' });
      } else {
        deleteSingleDay();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete all appointments in single days
  const deleteAllAppointments = async () => {
    const ids = removeWorkDay.data.map((d) => d._id);
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/delete/singleDay`, { ids }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      deleteSingleDay();
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete appointemnts, which don't enter the new time, when you change time of a work day and send notification
  const deleteDayAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      updateTime(removeWorkDay.time.day, removeWorkDay.time.toSet, removeWorkDay.time.time);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const disabledWorkingHours = (day, restrict) => {
    const hours = [];
    const yad = dani[new Date(day.date).getDay()];

    allWorkingDays.forEach((workDay) => {
      if (workDay[yad] && workDay.ordination._id === ordination) {
        const [startHour] = ordinationWorkDay[yad].from.split(':');
        const [endHour] = ordinationWorkDay[yad].to.split(':');

        for (let i = 0; i < +startHour; i++) hours.push(+i);
        for (let i = +endHour + 1; i < 24; i++) hours.push(+i);
      }
    });

    doctorSingleDays?.forEach((yad) => {
      const toRemove = yad.days.find(
        (y) => y.date.substring(0, 10) === new Date(day.date).toISOString().substring(0, 10),
      );

      if (toRemove) {
        const [fromHours, fromMinutes] = toRemove.from.split(':');
        const [toHours, toMinutes] = toRemove.to.split(':');
        const from = +day.from?.split(':')[0];

        for (let i = +fromHours; i < +toHours; i++) {
          if (+fromMinutes === 0 && i === +fromHours) {
            hours.push(+i);
          } else {
            continue;
          }
          hours.push(+i);
        }

        if (+toMinutes >= 45) hours.push(+toHours);

        for (let i = 0; i < 24; i++) {
          if (restrict) {
            // for FROM
            if (+fromHours >= +from) {
              if (+fromHours < i) hours.push(i);
            } else {
              if (+toHours >= i) hours.push(i);
            }
          } else {
            // for TO
            if (+fromHours < i && i < +toHours) hours.push(i);
            if (+fromMinutes === 0) hours.push(+fromHours);
          }
        }
      }
    });

    hours.push(0, 1, 2, 3, 4, 5, 6, 23);
    return [...new Set(hours)];
  };

  const disabledWorkingMinutes = (day, hour) => {
    const minutes = [];

    allWorkingDays.forEach((workDay) => {
      if (workDay[day] && workDay.ordination._id === ordination) {
        const [fromHour, fromMinute] = ordinationWorkDay[day].from.split(':');
        const [toHour, toMinute] = ordinationWorkDay[day].to.split(':');

        if (+fromHour === hour) {
          for (let i = 0; i < +fromMinute; i++) minutes.push(i);
        }
        if (+toHour === hour) {
          for (let i = +toMinute + 1; i < 60; i++) minutes.push(i);
        }
      }
    });

    doctorSingleDays?.forEach((yad) => {
      const date = yad.days.find((y) => dani[new Date(y.date).getDay()] === day);

      if (date) {
        const [fromHour, fromMinutes] = date.from.split(':');
        const [toHour, toMinutes] = date.to.split(':');

        if (+toHour === hour) {
          for (let i = 0; i < +toMinutes; i++) minutes.push(i);
        }
        if (+fromHour === hour) {
          for (let i = +fromMinutes; i <= 60; i++) minutes.push(i);
        }
      }
    });

    if (hour === 22) {
      minutes.push(15, 30, 45);
    }

    return minutes;
  };

  return (
    <Modal
      className='doctor-modal'
      visible={singleDayOpen}
      onCancel={() => {
        setSingleDayOpen(false);
        setSingleDayId(null);
      }}
      footer={null}
      width={450}
      centered
    >
      <Card
        title={singleDayId ? 'Ažuriraj radne dane' : `Dodaj radni dan`}
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <Form onFinish={(values) => createSingleDay(values)} layout='vertical'>
          <DayPicker
            selectedDays={selectedDays.map((day) => new Date(day.date))}
            disabledDays={disabledDays}
            onDayClick={handleDaySelect}
            firstDayOfWeek={1}
            className='non-working-days-day-picker'
          />
          {selectedDays?.map((day, i) => (
            <div key={i}>
              {new Date(day.date).setHours('22', '0', '0') > new Date().getTime() && (
                <>
                  <p
                    style={{
                      color: '#627b90',
                      margin: 0,
                      marginTop: '.3rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {moment(day.date).locale('sr').format('DD. MMMM YYYY.').toUpperCase()}
                  </p>
                  <div className='flex-row-between'>
                    <span style={{ color: '#627b90' }}>Od:</span>
                    <TimePicker
                      disabledHours={() => disabledWorkingHours(day, false)}
                      disabledMinutes={(hour) =>
                        disabledWorkingMinutes(dani[new Date(day.date).getDay()], hour, 'from')
                      }
                      onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                      bordered={false}
                      showNow={false}
                      minuteStep={15}
                      format={format}
                      onSelect={(time) => setupHours(time, day, 'from')}
                      onChange={(time) => setupHours(time, day, 'from')}
                      value={
                        selectedDays.find((yad) => day === yad)?.from &&
                        moment(selectedDays.find((yad) => day === yad)?.from, format)
                      }
                    />
                    <span style={{ color: '#627b90' }}>Do:</span>
                    <TimePicker
                      disabledHours={() => disabledWorkingHours(day, true)}
                      disabledMinutes={(hour) => disabledWorkingMinutes(dani[new Date(day.date).getDay()], hour, 'to')}
                      onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                      style={{ opacity: `${Boolean(!day.from) ? '.3' : '1'}` }}
                      disabled={Boolean(!day.from)}
                      bordered={false}
                      showNow={false}
                      minuteStep={15}
                      format={format}
                      onSelect={(time) => setupHours(time, day, 'to')}
                      onChange={(time) => setupHours(time, day, 'to')}
                      value={
                        selectedDays.find((yad) => day === yad)?.to &&
                        moment(selectedDays.find((yad) => day === yad)?.to, format)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          ))}
          <div className={`flex-row-${singleDayId ? 'between' : 'end'}`}>
            <button className='action-button border-dark' htmltype='submit' style={{ marginTop: '1rem' }}>
              <img src='/images/save.svg' alt='save' />
              <span>{singleDayId ? 'Ažuriraj' : 'Potvrdi'}</span>
            </button>
            {singleDayId && (
              <Popconfirm
                title='Da li stvarno želite da obrišete radni dan?'
                onConfirm={beforeDelete}
                okText='Da'
                cancelText='Ne'
              >
                <button className='action-button delete-button' style={{ marginTop: '1rem', marginLeft: 'auto' }}>
                  <img src='/images/delete.svg' alt='delete' />
                  <span>Obriši</span>
                </button>
              </Popconfirm>
            )}
          </div>
        </Form>
      </Card>
      <DeleteWorkDayModal
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        deleteDayAppointments={deleteDayAppointments}
        deleteAllAppointments={deleteAllAppointments}
      />
      <RemoveWorkDayModal
        appointments={appointments}
        removeAppointments={removeAppointments}
        setRemoveAppointments={setRemoveAppointments}
        deleteAppointments={deleteAppointments}
      />
    </Modal>
  );
};

export default SingleDayModal;
