import React, { useContext, useEffect, useState, useCallback } from 'react';
import { NotificationTable } from '../../components/tables';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { io } from 'socket.io-client';
import { SidebarContext } from '../../App';
import Axios from 'axios';
import moment from 'moment';

let type = 'admin';
const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const AdminNotifications = ({ notificationEvents }) => {
  const [notifications, setNotifications] = useState([]);
  let [index, setIndex] = useState(0);
  const { sidebarContext } = useContext(SidebarContext);

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/notifications`, headers);
      setNotifications(data.notifications);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, notificationEvents]);

  useEffect(() => {
    let tableContent = document.querySelector(`.scroll-row-${index}`);
    let observer = new IntersectionObserver(intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (tableContent && type) observer.observe(tableContent);
  });

  useEffect(() => {
    let readType = 'readAdmin';
    let initialIndex = notifications.findIndex((item) => item[readType] === false);
    setIndex(initialIndex);
  }, [notifications]);

  const intersectionCallback = async (entries) => {
    const offset = 15;
    let readType = 'readAdmin';
    if (entries[0].isIntersecting && notifications.findIndex((item) => item[readType] === false) !== -1) {
      const call = await Axios.get(`${SERVER_URL}/notifications`, headers);
      let notifications = call.data.notifications;
      let startIndex = notifications.findIndex((item) => item[readType] === false);
      let ids = notifications.slice(startIndex, startIndex + offset).map((item) => item._id);
      let numberOfUnread = await Axios.put(`${SERVER_URL}/notifications-set-read?readType=${readType}`, ids, headers);
      if (numberOfUnread.data.length < offset) {
        await Axios.put(
          `${SERVER_URL}/notifications-set-read`,
          numberOfUnread.data.map((item) => item._id),
          headers,
        );
      } else {
        setIndex(index + offset);
      }
      await sidebarContext.getNumberOfUnreadNotifications();
    }
  };

  const newNotification = useCallback(async () => {
    let readType = 'readAdmin';
    const notifications = await Axios.get(`${SERVER_URL}/notifications`, headers);
    setNotifications(notifications.data.notifications);
    let startIndex = [...notifications.data.notifications].findIndex((item) => item[readType] === false);
    setIndex(startIndex);
    await sidebarContext.getNumberOfUnreadNotifications();
  }, [sidebarContext]);

  useEffect(() => {
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    socket.emit('user-connected', user?.id);
    socket.on('new-notification-admin-received', async () => {
      await newNotification();
    });
  }, [newNotification]);

  const columnKeysTranslated = ['Poruka', 'Datum'];
  const columnKeys = ['message', 'created'];

  let tableData = [];
  if (notifications && notifications.length > 0) {
    tableData = notifications.map((item) => {
      let message;
      switch (item.message) {
        case 'ADD_DOCTOR_TO_ORDINATION':
          message = `Ordinacija ${item.ordination?.name} želi da doda doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`;
          break;
        case 'ADD_ORDINATION_TO_DOCTOR':
          message = `Doktor ${item.doctor?.firstName} ${item.doctor?.lastName} želi da doda ordinaciju ${item.ordination?.name}`;
          break;
        case 'CHANGED_TERM':
          message = `Pacijentu ${item.patient?.firstName} ${item.patient?.lastName} je pomeren termin u ordinaciji ${item.ordinationFromCalendar?.name
            } kod doktora ${item.doctorFromCalendar?.firstName} ${item.doctorFromCalendar?.lastName} za ${moment(
              item.appointment?.startDate,
            ).format('DD MMMM YYYY, HH:mm')}`;
          break;
        case 'SCHEDULE_TERM':
          message = `Pacijent ${item.patient?.firstName} ${item.patient?.lastName} je kreirao termin ${type === 'doctor'
            ? `u ordinaciji ${item.ordination?.name}`
            : `kod doktora ${item.doctor?.firstName} ${item.doctor?.lastName}`
            } ${moment(item.appointment?.startDate).format('DD MMMM YYYY, HH:mm')}`;
          break;
        default:
          break;
      }
      return {
        _id: item._id,
        ordination: item.ordination,
        doctor: item.doctor,
        message,
        created: new Date(item.createdAt).toLocaleDateString(),
        resolved: item.resolved,
      };
    });
  }

  return (
    <div className='doctors-table'>
      <div className='doctors-container'>
        {notifications && (
          <div className='table-wrapper'>
            <NotificationTable
              fetchNotifications={fetchNotifications}
              sidebarContext={sidebarContext}
              data={tableData}
              columnKeys={columnKeys}
              columnKeysTranslated={columnKeysTranslated}
              readonly={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminNotifications;
