import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  RedoOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import { UserContext } from '../../App';
import PatientEditForm from '../forms/PatientEditForm';
import $ from 'jquery';
import { EditOrdinationPatient } from '../modals';

let searchInput;
let patients;

const PatientsTable = ({ data, activate, deactivate, columnKeys, columnKeysTranslated, editPath, viewPath, setReset, tableKey, updatePatient }) => {
  const user = useContext(UserContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [patientData, setPatientData] = useState(undefined);
  const [ordModal, setOrdModal] = useState(false);

  if (data) {
    patients = data.map((item) => {
      let asd = [];
      if (user.data.userType === 'ordination') {
        item.doctors.forEach((doc) => doc.ordination.includes(user.data.ordination[0]) && asd.push(doc));
        item.doctor = item.doctors.map(
          (doc, i) => `${doc.firstName} ${doc.lastName}${i < item.doctors.length - 1 ? `,` : ''} `,
        );
      }
      return item;
    });
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder='Pretraži'
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={(e) => {
              if (e.target.value === '') {
                setReset(reset => reset.filter(r => r !== dataIndex));
                handleReset(clearFilters);
              } else {
                setReset(prev => [...prev, dataIndex]);
                handleSearch(selectedKeys, confirm, dataIndex);
              }
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            style={{
              width: 195,
              marginBottom: 8,
              display: 'block',
              border: '1px solid #4dc1be',
              borderRadius: 20,
              paddingLeft: 10,
            }}
          />
          <Button
            onClick={() => {
              setReset(prev => [...prev, dataIndex]);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size='small'
            style={{ width: 90, marginRight: 15 }}
          >
            Pretraži
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setReset(reset => reset.filter(r => r !== dataIndex));
              handleReset(clearFilters);
            }}
            size='small'
            style={{ width: 90 }}
          >
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <svg fill={filtered ? 'red' : 'white'} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" > <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" /></svg>,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    $(document).ready(function () {
      $('.ant-table-filter-trigger.active').parent().parent().attr('style', 'background-color: #4dc1be !important');
    });
  };
  // pogresna pozadina #1a6c77
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const toggleModal = (as) => {
    setIsOpenModal(true);
    setPatientData(as);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const userType = user.data.userType;

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns?.push({
    title: 'Postupak',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {userType === 'admin' && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={editPath + record._id}>
              <EditOutlined title='Izmeni pacijenta' style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        {userType === 'ordination' && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <EditOutlined
              title='Izmeni pacijenta'
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => { setOrdModal(true); setPatientData(record); }}
            />
          </div>
        )}

        {viewPath && userType !== 'admin' ? (
          <>
            <Link to={`${editPath}${record.firstName.toLowerCase()}-${record.lastName.toLowerCase()}-${record._id}`}>
              <span style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => sessionStorage.removeItem('appointment')}>
                <EyeOutlined title='Pregled pacijenta' style={{ textDecoration: 'none', color: 'black' }} />
              </span>
            </Link>
            <Link to={`/admin/patients/${record.firstName.toLowerCase()}-${record.lastName.toLowerCase()}-${record._id}`}>
              <span style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => sessionStorage.removeItem('appointment')}>
                <FileTextOutlined title='Karton pacijenta' style={{ textDecoration: 'none', color: 'black' }} />
              </span>
            </Link>
          </>
        ) : (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => toggleModal(record)}>
            <EyeOutlined title='Pregled pacijenta' style={{ textDecoration: 'none', color: 'black' }} />
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }}>
          {userType !== 'admin' ? (
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={'Da li ste sigurni da želite da uklonite pacijenta?'}
              onConfirm={() => deactivate(record._id)}
              okText='Da'
              cancelText='Ne'
            >
              <DeleteOutlined title={'Ukloni pacijenta iz ordinacije'} />
            </Popconfirm>
          ) : userType === 'admin' && record.status === 'ACTIVE' ? (
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={'Da li ste sigurni da želite da deaktivirate nalog?'}
              onConfirm={() => deactivate(record.userReference._id)}
              okText='Da'
              cancelText='Ne'
            >
              <DeleteOutlined title={'Deaktivirajte nalog'} />
            </Popconfirm>
          ) : (
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={'Da li ste sigurni da želite da aktivirate nalog?'}
              onConfirm={() => activate(record.userReference._id)}
              okText='Da'
              cancelText='Ne'
            >
              <RedoOutlined title={'Aktivirajte nalog'} />
            </Popconfirm>
          )}
        </div>
      </div>
    ),
  });

  if (userType === 'admin') {
    columns.push({
      title: 'Status',
      render: (item) => (
        <>
          {item.status === 'ACTIVE' && <span style={{ color: 'green' }}>active</span>}
          {item.status === 'INACTIVE' && <span style={{ color: 'crimson' }}>inactive</span>}
          {item.status === 'DELETED' && <span style={{ color: 'black' }}>deleted</span>}
          {item.status === 'SUSPENDED' && <span style={{ color: 'yellow' }}>suspended</span>}
          {item.status === 'WAITING_FOR_ACTIVATION' && <span style={{ color: 'dodgerblue' }}>pending</span>}
        </>
      ),
    });
  }

  return (
    <>
      <div>
        <Table
          key={tableKey}
          size='middle'
          bordered
          dataSource={patients}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 50,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: (
              <span>
                <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
                <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema pacijenata</p>
              </span>
            ),
          }}
        />
      </div>
      <EditOrdinationPatient
        ordModal={ordModal}
        setOrdModal={setOrdModal}
        patient={patientData}
        updatePatient={updatePatient}
      />
      <Modal
        className='doctor-modal patient'
        visible={isOpenModal}
        onCancel={closeModal}
        footer={null}
        width={1100}
        centered
      >
        <PatientEditForm data={patientData} isView={true} readonly={true} />
      </Modal>
    </>
  );
};

export default PatientsTable;
