import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Layout, Menu } from 'antd';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import { NavMenuContext } from '../../App';
import { InfoCircleOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Sider } = Layout;

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const NavMenu = ({ collapsedNav }) => {
  const history = useHistory();
  const { isNavMenuOpen, setNavMenuOpen } = useContext(NavMenuContext);

  const [currentSidebarSpecialization, setCurrentSidebarSpecialization] = useState();
  const [currentDoctorId, setCurrentDoctorId] = useState();
  const [search, setSearch] = useState('');
  const [doctors, setDoctors] = useState();
  const [openKeys, setOpenKeys] = useState([]);
  const [activeDoctor, setActiveDoctor] = useState('');
  const [ordinationCategories, setOrdinationCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [displayDoctors, setDisplayDoctors] = useState([]);

  useEffect(() => {
    setActiveDoctor(history.location.pathname.split('-')[2]);
  }, [history.location.pathname]);

  const getDoctors = useCallback(async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/doctors-by-ordination`,
        { ordination: currentuser?.ordination },
        headers,
      );
      setDoctors(data);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  const getOrdination = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/ordination/${currentuser.ordination}`, headers);
      const names = data?.category.map((cat) => cat.name.sr);
      setOrdinationCategories(names);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    getDoctors();
    setCurrentSidebarSpecialization(sessionStorage.getItem('openedSpecializationId'));
    setCurrentDoctorId(sessionStorage.getItem('currentDoctorId'));
  }, [getDoctors]);

  useEffect(() => {
    let timer;
    if (search.trim() !== '') {
      timer = setTimeout(() => {
        Axios.get(`${SERVER_URL}/doctors/search/${search}?id=${currentuser.ordination}`, headers)
          .then((res) => setDoctors(res.data.doctors))
          .catch((err) => console.log(err));
      }, 500);
    } else {
      getDoctors();
    }

    return () => clearTimeout(timer);
  }, [search, getDoctors]);

  useEffect(() => {
    setOpenKeys([categories[0]]);
  }, [categories]);

  useEffect(() => {
    getOrdination();
  }, [getOrdination]);

  useEffect(() => {
    const docCategories = [];
    doctors?.forEach((doc) =>
      doc.category.forEach((cat) => !docCategories?.includes(cat.name.sr) && docCategories.push(cat.name.sr)),
    );

    const commons = docCategories?.filter((cat) => ordinationCategories?.includes(cat));
    setCategories(commons);

    const filteredDoctors = [];
    doctors?.forEach((doc) =>
      doc.category.filter((docCat) => commons.includes(docCat.name.sr) && filteredDoctors.push(doc)),
    );
    setDisplayDoctors([...new Set(filteredDoctors)]);
  }, [doctors, ordinationCategories]);

  useEffect(() => {
    if (search !== '') {
      setOpenKeys(categories);
    }
  }, [search, categories]);

  const getCurrentDoctorActive = async ({ title, firstName, lastName }) => {
    sessionStorage.setItem('currentDoctorFullName', `${firstName}-${lastName}`.toLowerCase());
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (categories.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const navMenuToggle = () => {
    setNavMenuOpen(!isNavMenuOpen);
  };

  return (
    <Sider
      breakpoint='laptop2'
      className={!isNavMenuOpen ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isNavMenuOpen}
      style={{ zIndex: 6, marginLeft: collapsedNav ? '40px' : '0' }}
      width={224}
    >
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={[currentDoctorId]}
        defaultOpenKeys={[currentSidebarSpecialization]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        <div>
          <button type='button' className='close-sider' onClick={navMenuToggle}>
            X
          </button>
        </div>

        {categories?.length > 0 ? (
          <>
            <div className='omot-search-input-sidebar'>
              <div className='search-input'>
                <div className='form-content'>
                  <input type='text' id='searchInput' onChange={(e) => setSearch(e.target.value)} />
                  <button className='search-button'>
                    <img src='/images/loupe.svg' alt='loupe' />
                  </button>
                </div>
              </div>
            </div>
            {categories?.map((cat) => (
              <SubMenu key={cat} title={<span title={cat}>{cat}</span>} style={{ padding: '0' }}>
                {displayDoctors
                  ?.sort((a, b) => a.lastName.localeCompare(b.lastName))
                  .map((doctor, i) => (
                    <div key={i}>
                      {doctor.category.find((docCat) => docCat.name.sr === cat) && (
                        <Menu.Item key={`${cat}_${doctor._id}`}>
                          <Link
                            onClick={() => getCurrentDoctorActive(doctor)}
                            to={`/admin/calendar/${doctor.firstName}-${doctor.lastName}-${doctor._id}`.toLowerCase()}
                            className={`doctor-link ${activeDoctor === doctor._id ? 'red' : ''}`}
                            title={`${doctor.title || 'dr'} ${doctor.lastName} ${doctor.firstName}`}
                          >
                            {`${doctor.title || 'dr'} ${doctor.lastName} ${doctor.firstName}`}
                          </Link>
                        </Menu.Item>
                      )}
                    </div>
                  ))}
              </SubMenu>
            ))}
          </>
        ) : (
          <p style={{ color: '#4dc1be', textAlign: 'center', lineHeight: '1.3', marginTop: '2rem' }}>
            <InfoCircleOutlined style={{ fontSize: '1.4rem', marginBottom: '0.5rem' }} />
            <br />
            Dodajte doktora sa Vašom kategorijom
          </p>
        )}
      </Menu>
    </Sider>
  );
};

export default NavMenu;
