import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import PrescriptionForm from '../../components/forms/PrescriptionForm';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Prescription = () => {
  const [patientData, setPatientData] = useState(undefined);
  const location = useLocation();
  const pathName = location.pathname;
  const patientUrlData = pathName.split('/');
  const currentPatientUrlData = patientUrlData[3];
  const patientId = currentPatientUrlData.split('-').pop();

  const fetchPatientData = useCallback(async (patientId) => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/patient/${patientId}`, headers);
      setPatientData(data.patient);
      return data.patient;
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    fetchPatientData(patientId);
  }, [patientId, fetchPatientData]);

  return (
    <div className='patient'>
      <div className='patient-profile-form-container create-report'>
        <div className={userType === 'doctor' ? 'profile-form-wrapper mobile-top-space-big' : 'profile-form-wrapper'}>
          <div className='cards-border'>
            <div>
              <div className='dashboard'>
                <div className='card-wrapper'>
                  <PrescriptionForm patient={patientData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prescription;
