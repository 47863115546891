import React, { useEffect, useState, useCallback } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Modal, Card, Checkbox, TimePicker, Popconfirm, Row, Col, Form, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { io } from 'socket.io-client';

import { DeleteWorkDayModal, RemoveWorkDayModal } from './';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { useLocation } from 'react-router-dom';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const format = 'HH:mm';
const dani = ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'];

const OrdinationSingleDays = ({
  openOrdinationSingleDays,
  setOpenOrdinationSingleDays,
  ordinationSingleDays,
  workingDays,
  ordination,
  doctor,
  notificationEvents,
  appointmentDuration
}) => {
  const [allOrdinationSingleDays, setAllOrdinationSingleDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [removeAppointments, setRemoveAppointments] = useState(false);
  const [appointments, setAppointments] = useState({});
  const [ordSingleDay, setOrdSingleDay] = useState(undefined);
  const [removeWorkDay, setRemoveWorkDay] = useState({
    open: false,
    data: [],
    mode: '',
    edit: '',
  });

  const location = useLocation();
  const queryDocId = location.pathname.split('/')[4];
  const doctorId = user?.userType === 'doctor' ? user?.doctor : queryDocId;

  const getAllOrdinationSingleDays = useCallback(async () => {
    try {
      if (doctor && doctorId) {
        const { data } = await axios.get(`${SERVER_URL}/ordinationSingleDay?doc=${doctorId}&ord=${doctor.ordination.map(ord => ord._id)}`, headers);
        setAllOrdinationSingleDays(data.filter(d => d.ordination._id !== ordination));
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId, ordination, doctor]);

  useEffect(() => {
    getAllOrdinationSingleDays();
  }, [getAllOrdinationSingleDays]);

  const getOrdSingleDay = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordinationSingleDay?doc=${doctorId}&ord=${ordination}`, headers);
      if (data.length) {
        setSelectedDays(data[0].days);
      } else {
        setSelectedDays([]);
      }
      setOrdSingleDay(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [ordination, doctorId]);

  useEffect(() => {
    if (ordination) getOrdSingleDay();
  }, [ordination, getOrdSingleDay, notificationEvents]);

  const disabledDays = (date) => {
    if (ordinationSingleDays.length) {
      const doesExist = ordinationSingleDays[0].days.find(day => new Date(day.date).toDateString() === new Date(date).toDateString() && new Date(date) > new Date());
      return !doesExist;
    } else {
      return false;
    }
  };

  const handleDaySelect = async (day, { selected }) => {
    const days = selectedDays.concat();
    const isIncluded = ordinationSingleDays[0].days.find(yad => new Date(yad.date).toDateString() === new Date(day).toDateString());
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay.date), day),
      );
      if (days[selectedIndex].from && days[selectedIndex].to) {
        const { data } = await axios.post(`${SERVER_URL}/day?single=true`, { day: days[selectedIndex], doctorId }, headers);
        if (data.length) {
          setRemoveAppointments(true);
          setAppointments({ data, date: day, day: dani[new Date(day).getDay()] });
        } else {
          days.splice(selectedIndex, 1);
        }
      } else {
        days.splice(selectedIndex, 1);
      }
    } else {
      if (isIncluded) days.push({ date: day, from: '', to: '' });
    }
    days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    setSelectedDays(days);
  };

  const updateTime = (day, toSet, time) => {
    const copy = [...selectedDays];
    const dayInWeek = dani[new Date(day.date).getDay()];

    const pickedDayIndex = copy.findIndex(yad => yad === day);
    if (pickedDayIndex !== -1) {
      copy[pickedDayIndex][toSet] = time;
    }

    // if from is removed, remove the to too
    if (toSet === 'from' && time === '') {
      copy.splice(pickedDayIndex, 1);
    }

    // is inserted time is in work time, if it is display checkbox
    if (copy[pickedDayIndex]) {
      if (workingDays[dayInWeek] && copy[pickedDayIndex].from && copy[pickedDayIndex].to) {
        const restrictFrom = workingDays[dayInWeek].from;
        const restrictTo = workingDays[dayInWeek].to;
        const workFrom = copy[pickedDayIndex].from;
        const workTo = copy[pickedDayIndex].to;
        if (
          (restrictFrom < workFrom && workFrom < restrictTo) ||
          (restrictFrom < workTo && workTo < restrictTo)
        ) {
          copy[pickedDayIndex].restrict = true;
        } else {
          copy[pickedDayIndex].restrict = false;
        }
      } else {
        delete copy[pickedDayIndex].restrict;
      }
    }

    setSelectedDays(copy);
  };

  const setupHours = async (momentTime, day, toSet) => {
    const time = momentTime !== null ? moment(momentTime).format(format) : '';
    const fromatedDay = dani[new Date(day.date).getDay()];

    // if time is removed from FROM, get all booked appointemnts
    if (time === '' && (toSet === 'from')) {
      try {
        const { data } = await axios.post(`${SERVER_URL}/day?single=true`, { day, doctorId, ordination }, headers);
        if (data.length) {
          setRemoveAppointments(true);
          setAppointments({ data, day: fromatedDay, toSet, time });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      // otherwise find the day and check if FROM or TO is set
      // if yes, find appointments in that reach
      // if not, update time
      try {
        const { data } = await axios.post(`${SERVER_URL}/appointments/reach`, { time, day, toSet, doc: doctorId, ord: ordination, isDate: true }, headers);
        if (data.length) {
          setRemoveWorkDay({ open: true, data, mode: 'edit', time: { day, toSet, time } });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
  };

  // delete all appointments in single days
  const deleteAllAppointments = async () => {
    const ids = removeWorkDay.data.map((d) => d._id);
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/delete/singleDay`, { ids }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      deleteSingleDay();
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // send notifications to ordination and patients for appointment cancellation
  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: user?.userType,
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-ordination-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });
    }
  };

  const overrideSingleDay = (day, isChecked) => {
    const copy = [...selectedDays];
    const pickedDayIndex = copy.findIndex(yad => yad === day);
    if (pickedDayIndex !== -1) {
      copy[pickedDayIndex].override = isChecked;
    }
    setSelectedDays(copy);
  };

  const disabledWorkingHours = (day, type) => {
    const hours = [];
    const formatDay = new Date(day.date).toDateString();
    const selectedFrom = selectedDays.find(yad => new Date(yad.date).toDateString() === new Date(day.date).toDateString());

    // disable hours outside of selected ordination reach
    ordinationSingleDays[0].days.forEach(yad => {
      if (new Date(yad.date).toDateString() === formatDay) {
        const [startHours, startMinutes] = yad.from.split(':');
        const [endHours] = yad.to.split(':');

        for (let i = 0; i < +startHours; i++) hours.push(+i);
        for (let i = +endHours + 1; i < 24; i++) hours.push(+i);
        if (startMinutes === 0) hours.push(startHours);
      };
    });

    // disable hours outside of other ordinations reach
    allOrdinationSingleDays.forEach(singleDay => {
      if (singleDay.days.length) {
        singleDay.days.forEach(single => {
          if (new Date(single.date).toDateString() === new Date(day.date).toDateString()) {
            const [startHour, startMinute] = single.from.split(':');
            const [endHour] = single.to.split(':');

            // if FROM is selected, disable enabled hours before startHour
            if (selectedFrom && type === 'to') {
              const startSelectedHour = +selectedFrom.from.split(':')[0];
              if (startSelectedHour <= +startHour) {
                for (let i = 0; i < startSelectedHour; i++) hours.push(i);
                for (let i = +endHour; i < 24; i++) hours.push(i);
              }
              if (startSelectedHour >= +endHour) {
                for (let i = 0; i < +endHour; i++)hours.push(i);
              }
            }

            for (let i = +startHour + 1; i < +endHour; i++) hours.push(+i);
            if (+startMinute === 0) hours.push(+startHour);
          }
        });
      }
    });

    return [...new Set(hours)];
  };

  const disabledWorkingMinutes = (day, hour) => {
    const minutes = [];

    ordinationSingleDays[0].days.forEach(yad => {
      if (new Date(day).toDateString() === new Date(yad.date).toDateString()) {
        const [startHour, startMinute] = yad.from.split(':');
        const [endHour, endMinute] = yad.to.split(':');

        if (+startHour === hour) {
          for (let i = 0; i < +startMinute; i++) minutes.push(i);
        }
        if (+endHour === hour) {
          for (let i = +endMinute; i < 60; i++) minutes.push(i);
        }
      }
    });

    // disable hours outside of other ordinations reach
    allOrdinationSingleDays.forEach(singleDay => {
      if (singleDay.days.length) {
        singleDay.days.forEach(single => {
          if (new Date(single.date).toDateString() === new Date(day).toDateString()) {
            const [startHour, startMinute] = single.from.split(':');
            const [endHour, endMinute] = single.to.split(':');

            if (hour === +startHour) {
              for (let i = +startMinute + 1; i < 60; i++) minutes.push(i);
            }
            if (hour === +endHour) {
              for (let i = 0; i < +endMinute; i++) minutes.push(i);
            }
          }
        });
      }
    });

    if (hour === 22) {
      minutes.push(15, 30, 45);
    }

    return minutes;
  };

  const createSingleDay = async (values) => {
    selectedDays.forEach((day) => {
      if (day.from > day.to) {
        day.error = true;
        notification.warn({
          message: `${new Date(day.date).toDateString()}: OD mora biti manje od DO`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if ((!day.from && day.to) || (day.from && !day.to) || (!day.from && !day.to)) {
        day.error = true;
        notification.warn({
          message: `${new Date(day.date).toDateString()}: Popunite u celosti`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      if (day.from < day.to) {
        delete day.error;
      }
    });

    let error = selectedDays.find((day) => day.error);
    if (error) return;

    values.ordination = ordination;
    values.doctor = doctorId;
    values.days = selectedDays;
    values.duration = appointmentDuration;

    try {
      const socket = io(SOCKET_URL, { path: '/socket.io' });
      if (ordSingleDay.length) {
        // update
        const { data } = await axios.put(`${SERVER_URL}/ordinationSingleDay/${ordSingleDay[0]._id}`, values, headers);
        const notificationData = {
          ordination,
          doctor: doctorId,
          miscellaneousData: { oldDays: data.oldDays.days, newDays: data.newDays.days },
          mode: 'UPDATE',
          ordinationSingleDays: true,
          requestedBy: user?.userType,
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-single-days', notificationData);

        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else {
        // create
        const { data } = await axios.post(`${SERVER_URL}/ordinationSingleDay`, values, headers);
        const notificationData = {
          ordination,
          doctor: doctorId,
          miscellaneousData: data.day.days,
          mode: 'CREATE',
          ordinationSingleDays: true,
          requestedBy: user?.userType,
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-single-days', notificationData);

        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }

      getOrdSingleDay();
      setOpenOrdinationSingleDays(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const beforeDelete = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/remove/ordinationSingleDay/${doctorId}?ord=${ordination}`, headers);
      if (data.length) {
        setRemoveWorkDay({ open: true, data, edit: 'delete' });
      } else {
        deleteSingleDay();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteSingleDay = async () => {
    try {
      const socket = io(SOCKET_URL, { path: '/socket.io' });
      const { data } = await axios.delete(`${SERVER_URL}/ordinationSingleDay/${ordSingleDay[0]._id}`, headers);
      const notificationData = {
        ordination,
        doctor: doctorId,
        miscellaneousData: {},
        mode: 'DELETE',
        requestedBy: user?.userType,
        ordinationSingleDays: true
      };
      await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
      socket.emit('send-single-days', notificationData);

      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });

      getOrdSingleDay();
      setOpenOrdinationSingleDays(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/delete/day`, { days: appointments.data }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      sendDeleteNotification(appointments);

      const days = selectedDays.concat();
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay.date), appointments.date),
      );
      days.splice(selectedIndex, 1);
      setSelectedDays(days);

      setAppointments({});
      setRemoveAppointments(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete appointemnts, which don't enter the new time, when you change time of a work day and send notification
  const deleteDayAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      updateTime(removeWorkDay.time.day, removeWorkDay.time.toSet, removeWorkDay.time.time);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      className='doctor-modal'
      visible={openOrdinationSingleDays}
      onCancel={() => setOpenOrdinationSingleDays(false)}
      footer={null}
      width={458}
      centered
    >
      <Card
        title="Ordinacijski jednokratni dani"
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <Form onFinish={(values) => createSingleDay(values)} layout='vertical'>
          <DayPicker
            selectedDays={selectedDays.map((day) => new Date(day.date))}
            disabledDays={disabledDays}
            onDayClick={handleDaySelect}
            firstDayOfWeek={1}
            className='non-working-days-day-picker'
          />
          {selectedDays?.map((day, i) => (
            <div key={i} >
              {new Date(day.date).setHours('22', '0', '0') > new Date().getTime() && (
                <>
                  <p style={{ color: '#627b90', margin: 0, marginTop: '.3rem', display: 'flex', justifyContent: 'space-between' }}>
                    {moment(day.date).locale('sr').format('DD. MMMM YYYY.').toUpperCase()}
                    {day.restrict &&
                      <Checkbox
                        style={{ color: '#627b90' }}
                        checked={day.override}
                        onChange={(e) => overrideSingleDay(day, e.target.checked)}
                      >
                        Pregazi radno vreme
                      </Checkbox>
                    }
                  </p>
                  <span style={{ color: '#627b90' }}>Od:</span>
                  <TimePicker
                    disabledHours={() => disabledWorkingHours(day, 'from')}
                    disabledMinutes={(hour) => disabledWorkingMinutes(day.date, hour, 'from')}
                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                    bordered={false}
                    showNow={false}
                    minuteStep={15}
                    format={format}
                    onSelect={time => setupHours(time, day, 'from')}
                    onChange={time => setupHours(time, day, 'from')}
                    value={selectedDays.find(yad => day === yad)?.from && moment(selectedDays.find(yad => day === yad)?.from, format)}
                  />
                  <span style={{ color: '#627b90' }}>Do:</span>
                  <TimePicker
                    disabledHours={() => disabledWorkingHours(day, 'to')}
                    disabledMinutes={(hour) => disabledWorkingMinutes(day.date, hour, 'to')}
                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                    style={{ opacity: `${Boolean(!day.from) ? '.3' : '1'}` }}
                    disabled={Boolean(!day.from)}
                    bordered={false}
                    showNow={false}
                    minuteStep={15}
                    format={format}
                    onSelect={time => setupHours(time, day, 'to')}
                    onChange={time => setupHours(time, day, 'to')}
                    value={selectedDays.find(yad => day === yad)?.to && moment(selectedDays.find(yad => day === yad)?.to, format)}
                  />
                </>
              )}
            </div>
          ))}
          <Row className='doctor-working-hours-buttons'>
            <Col style={{ display: 'flex' }}>
              <button className='action-button border-dark' htmltype='submit' style={{ marginTop: '1rem', marginLeft: 'auto' }}>
                <img src='/images/save.svg' alt='save' />
                <span>{ordSingleDay?.length ? 'Ažuriraj' : 'Potvrdi'}</span>
              </button>
              {ordSingleDay?.length ?
                <Popconfirm
                  title='Da li stvarno želite da obrišete radni dan?'
                  onConfirm={beforeDelete}
                  okText='Da'
                  cancelText='Ne'
                >
                  <button className='action-button delete' style={{ marginTop: '1rem', marginLeft: '.5rem' }}>
                    <img src='/images/delete.svg' alt='delete' />
                    <span>Obriši</span>
                  </button>
                </Popconfirm> : null
              }
            </Col>
          </Row>
        </Form>
      </Card>
      <DeleteWorkDayModal
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        deleteAllAppointments={deleteAllAppointments}
        deleteDayAppointments={deleteDayAppointments}
      />
      <RemoveWorkDayModal
        appointments={appointments}
        removeAppointments={removeAppointments}
        setRemoveAppointments={setRemoveAppointments}
        deleteAppointments={deleteAppointments}
      />
    </Modal>
  );
};

export default OrdinationSingleDays;