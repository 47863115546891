import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Select, Row, Col, notification, Checkbox } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import UploadBox from '../../components/base/UploadBox';
import { GalleryModal } from '../../components/forms';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { DownOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

export default function EditOrdination(props) {
  const [form] = Form.useForm();
  const { ordinationId } = props.match.params;
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [ordination, setOrdination] = useState({});
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [phone, setPhone] = useState();
  const [cords, setCords] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [timerID, setTimerID] = useState(null);
  const [visibility, setVisibility] = useState();

  const setupAddress = (search, cb) => {
    clearTimeout(timerID);
    const timeout = setTimeout(() => geolocation(search, cb), 700);
    setTimerID(timeout);
  };

  useEffect(() => {
    setVisibility(ordination.visibility);
  }, [ordination]);

  const geolocation = async (input, callback) => {
    try {
      if (input !== '') {
        const { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=${process.env.REACT_APP_MAP_KEY}`,
        );

        const formatData = data.results.map(r => ({
          label: r.formatted_address,
          value: `${r.geometry.location.lat}, ${r.geometry.location.lng}`
        }));

        setRecommendations(formatData);
        callback(formatData);
      }
    } catch (error) {
      notification.info({
        message: "Precizirajte adresu bolje. Unesite grad ili broj ulice.",
        placement: "bottomRight",
        duration: 2.5
      });
    }
  };

  const getCities = async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const getCategories = async () => {
    try {
      const categoryCall = await axios.get(`${SERVER_URL}/categories`);
      setCategories(categoryCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const getOrdination = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordination/${ordinationId}`, headers);
      setOrdination(data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, [ordinationId]);

  useEffect(() => {
    getOrdination();
    getCities();
    getCategories();
  }, [getOrdination]);

  useEffect(() => {
    if (ordination && ordination.avatar) {
      setImage(ordination && ordination.avatar);
    } else {
      setImage(null);
    }
    form.resetFields();
  }, [form, ordination]);

  let data;
  if (ordination.name) {
    data = {
      ...ordination,
      address: { label: ordination.address, value: ordination.cords },
      email: ordination.userReference?.email,
      phone: ordination.phone[0],
      category: ordination.category?.map((cat) => cat._id),
    };
  }

  const onFinish = async (values) => {
    try {
      values.avatar = image;
      values.phone = phone;
      values.address = values.address.label;
      if (cords) {
        values.cords = cords;
      }
      values.visibility = visibility || false;
      const { data } = await axios.put(`${SERVER_URL}/ordination/${ordinationId}`, values, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      getOrdination();
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed in OrdinationForm:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ avatar: values });
    setImage(values);
  };

  return (
    <div className='editovanje-klinike'>
      <div className='profile-container'>
        <div className='ordination-form-container'>
          <div className='ordination-form-wrapper wide'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                {/* eslint-disable-next-line no-useless-concat */}
                <Card title={`Klinika ${ordination?.name}`} bordered={false}>
                  <Form
                    initialValues={data}
                    onFinish={(values) => onFinish(values)}
                    onFinishFailed={onFinishFailed}
                    layout='horizontal'
                    form={form}
                    style={{ borderRadius: '13px' }}
                  >
                    <Row className='card-form'>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                        <Form.Item
                          style={{ marginLeft: '2px' }}
                          label='NAZIV'
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite naziv!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item label='ADRESA' name='address'>
                          <AsyncSelect
                            loadOptions={setupAddress}
                            defaultOptions={recommendations}
                            placeholder='Ulica broj, grad'
                            onChange={e => setCords(e.value)}
                            styles={{
                              control: base => ({
                                ...base,
                                minHeight: 30,
                                border: 0,
                                boxShadow: "none",
                                borderRadius: 0,
                                borderBottom: '1px solid #e5e5e5',
                              }),
                              input: base => ({
                                ...base,
                                color: '#627b90',
                                fontWeight: 'bold'
                              }),
                              singleValue: provided => ({
                                ...provided,
                                color: '#627b90',
                                fontWeight: 'bold'
                              }),
                              menu: base => ({
                                ...base,
                                marginTop: '0rem',
                                borderRadius: 0
                              })
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: (props) =>
                                <components.DropdownIndicator {...props}>
                                  <DownOutlined />
                                </components.DropdownIndicator>
                            }}
                          />
                        </Form.Item>

                        <Form.Item label='GRAD' name='city'>
                          <Select
                            showArrow
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city._id} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='DRŽAVA' name='country'>
                          <Input style={{ paddingLeft: '0px', marginLeft: '5px' }} />
                        </Form.Item>

                        <Form.Item
                          label='TELEFON'
                          name='phone'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite telefon!',
                            },
                          ]}
                        >
                          <PhoneInput
                            style={{ border: 'none', borderBottom: '1px solid #e5e5e5', paddingLeft: '2px' }}
                            international
                            defaultCountry='RS'
                            countryCallingCodeEditable={false}
                            value={phone}
                            onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                          />
                        </Form.Item>

                        <Form.Item
                          label='EMAIL'
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite telefon!',
                            },
                          ]}
                        >
                          <Input type='email' />
                        </Form.Item>

                        <Form.Item label='WEBSAJT' name='website'>
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='OBLAST RADA'
                          name='category'
                          className='panel-body'
                          rules={[{ required: true, message: 'Obavezno odaberite specijalizaciju!' }]}
                        >
                          <Select
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {categories?.map((cat) => (
                              <Option key={cat._id} value={cat._id}>
                                {cat.name.sr}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='O KLINICI' name='description'>
                          <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item label='VIDLJIVOST' name='visibility'>
                          <Checkbox checked={visibility} onChange={() => setVisibility(!visibility)} />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                        <Form.Item className='upload-wrapper right' name='avatar'>
                          <UploadBox
                            editHandler={avatarHandler}
                            deleteHandler={deleteAvatarHandler}
                            image={image}
                            index={0}
                            name='avatar'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className='ordination-buttons' style={{ justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <button className='action-button border-dark' htmltype='submit' style={{ float: 'right' }}>
                            <img src='/images/save.svg' alt='save' />
                            <span>Potvrdi</span>
                          </button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>

                {modal.visible && (
                  <GalleryModal
                    visible={modal.visible}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageSavePath='public/images/users/'
                    imageType='data'
                    imageHeight={200}
                    SERVER_URL={SERVER_URL}
                    token={user?.token}
                    form={form}
                    onCancel={() => setModal({ ...modal, visible: false })}
                    onInsert={(values) => onInsert(values, modal.formKey)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
