import React from 'react';
import { Input, Form } from 'antd';

const CategoryForm = ({ onSubmit, category, token, language, setEdit, SERVER_URL }) => {
  const [form] = Form.useForm();
  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete category[key]);

  if (category) {
    form.setFieldsValue({
      name: category.name?.sr,
    });
  }

  const onFinish = async (values, isNew = true) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{!category.name ? 'Kreiraj' : 'Izmeni'}</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, category: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>

      <div className='panel-body'>
        <Form
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Form.Item label='NAZIV' name='name' style={{ margin: '0 8px' }}>
            <Input />
          </Form.Item>
          <div
            className='text-right'
            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: '10px' }}
          >
            <button className='add-specialization border-dark' htmltype='submit'>
              <img src='/images/save.svg' alt='save' />
              <span>Potvrdi</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CategoryForm;
