import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import PatientEditForm from '../../components/forms/PatientEditForm';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};
const appointment = sessionStorage.getItem('appointment');
let patientId;

const Patient = () => {
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [singlePatient, setSinglePatient] = useState(undefined);

  useEffect(() => {
    const url = history.location.pathname;
    const lastSegment = url.split('/').pop();
    patientId = lastSegment.split('-').pop();
  }, [history]);

  const getPatient = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/patient/${patientId}`, headers);
      setSinglePatient(data.patient);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    getPatient();
  }, [getPatient]);

  const createPatient = async (data) => {
    try {
      let isError = false;
      if (isError === false) {
        const patient = await Axios.post(`${SERVER_URL}/patient`, { ...data }, headers);
        if (patient.status === 201) {
          notification.success({
            message: 'Kreiran je profil pacijenta.',
            placement: 'bottomRight',
            duration: 2.5
          });
          setResult(true);
          history.push('/admin');
        }
      }
    } catch (err) {
      console.log(err.message);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const updatePatient = async (updateData, id) => {
    try {
      const patient = await Axios.put(`${SERVER_URL}/patient/${id}`, { ...updateData }, headers);
      if (patient.status === 200) {
        notification.success({
          message: patient.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      getPatient();
    } catch (err) {
      console.log(err.message);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const deletePatient = async (id) => {
    try {
      const patient = await Axios.delete(`${SERVER_URL}/patient/${id}`, headers);
      if (patient.status === 200) {
        notification.success({
          message: 'Pacijent je izbrisan.',
          placement: 'bottomRight',
          duration: 2.5
        });
        history.push('/admin');
      }
    } catch (err) {
      console.log(err.message);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  return (
    <>
      <div className='patient'>
        <div
          className={
            singlePatient?.allowedFields?.length > 5
              ? 'patient-profile-form-container'
              : 'patient-profile-form-container allowed-data-width'
          }
        >
          <div
            className={
              appointment && userType !== 'ordination'
                ? 'profile-form-wrapper mobile-top-space-big'
                : 'profile-form-wrapper'
            }
          >
            {singlePatient && (
              <PatientEditForm
                data={singlePatient}
                createPatientHandler={createPatient}
                updatePatientHandler={updatePatient}
                deletePatientHandler={deletePatient}
                setResult={setResult}
                result={result}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient;
