import React, { createContext, useEffect, useMemo, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.scss';

export const UserContext = createContext(null);
export const NavMenuContext = createContext();
export const SidebarContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/login`,
      { email: user.email, password: user.password },
      { withCredentials: false },
    );

    if (
      userResponse.data.message === 'Auth successful' &&
      userResponse.data.role.includes('admin') &&
      userResponse.data.role.includes('user')
    ) {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.href = '/admin';
    } else if (userResponse.data.message === 'Auth successful' && userResponse.data.role.includes('concordAdmin')) {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.href = '/admin';
    } else if (userResponse.data.message === 'Auth successful' && !userResponse.data.role.includes('admin')) {
      return message.info('Nije vam omogućen pristup aplikaciji.', 3);
    }
  } catch (err) {
    return message.warning(err.response.data.message, 3);
  }
};

let user = sessionStorage.getItem('user');
if (user) user = JSON.parse(user);

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();
  const [isNavMenuOpen, setNavMenuOpen] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(0);

  let id, type;

  if (user && user.userType === 'doctor') {
    id = user.doctor;
    type = 'doctor';
  } else if (user && user.userType === 'ordination') {
    id = user.ordination[0];
    type = 'ordination';
  } else {
    type = 'admin';
  }

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({ selected: defaultLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  const getNumberOfUnreadNotifications = useCallback(async () => {
    const response = await Axios.get(
      `${SERVER_URL}/unread-notification?type=${type}${type === 'admin' ? '' : `&id=${id}`}`,
      headers,
    );
    setUnreadNotification(response.data.length);
  }, [id, type]);

  useEffect(() => {
    getNumberOfUnreadNotifications();
  }, [getNumberOfUnreadNotifications]);

  const sidebarContext = useMemo(
    () => ({
      getNumberOfUnreadNotifications: async () => {
        await getNumberOfUnreadNotifications();
      },
    }),
    [getNumberOfUnreadNotifications],
  );

  return (
    <SidebarContext.Provider value={{ sidebarContext, unreadNotification }}>
      <UserContext.Provider value={{ isLoggedIn, handleLogin, data: user, language, setLanguage }}>
        <NavMenuContext.Provider value={{ isNavMenuOpen, setNavMenuOpen }}>
          <Router>
            {isLoggedIn && <Layout setLoggedIn={setLoggedIn} user={user} />}
            {!isLoggedIn && <Route exact path='/' component={Login} />}
            {!isLoggedIn && <Route exact path='/login' component={Login} />}
            {!isLoggedIn && <Route exact path='/admin' component={Login} />}
            <Route exact path='/register' component={Register} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset-password/:token' component={ResetPassword} />
          </Router>
        </NavMenuContext.Provider>
      </UserContext.Provider>
    </SidebarContext.Provider>
  );
};

export default App;
