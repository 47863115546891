import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Form } from 'antd';
import { SERVER_URL, SOCKET_URL } from '../../config';
import moment from 'moment';
import 'moment/locale/sr';
import CalendarFull from '../../components/calendar';
import { io } from 'socket.io-client';

moment.locale('sr');

const currentUser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentUser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentUser?.token}` },
};
const now = new Date();

const Calendar = ({ notificationEvents, setEventToUpdate }) => {
  const [form] = Form.useForm();
  const [refetch, setRefetch] = useState(false);
  const [events, setEvents] = useState([]);
  const [doctorId, setDoctorId] = useState('');
  const [isCalendarModalVisible, setIsCalendarModalVisible] = useState(false);
  const [result, setResult] = useState(false);
  const [allCalendarData, setAllCalendarData] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    setDoctorId(history.location.pathname.split('-')[2]);
  }, [history.location.pathname]);

  const getDoctor = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
      sessionStorage.setItem('currentDoctorFullName', `${data.firstName}-${data.lastName}`.toLowerCase());
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    if (doctorId) getDoctor();
  }, [getDoctor, doctorId]);

  const getCalendarData = useCallback(async () => {
    try {
      const { data } = await Axios.post(`${SERVER_URL}/calendar-doctor/${doctorId}`, { ordinations: currentUser?.ordination }, headers);
      setAllCalendarData(data);
    } catch (err) {
      notification.info({
        message: 'Kreirajte ili dodajte novog lekara kako biste pristupili kalendaru.',
        placement: 'topBottom',
        duration: 2.5
      });
    }
  }, [doctorId]);

  useEffect(() => {
    if (doctorId) getCalendarData();
  }, [result, notificationEvents, doctorId, getCalendarData]);

  const createAppointemntAndPatient = async (data) => {
    setResult(false);
    try {
      const patient = await Axios.put(`${SERVER_URL}/create/patient?ordination=${data.ordinations?._id || data.ordinations || currentUser?.ordination[0] || ''}&forbid=false`, { ...data }, headers);
      let patientData;
      if (patient.data.patient) {
        patientData = {
          ...data,
          dateOfBirth: patient.data.patient.dateOfBirth,
          firstName: patient.data.patient.firstName,
          lastName: patient.data.patient.lastName,
          phone: patient.data.patient.phone,
          email: patient.data.patient.email,
        };
      } else {
        patientData = { ...data };
      }

      if (patient.data.message)
        notification.info({
          message: patient.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });

      setEventToUpdate({ message: "Novi pacijent je dodat u ordinaciju" });
      createAppointment(patientData);
    } catch (err) {
      if (err.response) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      console.log(err.message);
    }
  };

  const createAppointment = async (data) => {
    setResult(false);
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    try {
      const calendar = await Axios.post(`${SERVER_URL}/calendars`, { ...data, userType }, headers);
      const response = calendar.data.event;
      const appointment = response._id;
      const patientId = response.patient;
      const doctorId = response.doctor;
      const ordinationId = response.ordinations;
      const startDate = response.startDate;
      const endDate = response.endDate;
      let message = 'SCHEDULE_TERM';

      // does the created appointment overlaps with any other?
      const appointments = events.filter(e => new Date(e.start).toLocaleDateString() === new Date(startDate).toLocaleDateString());
      appointments.forEach(termin => {
        if (
          (termin.start > startDate && termin.end < endDate)
          || (termin.start <= startDate && termin.end >= endDate)
          || (termin.end > startDate && termin.end < endDate)
          || (termin.start > startDate && termin.start < endDate)
        ) {
          message = 'OVERLAP_SHEDULE_TERM';
        }
      });

      await Axios.post(
        `${SERVER_URL}/notifications`,
        {
          patient: response.patient,
          requestedBy: userType,
          doctor: doctorId,
          ordination: ordinationId,
          appointment,
          examinationType: response.examinationType,
          message,
          startDate: response.startDate,
        },
        headers,
      );

      if (now < new Date(startDate)) {
        await Axios.post(
          `${SERVER_URL}/send-push-token/${response.patient}`,
          {
            startDate,
            endDate,
            calendarId: appointment,
            message: 'SCHEDULE_TERM',
            doctor: response.doctor,
            ordination: ordinationId,
          },
          headers,
        );
      }

      if (userType === 'doctor') {
        socket.emit('new-notification-ordination-created', {
          ordinationId,
          doctorId,
          patientId,
          startDate,
          endDate,
          appointment,
          message,
          requestedBy: 'doctor',
          calendarId: appointment,
        });
      } else if (userType === 'ordination') {
        socket.emit('new-notification-doctor-created', {
          doctorId,
          patientId,
          ordinationId,
          startDate,
          endDate,
          appointment,
          message: 'SCHEDULE_TERM',
          requestedBy: 'ordination',
          calendarId: appointment,
        });
      }
      if (calendar.data.message) {
        notification.success({
          message: 'Kreiran je novi termin.',
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      if (calendar.data.googleStatus) {
        notification.info({
          message: 'Termin je sačuvan na google kalendaru',
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      setResult(true);
      setIsCalendarModalVisible(false);
      form.resetFields();
    } catch (err) {
      if (err.response) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
      console.log(err.message);
    }
  };

  const sendDoctorNoteNotification = async (data) => {
    const doctorId = data.doctor._id;
    const ordinationId = data.ordinations._id;
    const patientId = data.patient._id;
    try {
      await Axios.post(
        `${SERVER_URL}/notifications`,
        {
          patient: patientId,
          requestedBy: userType,
          doctor: doctorId,
          ordination: ordinationId,
          appointment: data._id,
          examinationType: data.examinationType,
          message: 'CHANGED_NOTE',
          startDate: data.startDate,
          doctorNote: data.doctorNote,
        },
        headers,
      );

      if (now < new Date(data.startDate)) {
        await Axios.post(
          `${SERVER_URL}/send-push-token/${data.patient._id}`,
          {
            startDate: data.startDate,
            endDate: data.endDate,
            calendarId: data._id,
            message: 'CHANGED_NOTE',
            doctor: doctorId,
            ordination: ordinationId,
          },
          headers,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sendEmail = async (email, message, props) => {
    try {
      const { data } = await Axios.post(`${SERVER_URL}/calendar/email`, { email, message, props }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteDate = async (id) => {
    setResult(false);
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    const calendar = allCalendarData.find((item) => item._id === id);
    const patientId = calendar.patient._id;
    const doctorId = calendar.doctor;
    const ordinationId = calendar.ordinations;
    const startDate = calendar.startDate;
    const endDate = calendar.endDate;

    await Axios.post(
      `${SERVER_URL}/notifications`,
      {
        patient: patientId,
        requestedBy: userType,
        doctor: doctorId,
        ordination: ordinationId,
        startDate: calendar.startDate,
        examinationType: calendar.examinationType,
        message: 'CANCEL_TERM',
      },
      headers,
    );

    if (now < new Date(startDate)) {
      await Axios.post(
        `${SERVER_URL}/send-push-token/${calendar.patient._id}`,
        {
          startDate: calendar.startDate,
          endDate: calendar.endDate,
          calendarId: id,
          message: 'CANCEL_TERM',
          doctor: calendar.doctor,
          ordination: ordinationId,
          requestedBy: userType,
        },
        headers,
      );
    }

    if (userType === 'doctor') {
      socket.emit('new-notification-ordination-created', {
        ordinationId: ordinationId._id,
        patientId,
        doctorId,
        startDate,
        endDate,
        appointment: id,
        message: 'CANCEL_TERM',
        requestedBy: 'doctor',
        calendarId: id,
      });
    } else if (userType === 'ordination') {
      socket.emit('new-notification-doctor-created', {
        doctorId,
        patientId,
        ordinationId,
        startDate,
        endDate,
        appointment: id,
        message: 'CANCEL_TERM',
        requestedBy: 'ordination',
        calendarId: id,
      });
    }
    const deletedDate = await Axios.delete(`${SERVER_URL}/calendar/${id}?type=${userType}`, headers);
    if (deletedDate.status === 200) {
      notification.success({
        message: 'Termin je izbrisan.',
        placement: 'bottomRight',
        duration: 2.5
      });
      setResult(true);
    }
  };

  const updateDate = async (values, id) => {
    values.doctor = doctorId;
    try {
      const { data } = await Axios.put(`${SERVER_URL}/calendar/${id}`, { ...values, userType }, headers);

      let socket = io(SOCKET_URL, { path: '/socket.io' });
      const response = data.updatedDate;
      const appointment = response._id;
      const doctorId = response.doctor._id;
      const ordinationId = response.ordinations;
      const patientId = response.patient._id;
      const startDate = response.startDate;
      const endDate = response.endDate;
      let message = 'CHANGED_TERM';

      // does the prolongued appointment overlaps with any other
      // if yes send notification to ordination
      if (currentUser.ordination.length) {
        const { data: data2 } = await Axios.post(`${SERVER_URL}/calendar-doctor/${doctorId}`, { ordinations: currentUser.ordination }, headers);
        const appointments = data2.filter(c => new Date(c.startDate).toLocaleDateString() === new Date(startDate).toLocaleDateString() && c._id !== id);
        appointments.forEach(termin => {
          if (
            (termin.startDate > startDate && termin.endDate < endDate)
            || (termin.startDate <= startDate && termin.endDate >= endDate)
            || (termin.endDate > startDate && termin.endDate < endDate)
            || (termin.startDate > startDate && termin.startDate < endDate)
          ) {
            message = 'OVERLAP_PROLONGUE_TERM';
          }
        });

        if (userType === 'doctor') {
          socket.emit('new-notification-ordination-created', {
            ordinationId,
            patientId,
            doctorId,
            startDate,
            endDate,
            message,
            requestedBy: 'doctor',
            calendarId: appointment,
          });
        } else if (userType === 'ordination') {
          socket.emit('new-notification-doctor-created', {
            ordinationId,
            patientId,
            doctorId,
            startDate,
            endDate,
            message,
            requestedBy: 'ordination',
            calendarId: appointment,
          });
        }
      }

      notification.success({
        message: 'Ažurirani su podaci za ovaj termin.',
        placement: 'bottomRight',
        duration: 2.5
      });
      setResult(true);
      form.resetFields();
      return data.updatedDate;
    } catch (error) {
      console.log(error.message);
    }
  };

  // Adding dropdown for switch between types of view: Weekly, Daily
  let testHTML =
    `<div id="veryUniqueID" class="dropdownContainer">
      <div id="dropdownViewContainer" class="dropdownViewContainer">${sessionStorage.getItem('view') === 'timeGridDay' ? "Dan" : 'Nedelja'}<img src="/images/arrowDropdown.svg" class="dropdownIcon" />
      </div>
    </div>`;

  let dropdownContent;

  const changeViewToDaily = (e) => {
    e.stopPropagation();
    sessionStorage.setItem('view', 'timeGridDay');
    window.location.reload();
  };

  const changeViewToWeekly = (e) => {
    e.stopPropagation();
    sessionStorage.setItem('view', 'timeGridWeek');
    window.location.reload();
  };

  if (typeof document !== undefined && !document.getElementById('veryUniqueID')) {
    const dateDropdown = document?.querySelector('.fc-toolbar-chunk');
    dateDropdown?.querySelector('.fc-toolbar-title')?.classList.add('toolBarTitle');
    dateDropdown?.classList.add('dropdownElementFlex');
    dropdownContent = document?.createElement('div');
    dropdownContent.innerHTML += testHTML;
    dateDropdown?.appendChild(dropdownContent);
  }

  const deleteDropdownoptions = () => {
    if (document.querySelector('.dropdownOptions')) {
      const toRemove = document.querySelector('.dropdownOptions').parentElement;
      document.querySelector('.dropdownContainer').removeChild(toRemove);
      setRefetch(!refetch);
    }
  };

  let dropdownContentOptions;

  const renderDropdownoptions = (event) => {
    event.stopPropagation();

    if (!document.querySelector('.dropdownOptions')) {
      const dropdownOptionsWrapper = document.querySelector('.dropdownContainer');
      dropdownContentOptions = document.createElement('div');
      dropdownContentOptions.innerHTML += `<ul class="dropdownOptions">
      <li id="dailyViewCalendar">Dan</li>
      <li id="weeklyViewCalendar">Nedelja</li>
      <li>Mesec</li>
    </ul>`;
      dropdownOptionsWrapper.appendChild(dropdownContentOptions);

    }
    document.getElementById('dailyViewCalendar').addEventListener('click', changeViewToDaily);
    document.getElementById('weeklyViewCalendar').addEventListener('click', changeViewToWeekly);
    if (document.querySelector('.dropdownOptions')) {
      document.getElementById('veryUniqueID').addEventListener('click', deleteDropdownoptions);
    }
  };

  useEffect(() => {
    if (document.getElementById('veryUniqueID')) {
      document.getElementById('veryUniqueID').addEventListener('click', renderDropdownoptions);
    }
  });
  // Adding dropdown for switch between types of view: Weekly, Daily

  return (
    <div className={`${userType === 'ordination' ? 'edit-doctor-calendar-page' : ''}`}>
      <CalendarFull
        updateDate={updateDate}
        form={form}
        events={events}
        setEvents={setEvents}
        createAppointemntAndPatient={createAppointemntAndPatient}
        createAppointment={createAppointment}
        sendNotification={sendDoctorNoteNotification}
        sendEmail={sendEmail}
        setIsCalendarModalVisible={setIsCalendarModalVisible}
        isCalendarModalVisible={isCalendarModalVisible}
        result={result}
        calendarData={allCalendarData}
        deleteDate={deleteDate}
        notificationEvents={notificationEvents}
      />
    </div>
  );
};

export default Calendar;
