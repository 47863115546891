/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Card, Select, Row, Popconfirm, Modal, DatePicker } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
// const today = new Date();

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const cardTabs = [
  { key: 'existing_patient', tab: 'Postojeci' },
  { key: 'new_patient', tab: 'Nov pacijent' },
];

const CalendarDataForm = ({
  props,
  businessHours,
  selectedInfoDate,
  handleCalendarModalCancel,
  editCalendarDate,
  startTime,
  events,
  setEvents,
}) => {
  const history = useHistory();
  const form = props.form;

  const [appointmentOption, setAppointmentOption] = useState('existing_patient');
  const [selectedOrdination, setSelectedOrdination] = useState('');
  const [startingDate, setStartingDate] = useState(undefined);
  const [endingDate, setEndingDate] = useState(undefined);
  const [startingHour, setStartingHour] = useState(undefined);
  const [startingMinutes, setStartingMinutes] = useState(undefined);
  const [endingHour, setEndingHour] = useState(undefined);
  const [endingMinutes, setEndingMinutes] = useState(undefined);
  const [chosenDate, setChosenDate] = useState(undefined);
  const [isNew, setIsNew] = useState(undefined);
  const [isEditable, setIsEditable] = useState(undefined);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [copyPatients, setCopyPatients] = useState([]);
  const [selectUsed, setSelectUsed] = useState(false);
  const [doctorId, setDoctorId] = useState();
  const [doctorOrdinations, setDoctorOrdinations] = useState([]);
  const [ordination, setOrdination] = useState([]);
  const [ordinationDuration, setOrdinationDuration] = useState();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [appointmentDuration, setAppointmentDuration] = useState();
  const [child, setChild] = useState(false);
  const [phone, setPhone] = useState();
  const [workDay, setWorkDay] = useState();
  const [patientEmail, setPatientEmail] = useState('');
  const [edit, setEdit] = useState(false);
  const [ordinationName, setOrdinationName] = useState('');

  // const [layout, setLayout] = useState({
  //   labelCol: { span: appointmentOption === 'postojeci' ? 7 : 4 },
  //   wrapperCol: { span: appointmentOption === 'postojeci' ? 17 : 20 },
  // });

  const layout = {
    labelCol: { span: appointmentOption === 'new_patient' ? 7 : 4 },
    wrapperCol: { span: appointmentOption === 'new_patient' ? 17 : 20 },
  };

  // useEffect(() => {
  //   console.log('AP', appointmentOption);
  //   setLayout({
  //     labelCol: { span: appointmentOption === 'postojeci' ? 7 : 4 },
  //     wrapperCol: { span: appointmentOption === 'postojeci' ? 17 : 20 },
  //   });
  // }, [appointmentOption]);

  const convert = (compare, comparator) => {
    return (
      comparator.children
        .join()
        .toLowerCase()
        .replace(/ć/g, 'c')
        .replace(/č/g, 'c')
        .replace(/đ/g, 'dj')
        .replace(/š/g, 's')
        .replace(/ž/g, 'z')
        .replace(/dž/g, 'dz')
        .indexOf(
          compare
            .toLowerCase()
            .replace(/ć/g, 'c')
            .replace(/č/g, 'c')
            .replace(/đ/g, 'dj')
            .replace(/š/g, 's')
            .replace(/ž/g, 'z')
            .replace(/dž/g, 'dz'),
        ) >= 0
    );
  };

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  useEffect(() => {
    if (userType === 'doctor') {
      const ordination = businessHours.find(
        (day) => day.daysOfWeek[0] === workDay && day.startTime < startTime && day.endTime > startTime,
      );
      if (ordination && ordination.ordination) setSelectedOrdination(ordination.ordination._id);
    } else if (userType === 'ordination') {
      setSelectedOrdination(currentuser?.ordination && currentuser?.ordination[0]);
    }
  }, [businessHours, startTime, workDay]);

  const getWorkingDays = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/doctor/workingDay/${doctorId}`, headers);
      if (data[0]) {
        setAppointmentDuration(data[0].duration);
        setOrdinationDuration(data[0].duration);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    if (doctorId && !selectedOrdination) {
      getWorkingDays();
    }
  }, [doctorId, getWorkingDays, selectedOrdination]);

  useEffect(() => {
    if (props.isCalendarModalVisible) {
      if (businessHours && selectedOrdination) {
        setOrdinationDuration(
          businessHours.find((hour) => hour.ordination._id === selectedOrdination).appointmentDuration,
        );
        if (!editCalendarDate?.examinationType.duration) {
          setAppointmentDuration(
            businessHours.find((hour) => hour.ordination._id === selectedOrdination).appointmentDuration,
          );
        } else {
          const examinationDuration =
            (new Date(editCalendarDate.endDate).getTime() - new Date(editCalendarDate.startDate).getTime()) / 60000;
          if (examinationDuration !== +editCalendarDate.examinationType.duration) {
            setAppointmentDuration(examinationDuration);
          } else {
            setAppointmentDuration(editCalendarDate.examinationType.duration);
          }
        }
      } else {
        setSelectedService('');
        if (businessHours && selectedOrdination) {
          setAppointmentDuration(
            businessHours.find((hour) => hour.ordination._id === selectedOrdination).appointmentDuration,
          );
        } else {
          if (editCalendarDate) {
            const examinationDuration =
              (new Date(editCalendarDate.endDate).getTime() - new Date(editCalendarDate.startDate).getTime()) / 60000;
            if (examinationDuration !== +editCalendarDate.examinationType.duration) {
              setAppointmentDuration(examinationDuration);
            } else {
              setAppointmentDuration(editCalendarDate.examinationType.duration);
            }
          } else {
            getWorkingDays();
          }
        }
      }
    } else {
      setSelectedService('');
    }
  }, [businessHours, selectedOrdination, props.isCalendarModalVisible, editCalendarDate, getWorkingDays]);

  const getServices = useCallback(async () => {
    try {
      if (doctorId && doctorId !== 'undefined') {
        const { data } = await Axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
        setDoctorOrdinations(data.ordination);
        const serve = await Axios.post(
          `${SERVER_URL}/doctor/services`,
          {
            doctor: doctorId,
            ordination: selectedOrdination
              ? selectedOrdination
              : userType === 'ordination' && currentuser?.ordination[0],
          },
          headers,
        );
        if (serve.data) setServices(serve.data.services);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [doctorId, selectedOrdination]);

  useEffect(() => {
    if (selectedOrdination && doctorId) {
      getServices();
    } else {
      getServices();
    }
  }, [selectedOrdination, doctorId, getServices]);

  const getOrdination = useCallback(async () => {
    try {
      if (userType === 'ordination') {
        const { data } = await Axios.get(`${SERVER_URL}/ordination/${currentuser?.ordination}`, headers);
        setOrdination(data);
      } else {
        if (doctorId) {
          const { data } = await Axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
          setOrdination(data.ordination);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [doctorId]);

  useEffect(() => {
    if (userType === 'doctor') {
      Axios.get(`${SERVER_URL}/patient/doctor/${currentuser?.doctor}`, headers)
        .then((res) => {
          setFilteredPatients(res.data);
          setCopyPatients(res.data);
        })
        .catch((err) => console.log(err));
    } else if (userType === 'ordination') {
      Axios.post(`${SERVER_URL}/patient/ordination`, { ordinations: currentuser?.ordination }, headers)
        .then((res) => {
          setFilteredPatients(res.data);
          setCopyPatients(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [props.createAppointemntAndPatient]);

  const createFormValues = useCallback(() => {
    if (editCalendarDate !== undefined) {
      setIsNew(false);
      setAppointmentOption('new_patient');
      switch (new Date(editCalendarDate.startDate).toDateString().split(' ')[0]) {
        case 'Mon':
          setWorkDay(1);
          break;
        case 'Tue':
          setWorkDay(2);
          break;
        case 'Wed':
          setWorkDay(3);
          break;
        case 'Thu':
          setWorkDay(4);
          break;
        case 'Fri':
          setWorkDay(5);
          break;
        case 'Sat':
          setWorkDay(6);
          break;
        case 'Sun':
          setWorkDay(0);
          break;
        default:
          break;
      }
      form.setFieldsValue({
        ...editCalendarDate,
        dateOfBirth: editCalendarDate.patient.dateOfBirth
          ? moment(editCalendarDate.patient.dateOfBirth)
          : editCalendarDate.dateOfBirth && moment(editCalendarDate.dateOfBirth),
        examinationType: !selectedService ? editCalendarDate.examinationType._id : selectedService,
        email: editCalendarDate.patient.userReference?.email,
        ordinations: editCalendarDate.ordinations?._id,
        parentFirstName: editCalendarDate.patient.parent?.firstName,
        parentLastName: editCalendarDate.patient.parent?.lastName,
        parentEmail: editCalendarDate.patient.parent?.email,
        parentPhone: editCalendarDate.patient.parent?.phone,
      });

      const startingDate = new Date(editCalendarDate.startDate);
      setStartingDate(startingDate);
      const endingDate = new Date(editCalendarDate.startDate);
      const endDate = new Date(editCalendarDate.endDate);

      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      setChosenDate(startingDate.toLocaleDateString('sr', 'RS', options));

      setStartingHour(
        startingDate.getHours().toString().length === 1 ? `0${startingDate.getHours()}` : startingDate.getHours(),
      );
      setStartingMinutes(
        startingDate.getMinutes().toString().length === 1 ? `0${startingDate.getMinutes()}` : startingDate.getMinutes(),
      );

      if (appointmentDuration) {
        endingDate.setMinutes(endingDate.getMinutes() + +appointmentDuration);
      } else {
        endingDate.setMinutes(endingDate.getMinutes() + +ordinationDuration);
      }

      if (new Date(endingDate).getTime() < endDate.getTime() && !selectedService) {
        setEndingDate(endDate);
        setEndingHour(endDate.getHours().toString().length === 1 ? `0${endDate.getHours()}` : endDate.getHours());
        setEndingMinutes(
          endDate.getMinutes().toString().length === 1 ? `0${endDate.getMinutes()}` : endDate.getMinutes(),
        );
      } else {
        setEndingDate(endingDate);
        setEndingHour(
          endingDate.getHours().toString().length === 1 ? `0${endingDate.getHours()}` : endingDate.getHours(),
        );
        setEndingMinutes(
          endingDate.getMinutes().toString().length === 1 ? `0${endingDate.getMinutes()}` : endingDate.getMinutes(),
        );
      }

      setIsEditable(true);
    } else {
      setIsNew(true);
      setChild(false);
      switch (selectedInfoDate?.start.toString().split(' ')[0]) {
        case 'Mon':
          setWorkDay(1);
          break;
        case 'Tue':
          setWorkDay(2);
          break;
        case 'Wed':
          setWorkDay(3);
          break;
        case 'Thu':
          setWorkDay(4);
          break;
        case 'Fri':
          setWorkDay(5);
          break;
        case 'Sat':
          setWorkDay(6);
          break;
        case 'Sun':
          setWorkDay(0);
          break;
        default:
          break;
      }
      const startingDate = new Date(selectedInfoDate?.start);
      setStartingDate(startingDate);
      const endingDate = new Date(selectedInfoDate?.start);

      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      setChosenDate(startingDate.toLocaleDateString('sr', 'RS', options));

      setStartingHour(
        startingDate.getHours().toString().length === 1 ? `0${startingDate.getHours()}` : startingDate.getHours(),
      );
      setStartingMinutes(
        startingDate.getMinutes().toString().length === 1 ? `0${startingDate.getMinutes()}` : startingDate.getMinutes(),
      );

      if (appointmentDuration) {
        endingDate.setMinutes(endingDate.getMinutes() + +appointmentDuration);
      } else {
        endingDate.setMinutes(endingDate.getMinutes() + +ordinationDuration);
      }
      setEndingDate(endingDate);

      setEndingHour(
        endingDate.getHours().toString().length === 1 ? `0${endingDate.getHours()}` : endingDate.getHours(),
      );
      setEndingMinutes(
        endingDate.getMinutes().toString().length === 1 ? `0${endingDate.getMinutes()}` : endingDate.getMinutes(),
      );

      setIsEditable(true);
    }
  }, [appointmentDuration, editCalendarDate, form, selectedInfoDate, selectedService, ordinationDuration]);

  useEffect(() => {
    createFormValues();
  }, [createFormValues]);

  useEffect(() => {
    setSelectUsed(false);
    if (currentuser?.doctor) {
      setDoctorId(currentuser?.doctor);
    } else {
      setDoctorId(history.location.pathname.split('-')[2]);
    }
  }, [history, doctorId, props.result, handleCalendarModalCancel, editCalendarDate, selectedInfoDate]);

  useEffect(() => {
    getOrdination();
  }, [getOrdination]);

  const onFinish = (values) => {
    if (!values.search && appointmentOption === 'existing_patient') return 0;
    const returnValues = {
      ...values,
      startDate: startingDate,
      endDate: endingDate,
      firstName: values.firstName,
      lastName: values.lastName,
      doctors: doctorId,
      child,
    };

    if (values.dateOfBirth) {
      const date = values.dateOfBirth.toDate().toLocaleDateString('en-GB');
      const year = date.split('/')[2];
      const month = date.split('/')[1];
      const day = date.split('/')[0];
      returnValues.dateOfBirth = `${year}-${month}-${day}`;
    }

    if (userType === 'ordination') {
      returnValues.ordinations = currentuser?.ordination;
    } else {
      if (selectedOrdination && doctorOrdinations.length) {
        returnValues.ordinations = selectedOrdination;
      }
    }

    const doctor = businessHours.find((hour) => hour.ordination?._id === returnValues.ordinations);

    if (isNew) {
      if (!selectUsed) {
        props.createAppointemntAndPatient(returnValues);
      } else {
        props.createAppointment(returnValues);
      }
      const startDate = new Date(returnValues.startDate).setHours(new Date(returnValues.startDate).getHours() + 2);
      const endDate = new Date(returnValues.endDate).setHours(new Date(returnValues.endDate).getHours() + 2);
      if (doctor && doctor.doctorEmail && userType === 'doctor') {
        props.sendEmail(doctor.doctorEmail, 'CREATE', {
          patientName: `${returnValues.firstName} ${returnValues.lastName}`,
          examinationType: services.find((s) => s._id === selectedService),
          startDate: new Date(startDate).toLocaleString(),
          endDate: new Date(endDate).toLocaleString(),
          doctorName: `${currentuser?.firstName} ${currentuser?.lastName}`,
        });
      }
    } else {
      if (values.doctorNote && editCalendarDate.doctorNote !== values.doctorNote) {
        props.sendNotification({ ...editCalendarDate, doctorNote: values.doctorNote });
      }
      const updatedDate = props.updateDate(returnValues, editCalendarDate._id);
      updatedDate
        .then((res) => {
          const newEvents = events.map((event) => {
            if (event.extendedProps?.id === res._id) {
              event.start = res.startDate;
              event.end = res.endDate;
              event.extendedProps.start = res.startDate;
              event.extendedProps.end = res.endDate;
            }
            return event;
          });
          setEvents(newEvents);
        })
        .catch((err) => console.log(err));
      handleCalendarModalCancel();
    }

    setChild(false);
    setSelectUsed(false);
    setAppointmentOption('existing_patient');
    setEdit(false);
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields && !errorInfo.errorFields.length) {
      handleCalendarModalCancel();
    }
  };

  const onDelete = () => {
    if (editCalendarDate.ordinations) {
      const doctor = businessHours.find((hour) => hour.ordination?._id === editCalendarDate.ordinations._id);
      if (doctor && doctor.doctorEmail && userType === 'doctor') {
        const startDate = new Date(editCalendarDate.startDate).setHours(
          new Date(editCalendarDate.startDate).getHours() + 2,
        );
        const endDate = new Date(editCalendarDate.endDate).setHours(new Date(editCalendarDate.endDate).getHours() + 2);
        props.sendEmail(doctor.doctorEmail, 'DELETE', {
          patientName: `${editCalendarDate.firstName} ${editCalendarDate.lastName}`,
          examinationType: editCalendarDate.examinationType,
          startDate: new Date(startDate).toLocaleString(),
          endDate: new Date(endDate).toLocaleString(),
          doctorName: `${currentuser?.firstName} ${currentuser?.lastName}`,
        });
      }
    }
    props.deleteDate(editCalendarDate._id);
    handleCalendarModalCancel();
  };

  const cancel = (e) => {
    setSelectUsed(false);
  };

  const fillTheFields = (id) => {
    const data = filteredPatients.find((patient) => patient._id === id);
    isChild(new Date(data.dateOfBirth));
    if (data) {
      setPatientEmail(data.userReference?.email);
      form.setFieldsValue({
        examinationType: selectedService ? selectedService : '',
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.userReference?.email,
        phone: data.phone,
        dateOfBirth: data.dateOfBirth && moment(data.dateOfBirth),
        parentFirstName: data.parent?.firstName,
        parentLastName: data.parent?.lastName,
        parentEmail: data.parent?.email,
        parentPhone: data.parent?.phone,
      });
    }
    setAppointmentOption('new_patient');
    setSelectUsed(true);
  };

  useEffect(() => {
    setOrdinationName(userType === 'ordination'
      ? currentuser?.name
      : selectedOrdination && ordination?.find((ord) => ord._id === selectedOrdination).name);
  }, [ordination, selectedOrdination]);

  const initialValues = {
    ordinations: userType === 'ordination' ? currentuser?.ordination[0] : selectedOrdination,
    ordinationName:
      userType === 'ordination'
        ? currentuser?.name
        : selectedOrdination && ordination?.find((ord) => ord._id === selectedOrdination).name,
  };

  useEffect(() => {
    setAppointmentOption(isNew ? 'existing_patient' : 'new_patient');
  }, [isNew]);

  const filterPatients = (e) => {
    const search = e
      .toLowerCase()
      .replace(/ć/g, 'c')
      .replace(/č/g, 'c')
      .replace(/đ/g, 'dj')
      .replace(/š/g, 's')
      .replace(/ž/g, 'z')
      .replace(/dž/g, 'dz');
    const copy = copyPatients.filter((patient) => {
      const name = `${patient.firstName} ${patient.lastName}`
        .toLowerCase()
        .replace(/ć/g, 'c')
        .replace(/č/g, 'c')
        .replace(/đ/g, 'dj')
        .replace(/š/g, 's')
        .replace(/ž/g, 'z')
        .replace(/dž/g, 'dz');
      return name.indexOf(search) >= 0;
    });

    setFilteredPatients(copy);
  };

  return (
    <Modal
      className='doctor-modal'
      visible={props.isCalendarModalVisible}
      onCancel={() => {
        handleCalendarModalCancel();
        setEdit(false);
      }}
      footer={null}
      width={700}
      centered
    >
      <Card
        title={isNew ? 'Zakazivanje novog termina' : 'Zakazani termin'}
        extra={
          !isNew && (
            <div>
              <span onClick={() => setEdit((prev) => !prev)} style={{ cursor: 'pointer' }}>
                <img src='/images/pencil.png' alt='edit' />
              </span>
              <Popconfirm
                title='Da li ste sigurni da želite da obrišete termin?'
                onConfirm={onDelete}
                onCancel={cancel}
                okText='Da'
                cancelText='Ne'
              >
                <span style={{ cursor: 'pointer', marginLeft: '30px' }}>
                  <img src='/images/trash.png' alt='delete' />
                </span>
              </Popconfirm>
            </div>
          )
        }
        bordered={false}
        tabList={isNew ? cardTabs : []}
        activeTabKey={appointmentOption}
        onTabChange={(key) => setAppointmentOption(key)}
        className='calendar-data-form-card'
      >
        {/* <img className='modal-icon' src='/images/modal-patient-icon.svg' alt='patient' /> */}
        <div className={isEditable ? 'calendar-editable-inputs' : 'calendar-disabled-inputs'}>
          <Form
            {...layout}
            name='basic'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values)}
            onFinishFailed={onFinishFailed}
            layout='horizontal'
            form={form}
            className='modal-form'
          >
            <Form.Item
              label='DATUM'
              className={`${appointmentOption === 'new_patient' ? 'modal-form-time' : 'modal-form-time-melo-green'}`}
            >
              {chosenDate}
            </Form.Item>
            <Form.Item
              label='VREME'
              className={`${appointmentOption === 'new_patient' ? 'modal-form-time' : 'modal-form-time-melo-green'}`}
            >
              <span>
                {startingHour}:{startingMinutes}
              </span>
              <span> do </span>
              <span>
                {endingHour}:{endingMinutes}
              </span>
              <small style={{ marginLeft: '0.4rem' }}>({appointmentDuration || ordinationDuration} minuta)</small>
            </Form.Item>
            {appointmentOption === 'existing_patient' ? (
              <>
                {isNew && (
                  <>
                    <Form.Item name='search' className='zakazivanje-novog-termina-search'>
                      <Input
                        placeholder='Pretraga'
                        bordered={false}
                        onChange={(e) => filterPatients(e.target.value)}
                        prefix={<img className='input-icon' src='/images/search-icon-modal.png' alt='search' />}
                      />
                    </Form.Item>
                    <div style={{ height: '300px', overflowY: 'auto' }} className='zakazivanje-novog-termina-pacijenti'>
                      {filteredPatients?.map((patient) => (
                        <div
                          key={patient._id}
                          onClick={() => fillTheFields(patient._id)}
                          className='zakazivanje-novog-termina-pacijent'
                        >
                          <span>
                            {patient.firstName} {patient.lastName}
                          </span>
                          <p>{patient.phone}</p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {doctorOrdinations.length && userType === 'doctor' ? (
                  <Form.Item label='ORDINACIJA'>
                    <Input disabled value={ordinationName} />
                  </Form.Item>
                ) : (
                  ''
                )}
                <Form.Item
                  label='VRSTA PREGLEDA'
                  name='examinationType'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo, unesite vrstu pregleda!',
                    },
                  ]}
                >
                  <Select
                    disabled={!edit && !isNew}
                    bordered={false}
                    style={{ borderBottom: 'none' }}
                    showSearch
                    filterOption={(input, option) => convert(input, option)}
                    onChange={(val) => {
                      setSelectedService(val);
                      setAppointmentDuration(
                        services.find((service) => service._id === val).duration || ordinationDuration,
                      );
                    }}
                  >
                    {services?.map((service) => (
                      <Option key={service._id} value={service._id}>
                        {service.title} <small>{service.duration && `(${service.duration} minuta)`}</small>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label='IME'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo, unesite ime!',
                    },
                  ]}
                >
                  <Input disabled={!isNew ? true : false} />
                </Form.Item>
                <Form.Item
                  label='PREZIME'
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo, unesite prezime!',
                    },
                  ]}
                >
                  <Input disabled={!isNew ? true : false} />
                </Form.Item>
                {!child && (
                  <Form.Item
                    label='TELEFON'
                    name='phone'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo, unesite telefon!',
                      },
                    ]}
                  >
                    <PhoneInput
                      disabled={!edit && !isNew}
                      international
                      defaultCountry='RS'
                      countryCallingCodeEditable={false}
                      value={phone}
                      onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                    />
                  </Form.Item>
                )}
                <Form.Item label='DATUM ROĐENJA' name='dateOfBirth'>
                  <DatePicker
                    disabled={!isNew && !edit ? true : false}
                    showToday={false}
                    placeholder=''
                    bordered={false}
                    style={{ width: '100%' }}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => isChild(e)}
                  />
                </Form.Item>
                {child && (
                  <>
                    <h2 style={{ color: '#4dc1be' }}>Roditelj</h2>
                    <p style={{ color: '#627b90' }}>Kod maloletnika unesite podatke staratelja/roditelja</p>
                    <Form.Item
                      label='IME'
                      name='parentFirstName'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite ime!',
                        },
                      ]}
                    >
                      <Input disabled={!edit && !isNew} />
                    </Form.Item>

                    <Form.Item
                      label='PREZIME'
                      name='parentLastName'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite prezime!',
                        },
                      ]}
                    >
                      <Input disabled={!edit && !isNew} />
                    </Form.Item>

                    <Form.Item
                      label='EMAIL'
                      name='parentEmail'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite email!',
                        },
                      ]}
                    >
                      <Input type='email' defaultValue={patientEmail && patientEmail} disabled={!edit && !isNew} />
                    </Form.Item>
                    <Form.Item
                      label='TELEFON'
                      name='parentPhone'
                      rules={[
                        {
                          required: true,
                          message: 'Molimo, unesite telefon!',
                        },
                      ]}
                    >
                      <PhoneInput
                        disabled={!edit && !isNew}
                        international
                        defaultCountry='RS'
                        countryCallingCodeEditable={false}
                        value={phone}
                        onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                      />
                    </Form.Item>
                  </>
                )}
                {!child && (
                  <>
                    <Form.Item label='EMAIL' name='email'>
                      <Input type='email' disabled={!isNew ? true : false} />
                    </Form.Item>
                    <Form.Item label='NAPOMENA PACIJENTA' name='note'>
                      <TextArea rows={2} disabled={!edit && !isNew} />
                    </Form.Item>
                    <Form.Item label='NAPOMENA LEKARA' name='doctorNote'>
                      <TextArea rows={2} disabled={!edit && !isNew} />
                    </Form.Item>
                  </>
                )}
              </>
            )}
            <Row className='calendar-buttons'>
              {editCalendarDate && editCalendarDate?.patient && (
                <div className='patient-card'>
                  <button
                    className='action-button'
                    onClick={() => {
                      if (userType === 'doctor') sessionStorage.setItem('appointment', editCalendarDate._id);
                      window.location.href = `/admin/patients/${editCalendarDate?.firstName.toLowerCase()}-${editCalendarDate?.lastName.toLowerCase()}-${editCalendarDate.patient._id}`;
                    }}
                  >
                    <span>Karton pacijenta</span>
                  </button>
                  {isEditable && userType === 'ordinationDoctor' && (
                    <Link
                      to={`/admin/patients/${editCalendarDate?.firstName.toLowerCase()}-${editCalendarDate?.lastName.toLowerCase()}-${editCalendarDate.patient}/izvestaji`}
                    >
                      <button className='action-button' style={{ marginRight: '15px' }}>
                        <span>Kreiraj izveštaj</span>
                      </button>
                    </Link>
                  )}
                </div>
              )}
              {isEditable && !edit && isNew && (
                <div className='patient-card calendar'>
                  {appointmentOption === 'new_patient' ? (
                    <button
                      className={isNew ? 'action-button float-right' : 'action-button action-edit'}
                      htmltype='submit'
                      onClick={onFinishFailed}
                    >
                      <img src='/images/save.svg' alt='save' />
                      <span>Zakaži pregled</span>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {isEditable && edit && (
                <div className='patient-card calendar'>
                  {appointmentOption === 'new_patient' ? (
                    <button
                      className={isNew ? 'action-button float-right' : 'action-button action-edit'}
                      htmltype='submit'
                      onClick={onFinishFailed}
                    >
                      <img src='/images/save.svg' alt='save' />
                      <span>Snimi promene</span>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </Row>
          </Form>
        </div>
      </Card>
    </Modal>
  );
};

export default CalendarDataForm;
