import React, { useState, useEffect, useCallback } from 'react';
import { Card, Form, Row, Col, notification, Popconfirm, TimePicker, Radio } from 'antd';
import axios from 'axios';
import { io } from 'socket.io-client';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { differenceInCalendarDays } from 'date-fns';

import { SERVER_URL, SOCKET_URL } from '../../config';
import { DeleteWorkDayModal, RemoveWorkDayModal, SingleDayModal } from '../../components/modals';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};
const ordination = user?.ordination;
const dani = ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'];
const radniDani = ['ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota', 'nedelja'];
const format = 'HH:mm';

const WorkDays = ({ notificationEvents }) => {
  const [doctors, setDoctors] = useState([]);
  const [workingDays, setWorkingDays] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [removeAppointments, setRemoveAppointments] = useState(false);
  const [appointments, setAppointments] = useState({});
  const [appointmentDuration, setAppointmentDuration] = useState('');
  const [singleDayId, setSingleDayId] = useState(null);
  const [allWorkingDays, setAllWorkingDays] = useState([]);
  const [singleDayOpen, setSingleDayOpen] = useState(false);
  const [allSingleDays, setAllSingleDays] = useState([]);
  const [removeWorkDay, setRemoveWorkDay] = useState({
    open: false,
    data: [],
    mode: '',
    time: {},
    nonWork: undefined,
  });

  const getAllSingleDays = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/singleDay/${ordination}?type=ordination`, headers);
      setAllSingleDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    getAllSingleDays();
  }, [getAllSingleDays, notificationEvents]);

  const getAllWorkingDays = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordination/workingDay/${ordination}`, headers);
      setAllWorkingDays([data]);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    getAllWorkingDays();
  }, [getAllWorkingDays]);

  const getDoctors = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/doctors-by-ordination`, { ordination }, headers);
      setDoctors(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  // send notification to itself for cancelled terms
  const cancelNotification = async (appointments) => {
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    const data = appointments.data.map((d) => ({
      ...d,
      doctor: doctors?.find((a) => a._id === d.doctor),
    }));
    socket.emit('send-cancel-appointments', { data, ordination: user.ordination[0] });
  };

  // send notifications to ordination and patients for appointment cancellation
  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: 'ordination',
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-doctor-created', {
        ordinationId: ordination,
        doctorId: doctor,
        patientId: patient,
        startDate,
        message,
        calendarId,
        requestedBy,
      });
    }
  };

  const getWorkDays = useCallback(async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/ordination/workingDay`, { ordinations: ordination }, headers);
      if (data.length) {
        setWorkingDays(data[0]);
        setAppointmentDuration(data[0].duration || '15');
        if (data[0].nonWorking) {
          setSelectedDays(data[0].nonWorking);
        } else {
          setSelectedDays([]);
        }
      } else {
        setAppointmentDuration('15');
        setWorkingDays({});
        setSelectedDays([]);
      }
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, []);

  const setupHours = async (momentTime, day, toSet) => {
    const time = momentTime !== null ? moment(momentTime).format(format) : '';

    // if time is removed from FROM, get all booked appointemnts
    if (time === '' && toSet === 'from') {
      try {
        const { data } = await axios.post(`${SERVER_URL}/day`, { day, ordination }, headers);
        if (data.length) {
          setRemoveAppointments(true);
          setAppointments({ data, day, toSet, time });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      // otherwise find the day and check if FROM or TO is set
      // if yes, find appointments in that reach
      // if not, update time
      if ((toSet === 'from' && workingDays[day]) || (toSet === 'to' && workingDays[day].to)) {
        try {
          const { data } = await axios.post(
            `${SERVER_URL}/appointments/reach`,
            { time, day, toSet, ord: ordination },
            headers,
          );
          if (data.length) {
            setRemoveWorkDay({ open: true, data, mode: 'edit', time: { day, toSet, time } });
          } else {
            updateTime(day, toSet, time);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        updateTime(day, toSet, time);
      }
    }
  };

  const updateTime = (day, toSet, time) => {
    const workDays = {
      ...workingDays,
      [day]: {
        ...workingDays[day],
        [toSet === 'from' ? 'from' : 'to']: time,
      },
    };

    // if from is removed, remove the to too
    if (toSet === 'from' && time === '') {
      delete workDays[day];
    }

    // update hours in non-working days
    const newDays = selectedDays.map((yad) => {
      const dayInWeek = dani[new Date(yad.date).getDay()];
      return {
        date: yad.date,
        from: toSet === 'from' && dayInWeek === day ? workDays[day]?.from : yad.from,
        to: toSet === 'to' && dayInWeek === day ? workDays[day]?.to : yad.to,
      };
    });

    // if hours for the work day are removed
    const filterDays = newDays.filter((yad) => workDays[dani[new Date(yad.date).getDay()]]);

    setSelectedDays(filterDays);
    setWorkingDays(workDays);
  };

  useEffect(() => {
    getWorkDays();
  }, [getWorkDays]);

  const createWorkingDays = async () => {
    Object.keys(workingDays).forEach((key, val) => {
      if (typeof workingDays[key] === 'object' && workingDays[key] !== null && key !== 'nonWorking') {
        if (workingDays[key].from > workingDays[key].to) {
          workingDays[key].error = true;
          return notification.warn({
            message: `${key}: OD mora biti manje od DO`,
            placement: 'bottomRight',
            duration: 2.5,
          });
        } else if ((!workingDays[key].from && workingDays[key].to) || (workingDays[key].from && !workingDays[key].to)) {
          workingDays[key].error = true;
          return notification.warn({
            message: `${key}: Popunite u celosti`,
            placement: 'bottomRight',
            duration: 2.5,
          });
        } else if (!workingDays[key].from && !workingDays[key].to) {
          delete workingDays[key];
        }
        if (workingDays[key]) {
          delete workingDays[key].error;
        }
      }
    });

    selectedDays.forEach((day) => {
      if (day.from > day.to) {
        day.error = true;
        return notification.warn({
          message: `${new Date(day.date).toDateString()}: OD mora biti manje od DO`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      if (day.from < day.to) {
        delete day.error;
      }
    });

    const errorInNonWorking = selectedDays.find((day) => day.error);

    let error = Object.keys(workingDays).find(
      (key, _) => workingDays[key] !== null && typeof workingDays[key] === 'object' && workingDays[key].error,
    );

    if (!error && errorInNonWorking) error = true;

    try {
      if (!error) {
        workingDays.ordination = ordination;
        workingDays.nonWorking = selectedDays;
        workingDays.duration = appointmentDuration;

        if (workingDays.nonWorking) {
          const { data } = await axios.post(`${SERVER_URL}/appointments/nonDay?type=ordination`, workingDays, headers);

          if (data.length) {
            setRemoveWorkDay({ open: true, data, mode: 'delete', nonWork: workingDays });
          } else {
            updateWorkingDays();
          }
        } else {
          updateWorkingDays();
        }
      }
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const updateWorkingDays = async () => {
    try {
      if (removeWorkDay.data.length) {
        const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
        notification.info({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
        sendDeleteNotification(removeWorkDay);
      }

      if (workingDays.createdAt) {
        const { data } = await axios.put(`${SERVER_URL}/workingDay/ordination/${ordination}`, workingDays, headers);
        const oldDays = [];
        Object.keys(data.oldDay).forEach((key, _) => {
          if (radniDani.includes(key)) {
            oldDays.push({ ...data.oldDay[key], day: key });
          }
        });
        const newDays = [];
        Object.keys(data.newDay).forEach((key, _) => {
          if (radniDani.includes(key)) {
            newDays.push({ ...data.newDay[key], day: key });
          }
        });

        const oldNonWorkingDays = data.oldDay.nonWorking;
        const newNonWorkingDays = data.newDay.nonWorking;

        let socket = io(SOCKET_URL, { path: '/socket.io' });
        data.doctors.forEach(async (doc) => {
          let notificationData = {
            ordination,
            doctor: doc,
            miscellaneousData: { newDays, oldDays, newNonWorkingDays, oldNonWorkingDays },
            requestedBy: 'ordination',
            message: 'UPDATE_WORK_DAY',
          };
          await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
          socket.emit('send-work-days', notificationData);
        });

        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else {
        const { data } = await axios.post(`${SERVER_URL}/workingDay`, workingDays, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      getWorkDays();
      cancelNotification(removeWorkDay);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete appointemnts, which don't enter the new time, when you change time of a work day and send notification
  const deleteDayAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      updateTime(removeWorkDay.time.day, removeWorkDay.time.toSet, removeWorkDay.time.time);
      cancelNotification(removeWorkDay);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // if you remove a work day (ponedeljak), delete all appointments of that day and send notifications
  const deleteAllAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.delete(`${SERVER_URL}/appointments/day?ord=${ordination}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      deleteWorkingDay();
      cancelNotification(removeWorkDay);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // before deleting all working days, open popup
  const beforeDelete = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/appointments/day?ord=${ordination}`, headers);
      if (data.length) {
        setRemoveWorkDay({ open: true, data, edit: 'delete' });
      } else {
        deleteWorkingDay();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteWorkingDay = async () => {
    try {
      const { data } = await axios.delete(`${SERVER_URL}/ordination/workingDay/${ordination}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });

      let socket = io(SOCKET_URL, { path: '/socket.io' });
      data.doctors.forEach(async (doctor) => {
        let notificationData = {
          ordination,
          doctor,
          miscellaneousData: { appointments: removeWorkDay.data },
          requestedBy: 'ordination',
          message: 'DELETE_WORK_DAY',
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-work-days', notificationData);
      });

      getWorkDays();
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/delete/day`, { days: appointments.data }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      sendDeleteNotification(appointments);

      const { day, toSet, time } = appointments;
      updateTime(day, toSet, time);

      setAppointments({});
      cancelNotification(appointments);
      setRemoveAppointments(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // triggered when selecting non-working day
  const handleDayClick = (day, { selected }) => {
    if (disabledCalendarDays(day)) return;
    const days = selectedDays.concat();
    const dayInWeek = workingDays[dani[new Date(day).getDay()]];
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay.date), day),
      );
      days.splice(selectedIndex, 1);
    } else {
      days.push({ date: day, from: dayInWeek.from, to: dayInWeek.to });
    }
    days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    setSelectedDays(days);
  };

  const setupTime = (momentTime, day, toSet) => {
    const time = moment(momentTime).format(format);
    const newDays = [...selectedDays];
    const findDay = newDays.find((selectedDay) => selectedDay.date === day);
    if (toSet === 'from') findDay.from = time;
    if (toSet === 'to') findDay.to = time;
    const index = newDays.indexOf(findDay);
    if (index !== -1) newDays[index] = findDay;
    setSelectedDays(newDays);
  };

  const disabledCalendarDays = (date) => {
    const index = new Date(date).getDay();

    if (workingDays[dani[index]]) {
      return differenceInCalendarDays(date, new Date()) < 0;
    }
    return true;
  };

  const disableHours = (day) => {
    const index = dani[new Date(day.date).getDay()];
    const min = +workingDays[index]?.from.split(':')[0];
    const max = +workingDays[index]?.to.split(':')[0];
    const numbers = [];

    for (let i = 0; i < 24; i++) {
      if (i < min || max < i) numbers.push(i);
    }

    return numbers;
  };

  const disableMinutes = (hour, day, toSet) => {
    const minutes = [];
    const index = dani[new Date(day.date).getDay()];
    let time;

    if (hour) {
      time = toSet === 'from' ? workingDays[index].from : workingDays[index].to;
      if (hour === +time.split(':')[0] && toSet === 'to') {
        const minute = time.split(':')[1];
        for (let i = minute; i < 60; i++) {
          minutes.push(i);
        }
      }
      if (hour === +time.split(':')[0] && toSet === 'from') {
        const minute = time.split(':')[1];
        for (let i = 0; i < minute; i++) {
          minutes.push(i);
        }
      }
    }

    return minutes;
  };

  const hasNonWorkingDays = selectedDays?.filter(
    (day) => new Date(day.date).setHours('22', '0', '0') > new Date().getTime(),
  );

  /**
   * IMPORTANT:
   * onSelect and onCange are mandatory in TimePicker component
   * onSelect => removes the necessity of confirming time with OK
   * onChnage => enables the time to be removed when clicking x
   */
  return (
    <div className='card-container max1400'>
      <div className='card-content'>
        <div className='dashboard'>
          <div className='card-wrapper dan'>
            <div className='radno-naslov'>
              <Card bordered={false} title='Postavite radno vreme'>
                <Form onFinish={() => createWorkingDays()}>
                  <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} lg={{ span: 15, offset: 0 }}>
                      <Form.Item label='TRAJANJE TERMINA'>
                        <Radio.Group
                          onChange={(e) => setAppointmentDuration(e.target.value)}
                          value={appointmentDuration}
                        >
                          <Radio value='15'>15 minuta</Radio>
                          <Radio value='30'>30 minuta</Radio>
                          <Radio value='45'>45 minuta</Radio>
                          <Radio value='60'>60 minuta</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {radniDani.map((day, i) => (
                        <div
                          key={i}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                          }}
                        >
                          <p style={{ minWidth: '100px', color: '#627b90', margin: 0 }}>{day.toUpperCase()}</p>
                          <span style={{ color: '#627b90' }}>Od:</span>
                          <TimePicker
                            onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 23]}
                            disabledMinutes={(hour) => hour === 22 && [15, 30, 45]}
                            bordered={false}
                            showNow={false}
                            minuteStep={15}
                            format={format}
                            onSelect={(time) => setupHours(time, day, 'from')}
                            onChange={(time) => setupHours(time, day, 'from')}
                            value={workingDays[day]?.from && moment(workingDays[day]?.from, format)}
                          />
                          <span style={{ color: '#627b90' }}>Do:</span>
                          <TimePicker
                            onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                            style={{ opacity: `${Boolean(!workingDays[day]?.from) ? '.3' : '1'}` }}
                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 23]}
                            disabledMinutes={(hour) => hour === 22 && [15, 30, 45]}
                            bordered={false}
                            disabled={Boolean(!workingDays[day]?.from)}
                            showNow={false}
                            minuteStep={15}
                            format={format}
                            onSelect={(time) => setupHours(time, day, 'to')}
                            onChange={(time) => setupHours(time, day, 'to')}
                            value={workingDays[day]?.to && moment(workingDays[day]?.to, format)}
                          />
                        </div>
                      ))}
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      lg={{ span: 9, offset: 0 }}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <h2 style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Izaberite neradne dane</h2>
                      <Form.Item>
                        <DayPicker
                          disabledDays={disabledCalendarDays}
                          selectedDays={selectedDays.map((day) => new Date(day.date))}
                          onDayClick={handleDayClick}
                          firstDayOfWeek={1}
                          className='non-working-days-day-picker'
                        />
                        {hasNonWorkingDays.length ? <h1>Neradni dani</h1> : ''}
                        <div style={{ maxHeight: '220px', overflowY: 'auto' }}>
                          {selectedDays?.map((day, i) => (
                            <div key={i}>
                              {new Date(day.date).setHours('22', '0', '0') > new Date().getTime() && (
                                <>
                                  <p style={{ color: '#627b90', margin: 0, marginTop: '.3rem' }}>
                                    {moment(day.date).locale('sr').format('DD. MMMM YYYY.').toUpperCase()}
                                  </p>
                                  <span style={{ color: '#627b90' }}>Od:</span>
                                  <TimePicker
                                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                                    disabledHours={() => disableHours(day, false)}
                                    disabledMinutes={(hour) => disableMinutes(hour, day, 'from')}
                                    bordered={false}
                                    showNow={false}
                                    minuteStep={15}
                                    format={format}
                                    allowClear={false}
                                    onSelect={(time) => setupTime(time, day.date, 'from')}
                                    value={
                                      selectedDays?.find((selectedDay) => selectedDay.date === day.date).from &&
                                      moment(
                                        selectedDays?.find((selectedDay) => selectedDay.date === day.date).from,
                                        format,
                                      )
                                    }
                                  />
                                  <span style={{ color: '#627b90' }}>Do:</span>
                                  <TimePicker
                                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                                    disabledHours={() => disableHours(day, true)}
                                    disabledMinutes={(hour) => disableMinutes(hour, day, 'to')}
                                    bordered={false}
                                    showNow={false}
                                    minuteStep={15}
                                    format={format}
                                    allowClear={false}
                                    onSelect={(time) => setupTime(time, day.date, 'to')}
                                    value={
                                      selectedDays?.find((selectedDay) => selectedDay.date === day.date).to &&
                                      moment(
                                        selectedDays?.find((selectedDay) => selectedDay.date === day.date).to,
                                        format,
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                      {allSingleDays.length && !allSingleDays[0].days.length ?
                        <span
                          className='action-button border-dark'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSingleDayOpen(true);
                            setSingleDayId((allSingleDays.length && allSingleDays[0]._id) || null);
                          }}
                        >
                          Postavi jednokratne dane
                        </span>
                      ) : (
                        <span
                          className='action-button border-dark'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSingleDayOpen(true);
                            setSingleDayId((allSingleDays.length && allSingleDays[0]._id) || null);
                          }}
                        >
                          Jednokratni radni dani
                        </span>
                      }
                    </div>
                  </Row> */}
                  <Row className='doctor-working-hours-buttons'>
                    <Col style={{ display: 'flex' }}>
                      <button className='action-button border-dark' htmltype='submit'>
                        <img src='/images/save.svg' alt='save' />
                        <span>{workingDays?.createdAt ? 'Ažuriraj' : 'Potvrdi'}</span>
                      </button>
                      {workingDays?.createdAt && (
                        <Popconfirm
                          title='Da li stvarno želite da obrišete radno vreme?'
                          onConfirm={beforeDelete}
                          onCancel={(e) => console.log(e)}
                          okText='Da'
                          cancelText='Ne'
                        >
                          <button className='action-button delete'>
                            <img src='/images/delete.svg' alt='delete' />
                            <span>Obriši</span>
                          </button>
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <DeleteWorkDayModal
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        updateWorkingDays={updateWorkingDays}
        deleteDayAppointments={deleteDayAppointments}
        deleteAllAppointments={deleteAllAppointments}
      />
      <RemoveWorkDayModal
        appointments={appointments}
        removeAppointments={removeAppointments}
        setRemoveAppointments={setRemoveAppointments}
        deleteAppointments={deleteAppointments}
      />
      <SingleDayModal
        requestedBy='ordination'
        singleDayOpen={singleDayOpen}
        setSingleDayOpen={setSingleDayOpen}
        ordination={ordination}
        singleDayId={singleDayId}
        setSingleDayId={setSingleDayId}
        allSingleDays={allSingleDays}
        getAllSingleDays={getAllSingleDays}
        workingDays={workingDays}
        allWorkingDays={allWorkingDays}
        appointmentDuration={appointmentDuration}
      />
    </div>
  );
};

export default WorkDays;
