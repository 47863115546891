import React from 'react';

const PrintDocument = React.forwardRef(({ singleDocument }, ref) => {
  return (
    <div className='recepie' ref={ref}>
      <img src={singleDocument.document} alt={singleDocument.name} />
    </div>
  );
});

export default PrintDocument;