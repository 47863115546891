import React, { useState, useContext, useEffect } from 'react';
import { Upload, notification, Modal, Card, Form, Input, Popconfirm, Row, Col, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import BannersTable from '../../components/tables/BannersTable';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export default function AdminBanners() {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [opennedBanner, setOpennedBanner] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [banner, setBanner] = useState([]);

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  };

  const banners = [
    { title: 'bannerTop', name: 'Banner home screen top' },
    { title: 'bannerSearch', name: 'Banner pretraga lekara' },
    { title: 'bannerList', name: 'Banner lista mojih lekara' },
    { title: 'bannerSettings', name: 'Banner screen podesavanja' },
    { title: 'bannerDocuments', name: 'Banner dokumenti' }
  ];

  const getBanner = async (title) => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/banners/${title}`);
      setBanner(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (banner.length) {
      form.setFieldsValue({ banners: banner });
    } else {
      form.resetFields();
    }
  }, [banner, form]);

  const openModal = ({ title, name }) => {
    setIsOpen(true);
    setOpennedBanner(name);
    setBannerTitle(title);
    getBanner(title);
  };

  useEffect(() => {
    const divs = document.querySelectorAll('.ant-upload-list-item-card-actions-btn');
    if (divs.length) {
      divs.forEach((div) => {
        div.classList.remove('ant-btn');
        div.classList.remove('ant-btn-sm');
        div.classList.remove('ant-btn-text');
        div.classList.remove('ant-btn-icon-only');
      });
    }
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadImage = (val) => {
    try {
      if (val.file.response) {
        if (val.file.status === 'done') {
          notification.info({
            message: 'Slika je sačuvana',
            placement: 'bottomRight',
            duration: 2.5
          });
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa čuvanjem slika.',
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const removeImage = async (file) => {
    try {
      await Axios.delete(`${SERVER_URL}/remove-local-image/${file.response._id}`, {
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.info({
        message: 'Slika je uklonjena',
        placement: 'bottomRight',
        duration: 2.5
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = async (values) => {
    const banners = values.banners.map(banner => {
      return {
        ...banner,
        title: bannerTitle,
        image: banner.image?.fileList?.length === 0 ? null : banner.image?.file?.response.image || banner.image
      };
    });

    try {
      let data;
      if (Object.keys(banner).length) {
        data = await Axios.put(`${SERVER_URL}/banners/${bannerTitle}`, banners, headers);
      } else {
        data = await Axios.post(`${SERVER_URL}/banners`, banners, headers);
      }
      notification.success({
        message: data.data.message,
        placement: "bottomRight"
      });
      setIsOpen(false);
      getBanner(bannerTitle);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onDelete = async () => {
    try {
      const { data } = await Axios.delete(`${SERVER_URL}/banners/${bannerTitle}`, headers);
      notification.success({
        message: data.message,
        placement: "bottomRight"
      });
      setIsOpen(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="banners-page">
      <div style={{ padding: '8px' }}>
        <BannersTable openModal={openModal} banners={banners} />
        {isOpen &&
          <Modal
            className='doctor-modal'
            visible={isOpen}
            onCancel={() => {
              setIsOpen(false);
              form.resetFields();
            }}
            footer={null}
            width={650}
            centered
          >
            <Card
              title={opennedBanner}
              bordered={false}
              className='calendar-data-form-card date-change'
            >
              <div className='date-change-container'>
                <Form onFinish={(value) => onFinish(value)} layout='vertical' form={form} initialValues={banner}>
                  <Form.List name='banners'>
                    {(fields, { add, remove }) => (
                      <>
                        <Row gutter={16} className='custom-row'>
                          {fields.map((field, i) => (
                            <Col xs={24} md={12} lg={11} key={i} className='banner-individual'>
                              <Form.Item name={[field.name, 'image']}>
                                <Upload
                                  defaultFileList={banner[field.name] && banner[field.name].image && [{
                                    response: banner[field.name]?.image,
                                    name: banner[field.name]?.image?.originalname,
                                    status: 'done',
                                    url: `${SERVER_URL}${banner[field.name]?.image?.url}`
                                  }]}
                                  action={`${SERVER_URL}/upload-local-image`}
                                  name='image'
                                  maxCount={1}
                                  listType='picture'
                                  onPreview={handlePreview}
                                  headers={{
                                    Authorization: currentuser.data.token,
                                    uri: 'public/images/gallery/',
                                  }}
                                  onChange={uploadImage}
                                  onRemove={removeImage}
                                >
                                  <Button icon={<UploadOutlined />}>Otpremite sliku</Button>
                                </Upload>
                              </Form.Item>
                              <div>

                              </div>
                              <Form.Item label='Ime klijenta' name={[field.name, 'clientName']}>
                                <Input />
                              </Form.Item>
                              <Form.Item label="Datum isteka" name={[field.name, 'expiaryDate']}>
                                <Input type='date' style={{ cursor: 'text' }} />
                              </Form.Item>
                              <Button onClick={() => remove(field.name)}>Ukloni</Button>
                            </Col>
                          ))}
                        </Row>
                        {fields.length < 5 &&
                          <Button style={{ margin: '15px 0' }} onClick={() => add()}>
                            Dodajte kolonu
                          </Button>
                        }
                      </>
                    )}
                  </Form.List>
                  <Row className='doctor-working-hours-buttons'>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                      <button style={{ float: 'right', cursor: 'pointer' }} htmltype='submit' className='action-button border-dark'>
                        <img src='/images/save.svg' alt='save' />
                        <span>{Object.keys(banner).length ? "Ažuriraj" : 'Potvrdi'}</span>
                      </button>
                      {Object.keys(banner).length ?
                        <Popconfirm
                          title='Da li ste sigurni da želite da obrišete termin?'
                          onConfirm={onDelete}
                          okText='Da'
                          cancelText='Ne'
                        >
                          <button style={{ float: 'right', cursor: 'pointer', marginRight: '0px' }} className='action-button delete-button'>
                            <img src='/images/delete.svg' alt='delete' style={{ color: '#ff7373' }} />
                            <span>Obriši</span>
                          </button>
                        </Popconfirm>
                        :
                        <></>
                      }
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
          </Modal>
        }
        <Modal visible={previewVisible} title={previewTitle} footer={' '} onCancel={() => setPreviewVisible(false)}>
          <img
            alt='plus'
            style={{ width: '100%' }}
            src={previewImage}
          />
        </Modal>
      </div>
    </div>

  );
}
