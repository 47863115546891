import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import OrdinationTable from '../../components/tables/OrdinationsTable';

import { SERVER_URL } from '../../config';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Ordinations = () => {
  const [ordinations, setOrdinations] = useState([]);

  const getOrdinations = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/user/ordinations`, headers);
      setOrdinations(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getOrdinations();
  }, []);

  const deactivateOrdination = async (id) => {
    try {
      const deactivate = await Axios.get(`${SERVER_URL}/ordination/deactivate/${id}`, headers);
      notification.success({
        message: deactivate.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getOrdinations();
    } catch (err) {
      notification.error({
        message: err.message.data.response,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const activateOrdination = async (id) => {
    try {
      const activate = await Axios.get(`${SERVER_URL}/ordination/activate/${id}`, headers);
      notification.success({
        message: activate.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getOrdinations();
    } catch (err) {
      notification.error({
        message: err.message.data.response,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const columnKeysTranslated = ['Naziv', 'Adresa', 'Grad', 'Telefon', 'Websajt', 'Email', 'Kreiran'];
  const columnKeys = ['name', 'address', 'city', 'phone', 'website', 'email', 'createdAt'];

  let tableData = [];
  if (ordinations.data && ordinations.data.length > 0) {
    tableData = ordinations.data.map((item) => ({
      ...item,
      email: item.userReference?.email,
      status: item.userReference?.status,
      createdAt: !item.createdAt ? "" : new Date(item.createdAt).toLocaleString('sr-Latn-RS', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: '2-digit',
        minute: "2-digit",
      })
    }));
  }

  return (
    <div className='doctors-table' style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/concord/new-ordination'>
          <Button type='primary'>Nova ordinacija</Button>
        </Link>
      </div>

      {ordinations.isLoading && (
        <div style={{ justifyContent: 'center', display: 'flex', margin: '5rem' }}>
          <LoadingOutlined spin style={{ fontSize: '3rem' }} />
        </div>
      )}
      {!ordinations.isLoading && ordinations.data && ordinations.data.length > 0 && (
        <div className='table-wrapper'>
          <OrdinationTable
            data={tableData}
            deactivate={deactivateOrdination}
            activate={activateOrdination}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            title='User'
            editPath='/admin/concord/edit-ordination/'
            viewPath='/admin/view-user/'
          />
        </div>
      )}

      {!ordinations.isLoading && ordinations.data && ordinations.data.length === 0 && (
        <h2 style={{ color: '#4dc1be', marginTop: '2rem', textAlign: 'center' }}>NEMA ORDINACIJA</h2>
      )}
    </div>
  );
};

export default Ordinations;
