import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Card, Select, Row, Col, notification } from 'antd';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import { io } from 'socket.io-client';
import 'react-phone-number-input/style.css';

import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { UserContext } from '../../App';
import { SOCKET_URL, SERVER_URL } from '../../config';
import { AddOrdinationPrompt } from '../modals';

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const DoctorForm = ({
  data,
  updateHandler,
  categories,
  result,
  setResult,
  token,
  language,
}) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState();
  const [workDayOverlap, setWorkDayOverlap] = useState({ open: false, data: [] });

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  };

  const getCitites = useCallback(async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    getCitites();
  }, [getCitites]);

  const onFinish = async (values) => {
    values.ordination = currentuser.data.ordination;
    values.phone = phone;
    try {
      const { data } = await axios.put(`${SERVER_URL}/add/doctor?ordination=${currentuser.data.ordination}`, values, headers);

      await axios.post(`${SERVER_URL}/send-push-token/null`, {
        doctor: data.doctor._id,
        ordination: currentuser.data.ordination,
        message: "ADD_DOC"
      }, headers);

      // const adminResponse = await axios.get(`${SERVER_URL}/user-admin`, headers);
      // const adminId = adminResponse.data._id;
      let socket = io(SOCKET_URL, { path: '/socket.io' });
      socket.emit('add-doctor-created', { doctor: data.doctor._id, ordination: currentuser.data.ordination });

      // await axios.post(
      //   `${SERVER_URL}/send-push-token/${calendar.patient._id}`,
      //   {
      //     startDate: calendar.startDate,
      //     endDate: calendar.endDate,
      //     calendarId: id,
      //     message: 'CANCEL_TERM',
      //     doctor: calendar.doctor,
      //     ordination: ordinationId,
      //     requestedBy: userType,
      //   },
      //   headers,
      // );

      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });

      setTimeout(() => history.push('/admin/doctors'), 700);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const beforeAdd = async (values) => {
    try {
      const postData = {
        doctorData: values,
        ordId: currentuser.data.ordination
      };
      const { data } = await axios.post(`${SERVER_URL}/check/workingDay`, postData, headers);
      if (data.workDayOverlap.length || data.singleDayOverlap.length) {
        setWorkDayOverlap({ open: true, data });
      } else {
        onFinish(values);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='novi-lekar-klinika'>
      <div className='profile-container'>
        <div className='ordination-form-container'>
          <div className='ordination-form-wrapper wide'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                {!result && (
                  <Card title={'Novi profil lekara'} bordered={false}>
                    <Form
                      {...layout}
                      onFinish={beforeAdd}
                      onFinishFailed={onFinishFailed}
                      layout='horizontal'
                      form={form}
                      style={{ borderRadius: '13px' }}
                    >
                      <Row className='card-form'>
                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                          <Form.Item
                            label='IME'
                            name='firstName'
                            rules={[
                              {
                                required: true,
                                message: 'Molimo Vas, unesite ime!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label='PREZIME'
                            name='lastName'
                            rules={[
                              {
                                required: true,
                                message: 'Molimo, unesite prezime!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label='EMAIL'
                            name='email'
                            rules={[
                              {
                                required: true,
                                message: 'Molimo, unesite ispravan email!',
                              },
                            ]}
                          >
                            <Input type='email' />
                          </Form.Item>

                          <Form.Item
                            label='GRAD'
                            name='city'
                            rules={[
                              {
                                required: true,
                                message: 'Obavezno upišite grad!',
                              },
                            ]}
                          >
                            <Select mode='multiple' showArrow={true}>
                              {cities?.map((city) => (
                                <Option key={city._id} value={city.title}>
                                  {city.title}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label='TELEFON'
                            name='phone'
                            rules={[
                              {
                                required: true,
                                message: 'Molimo, unesite broj telefona!',
                              },
                            ]}
                          >
                            <PhoneInput
                              style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry='RS'
                              value={phone}
                              onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                            />
                          </Form.Item>

                          <Form.Item
                            label='OBLAST RADA'
                            name='category'
                            className='panel-body'
                            rules={[{ required: true, message: 'Molimo, unesite specijalizaciju!' }]}
                          >
                            <Select
                              showArrow
                              showSearch
                              mode='multiple'
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')
                                  .indexOf(input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz')) >= 0
                              }
                            >
                              {categories?.map((cat) => (
                                <Option key={cat._id} value={cat._id}>
                                  {cat.name.sr}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label='BROJ LICENCE'
                            name='license'
                            rules={[
                              {
                                required: true,
                                message: 'Molimo, unesite broj licence!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                          <Form.Item className='upload-wrapper right' name='avatar'>
                            <UploadBox
                              editHandler={avatarHandler}
                              deleteHandler={deleteAvatarHandler}
                              image={image}
                              index={0}
                              name='avatar'
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={{ span: 1, offset: 23 }}>
                          <Form.Item>
                            <button
                              className='action-button border-dark'
                              htmltype='submit'
                              style={{ float: 'right', marginRight: '-2px' }}
                            >
                              <img src='../images/save.svg' alt='save' />
                              <span>Dodaj</span>
                            </button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                )}

                {modal.visible && (
                  <GalleryModal
                    width={700}
                    centered
                    visible={modal.visible}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageSavePath='public/images/users/'
                    imageType='data'
                    imageHeight={200}
                    SERVER_URL={SERVER_URL}
                    token={token.data.token}
                    form={form}
                    onCancel={() => setModal({ ...modal, visible: false })}
                    onInsert={(values) => onInsert(values, modal.formKey)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrdinationPrompt
        workDayOverlap={workDayOverlap}
        setWorkDayOverlap={setWorkDayOverlap}
      />
    </div>
  );
};

export default DoctorForm;
