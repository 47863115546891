import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import DoctorsTable from '../../../components/tables/DoctorsTable';

import { SERVER_URL } from '../../../config';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [reset, setReset] = useState([]);
  const [tableKey, setTableKey] = useState(0);

  const getDoctors = async () => {
    try {
      const data = await Axios.get(`${SERVER_URL}/user/doctors`, headers);
      setDoctors(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  const deactivateDoctor = async (email) => {
    try {
      const deactivate = await Axios.get(`${SERVER_URL}/doctor/deactivate/${email}?admin=true`, headers);
      notification.success({
        message: deactivate.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getDoctors();
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const activateDoctor = async (email) => {
    try {
      const activate = await Axios.get(`${SERVER_URL}/doctor/activate/${email}`, headers);
      notification.success({
        message: activate.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getDoctors();
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const columnKeysTranslated = ['Ime', 'Prezime', 'Telefon', 'Grad', 'Email', 'Specijalizacija', 'Broj licence', 'Ordinacija', 'Kreiran'];
  const columnKeys = ['firstName', 'lastName', 'phone', 'city', 'email', 'categories', 'ordinations', 'license', 'createdAt'];

  let tableData = [];
  if (doctors.data && doctors.data.length > 0) {
    tableData = doctors.data.map((item) => {
      return {
        ...item,
        city: item.city.map((city, i) => `${city}${i < item.city.length - 1 ? `,` : ''} `),
        categories: item.category.map((cat, i) => `${cat?.name?.sr}${i < item.category.length - 1 ? `,` : ''} `),
        ordinations: item.ordination.map((ord, i) => `${ord.name}${i < item.ordination.length - 1 ? `,` : ''} `),
        status: item.userReference?.status,
        email: item.userReference?.email,
        createdAt: !item.createdAt ? "" : new Date(item.createdAt).toLocaleString('sr-Latn-RS', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: '2-digit',
          minute: "2-digit",
        })
      };
    });
  }

  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    setReset([]);
  };

  return (
    <div className='doctors-table' style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/concord/new-doctor'>
          <Button type='primary'>Novi Lekar</Button>
        </Link>
        {reset.length ? (
          <Button style={{ marginLeft: '0.5rem' }} type='primary' onClick={() => resetFilters()}>
            Reset filtere
          </Button>
        ) : (
          ''
        )}
      </div>

      {doctors.isLoading && (
        <div style={{ justifyContent: 'center', display: 'flex', margin: '5rem' }}>
          <LoadingOutlined spin style={{ fontSize: '3rem' }} />
        </div>
      )}
      {!doctors.isLoading && doctors.data && doctors.data.length > 0 && (
        <div className='table-wrapper'>
          <DoctorsTable
            key={tableKey}
            setReset={setReset}
            data={tableData}
            deactivate={deactivateDoctor}
            activate={activateDoctor}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            title='User'
            editPath='/admin/concord/edit-doctor/'
            viewPath='/admin/view-user/'
          />
        </div>
      )}
      {!doctors.isLoading && doctors.data && doctors.data.length === 0 && (
        <h2 style={{ color: '#4dc1be', marginTop: '2rem', textAlign: 'center' }}>NEMA LEKARA</h2>
      )}
    </div>
  );
};

export default Doctors;
