import React, { useContext } from 'react';
import { notification } from 'antd';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';

import { SERVER_URL, SOCKET_URL } from '../../config';
import PatientForm from '../../components/forms/PatientForm';
import { UserContext } from '../../App';

const NewPatient = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  };

  const createNewPatient = async (patientData) => {
    try {
      let url;
      if (currentuser.data.userType === 'doctor') {
        url = `${SERVER_URL}/create/patient?doctor=${currentuser.data.doctor}&create=true`;
      } else {
        url = `${SERVER_URL}/create/patient?ordination=${currentuser.data.ordination}&create=true`;
      }

      const date = patientData.dateOfBirth.toDate().toLocaleDateString('en-GB');

      const year = date.split('/')[2];
      const month = date.split('/')[1];
      const day = date.split('/')[0];

      const { data } = await Axios.put(url, { ...patientData, dateOfBirth: `${year}-${month}-${day}` }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });

      if (currentuser.data.userType === 'ordination') {
        let socket = io(SOCKET_URL, { path: '/socket.io' });
        socket.emit('patient-created', data);
      }

      setTimeout(() => history.push('/admin/patients'), 500);
    } catch (err) {
      notification.info({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  return (
    <div className='patient-profile'>
      <div className='card-container mobile-top-space'>
        <div className='card-content'>
          <PatientForm handleCreate={createNewPatient} />
        </div>
      </div>
    </div>
  );
};

export default NewPatient;
