import React, { useContext } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img src='/images/logo-mojlekar.svg' alt='moj lekar logo' className='logo-image' />
        <h1 className='welcome'>Dobrodošli !</h1>
        <h3 className='login-with'>Ulogujte se sa email adresom</h3>
        <Card className='login-header' bordered={false}>
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed} className='error-message'>
            <Form.Item name='email' rules={[{ required: true, message: 'Molimo Vas unesite Vašu email adresu!' }]}>
              <Input
                placeholder='Email'
                prefix={<img className='input-icon' src='./images/letter.png' alt='letter icon' />}
              />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Molimo Vas unesite Vašu lozinku!' }]}>
              <Input.Password
                placeholder='Lozinka'
                prefix={<img className='input-icon' src='./images/padlock.png' alt='padlock icon' />}
              />
            </Form.Item>

            <Link className='user-link' style={{ color: 'whitesmoke' }} to='/forgot-password'>
              Zaboravili ste <span>lozinku</span>?
            </Link>
            <br />

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className='login-button' htmlType='submit'>
                Uloguj se
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
