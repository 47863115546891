import React, { useEffect, useState } from 'react';
import { Modal, Card, Form, Row, Col, Input, DatePicker, notification } from 'antd';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
import 'react-phone-number-input/style.css';

import { SERVER_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};
let patientData;

const layout = {
  labelCol: { span: 7 }
};

const EditOrdinationPatient = ({ ordModal, setOrdModal, patient, updatePatient }) => {
  const [form] = Form.useForm();
  const [child, setChild] = useState(false);
  const [phone, setPhone] = useState();
  const [removeBtn, setRemoveBtn] = useState(false);
  const [patientName, setPatientName] = useState('');

  useEffect(() => {
    if (patient) {
      patientData = {
        ...patient,
        dateOfBirth: patient.dateOfBirth && moment(patient.dateOfBirth),
        email: patient.userReference.email,
        ordinations: patient.ordinations?.map((ord) => ord.name),
        doctor: patient.doctors?.map((doc) => doc._id),
        parentFirstName: patient.parent?.firstName,
        parentLastName: patient.parent?.lastName,
        parentEmail: patient.parent?.email,
        parentPhone: patient.parent?.phone,
      };
      setPhone(patient.phone);
      setPatientName(`${patient.firstName} ${patient.lastName}`);
      form.setFieldsValue({ ...patientData });
    }
  }, [patient, form]);

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  useEffect(() => {
    isChild(patient?.dateOfBirth);
  }, [patient]);

  const onFinish = async (values) => {
    values.ordination = patient.ordinations;
    values.doctors = patient.doctors;
    child ? (values.parentPhone = phone) : (values.phone = phone);

    if (values.firstName === '' || values.lastName === '') {
      notification.info({
        message: "Molim, unesite ime i prezime pacijenta",
        duration: 2.5,
        placement: 'bottomRight'
      });
      return;
    }

    if (moment(values.dateOfBirth).toDate() > new Date()) {
      notification.info({
        message: "Datum rodjenja mora biti pre danasnjeg dana",
        duration: 2.5,
        placement: 'bottomRight'
      });
      return;
    }

    updatePatient(patientData._id, values);
    setOrdModal(false);
  };

  const visibleField = (field) => patient?.allowedFields.includes(`${field}`);

  const checkEmail = async (e) => {
    try {
      if (e !== '') {
        const { data } = await axios.get(`${SERVER_URL}/patient/check/email/${e}?id=${patientData.userReference._id}`, headers);
        setRemoveBtn(data.length);
        if (data.length) {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      visible={ordModal}
      onCancel={() => {
        form.resetFields();
        setOrdModal(false);
        setPhone(patient.phone);
      }}
      footer={null}
      width={600}
      centered
    >
      <Card
        title={`Izmenite ${patientName}`}
        bordered={false}
        className='calendar-data-form-card date-change'
      >
        <Form
          {...layout}
          initialValues={patientData}
          onFinish={(values) => onFinish(values)}
          form={form}
        >
          <Row className='card-form patient-edit-form'>
            <Col xs={{ span: 24, offset: 0 }}>
              {visibleField('firstName') && (
                <Form.Item label='IME' name='firstName'>
                  <Input />
                </Form.Item>
              )}
              {visibleField('lastName') && (
                <Form.Item label='PREZIME' name='lastName'>
                  <Input />
                </Form.Item>
              )}

              {child ? (
                <>
                  {visibleField('parentFirstName') && (
                    <Form.Item label='IME RODITELJA' name='parentFirstName'>
                      <Input />
                    </Form.Item>
                  )}
                  {visibleField('parentLastName') && (
                    <Form.Item label='PREZIME RODITELJA' name='parentLastName'>
                      <Input />
                    </Form.Item>
                  )}
                  {visibleField('parentEmail') && (
                    <Form.Item label='EMAIL RODITELJA' name='parentEmail'>
                      <Input onChange={(e) => checkEmail(e.currentTarget.value)} />
                    </Form.Item>
                  )}
                  {visibleField('parentPhone') && (
                    <Form.Item label='TELEFON RODITELJA' name='parentPhone'>
                      <PhoneInput
                        international
                        defaultCountry='RS'
                        countryCallingCodeEditable={false}
                        onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                      />
                    </Form.Item>
                  )}
                </>
              ) : (
                <>
                  {visibleField('email') && (
                    <Form.Item label='EMAIL' name='email'>
                      <Input onChange={(e) => checkEmail(e.currentTarget.value)} />
                    </Form.Item>
                  )}
                  {visibleField('phone') && (
                    <Form.Item label='TELEFON' name='phone'>
                      <PhoneInput
                        international
                        defaultCountry='RS'
                        countryCallingCodeEditable={false}
                        onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                      />
                    </Form.Item>
                  )}
                </>
              )}
              {visibleField('dateOfBirth') && (
                <Form.Item label='DATUM ROĐENJA' name='dateOfBirth'>
                  <DatePicker
                    showToday={false}
                    placeholder=''
                    style={{ width: '100%', border: 'none' }}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => isChild(e)}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <button className={`action-button ${removeBtn && 'disabled'}`} htmltype='submit' disabled={removeBtn}>
            <img src='/images/save.svg' alt='accept' />
            <span>Potvrdi</span>
          </button>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditOrdinationPatient;
