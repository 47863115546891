import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Card, Row, Col, notification, Select, DatePicker, Tooltip } from 'antd';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import { EditOutlined, WarningOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
import 'react-phone-number-input/style.css';

import { SERVER_URL } from '../../config';

const { Option } = Select;
const { TextArea } = Input;

let patientData,
  upperBP = '',
  lowerBP = '';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const layouts = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const PatientEditForm = ({ data, createPatientHandler, updatePatientHandler, result, readonly }) => {
  const [form] = Form.useForm();
  const [child, setChild] = useState(false);
  const [ordinations, setOrdinations] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [toEdit, setToEdit] = useState(false);
  const [phone, setPhone] = useState();
  const [removeBtn, setRemoveBtn] = useState(false);

  const getDoctors = useCallback(async () => {
    try {
      const doctorCall = await axios.get(`${SERVER_URL}/doctors`, headers);
      setDoctors(doctorCall.data.items);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, []);

  const getOrdinations = useCallback(async () => {
    try {
      const ordinationCall = await axios.get(
        `${SERVER_URL}/ordination/${currentuser?.ordination || patientData.ordinations[0]._id}`,
        headers,
      );
      setOrdinations(ordinationCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, []);

  if (data) {
    patientData = {
      ...data,
      dateOfBirth: data.dateOfBirth && moment(data.dateOfBirth),
      email: data.userReference?.email,
      ordinations: data.ordinations?.map((ord) => ord.name),
      doctor: data.doctors?.map((doc) => doc._id),
      parentFirstName: data.parent?.firstName,
      parentLastName: data.parent?.lastName,
      parentEmail: data.parent?.email,
      parentPhone: data.parent?.phone,
    };
  }

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  useEffect(() => {
    isChild(patientData.dateOfBirth);
  }, []);

  const onFinish = (values) => {
    values.ordination = [ordinations._id];
    values.doctors = values.doctor;
    delete values.doctor;
    if (upperBP && lowerBP)
      values.bloodPressure = `${upperBP}/${lowerBP}`;

    if (currentuser.userType === 'ordination')
      values.doctors = data.doctors;

    child ? (values.parentPhone = phone) : (values.phone = phone);

    if (!patientData) {
      createPatientHandler(values);
    } else {
      updatePatientHandler(values, data._id);
    }
  };

  useEffect(() => {
    if (currentuser?.userType !== 'admin') getOrdinations();
    getDoctors();
  }, [getOrdinations, getDoctors]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed in PatientEditForm:', errorInfo);
  };

  const checkEmail = async (e) => {
    try {
      if (e !== '') {
        const { data } = await axios.get(`${SERVER_URL}/patient/check/email/${e}?id=${patientData.userReference._id}`, headers);
        setRemoveBtn(data.length);
        if (data.length > 0) {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5,
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const text = (
    <Tooltip title='Pacijent sa unetim mejlom postoji' color='black'>
      <WarningOutlined className='warning-sign' />
    </Tooltip>
  );

  const editPatient = () => {
    if (currentuser.userType === 'ordination') setToEdit((prev) => !prev);
  };

  const visibleField = (field) => currentuser?.userType !== 'admin' && patientData.allowedFields.includes(`${field}`);

  return (
    <div className='pacijent-view'>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card
            bordered={false}
            title=<div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Pacijent {patientData.firstName} {patientData.lastName}
              </span>
              {currentuser.userType === 'ordination' ? (
                <EditOutlined onClick={editPatient} style={{ cursor: 'pointer' }} />
              ) : null}
            </div>
          >
            {!result && (
              <div className={currentuser.userType !== 'ordination' && 'patient-disabled-inputs'}>
                <Form
                  {...layouts}
                  initialValues={patientData}
                  onFinish={(values) => onFinish(values)}
                  onFinishFailed={onFinishFailed}
                  form={form}
                >
                  {!readonly ? (
                    <Row className='card-form patient-edit-form'>
                      <Col xs={{ span: 24, offset: 0 }}>
                        {visibleField('firstName') && (
                          <Form.Item label='IME' name='firstName'>
                            <Input readOnly={!toEdit} />
                          </Form.Item>
                        )}
                        {visibleField('lastName') && (
                          <Form.Item label='PREZIME' name='lastName'>
                            <Input readOnly={!toEdit} />
                          </Form.Item>
                        )}

                        {child ? (
                          <>
                            {visibleField('parentFirstName') && (
                              <Form.Item label='IME RODITELJA' name='parentFirstName'>
                                <Input readOnly={!toEdit} />
                              </Form.Item>
                            )}
                            {visibleField('parentLastName') && (
                              <Form.Item label='PREZIME RODITELJA' name='parentLastName'>
                                <Input readOnly={!toEdit} />
                              </Form.Item>
                            )}
                            {visibleField('parentEmail') && (
                              <Form.Item label='EMAIL RODITELJA' name='parentEmail'>
                                <Input onChange={(e) => checkEmail(e.currentTarget.value)} readOnly={!toEdit} />
                              </Form.Item>
                            )}
                            {visibleField('parentPhone') && (
                              <Form.Item label='TELEFON RODITELJA' name='parentPhone'>
                                <PhoneInput
                                  style={{ borderBottom: '1px solid #e5e5e5' }}
                                  international
                                  defaultCountry='RS'
                                  countryCallingCodeEditable={false}
                                  onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                                  readOnly={!toEdit}
                                />
                              </Form.Item>
                            )}
                          </>
                        ) : (
                          <>
                            {visibleField('email') && (
                              <Form.Item label='EMAIL' name='email'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <Input onChange={(e) => checkEmail(e.currentTarget.value)} readOnly={!toEdit} defaultValue={patientData.email} />
                                  {removeBtn ? text : ''}
                                </div>
                              </Form.Item>
                            )}
                            {visibleField('phone') && (
                              <Form.Item label='TELEFON' name='phone'>
                                <PhoneInput
                                  style={{ borderBottom: '1px solid #e5e5e5' }}
                                  international
                                  defaultCountry='RS'
                                  countryCallingCodeEditable={false}
                                  onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                                  readOnly={!toEdit}
                                />
                              </Form.Item>
                            )}
                          </>
                        )}
                        {visibleField('dateOfBirth') && (
                          <Form.Item label='DATUM ROĐENJA' name='dateOfBirth'>
                            <DatePicker
                              showToday={false}
                              placeholder=''
                              style={{ width: '100%', border: 'none', borderBottom: '1px solid #e5e5e5' }}
                              format={'DD/MM/YYYY'}
                              onChange={(e) => isChild(e)}
                              readOnly={!toEdit}
                            />
                          </Form.Item>
                        )}
                        {visibleField('ordinations') && (
                          <Form.Item label='ORDINACIJA' name='ordinations'>
                            <Input />
                          </Form.Item>
                        )}
                        {visibleField('doctor') && (
                          <Form.Item label='DOKTOR' name='doctor'>
                            <Select mode='multiple' showArrow={true}>
                              {doctors?.map((doc) => (
                                <Option value={doc._id} key={doc._id}>
                                  {doc.firstName} {doc.lastName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                        {/* {visibleField('address') && (
                        <Form.Item label='ADRESA' name='address'>
                          <Input />
                        </Form.Item>
                      )} */}
                        {visibleField('city') && (
                          <Form.Item label='GRAD' name='city'>
                            <Input />
                          </Form.Item>
                        )}
                        {visibleField('gender') && (
                          <Form.Item label='POL' name='gender'>
                            <Select>
                              <Option value='muški'>muški</Option>
                              <Option value='ženski'>ženski</Option>
                            </Select>
                          </Form.Item>
                        )}
                        {visibleField('bodyWeight') && (
                          <Form.Item label='TELESNA TEŽINA' name='bodyWeight'>
                            <Input type='number' min={0} />
                          </Form.Item>
                        )}
                        {visibleField('height') && (
                          <Form.Item label='VISINA' name='height'>
                            <Input type='number' min={0} />
                          </Form.Item>
                        )}
                        {visibleField('bloodType') && (
                          <Form.Item label='KRVNA GRUPA' name='bloodType'>
                            <Select>
                              <Option value='0+'>0+</Option>
                              <Option value='0-'>0-</Option>
                              <Option value='A+'>A+</Option>
                              <Option value='A-'>A-</Option>
                              <Option value='B+'>B+</Option>
                              <Option value='B-'>B-</Option>
                              <Option value='AB+'>AB-</Option>
                              <Option value='AB-'>AB+</Option>
                            </Select>
                          </Form.Item>
                        )}
                        {/* {visibleField('bloodPressure') && (
                        <Form.Item label='KRVNI PRITISAK' name='bloodPressure'>
                          <Input
                            type='number'
                            min={0}
                            style={{ width: '4.5rem' }}
                            onChange={(e) => (upperBP = e.currentTarget.value)}
                            value={upperBP}
                          />
                          /
                          <Input
                            type='number'
                            min={0}
                            style={{ width: '4.5rem' }}
                            onChange={(e) => (lowerBP = e.currentTarget.value)}
                            value={lowerBP}
                          />
                        </Form.Item>
                      )}
                      {visibleField('bloodSugar') && (
                        <Form.Item label='ŠEĆER U KRVI' name='bloodSugar'>
                          <Input />
                        </Form.Item>
                      )} */}
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 1 }}>
                        {visibleField('allergiesNote') && (
                          <Form.Item label='ALERGIJE' name='allergiesNote'>
                            <TextArea rows={4} />
                          </Form.Item>
                        )}
                        {visibleField('inheritedDiseaseNote') && (
                          <Form.Item label='NASLEDNE BOLESTI' name='inheritedDiseaseNote'>
                            <TextArea rows={4} />
                          </Form.Item>
                        )}
                        {/* {visibleField('immunizationsNote') && (
                        <Form.Item label='IMUNIZACIJE' name='immunizationsNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                      )} */}
                      </Col>
                      {/* <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                      {visibleField('therapiesNote') && (
                        <Form.Item label='TERAPIJE U TOKU' name='therapiesNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                      )}
                    </Col> */}
                      {toEdit &&
                        <button className={`action-button ${removeBtn && 'disabled'}`} htmltype='submit' disabled={removeBtn}>
                          <img src='/images/save.svg' alt='accept' />
                          <span>Potvrdi</span>
                        </button>
                      }
                    </Row>
                  ) : (
                    <Row className='card-form patient-edit-form'>
                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                        <Form.Item label='IME'>
                          <Input value={patientData.firstName} />
                        </Form.Item>
                        <Form.Item label='PREZIME'>
                          <Input value={patientData.lastName} />
                        </Form.Item>
                        <Form.Item label='DATUM ROĐENJA'>
                          <DatePicker
                            bordered={false}
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            value={patientData.dateOfBirth}
                            onChange={(e) => isChild(e)}
                            placeholder=''
                          />
                        </Form.Item>
                        {child ? (
                          <>
                            <Form.Item label='IME RODITELJA' name='parentFirstName'>
                              <Input readOnly defaultValue={patientData.parent?.firstName} />
                            </Form.Item>
                            <Form.Item label='PREZIME RODITELJA' name='parentLastName'>
                              <Input readOnly defaultValue={patientData.parent?.lastName} />
                            </Form.Item>
                            <Form.Item style={{ display: 'block' }} label='EMAIL RODITELJA' name='parentEmail'>
                              <Input readOnly defaultValue={patientData.parent?.email} />
                            </Form.Item>
                            <Form.Item label='TELEFON RODITELJA' name='parentPhone'>
                              <PhoneInput
                                readOnly
                                style={{ border: 'none' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                                value={patientData.parent?.phone}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item style={{ display: 'block' }} label='EMAIL'>
                              <Input value={patientData.email} />
                            </Form.Item>
                            <Form.Item label='TELEFON'>
                              <PhoneInput
                                style={{ border: 'none' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                                value={patientData.phone}
                                onChange={() => console.log('no')}
                              />
                            </Form.Item>
                          </>
                        )}
                        {/* <Form.Item label='ADRESA'>
                        <Input value={patientData.address} />
                      </Form.Item> */}
                        <Form.Item label='GRAD'>
                          <Input defaultValue={patientData.city} />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                        <Form.Item label='ORDINACIJA'>
                          <Select
                            mode='multiple'
                            removeIcon={false}
                            bordered={false}
                            value={data.ordinations?.map((ord) => ord.name)}
                          />
                        </Form.Item>

                        <Form.Item label='DOKTOR'>
                          <Select
                            mode='multiple'
                            removeIcon={false}
                            bordered={false}
                            value={data.doctors?.map((doc) => `${doc.firstName} ${doc.lastName}`)}
                          />
                        </Form.Item>
                        <Form.Item label='POL'>
                          <Input value={patientData.gender} />
                        </Form.Item>
                        <Form.Item label='TELESNA TEŽINA'>
                          <Input value={patientData.bodyWeight} />
                        </Form.Item>
                        <Form.Item label='VISINA'>
                          <Input value={patientData.height} />
                        </Form.Item>
                        <Form.Item label='KRVNA GRUPA'>
                          <Input value={patientData.bloodType} />
                        </Form.Item>
                        {/* <Form.Item label='KRVNI PRITISAK'>
                        <Input style={{ width: '5rem' }} value={patientData?.bloodPressure?.split('/')[0]} /> /
                        <Input style={{ width: '5rem' }} value={patientData?.bloodPressure?.split('/')[1]} />
                      </Form.Item>
                      <Form.Item label='ŠEĆER U KRVI'>
                        <Input value={patientData.bloodSugar} />
                      </Form.Item> */}
                      </Col>

                      <Col
                        xs={{ span: 24, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 6, offset: 1 }}
                        className='dz'
                      >
                        <Form.Item style={{ display: 'block' }} label='ALERGIJE'>
                          <TextArea rows={4} value={patientData.allergiesNote} />
                        </Form.Item>
                        <Form.Item style={{ display: 'block' }} label='NASLEDNE BOLESTI'>
                          <TextArea rows={4} value={patientData.inheritedDiseaseNote} />
                        </Form.Item>
                        {/* <Form.Item style={{ display: 'block' }} label='IMUNIZACIJE'>
                        <TextArea rows={4} value={patientData.immunizationsNote} />
                      </Form.Item> */}

                        {/* <Form.Item style={{ display: 'block' }} label='TERAPIJE U TOKU'>
                        <TextArea rows={4} value={patientData.therapiesNote} />
                      </Form.Item> */}
                      </Col>
                    </Row>
                  )}
                </Form>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PatientEditForm;
