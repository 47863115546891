import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DoctorForm from '../../components/forms/DoctorForm';
import Axios from 'axios';
import { ROUTES, SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const NewDoctor = () => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [categories, setCategories] = useState(undefined);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className='card-container mobile-top-space'>
      <div className='card-content'>
        {categories && (
          <DoctorForm
            data={null}
            result={result}
            setResult={setResult}
            SERVER_URL={SERVER_URL}
            categories={categories}
            language={'sr'}
            token={currentuser}
          />
        )}
        <div key={ROUTES[1].label.toLowerCase()} title={ROUTES[1].title}>
          {ROUTES[1].children
            .filter((item) => item.showInMenu)
            .map((item) => (
              <div key={item.path}>
                <Link to={item.path}>{item.label}</Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NewDoctor;
