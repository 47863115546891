import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const userType = currentuser?.userType;
const userId = currentuser?.doctor;

const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Home = () => {
  const user = useContext(UserContext);
  const [currentDoctor, setCurrentDoctor] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const { data } = await Axios.post(
        `${SERVER_URL}/doctors-by-ordination`,
        { ordination: user.data.ordination },
        headers,
      );
      return data;
    } catch (err) {
      console.log(err.message);
    }
  }, [user]);

  const fetchCategories = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/categories`);
      return data;
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  const getCurrentDoctor = useCallback(() => {
    if (userType === 'doctor') {
      Axios.get(`${SERVER_URL}/doctors/${userId}`, headers)
        .then((res) => {
          const data = res.data;
          if (data) {
            setCurrentDoctor(`${data.firstName}-${data.lastName}-${data._id}`.toLowerCase());
          }
        })
        .catch((err) => console.log(err.message));
    } else if (userType === 'ordination') {
      if (sessionStorage.getItem('currentDoctorFullName') && sessionStorage.getItem('currentDoctorId')) {
        setCurrentDoctor(`${sessionStorage.getItem('currentDoctorFullName')}-${sessionStorage.getItem('currentDoctorId')}`);
      } else {
        setCurrentDoctor('null-null');
      }
    }
  }, []);

  useEffect(() => {
    if (userType !== 'admin')
      fetchData().then((r) => {
        fetchCategories().then((r1) => {
          getCurrentDoctor(r, r1);
        });
      });
  }, [fetchData, fetchCategories, getCurrentDoctor]);

  return (
    <div className='home-page-container'>
      {userType !== 'admin' && (
        <>
          <div className='home-page-content-wrapper'>
            <div className='home-page-doctors'>
              <img src='./images/home-page-doctors.png' alt='doctors' />
            </div>
            <div className='home-page-buttons'>
              <Link to={`/admin/calendar/${currentDoctor}`} title='Moj organizator'>
                <button>
                  <img src='./images/home-calendar.svg' alt='calendar' />
                </button>
                <span>Moj organizator</span>
              </Link>

              <Link to='/admin/patients' title='Pacijenti'>
                <button>
                  <img src='./images/home-patients.svg' alt='patients' />
                </button>
                <span>Pacijenti</span>
              </Link>

              {userType === 'doctor' ? (
                <Link to={'/admin/doctor/profile'} title='Profil'>
                  <button>
                    <img src='./images/ordination-icon-blue.svg' alt='doctors' style={{ height: '40px' }} />
                  </button>
                  <span>Profil</span>
                </Link>
              ) : (
                <Link to={'/admin/doctors'} title='Doktori'>
                  <button>
                    <img src='./images/home-doctors.svg' alt='doctors' />
                  </button>
                  <span>Lekari</span>
                </Link>
              )}
            </div>

            <div className='home-page-flowers'>
              <img src='./images/flowers.png' alt='flowers' />
            </div>
          </div>
          <div className='home-page-base'>
            <img src='./images/green-base.png' alt='green base' />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
