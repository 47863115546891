import Doctors from '../pages/doctors/Doctors';
import Calendar from '../pages/doctors/Calendar';
import DoctorProfile from '../pages/doctors/DoctorProfile';
import NewDoctor from '../pages/doctors/NewDoctor';
import OrdinationWorkDays from '../pages/ordination/WorkDays';
import WorkDays from '../pages/doctors/WorkDays';
import WorkHours from '../pages/doctors/WorkHours';
import OrdinationProfile from '../pages/ordination/Ordination';
import Patients from '../pages/patients/Patients';
import NewPatient from '../pages/patients/NewPatient';
import Patient from '../pages/patients/Patient';
import PatientHistory from '../pages/patients/PatientHistory';
import Reports from '../pages/patients/Reports';
import Prescription from '../pages/patients/Prescription';

import AdminOrdinations from '../pages/ordination/Ordinations';
import AdminNewOrdination from '../pages/ordination/NewOrdination';
import AdminEditOrdination from '../pages/ordination/EditOrdination';

import AdminPatients from '../pages/patients/concordAdmin/Patients';
import AdminNewPatient from '../pages/patients/concordAdmin/NewPatient';
import AdminEditPatient from '../pages/patients/concordAdmin/EditPatient';

import AdminDoctors from '../pages/doctors/concordAdmin/Doctors';
import AdminNewDoctor from '../pages/doctors/concordAdmin/NewDoctor';
import AdminEditDoctor from '../pages/doctors/concordAdmin/EditDoctor';

import AdminCategories from '../pages/categories/Categories';
import AdminServices from '../pages/services/Services';
import AdminCities from '../pages/cities/Cities';
import AdminNotifications from '../pages/notifications/AdminNotifications';
import AdminBanners from '../pages/banners/AdminBanners';

import QRCode from '../pages/qr/QRCode';

import Notifications from '../pages/notifications/Notifications';

const routes = [
  {
    label: 'Calendar',
    children: [
      {
        path: '/admin/calendar/:id',
        component: Calendar,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'QRCode',
    children: [
      {
        path: '/admin/qr-code',
        component: QRCode,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Doctors',
    children: [
      {
        path: '/admin/doctors',
        component: Doctors,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        // label: 'All Doctors',
        path: '/admin/new-doctor',
        component: NewDoctor,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        // label: 'All Doctors',
        path: '/admin/doctor/profile',
        component: DoctorProfile,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/doctor/workdays',
        component: WorkDays,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/doctor/hours/:id',
        component: WorkHours,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Ordination',
    children: [
      {
        path: '/admin/ordination/profile',
        component: OrdinationProfile,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/ordination/workdays',
        component: OrdinationWorkDays,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Patients',
    children: [
      {
        path: '/admin/patients',
        component: Patients,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/patients/:id',
        component: Patient,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/patients/:id/istorija',
        component: PatientHistory,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        path: '/admin/patients/:id/izvestaji',
        component: Reports,
        allowed: ['doctor'],
        showInMenu: true,
      },
      {
        path: '/admin/patients/:id/recept',
        component: Prescription,
        allowed: ['doctor'],
        showInMenu: true,
      },
      {
        path: '/admin/new-patient',
        component: NewPatient,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminOrdinations',
    children: [
      {
        label: 'Ordinations',
        path: '/admin/concord/ordinations',
        component: AdminOrdinations,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'New Ordination',
        path: '/admin/concord/new-ordination',
        component: AdminNewOrdination,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Ordination',
        path: '/admin/concord/edit-ordination/:ordinationId',
        component: AdminEditOrdination,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminPatients',
    children: [
      {
        label: 'All Patients',
        path: '/admin/concord/patients',
        component: AdminPatients,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'New Patients',
        path: '/admin/concord/new-patient',
        component: AdminNewPatient,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Patient',
        path: '/admin/concord/edit-patient/:patientId',
        component: AdminEditPatient,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminDoctors',
    children: [
      {
        label: 'All Doctors',
        path: '/admin/concord/doctors',
        component: AdminDoctors,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'New Doctor',
        path: '/admin/concord/new-doctor',
        component: AdminNewDoctor,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Doctor',
        path: '/admin/concord/edit-doctor/:doctorId',
        component: AdminEditDoctor,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminCategories',
    children: [
      {
        label: 'All Categories',
        path: '/admin/concord/categories',
        component: AdminCategories,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminServices',
    children: [
      {
        label: 'All Services',
        path: '/admin/concord/services',
        component: AdminServices,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminCities',
    children: [
      {
        label: 'All Cities',
        path: '/admin/concord/cities',
        component: AdminCities,
        allowed: ['concordAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminNotifications',
    children: [
      {
        label: 'All Notifications',
        path: '/admin/concord/notifications',
        component: AdminNotifications,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'ConcordAdminBanners',
    children: [
      {
        label: 'All Banners',
        path: '/admin/concord/banners',
        component: AdminBanners,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'DoctorNotifications',
    children: [
      {
        label: 'All Notifications',
        path: '/admin/doctor/notifications',
        component: Notifications,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'OrdinationNotifications',
    children: [
      {
        label: 'All Notifications',
        path: '/admin/ordination/notifications',
        component: Notifications,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Notifications',
    children: [
      {
        label: 'All Notifications',
        path: '/admin/notifications',
        component: Notifications,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
];

export default routes;
