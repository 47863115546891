import React, { useEffect, useCallback, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { io } from 'socket.io-client';

import { DoctorsTable } from '../../components/tables';
import { SERVER_URL, SOCKET_URL } from '../../config';

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const Doctors = () => {
  const history = useHistory();
  const [doctors, setDoctors] = useState([]);
  const [search, setSearch] = useState('');
  const [tableKey, setTableKey] = useState(0);
  const [reset, setReset] = useState([]);

  if (currentuser?.userType === 'doctor') {
    history.push('/admin');
  }

  const fetchDoctors = useCallback(async (id) => {
    try {
      const doctorsResponse = await Axios.post(`${SERVER_URL}/doctors-by-ordination`, { ordination: id }, headers);
      return doctorsResponse.data;
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    fetchDoctors(currentuser?.ordination).then((r) => {
      if (r) {
        setDoctors(r);
      } else setDoctors(null);
    });
  }, [fetchDoctors]);

  useEffect(() => {
    let timer;
    if (search.trim() !== '') {
      timer = setTimeout(() => {
        Axios.get(`${SERVER_URL}/doctors/search/${search}?id=${currentuser?.ordination}`, headers)
          .then((res) => setDoctors(res.data.doctors))
          .catch((err) => console.log(err));
      }, 500);
    } else {
      fetchDoctors(currentuser?.ordination).then((r) => setDoctors(r));
    }

    return () => clearTimeout(timer);
  }, [search, fetchDoctors]);

  const showDoctorModal = () => {
    history.push('/admin/new-doctor');
  };

  let categoryData = doctors?.map((doc) => doc?.category?.name?.sr);
  categoryData = [...new Set(categoryData)];
  categoryData.sort();

  const removeDoctor = async (id) => {
    try {
      const doctor = await Axios.delete(
        `${SERVER_URL}/remove/doctor/${id}?ordination=${currentuser?.ordination}`,
        headers,
      );
      if (doctor.status === 200) {
        let socket = io(SOCKET_URL, { path: '/socket.io' });
        socket.emit('remove-doctor-created', { doctor: id, ordination: currentuser.ordination[0] });

        await Axios.post(`${SERVER_URL}/send-push-token/null`, {
          doctor: id,
          ordination: currentuser.ordination[0],
          message: "REMOVE_DOC"
        }, headers);

        notification.success({
          message: doctor.data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      setDoctors((prevState) => prevState.filter((doctor) => doctor._id !== id));
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa menjanjem podataka iz profila. Probajte kasnije.',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const columnKeysTranslated = ['Ime', 'Prezime', 'Telefon', 'Grad', 'Email', 'Specijalizacija'];
  const columnKeys = ['firstName', 'lastName', 'phone', 'cities', 'email', 'categories'];

  let tableData = [];
  if (doctors && doctors.length > 0) {
    tableData = doctors.map((item) => {
      item.cities = item.city?.map((city, i) => `${city}${i < item.city.length - 1 ? ',' : ''} `);
      item.categories = item.category?.map((cat, i) => `${cat.name.sr}${i < item.category.length - 1 ? ',' : ''} `);
      item.email = item.userReference?.email;
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    setReset([]);
  };

  return (
    <div className='doctors-table'>
      <div className='above-table-content'>
        <button className='action-button' onClick={showDoctorModal}>
          <span>Dodaj lekara</span>
        </button>
        {reset.length ? (
          <button className='action-button' onClick={() => resetFilters()}>
            <span>Reset filtere</span>
          </button>
        ) : (
          ''
        )}
        <div className='search-input' style={{ marginLeft: 'auto' }}>
          <div className='form-content'>
            <input
              type='text'
              id='searchInput'
              className='table-search'
              placeholder='Pretraži'
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className='search-button'>
              <img src='/images/loupe.svg' alt='loupe' />
            </button>
          </div>
        </div>
      </div>

      <div className='doctors-container'>
        {doctors && (
          <div className='table-wrapper'>
            <DoctorsTable
              setReset={setReset}
              tableKey={tableKey}
              data={tableData}
              deactivate={removeDoctor}
              columnKeys={columnKeys}
              columnKeysTranslated={columnKeysTranslated}
              title='Patients'
              editPath='/admin/doctors/'
              calendarPath='/admin/calendar/'
              viewPath='/admin/view-user/'
              readonly={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Doctors;
