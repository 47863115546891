import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Select, Row, Col, notification } from 'antd';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { io } from "socket.io-client";

import UploadBox from '../../../components/base/UploadBox';
import { GalleryModal } from '../../../components/forms';
import { SERVER_URL, SOCKET_URL } from '../../../config';
import { RemoveDoctorDeleteAppointments } from '../../../components/modals';

const { Option } = Select;

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};

export default function EditDoctor(props) {
  const [form] = Form.useForm();
  const { doctorId } = props.match.params;
  const [doctorData, setDoctorData] = useState({});
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [ordinations, setOrdinations] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [removeWorkDay, setRemoveWorkDay] = useState({ open: false, data: [] });

  const getDoctor = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
      setDoctorData(data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, [doctorId]);

  const getCities = async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const getCategories = async () => {
    try {
      const categoryCall = await axios.get(`${SERVER_URL}/categories`);
      setCategories(categoryCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const getOrdinations = useCallback(async () => {
    try {
      const ordinationCall = await axios.get(`${SERVER_URL}/ordination`, headers);
      setOrdinations(ordinationCall.data.items);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  }, []);

  useEffect(() => {
    getDoctor();
    getOrdinations();
    getCities();
    getCategories();
  }, [getDoctor, getOrdinations]);

  useEffect(() => {
    if (doctorData.avatar) {
      setImage(doctorData.avatar);
    } else {
      setImage(null);
    }
    form.resetFields();
  }, [form, doctorData]);

  let data;
  if (doctorData.ordination) {
    data = {
      ...doctorData,
      phone: doctorData.phone[0],
      email: doctorData.userReference?.email,
      category: doctorData.category.map((cat) => cat._id),
      ordination: doctorData.ordination.map((ord) => ord._id),
      services: doctorData.services.map((service) => service._id),
    };
  }

  const onFinish = async (values) => {
    try {
      values.avatar = image;
      const { data } = await axios.put(`${SERVER_URL}/doctors/${doctorId}?admin=true`, values, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });

      if (data.removed.length || data.added.length) {
        await axios.post(`${SERVER_URL}/send-push-token/null`, {
          doctor: doctorId,
          removedOrdination: data.removed,
          addedOrdination: data.added,
          message: "ADD_DOC"
        }, headers);
      }

      let socket = io(SOCKET_URL, { path: '/socket.io' });
      socket.emit('admin-remove-doctor-created', {
        doctor: doctorId,
        removed: data.removed,
        added: data.added
      });

      getDoctor();
    } catch (error) {
      notification.info({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  // before removing ordination check whether it has appointments
  const beforeRemove = async (ordId) => {
    try {
      const appointments = await axios.get(`${SERVER_URL}/appointments/day?doc=${data._id}&ord=${ordId}&remove=true`, headers);
      if (appointments.data.length) {
        setRemoveWorkDay({ open: true, data: appointments.data, ordId });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // if admin cancels the prompt, revert the changes
  const revertChanges = (ordId) => {
    const ordIds = form.getFieldValue('ordination');
    ordIds.push(ordId);
    form.setFieldsValue({ ordination: ordIds });
  };

  // if admin accepts the prompt, delete appointments + notifications
  const deleteAppointments = async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      sendDeleteNotification(removeWorkDay);
      setRemoveWorkDay({ open: false, data: [], doc: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map(termin => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: "CANCEL_TERM",
      requestedBy: 'admin'
    }));
    await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const requestedBy = termin.requestedBy;
      const message = 'CANCEL_TERM';
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      socket.emit('new-notification-ordination-created', { ordinationId: ordination, doctorId: doctor, patientId: patient, startDate, message, calendarId, requestedBy });

      socket.emit('new-notification-doctor-created', { ordinationId: ordination, doctorId: doctor, patientId: patient, startDate, message, calendarId, requestedBy });
    };
  };

  return (
    <div className='edit-lekar-form'>
      <div className='profile-container'>
        <div className='ordination-form-container'>
          <div className='ordination-form-wrapper wide'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                <Card title={`Profil ${`${doctorData?.firstName} ${doctorData?.lastName}`}`} bordered={false}>
                  <Form
                    initialValues={data}
                    onFinish={(values) => onFinish(values)}
                    onFinishFailed={onFinishFailed}
                    layout='horizontal'
                    form={form}
                    style={{ borderRadius: '13px' }}
                  >
                    <Row className='card-form'>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                        <Form.Item
                          label='IME'
                          name='firstName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo Vas, unesite ime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='PREZIME'
                          name='lastName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite prezime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='EMAIL'
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite ispravan email!',
                            },
                          ]}
                        >
                          <Input type='email' />
                        </Form.Item>

                        <Form.Item
                          label='TELEFON'
                          name='phone'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite broj telefona!',
                            },
                          ]}
                        >
                          <PhoneInput
                            style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                            international
                            countryCallingCodeEditable={false}
                            title='zastava'
                          />
                        </Form.Item>

                        <Form.Item label='ORDINACIJA' name='ordination' className='panel-body'>
                          <Select
                            onDeselect={beforeRemove}
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {ordinations?.map((ord) => (
                              <Option key={ord._id} value={ord._id}>
                                {ord.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='GRAD'
                          name='city'
                          rules={[
                            {
                              required: true,
                              message: 'Obavezno upišite grad!',
                            },
                          ]}
                        >
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city._id} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='DRŽAVA' name='country'>
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='OBLAST RADA'
                          name='category'
                          className='panel-body'
                          rules={[{ required: true, message: 'Molimo, unesite specijalizaciju!' }]}
                        >
                          <Select
                            style={{ marginLeft: '0px' }}
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {categories?.map((cat) => (
                              <Option key={cat._id} value={cat._id}>
                                {cat.name.sr}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='NAČIN RADA'
                          name='typeOfWork'
                          className='panel-body'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesti način rada!',
                            },
                          ]}
                        >
                          <Select mode='multiple' showArrow={true}>
                            <Option value='Rad iz klinike'>Rad iz klinike</Option>
                            {/* <Option value='Izlazak na teren'>Izlazak na teren</Option> */}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label='BROJ LICENCE'
                          name='license'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesti bar jednu ordinaciju!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                        <Form.Item className='upload-wrapper right' name='avatar'>
                          <UploadBox
                            editHandler={avatarHandler}
                            deleteHandler={deleteAvatarHandler}
                            image={image}
                            index={0}
                            name='avatar'
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={{ span: 1, offset: 23 }}>
                        <Form.Item>
                          <button
                            className='action-button border-dark'
                            htmltype='submit'
                            style={{ float: 'right', marginRight: '-2px' }}
                          >
                            <img src='/images/save.svg' alt='save' />
                            <span>Potvrdi</span>
                          </button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>

                {modal.visible && (
                  <GalleryModal
                    width={700}
                    centered
                    visible={modal.visible}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageSavePath='public/images/users/'
                    imageType='data'
                    imageHeight={200}
                    SERVER_URL={SERVER_URL}
                    token={user.token}
                    form={form}
                    onCancel={() => setModal({ ...modal, visible: false })}
                    onInsert={(values) => onInsert(values, modal.formKey)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveDoctorDeleteAppointments
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        revertChanges={revertChanges}
        deleteAppointments={deleteAppointments}
        doctor={true}
      />
    </div>
  );
}
