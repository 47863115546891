import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { Modal } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  RedoOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import OrdinationForm from '../forms/OrdinationForm';
import $ from 'jquery';

const OrdinationTable = ({
  data,
  activate,
  deactivate,
  columnKeys,
  columnKeysTranslated,
  title,
  editPath,
  viewPath,
}) => {
  const user = useContext(UserContext);
  let searchInput;
  const userType = user.data.userType;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ordinationData, setOrdinationData] = useState(undefined);
  const [categories, setCategories] = useState([]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder='Pretraži'
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 195,
              marginBottom: 8,
              display: 'block',
              border: '1px solid #4dc1be',
              borderRadius: 20,
              paddingLeft: 10,
            }}
          />
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 15 }}
          >
            Pretraži
          </Button>
          <Button type='primary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <svg fill={filtered ? 'red' : 'white'} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" > <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" /></svg>,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    $(document).ready(function () {
      $('.ant-table-filter-trigger.active').parent().parent().attr('style', 'background-color: #4dc1be !important');
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const toggleModal = (as) => {
    setIsOpenModal(true);
    setOrdinationData(as);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  const fetchFormCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchFormCategories();
  }, []);

  columns?.push({
    title: 'Postupak',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined title='Izmeni ordinaciju' />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} onClick={() => toggleModal(record)}>
            <EyeOutlined title='Prikaži ordinaciju' style={{ textDecoration: 'none', color: 'black' }} />
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          {record.status === 'ACTIVE' ? (
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title='Da li ste sigurni da želite da deaktivirate ordinaciju?'
              onConfirm={() => deactivate(record._id)}
              okText='Da'
              cancelText='Ne'
            >
              <DeleteOutlined title='Deaktivirajte ordinaciju' />
            </Popconfirm>
          ) : (
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title='Da li ste sigurni da želite da aktivirate ordinaciju?'
              onConfirm={() => activate(record._id)}
              okText='Da'
              cancelText='Ne'
            >
              <RedoOutlined title='Aktivirajte ordinaciju' />
            </Popconfirm>
          )}
        </div>
      </div>
    ),
  });

  if (userType === 'admin') {
    columns.push({
      title: 'Status',
      render: (item) => (
        <>
          {item.status === 'ACTIVE' && <span style={{ color: 'green' }}>active</span>}
          {item.status === 'INACTIVE' && <span style={{ color: 'crimson' }}>inactive</span>}
          {item.status === 'DELETED' && <span style={{ color: 'black' }}>deleted</span>}
          {item.status === 'SUSPENDED' && <span style={{ color: 'yellow' }}>suspended</span>}
          {item.status === 'WAITING_FOR_ACTIVATION' && <span style={{ color: 'dodgerblue' }}>pending</span>}
        </>
      ),
    });
  }

  return (
    <>
      <div>
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 50,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: (
              <span>
                <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
                <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema ordinacija</p>
              </span>
            ),
          }}
        />
      </div>
      <Modal
        className='doctor-modal patient'
        visible={isOpenModal}
        onCancel={closeModal}
        footer={null}
        width={1100}
        centered
      >
        <OrdinationForm categories={categories} data={ordinationData} isView={true} readnonly={true} />
      </Modal>
    </>
  );
};

export default OrdinationTable;
