import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Footer from '../components/Footer';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import { Link } from 'react-router-dom';

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const ForgotPassword = () => {
  const forgotPassword = async (values) => {
    try {
      const sendPasswordLink = await Axios.post(`${SERVER_URL}/forgot-password`, { email: values.email });
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight',
          duration: 2.5
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img src='/images/logo-mojlekar.svg' alt='moj lekar logo' className='logo-image' />
        <h1 className='welcome'>Povratite svoju šifru</h1>
        <h3 className='login-with'>Unesite svoj email i dobićete instrukcije za povratak šifre</h3>
        <Card className='login-header' bordered={false}>
          <Form
            {...layout}
            name='basic'
            onFinish={forgotPassword}
            onFinishFailed={onFinishFailed}
            className='error-message'
          >
            <Form.Item name='email' rules={[{ required: true, message: 'Molimo Vas unesite Vašu email adresu!' }]}>
              <Input
                placeholder='Email'
                prefix={<img className='input-icon' src='./images/letter.png' alt='letter icon' />}
              />
            </Form.Item>

            <Link className='user-link' style={{ color: 'whitesmoke' }} to='/login'>
              Ulogujte se
            </Link>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className='login-button' htmlType='submit'>
                Pošalji link za restart šifre
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
