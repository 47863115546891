import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';

const PrintReport = React.forwardRef(({ singleReport, signature }, ref) => {
  const canvas = useRef({});

  useEffect(() => {
    canvas.current.fromDataURL(signature);
  }, [signature]);

  return (
    <div ref={ref}>
      <div className='desktop-top-data-modal'>
        <div className='report-ordination-info'>
          <p>Klinika: {singleReport.ordination?.name}</p>
          <p>
            {singleReport.ordination?.address ? `${singleReport.ordination?.address}, ` : ''}
            {singleReport.ordination?.city}
          </p>
          <p>{singleReport.ordination?.phone.map((item) => item)}</p>
          <p>{singleReport.ordination?.email}</p>
        </div>

        <div className='report-top-data-modal-wrapper'>
          <div className='report-top-data-modal'>
            <label>Datum: </label>
            <input defaultValue={singleReport.date} readOnly />
          </div>
          <div className='report-top-data-modal'>
            <label>Vreme: </label>
            <input defaultValue={moment(singleReport.createdAt).locale('sr').format('HH:mm')} readOnly />
          </div>
          <div className='report-top-data-modal'>
            <label>Lekar: </label>
            <input defaultValue={singleReport.doctorName} readOnly />
          </div>
          <div className='report-top-data-modal'>
            <label>Pacijent: </label>
            <input defaultValue={`${singleReport.patient.firstName} ${singleReport.patient.lastName}`} readOnly />
          </div>
          <div className='report-top-data-modal'>
            <label className='examination-type'>Vrsta pregleda: </label>
            <input defaultValue={singleReport.examinationType} readOnly />
          </div>
        </div>
      </div>

      <h1 className='document-title'>IZVEŠTAJ LEKARA SPECIJALISTE</h1>

      <div className='report-document-content'>
        <div className='report-note modal'>
          <span>Dg</span>
          <p>{singleReport && singleReport?.dgNote}</p>
        </div>

        <div className='report-note modal'>
          <span>Anamneza</span>
          <p>{singleReport && singleReport?.anamnesisNote}</p>
        </div>

        <div className='report-note modal'>
          <span>Terapija</span>
          <p>{singleReport && singleReport?.therapyNote}</p>
        </div>

        <div className='report-box-text info'>
          <p>
            {singleReport.ordination?.city}, {moment(new Date()).locale('sr').format('DD. MMMM YYYY.')}
          </p>
          <div className='doctor-signature'>
            <span>Potpis i faksimil lekara:</span>
            <SignatureCanvas
              onBegin={() => canvas.current.off()}
              ref={canvas}
              canvasProps={{ width: 556, height: 130 }}
            />
          </div>
        </div>

        <p className='report-box-text'>
          Pacijentu ili članu njegove porodice lekar specijalista dao je obaveštenje o nazivu medicinske mere, vrsti i
          načinu lečenja, dijagnostičkoj proceduri i mogućim rizicima od istog <strong>dobio - nije dobio</strong>{' '}
          pristanak za predloženu medicinsku meru (podvući ponuđeni odgovor). Pacijent upoznat sa lečenjem u skladu sa
          čl. 11. i 15. Zakona o pravim Pacijenata.
        </p>
      </div>
    </div>
  );
});

export default PrintReport;
