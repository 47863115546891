import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Row, Col, Select, notification, DatePicker } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;
const { Option } = Select;

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const NewPatient = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [child, setChild] = useState(false);
  const [ordinations, setOrdinations] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [patientExists, setPatientExists] = useState(false);
  // const [upperBP, setUpperBP] = useState('');
  // const [lowerBP, setLowerBP] = useState('');
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const isChild = (age) => {
    if (typeof age === 'string') {
      age = Date.parse(age);
    }
    if (new Date().getTime() - age - 86_400_000 < 568_036_800_000) {
      setChild(true);
    } else {
      setChild(false);
    }
  };

  const getOrdinations = useCallback(async () => {
    try {
      const ordinationCall = await axios.get(`${SERVER_URL}/ordination`, headers);
      setOrdinations(ordinationCall.data.items);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getDoctors = useCallback(async () => {
    try {
      const doctorCall = await axios.get(`${SERVER_URL}/doctors`, headers);
      setDoctors(doctorCall.data.items);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getCities = async () => {
    try {
      const cityCall = await axios.get(`${SERVER_URL}/cities`);
      setCities(cityCall.data);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  useEffect(() => {
    getOrdinations();
    getDoctors();
    getCities();
  }, [getDoctors, getOrdinations]);

  const onFinish = async (values) => {
    const inputValues = {};
    Object.keys(values).forEach((val, key) => {
      inputValues[val] = values[val];
    });
    // inputValues.bloodPressure = `${upperBP}/${lowerBP}`;
    inputValues.phone = phone;
    inputValues.child = child;

    try {
      const createPatient = await axios.post(`${SERVER_URL}/patient`, inputValues, headers);
      notification.success({
        message: createPatient.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      setChild(false);
      form.resetFields();
      setTimeout(() => history.push('/admin/concord/patients'), 500);
    } catch (error) {
      notification.info({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const checkEmail = async (e) => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/patient/check/email/${e}`, headers);
      if (data.length) {
        notification.info({
          message: 'Korisnik sa unetim emailom postoji',
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      setPatientExists(Boolean(data.length));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='novi-pacijent-profil'>
      <div className='patient-profile'>
        <div className='card-container'>
          <div className='card-content'>
            <div className='dashboard'>
              <div className='card-wrapper patient-card-form admin'>
                <Card title='Novi pacijent' bordered={false}>
                  <Form
                    {...layout}
                    name='basic'
                    layout='horizontal'
                    onFinish={(values) => onFinish(values)}
                    onFinishFailed={onFinishFailed}
                    form={form}
                  >
                    <Row className='card-form patient-edit-form'>
                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                        <Form.Item
                          label='IME'
                          name='firstName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo Vas, unesite ime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label='PREZIME'
                          name='lastName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite prezime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label='DATUM ROĐENJA'
                          name='dateOfBirth'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite datum rođenja!',
                            },
                          ]}
                        >
                          <DatePicker
                            showToday={false}
                            placeholder=''
                            style={{ width: '100%', border: 'none', borderBottom: '1px solid #e5e5e5' }}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => isChild(e)}
                          />
                        </Form.Item>

                        {child ? (
                          <>
                            <h2 style={{ color: '#4dc1be' }}>Roditelj</h2>
                            <p style={{ color: '#627b90' }}>Kod maloletnika unesite podatke staratelja/roditelja</p>
                            <Form.Item
                              label='IME'
                              name='parentFirstName'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite ime!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label='PREZIME'
                              name='parentLastName'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite prezime!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label='EMAIL'
                              name='parentEmail'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite email!',
                                },
                              ]}
                            >
                              <Input type='email' />
                            </Form.Item>
                            <Form.Item
                              label='TELEFON'
                              name='parentPhone'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite telefon!',
                                },
                              ]}
                            >
                              <PhoneInput
                                style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                                value={phone}
                                onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              label='EMAIL'
                              name='email'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite email!',
                                },
                              ]}
                            >
                              <Input type='email' onChange={(e) => checkEmail(e.currentTarget.value)} />
                            </Form.Item>
                            <Form.Item
                              label='TELEFON'
                              name='phone'
                              rules={[
                                {
                                  required: true,
                                  message: 'Molimo, unesite telefon!',
                                },
                              ]}
                            >
                              <PhoneInput
                                style={{ border: 'none', borderBottom: '1px solid #e5e5e5' }}
                                international
                                defaultCountry='RS'
                                countryCallingCodeEditable={false}
                                value={phone}
                                onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                              />
                            </Form.Item>
                          </>
                        )}
                        <Form.Item label='GRAD' name='city'>
                          <Select
                            showArrow
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz'),
                                ) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city._id} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                        {/* <Form.Item label='ADRESA' name='address'>
                        <Input />
                      </Form.Item> */}
                        <Form.Item label='ORDINACIJA' name='ordinations'>
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz'),
                                ) >= 0
                            }
                          >
                            {ordinations?.map((ord) => (
                              <Option key={ord._id} value={ord._id}>
                                {ord.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='DOKTORI' name='doctors'>
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .join('')
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz'),
                                ) >= 0
                            }
                          >
                            {doctors?.map((doc) => (
                              <Option key={doc._id} value={doc._id}>
                                {doc.title} {doc.firstName} {doc.lastName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='POL' name='gender'>
                          <Select>
                            <Option value='muški'>muški</Option>
                            <Option value='ženski'>ženski</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label='TELESNA TEŽINA' name='bodyWeight'>
                          <Input type='number' min={0} />
                        </Form.Item>
                        <Form.Item label='VISINA' name='height'>
                          <Input type='number' min={0} />
                        </Form.Item>
                        <Form.Item label='KRVNA GRUPA' name='bloodType'>
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value='0+'>0+</Option>
                            <Option value='0-'>0-</Option>
                            <Option value='A+'>A+</Option>
                            <Option value='A-'>A-</Option>
                            <Option value='B+'>B+</Option>
                            <Option value='B-'>B-</Option>
                            <Option value='AB+'>AB-</Option>
                            <Option value='AB-'>AB+</Option>
                          </Select>
                        </Form.Item>
                        {/* <Form.Item label='KRVNI PRITISAK' name='bloodPressure'>
                        <Input
                          type='number'
                          min={0}
                          style={{ width: '4.5rem' }}
                          onChange={(e) => setUpperBP(e.currentTarget.value)}
                        />{' '}
                        /
                        <Input
                          type='number'
                          min={0}
                          style={{ width: '4.5rem' }}
                          onChange={(e) => setLowerBP(e.currentTarget.value)}
                        />
                      </Form.Item>
                      <Form.Item label='ŠEĆER U KRVI' name='bloodSugar'>
                        <Input type={'number'} />
                      </Form.Item> */}
                      </Col>

                      <Col
                        xs={{ span: 24, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 6, offset: 1 }}
                        className='dz'
                      >
                        <Form.Item style={{ display: 'block' }} label='ALERGIJE' name='allergiesNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item style={{ display: 'block' }} label='NASLEDNE BOLESTI' name='inheritedDiseaseNote'>
                          <TextArea rows={4} />
                        </Form.Item>
                        {/* <Form.Item style={{ display: 'block' }} label='IMUNIZACIJE' name='immunizationsNote'>
                        <TextArea rows={4} />
                      </Form.Item> */}
                      </Col>

                      {/* <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 0 }}>
                      <Form.Item style={{ display: 'block' }} label='TERAPIJE U TOKU' name='therapiesNote'>
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col> */}
                    </Row>

                    <Row className='new-patient-button-row'>
                      <Col xs={{ span: 12, offset: 12 }}>
                        <Form.Item>
                          {!patientExists && (
                            <button style={{ float: 'right' }} htmltype='submit' className='action-button border-dark'>
                              <img src='/images/save.svg' alt='save' />
                              <span>Potvrdi</span>
                            </button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPatient;
