import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Card, Select, Row, Col, notification } from 'antd';
import UploadBox from '../../../components/base/UploadBox';
import { GalleryModal } from '../../../components/forms';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const currentuser = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const NewDoctor = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [ordinations, setOrdinations] = useState();
  const [categories, setCategories] = useState();
  const [cities, setCities] = useState();
  const [doctorExists, setDoctorExists] = useState(false);
  const [phone, setPhone] = useState();

  const getOrdinations = useCallback(() => {
    Axios.get(`${SERVER_URL}/ordination`, headers)
      .then((ord) => setOrdinations(ord.data.items))
      .catch((err) => console.log(err.message));
  }, []);

  const getCitites = () => {
    Axios.get(`${SERVER_URL}/cities`)
      .then((res) => setCities(res.data))
      .catch((err) => console.log(err.message));
  };

  const fetchFormCategories = async () => {
    try {
      const dataCategories = await Axios.get(`${SERVER_URL}/categories`);
      setCategories(dataCategories.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCitites();
    getOrdinations();
    fetchFormCategories();
  }, [getOrdinations]);

  const checkPassword = (str) => {
    return str.length >= 4;
  };

  const onFinish = async (values) => {
    try {
      values.phone = phone;
      const message = <span>Lozinka mora imati najmanje 4 karaktera</span>;
      if (!checkPassword(values.password)) {
        notification.info({
          message,
          placement: 'bottomRight',
          duration: 2.5
        });
      } else {
        const doctor = await Axios.post(`${SERVER_URL}/register?type=doctor&patient=true`, values);
        if (doctor.status === 201) {
          notification.success({
            message: 'Lekar i korisnik su kreirani.',
            placement: 'bottomRight',
            duration: 2.5
          });
          form.resetFields();
          setImage(null);
        }
        setTimeout(() => history.push('/admin/concord/doctors'), 500);
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const checkEmail = async (e) => {
    try {
      const check = await Axios.get(`${SERVER_URL}/doctor/email/${e}`, headers);
      if (check.data.length) {
        if (e !== '') {
          notification.info({
            message: 'Korisnik sa unetim emailom postoji',
            placement: 'bottomRight',
            duration: 2.5
          });
          setDoctorExists(true);
        }
      } else {
        setDoctorExists(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='novi-lekar-form'>
      <div className='profile-container'>
        <div className='ordination-form-container'>
          <div className='ordination-form-wrapper wide'>
            <div className='dashboard'>
              <div className='card-wrapper'>
                {/* eslint-disable-next-line no-useless-concat */}
                <Card title='Kreiraj Novog Lekara' bordered={false}>
                  <Form
                    name='basic'
                    onFinish={(values) => onFinish(values)}
                    onFinishFailed={onFinishFailed}
                    layout='horizontal'
                    form={form}
                    style={{ borderRadius: '13px' }}
                  >
                    <Row className='card-form'>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 0 }}>
                        <Form.Item
                          label='IME'
                          name='firstName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo Vas, unesite ime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='PREZIME'
                          name='lastName'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite prezime!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label='EMAIL'
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite ispravan email!',
                            },
                          ]}
                        >
                          <Input type='email' onChange={(e) => checkEmail(e.currentTarget.value)} />
                        </Form.Item>

                        <Form.Item
                          label='TELEFON'
                          name='phone'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite broj telefona!',
                            },
                          ]}
                        >
                          <PhoneInput
                            style={{ border: 'none', borderBottom: '1px solid #e5e5e5', marginLeft: '0px' }}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry='RS'
                            value={phone}
                            onChange={(num) => num === undefined ? setPhone('') : setPhone(num)}
                          />
                        </Form.Item>

                        <Form.Item label='ORDINACIJA' name='ordination' className='panel-body'>
                          <Select
                            mode='multiple'
                            showArrow={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {ordinations?.map((ord) => (
                              <Option key={ord._id} value={ord._id}>
                                {ord.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='GRAD'
                          name='city'
                          className='panel-body'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesti grad!',
                            },
                          ]}
                        >
                          <Select
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {cities?.map((city) => (
                              <Option key={city.title} value={city.title}>
                                {city.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='LOZINKA'
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, unesite lozinku!',
                            },
                          ]}
                        >
                          <Input type='password' />
                        </Form.Item>

                        <Form.Item
                          label='OBLAST RADA'
                          name='category'
                          className='panel-body'
                          rules={[{ required: true, message: 'Molimo, unesite specijalizaciju!' }]}
                        >
                          <Select
                            style={{ marginLeft: '0px' }}
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(input
                                  .toLowerCase()
                                  .replace(/ć/g, 'c')
                                  .replace(/č/g, 'c')
                                  .replace(/đ/g, 'dj')
                                  .replace(/š/g, 's')
                                  .replace(/ž/g, 'z')
                                  .replace(/dž/g, 'dz')) >= 0
                            }
                          >
                            {categories?.map((cat) => (
                              <Option key={cat._id} value={cat._id}>
                                {cat.name.sr}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='NAČIN RADA'
                          name='typeOfWork'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, odaberite način rada!',
                            },
                          ]}
                        >
                          <Select mode='multiple' showArrow={true}>
                            <Option value='Rad iz klinike'>Rad iz klinike</Option>
                            {/* <Option value='Izlazak na teren'>Izlazak na teren</Option> */}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label='BROJ LICENCE'
                          name='license'
                          rules={[
                            {
                              required: true,
                              message: 'Molimo, odaberite način rada!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 2 }}>
                        <Form.Item className='upload-wrapper right' name='avatar'>
                          <UploadBox
                            editHandler={avatarHandler}
                            deleteHandler={deleteAvatarHandler}
                            image={image}
                            index={0}
                            name='avatar'
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={{ span: 1, offset: 23 }}>
                        <Form.Item>
                          {!doctorExists && (
                            <button
                              className='action-button border-dark'
                              htmltype='submit'
                              style={{ float: 'right', marginRight: '-2px' }}
                            >
                              <img src='/images/save.svg' alt='save' />
                              <span>Kreiraj</span>
                            </button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>

                {modal.visible && (
                  <GalleryModal
                    width={700}
                    centered
                    visible={modal.visible}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageSavePath='public/images/users/'
                    imageType='data'
                    imageHeight={200}
                    SERVER_URL={SERVER_URL}
                    token={currentuser.token}
                    form={form}
                    onCancel={() => setModal({ ...modal, visible: false })}
                    onInsert={(values) => onInsert(values, modal.formKey)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDoctor;
