import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import NavMenu from './NavMenu';
import SidebarNarrow from './SidebarNarrow';
import AdminSidebarNarrow from './concordAdmin/SidebarNarrow';
import PatientNav from './PatientNav';
import Routes from '../routes/Routes';
import { Layout, Button, message } from 'antd';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { MenuOutlined } from '@ant-design/icons';
import { io } from 'socket.io-client';
import axios from 'axios';
import { useCallback } from 'react';

const { Header, Content } = Layout;
const currentuser = JSON.parse(sessionStorage.getItem("user"));
const userType = currentuser?.userType;
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${currentuser?.token}` },
};

const AdminLayout = ({ setLoggedIn, user }) => {
  const history = useHistory();
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);
  const [eventToUpdate, setEventToUpdate] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  const [doctor, setDoctor] = useState({});

  const location = useLocation();
  const pathName = location.pathname;
  const lastSegment = pathName.substring(pathName.lastIndexOf('/') + 1);
  const patientUrlData = pathName.split('/');
  const currentPatientUrlData = patientUrlData[3];

  const checkLoginStatus = useCallback(() => {
    if (user) {
      const timeLeft = ((new Date(user.expires).getTime() - new Date().getTime()) / 1000).toFixed();
      if (+timeLeft < 60 && +timeLeft > 0) {
        message.warning('Token ce isteci za 60 sekundi i bicete izlogovani. Molim da se izlogujete i ponovo ulogujete.', 10);
      }
    }
    if (!history.location.pathname.includes('/reset-password/')) {
      if (user && new Date() > new Date(user.expires)) {
        setLoggedIn(false);
        sessionStorage.removeItem('user');
        history.push('/login');
        message.info("Izlogovani ste zbog isteka tokena. Ulogujte se ponovo", 10);
      } else if (user === null) {
        setLoggedIn(false);
        sessionStorage.removeItem('user');
        history.push('/login');
      } else {
        setLoggedIn(true);
      }
    } else {
      setLoggedIn(false);
    }
  }, [history, setLoggedIn, user]);

  useEffect(() => {
    checkLoginStatus();
    const interval = setInterval(() => checkLoginStatus(), 61000);

    return () => clearInterval(interval);
  }, [setLoggedIn, checkLoginStatus]);

  const getDoctor = async (id) => {
    try {
      if (id) {
        const { data } = await axios.get(`${SERVER_URL}/doctors/${id}`, headers);
        setDoctorName(`${data.title || 'dr'} ${data.firstName} ${data.lastName}`);
        setDoctor(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (userType === 'ordination') {
      if (pathName.includes('/admin/calendar')) {
        getDoctor(history.location.pathname.split('-')[2]);
      } else {
        setDoctorName('');
      }
    } else if (userType === 'doctor') {
      getDoctor(currentuser?.doctor);
    }
  }, [history.location.pathname, pathName]);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    let userId;
    if (userType === 'doctor') {
      userId = currentuser.doctor;
    } else if (userType === 'ordination') {
      userId = currentuser.ordination;
    } else {
      userId = currentuser.id;
    }
    let socket = io(SOCKET_URL, { path: '/socket.io' });
    socket.emit('user-disconnected', userId);
    sessionStorage.clear();
    window.location.href = '/login';
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {(pathName && lastSegment && pathName === `/admin/patients/${currentPatientUrlData}`) ||
        pathName === `/admin/patients/${currentPatientUrlData}/istorija` ||
        pathName === `/admin/patients/${currentPatientUrlData}/izvestaji` ||
        pathName === `/admin/patients/${currentPatientUrlData}/recept` ? (
        <PatientNav isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
      ) : null}

      {pathName && userType === 'ordination' &&
        lastSegment &&
        pathName !== '/admin/calendar' &&
        pathName === `/admin/calendar/${lastSegment}` ? (
        <NavMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
      ) : null}

      {userType !== 'admin' ? (
        <SidebarNarrow
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          collapsedNav={collapsedNav}
          setEventToUpdate={setEventToUpdate}
        />
      ) : (
        <AdminSidebarNarrow isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
      )}

      <Layout>
        <Header className='admin_header' style={{ color: '#fff', position: 'fixed', width: '100%', zIndex: '100' }}>
          <div className='mobile-header-left-side'>
            <Button className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
              {React.createElement(MenuOutlined)}
            </Button>

            <Link to='/admin' className='logo-mobile'>
              <div className='logo'>
                <img src='/images/logo-mojlekar-dashboard.svg' alt='moj lekar logo' />
              </div>
            </Link>
          </div>

          <div className='header-left-side-content'>
            <Link to='/admin' className='logo-desktop'>
              <div className='logo'>
                <img
                  src='/images/logo-mojlekar-dashboard.svg'
                  alt='moj lekar logo'
                  style={{ maxHeight: 25, width: '80%' }}
                />
              </div>
            </Link>
            <div className='clinic-name-dp'>
              {userType === 'ordination' ? currentuser?.name : userType === 'doctor' && `${doctor?.title || ''} ${doctor?.firstName} ${doctor?.lastName}`}
            </div>
            {userType === 'ordination' && <div className='doctor-name'>
              <p>{doctorName}</p>
            </div>
            }
          </div>

          <div className='profile-image-wrapper'>
            <span className='header-name'>
              {userType === 'ordination' ? 'Ordinacija' : userType === 'admin' ? 'Concord' : 'Lekar'}
            </span>
            <img src='/images/logout.svg' alt='logout' title='Odjavi se' onClick={handleSignOutSubmit} />
          </div>
        </Header>

        <Content
          className={userType === 'admin' ? 'content-space-narrow admin-card-left-space' : 'content-space-narrow'}
        >
          {(pathName &&
            lastSegment &&
            pathName !== '/admin/calendar' &&
            pathName === `/admin/calendar/${lastSegment}` &&
            collapsedNav) ||
            (userType === 'admin' && collapsedNav) ? (
            <>
              <div className='overlay'></div>
              <Routes />
            </>
          ) : (
            <Routes notificationEvents={eventToUpdate} setEventToUpdate={setEventToUpdate} />
          )}
        </Content>
        <footer className='table-footer' />
      </Layout>
    </Layout>
  );
};

export default AdminLayout;;
