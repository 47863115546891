import React, { useState, useEffect, useCallback } from 'react';
import { Card, Form, Row, Col, notification, Popconfirm, TimePicker, Select, Radio } from 'antd';
import axios from 'axios';
import { differenceInCalendarDays } from 'date-fns';
import { EditFilled, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { io } from 'socket.io-client';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { DeleteWorkDayModal, RemoveWorkDayModal, OrdinationSingleDays } from '../../components/modals';
import { SERVER_URL, SOCKET_URL } from '../../config';
import ServiceModal from '../../components/modals/ServiceModal';
import SingleDayModal from '../../components/modals/SingleDayModal';

const { Option } = Select;

const user = JSON.parse(sessionStorage.getItem('user'));
const headers = {
  withCredentials: false,
  headers: { Authorization: `Bearer ${user?.token}` },
};
const ordination = user?.ordination && user.ordination[0];
const dani = ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'];
const radniDani = ['ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota', 'nedelja'];
const format = 'HH:mm';

const WorkHours = ({ match, notificationEvents }) => {
  const [form] = Form.useForm();
  const doctorId = match.params.id;

  const [ordinationSingleDays, setOrdinationSingleDays] = useState({});
  const [openOrdinationSingleDays, setOpenOrdinationSingleDays] = useState(false);
  const [ordinationWorkDay, setOrdinationWorkDay] = useState({});
  const [isCreatedByDoctor, setIsCreatedByDoctor] = useState(false);
  const [doctor, setDoctor] = useState();
  const [workingDays, setWorkingDays] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allWorkingDays, setAllWorkingDays] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [appointmentDuration, setAppointmentDuration] = useState('');
  const [singleDayOpen, setSingleDayOpen] = useState(false);
  const [singleDayId, setSingleDayId] = useState(null);
  const [allSingleDays, setAllSingleDays] = useState([]);
  const [removeAppointments, setRemoveAppointments] = useState(false);
  const [appointments, setAppointments] = useState({});
  const [removeWorkDay, setRemoveWorkDay] = useState({
    open: false,
    data: [],
    mode: '',
    time: {},
    nonWork: undefined,
  });

  const getOrdinationSingleDays = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/singleDay/${ordination}?type=ordination`, headers);
      setOrdinationSingleDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    ordination && getOrdinationSingleDays();
  }, [getOrdinationSingleDays]);

  const getOrdinationWorkDay = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordination/workingDay/${ordination}`, headers);
      setOrdinationWorkDay(data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    if (ordination) getOrdinationWorkDay();
  }, [getOrdinationWorkDay, notificationEvents]);

  const getAllSingleDays = useCallback(async () => {
    try {
      let url;
      if (ordination) {
        url = `${SERVER_URL}/singleDay/${doctorId}?type=ordination&ord=${ordination}`;
      } else {
        url = `${SERVER_URL}/singleDay/${doctorId}?type=doctor`;
      }
      const { data } = await axios.get(url, headers);
      setAllSingleDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  useEffect(() => {
    getAllSingleDays();
  }, [getAllSingleDays, notificationEvents]);

  const getDoctor = useCallback(async () => {
    try {
      const doc = await axios.get(`${SERVER_URL}/doctors/${doctorId}`, headers);
      setDoctor(doc.data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  const getAllWorkingDays = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/doctor/workingDay/${doctorId}`, headers);
      setAllWorkingDays(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  const getWorkDays = useCallback(async () => {
    try {
      const { data } = await axios.post(`${SERVER_URL}/workingDay/${doctorId}`, { ordination }, headers);

      if (data.length) {
        setWorkingDays(data[0]);
        setAppointmentDuration(data[0].duration || '15');
        setSelectedServices(data[0].services);
        if (data[0].nonWorking) {
          setSelectedDays(data[0].nonWorking);
        } else {
          setSelectedDays([]);
        }
      } else {
        setAppointmentDuration('15');
        setWorkingDays({});
        setSelectedDays([]);
        setSelectedServices([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  const setupHours = async (momentTime, day, toSet) => {
    const time = momentTime !== null ? moment(momentTime).format(format) : '';

    // if time is removed from FROM, get all booked appointemnts
    if (time === '' && toSet === 'from') {
      try {
        const { data } = await axios.post(`${SERVER_URL}/day`, { day, doctorId, ordination }, headers);
        if (data.length) {
          setRemoveAppointments(true);
          setAppointments({ data, day, toSet, time });
        } else {
          updateTime(day, toSet, time);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      // otherwise find the day and check if FROM or TO is set
      // if yes, find appointments in that reach
      // if not, update time
      if ((toSet === 'from' && workingDays[day]) || (toSet === 'to' && workingDays[day].to)) {
        try {
          const { data } = await axios.post(
            `${SERVER_URL}/appointments/reach`,
            { time, day, toSet, doc: doctorId, ord: ordination },
            headers,
          );
          if (data.length) {
            setRemoveWorkDay({ open: true, data, mode: 'edit', time: { day, toSet, time } });
          } else {
            updateTime(day, toSet, time);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        updateTime(day, toSet, time);
      }
    }
  };

  const getService = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/admin/services`,
        { doctor: doctorId, ordination: ordination ? ordination : undefined },
        headers,
      );
      setServices(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [doctorId]);

  const openEditModal = async (service) => {
    try {
      setIsCreatedByDoctor(service.doctor && service.title !== 'Zakaži termin' ? true : false);
      setOpenModal(true);
      setIsEdit(true);
      setServiceId(service._id);
      form.setFieldsValue({ ...service });
    } catch (error) {
      console.log(error.message);
    }
  };

  const submitService = async (values) => {
    try {
      values.doctor = doctorId;
      values.ordination = ordination;
      let data;
      if (isEdit) {
        data = await axios.put(`${SERVER_URL}/services/${serviceId}?doctor=true`, values, headers);
      } else {
        data = await axios.post(`${SERVER_URL}/services`, values, headers);
      }
      notification.success({
        message: data.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      setOpenModal(false);

      const updateIndexById = selectedServices.findIndex((s) => s._id === data.data.service._id);
      const updateIndexByTitle = selectedServices.findIndex((s) => s.title === data.data.service.title);
      if (updateIndexById !== -1) {
        selectedServices[updateIndexById] = data.data.service;
      }
      if (updateIndexByTitle !== -1) {
        selectedServices[updateIndexByTitle] = data.data.service;
      }
      if (!isEdit) {
        selectedServices.push(data.data.service);
      }
      setSelectedServices(selectedServices);
      getService();
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  useEffect(() => {
    getDoctor();
  }, [getDoctor]);

  useEffect(() => {
    getService();
    getWorkDays();
    if (ordination) {
      getAllWorkingDays();
    }
  }, [getAllWorkingDays, getService, getWorkDays, notificationEvents]);

  const createWorkingDays = async () => {
    Object.keys(workingDays).forEach((key, val) => {
      if (typeof workingDays[key] === 'object' && workingDays[key] !== null && key !== 'nonWorking') {
        if (workingDays[key].from > workingDays[key].to) {
          workingDays[key].error = true;
          return notification.warn({
            message: `${key}: OD mora biti manje od DO`,
            placement: 'bottomRight',
            duration: 2.5,
          });
        } else if ((!workingDays[key].from && workingDays[key].to) || (workingDays[key].from && !workingDays[key].to)) {
          workingDays[key].error = true;
          return notification.warn({
            message: `${key}: Popunite u celosti`,
            placement: 'bottomRight',
            duration: 2.5,
          });
        } else if (!workingDays[key].from && !workingDays[key].to) {
          delete workingDays[key];
        }
        if (workingDays[key]) {
          delete workingDays[key].error;
        }
      }
    });

    selectedDays.forEach((day) => {
      if (day.from > day.to) {
        day.error = true;
        return notification.warn({
          message: `${new Date(day.date).toDateString()}: OD mora biti manje od DO`,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }
      if (day.from < day.to) {
        delete day.error;
      }
    });

    const errorInNonWorking = selectedDays.find((day) => day.error);

    let error = Object.keys(workingDays).find(
      (key, _) => workingDays[key] !== null && typeof workingDays[key] === 'object' && workingDays[key].error,
    );

    if (!error && errorInNonWorking) error = true;

    try {
      if (!error) {
        if (user?.ordination) {
          workingDays.ordination = user?.ordination[0];
        }
        workingDays.doctor = doctorId;
        workingDays.nonWorking = selectedDays;
        workingDays.duration = appointmentDuration;
        workingDays.services = selectedServices.map((service) => service._id);

        if (workingDays.nonWorking) {
          const cancel = await axios.post(`${SERVER_URL}/appointments/nonDay`, workingDays, headers);

          if (cancel.data.length) {
            setRemoveWorkDay({ open: true, data: cancel.data, mode: 'delete', nonWork: workingDays });
          } else {
            updateWorkingDays();
          }
        } else {
          updateWorkingDays();
        }
      }
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  };

  const updateTime = (day, toSet, time) => {
    const workDays = {
      ...workingDays,
      [day]: {
        ...workingDays[day],
        [toSet === 'from' ? 'from' : 'to']: time,
      },
    };

    // if from is removed, remove the to too;
    if (toSet === 'from' && time === '') {
      delete workDays[day];
    }

    // update hours in non - working days;
    const newDays = selectedDays.map((yad) => {
      const dayInWeek = dani[new Date(yad.date).getDay()];
      return {
        date: yad.date,
        from: toSet === 'from' && dayInWeek === day ? workDays[day]?.from : yad.from,
        to: toSet === 'to' && dayInWeek === day ? workDays[day]?.to : yad.to,
      };
    });

    // if hours for the work day are removed;
    const filterDays = newDays.filter((yad) => workDays[dani[new Date(yad.date).getDay()]]);

    setSelectedDays(filterDays);
    setWorkingDays(workDays);
  };

  // send notifications to ordination and patients for appointment cancellation
  const sendDeleteNotification = async (data) => {
    const notificationData = data.data.map((termin) => ({
      patient: termin.patient,
      doctor: termin.doctor,
      ordination: termin.ordinations,
      appointment: termin._id,
      examinationType: termin.examinationType,
      startDate: termin.startDate,
      endDate: termin.endDate,
      message: 'CANCEL_TERM',
      requestedBy: ordination ? 'ordination' : 'doctor',
    }));

    if (ordination) {
      await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
    }

    let socket = io(SOCKET_URL, { path: '/socket.io' });
    for (let i = 0; i < notificationData.length; i++) {
      const termin = notificationData[i];
      const patient = termin.patient;
      const doctor = termin.doctor;
      const ordination = termin.ordination;
      const calendarId = termin.appointment;
      const startDate = termin.startDate;
      const endDate = termin.endDate;
      const message = 'CANCEL_TERM';
      const requestedBy = termin.requestedBy;
      await axios.post(
        `${SERVER_URL}/send-push-token/${patient}`,
        {
          doctor,
          ordination,
          calendarId,
          startDate,
          endDate,
          message,
        },
        headers,
      );

      if (ordination) {
        socket.emit('new-notification-doctor-created', {
          ordinationId: ordination,
          doctorId: doctor,
          patientId: patient,
          startDate,
          message,
          calendarId,
          requestedBy,
        });
      }
    }
  };

  const updateWorkingDays = async () => {
    try {
      if (removeWorkDay.data.length) {
        sendDeleteNotification(removeWorkDay);
        const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
        notification.info({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }

      if (workingDays.createdAt) {
        let url;
        if (ordination) {
          url = `${SERVER_URL}/workingDay/${doctorId}?ordination=${ordination}`;
        } else {
          url = `${SERVER_URL}/workingDay/${doctorId}`;
        }
        const { data } = await axios.put(url, workingDays, headers);
        const oldDays = [];
        Object.keys(data.oldDay).forEach((key, _) => {
          if (radniDani.includes(key)) {
            oldDays.push({ ...data.oldDay[key], day: key });
          }
        });
        const newDays = [];
        Object.keys(data.newDay).forEach((key, _) => {
          if (radniDani.includes(key)) {
            newDays.push({ ...data.newDay[key], day: key });
          }
        });

        const oldNonWorkingDays = data.oldDay.nonWorking;
        const newNonWorkingDays = data.newDay.nonWorking;

        let socket = io(SOCKET_URL, { path: '/socket.io' });
        let notificationData = {
          ordination,
          doctor: doctorId,
          miscellaneousData: { newDays, oldDays, newNonWorkingDays, oldNonWorkingDays },
          requestedBy: ordination ? 'ordination' : 'doctor',
          message: 'UPDATE_WORK_DAY',
        };
        await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
        socket.emit('send-work-days', notificationData);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else {
        const { data } = await axios.post(`${SERVER_URL}/workingDay`, workingDays, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
          duration: 2.5,
        });
      }

      getService();
      getWorkDays();
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  // before deleting all working days, open popup
  const beforeDelete = async () => {
    try {
      let url;
      if (ordination) {
        url = `${SERVER_URL}/appointments/day?doc=${doctorId}&ord=${ordination}`;
      } else {
        url = `${SERVER_URL}/appointments/day?doc=${doctorId}`;
      }
      const { data } = await axios.get(url, headers);
      if (data.length) {
        setRemoveWorkDay({ open: true, data, edit: 'delete' });
      } else {
        deleteWorkingDay();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteWorkingDay = async () => {
    try {
      const { data } = await axios.delete(`${SERVER_URL}/workingDay/${doctorId}?ordination=${ordination}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });

      let socket = io(SOCKET_URL, { path: '/socket.io' });
      let notificationData = {
        ordination,
        doctor: doctorId,
        miscellaneousData: {},
        requestedBy: ordination ? 'ordination' : 'doctor',
        message: 'DELETE_WORK_DAY',
      };
      await axios.post(`${SERVER_URL}/notifications`, notificationData, headers);
      socket.emit('send-work-days', notificationData);

      getWorkDays();
      getAllSingleDays();
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete appointemnts, which don't enter the new time, when you change time of a work day and send notification
  const deleteDayAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      const { data } = await axios.post(`${SERVER_URL}/appointments/day`, { data: removeWorkDay.data }, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      updateTime(removeWorkDay.time.day, removeWorkDay.time.toSet, removeWorkDay.time.time);
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteAppointments = async () => {
    try {
      sendDeleteNotification(appointments);
      const { data } = await axios.post(`${SERVER_URL}/delete/day`, { days: appointments.data }, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });

      const { day, toSet, time } = appointments;
      updateTime(day, toSet, time);

      setAppointments({});
      setRemoveAppointments(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // if you remove a work day (ponedeljak), delete all appointments of that day and send notifications
  const deleteAllAppointments = async () => {
    try {
      sendDeleteNotification(removeWorkDay);
      let url;
      if (ordination) {
        url = `${SERVER_URL}/appointments/day?doc=${doctorId}&ord=${ordination}`;
      } else {
        url = `${SERVER_URL}/appointments/day?doc=${doctorId}`;
      }
      const { data } = await axios.delete(url, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      deleteWorkingDay();
      setRemoveWorkDay({ open: false, data: [], mode: '' });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDayClick = (day, { selected }) => {
    if (disabledCalendarDays(day)) return;
    const days = selectedDays.concat();
    const dayInWeek = workingDays[dani[new Date(day).getDay()]];
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(new Date(selectedDay.date), day),
      );
      days.splice(selectedIndex, 1);
    } else {
      days.push({ date: day, from: dayInWeek.from, to: dayInWeek.to });
    }
    days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    setSelectedDays(days);
  };

  const setupTime = (momentTime, day, toSet) => {
    const time = moment(momentTime).format(format);
    const newDays = [...selectedDays];
    const findDay = newDays.find((selectedDay) => selectedDay.date === day);
    if (toSet === 'from') findDay.from = time;
    if (toSet === 'to') findDay.to = time;
    const index = newDays.indexOf(findDay);
    if (index !== -1) newDays[index] = findDay;
    setSelectedDays(newDays);
  };

  const disabledCalendarDays = (date) => {
    const index = new Date(date).getDay();

    if (workingDays[dani[index]]) {
      return differenceInCalendarDays(date, new Date()) < 0;
    }
    return true;
  };

  // Restrict hours when choosing FROM, when doctor belongs
  // to multiple ordinations
  const disabledWorkingHours = (day, restrict) => {
    const hours = [];

    // restrict hours from current ordination
    if (user.ordination.length && ordinationWorkDay) {
      if (ordinationWorkDay[day]) {
        if (ordinationWorkDay[day].from && ordinationWorkDay[day].to) {
          const [fromHours] = ordinationWorkDay[day].from.split(':');
          const [toHours] = ordinationWorkDay[day].to.split(':');

          for (let i = 0; i < +fromHours; i++) {
            hours.push(i);
          }
          for (let i = +toHours + 1; i < 24; i++) {
            hours.push(i);
          }
        }
      } else {
        for (let i = 0; i < 24; i++) {
          hours.push(i);
        }
      }
    }

    // restrict hours from other ordination (disable overlaps)
    allWorkingDays.forEach((workDay) => {
      if (workDay[day] && workDay.ordination._id !== ordination) {
        const startHours = +workDay[day].from.split(':')[0];
        const startMinutes = +workDay[day].from.split(':')[1];
        const endHours = +workDay[day].to.split(':')[0];
        const currentStartHours = +workingDays[day]?.from?.split(':')[0];

        for (let i = 0; i < 24; i++) {
          if (restrict) {
            // for FROM
            if (startHours >= currentStartHours) {
              if (startHours < i) hours.push(i);
            } else {
              if (endHours >= i) hours.push(i);
            }
          } else {
            // for TO
            if (startHours < i && i < endHours) hours.push(i);
            if (startMinutes === 0) hours.push(startHours);
          }
        }
      }
    });

    hours.push(0, 1, 2, 3, 4, 5, 6, 23);
    return [...new Set(hours)];
  };

  const disabledWorkingMinutes = (day, hour, toSet) => {
    const minutes = [];

    // restrict minutes from current ordination
    if (user.ordination.length && ordinationWorkDay) {
      if (ordinationWorkDay[day]) {
        if (ordinationWorkDay[day].from && ordinationWorkDay[day].to) {
          const [fromHours, fromMinutes] = ordinationWorkDay[day].from.split(':');
          const [toHours, toMinutes] = ordinationWorkDay[day].to.split(':');

          if (+toHours === hour) {
            for (let i = +toMinutes + 1; i < 60; i++) {
              minutes.push(i);
            }
          }
          if (+fromHours === hour) {
            for (let i = 0; i < +fromMinutes; i++) {
              minutes.push(i);
            }
          }
        }
      } else {
        minutes.push(0, 15, 30, 45);
      }
    }

    // restrict minutes from other ordination (disable overlaps)
    allWorkingDays.forEach((workDay) => {
      if (workDay[day] && workDay.ordination._id !== ordination) {
        if (toSet === 'from') {
          const [fromHour, fromMinutes] = workDay[day].from.split(':');
          const [toHour, toMinutes] = workDay[day].to.split(':');

          if (+toHour === hour) {
            for (let i = 0; i < +toMinutes; i++) {
              minutes.push(i);
            }
          }
          if (+fromHour === hour) {
            for (let i = +fromMinutes; i <= 60; i++) {
              minutes.push(i);
            }
          }
        } else {
          const [fromHour, fromMinutes] = workDay[day].from.split(':');
          if (+fromHour === hour) {
            for (let i = +fromMinutes + 1; i <= 60; i++) {
              minutes.push(i);
            }
          }
        }
      }
    });

    if (hour === 22) {
      minutes.push(15, 30, 45);
    }

    return minutes;
  };

  const disableHours = (day) => {
    const index = dani[new Date(day.date).getDay()];
    const min = +workingDays[index]?.from.split(':')[0];
    const max = +workingDays[index]?.to.split(':')[0];
    const numbers = [];

    for (let i = 0; i < 24; i++) {
      if (i < min || max < i) numbers.push(i);
    }

    return numbers;
  };

  const disableMinutes = (hour, day, toSet) => {
    const minutes = [];
    const index = dani[new Date(day.date).getDay()];
    let time;

    if (hour) {
      time = toSet === 'from' ? workingDays[index].from : workingDays[index].to;
      if (hour === +time.split(':')[0] && toSet === 'to') {
        const minute = time.split(':')[1];
        for (let i = minute; i < 60; i++) {
          minutes.push(i);
        }
      }
      if (hour === +time.split(':')[0] && toSet === 'from') {
        const minute = time.split(':')[1];
        for (let i = 0; i < minute; i++) {
          minutes.push(i);
        }
      }
    }

    return minutes;
  };

  const hasNonWorkingDays = selectedDays?.filter(
    (day) => new Date(day.date).setHours('22', '0', '0') > new Date().getTime(),
  );

  /**
   * IMPORTANT:
   * onSelect and onCange are mandatory in TimePicker component
   * onSelect => removes the necessity of confirming time with OK
   * onChnage => enables the time to be removed when clicking x
   */
  return (
    <div className='card-container max1400'>
      <div className='card-content'>
        <div className='dashboard'>
          <div className='card-wrapper dan'>
            <div className='radno-naslov'>
              <Card
                bordered={false}
                title={
                  user.userType === 'doctor'
                    ? 'Postavite radno vreme'
                    : `Radno vreme - ${doctor?.firstName} ${doctor?.lastName}`
                }
              >
                <Form onFinish={() => createWorkingDays()}>
                  <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} lg={{ span: 15, offset: 0 }}>
                      <Form.Item label='USLUGE'>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Select
                            showArrow
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .replace(/ć/g, 'c')
                                .replace(/č/g, 'c')
                                .replace(/đ/g, 'dj')
                                .replace(/š/g, 's')
                                .replace(/ž/g, 'z')
                                .replace(/dž/g, 'dz')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .replace(/ć/g, 'c')
                                    .replace(/č/g, 'c')
                                    .replace(/đ/g, 'dj')
                                    .replace(/š/g, 's')
                                    .replace(/ž/g, 'z')
                                    .replace(/dž/g, 'dz'),
                                ) >= 0
                            }
                            onChange={(val) =>
                              setSelectedServices(services.filter((service) => val.includes(service._id)))
                            }
                            value={selectedServices && selectedServices?.map((service) => service._id)}
                          >
                            {services?.map((service) => (
                              <Option key={service._id} value={service._id}>
                                {service.title}
                              </Option>
                            ))}
                          </Select>
                          <PlusCircleOutlined
                            title='Dodaj uslugu'
                            style={{ marginLeft: '0.5rem', fontSize: '1.2rem' }}
                            onClick={() => {
                              form.resetFields();
                              setIsEdit(false);
                              setOpenModal(true);
                              setIsCreatedByDoctor(true);
                            }}
                          />
                        </div>
                      </Form.Item>
                      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '.8rem' }}>
                        {selectedServices?.map((service) => (
                          <div
                            key={service._id}
                            style={{
                              width: 'max-content',
                              background: '#4CC1BE',
                              color: 'whitesmoke',
                              borderRadius: '.3rem',
                              padding: '0.2rem 0.7rem',
                              margin: '0.2rem 0.2rem 0 0',
                            }}
                          >
                            <span style={{ marginRight: '1rem' }}>
                              {service.title} - <strong>{service.duration || '/'}</strong> minuta
                            </span>
                            <span>
                              <i>{service.price || '/'} RSD</i>
                              <EditFilled
                                title='Izmeni uslugu'
                                style={{ marginLeft: '.6rem' }}
                                onClick={() => openEditModal({ ...service })}
                              />
                            </span>
                          </div>
                        ))}
                      </div>
                      {user?.userType === 'doctor' && (
                        <Form.Item label='TRAJANJE TERMINA'>
                          <Radio.Group
                            onChange={(e) => setAppointmentDuration(e.target.value)}
                            value={appointmentDuration}
                          >
                            <Radio value='15'>15 minuta</Radio>
                            <Radio value='30'>30 minuta</Radio>
                            <Radio value='45'>45 minuta</Radio>
                            <Radio value='60'>60 minuta</Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {radniDani.map((day, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <p style={{ minWidth: '100px', color: '#627b90' }}>{day.toUpperCase()}</p>
                          <span style={{ color: '#627b90' }}>Od:</span>
                          <TimePicker
                            style={{
                              opacity: `${user.ordination.length && ordinationWorkDay && Boolean(!ordinationWorkDay[day]?.from)
                                ? '.3'
                                : '1'
                                }`,
                            }}
                            onClick={() => {
                              document.querySelector('.ant-picker-footer')?.remove();
                              if (ordinationWorkDay && !ordinationWorkDay[day]?.from && user.ordination.length) {
                                notification.info({
                                  message: 'Ordinacija ne radi u ovom periodu',
                                  placement: 'bottomRight',
                                  duration: 2.5,
                                });
                              }
                            }}
                            disabledHours={() => disabledWorkingHours(day, false)}
                            disabledMinutes={(hour) => disabledWorkingMinutes(day, hour, 'from')}
                            bordered={false}
                            disabled={
                              user.ordination.length && !Object.keys(ordinationWorkDay).length
                                ? Boolean(!Object.keys(ordinationWorkDay).length && !ordinationWorkDay[day]?.from)
                                : false
                            }
                            showNow={false}
                            minuteStep={15}
                            format={format}
                            onSelect={(time) => setupHours(time, day, 'from')}
                            onChange={(time) => setupHours(time, day, 'from')}
                            value={workingDays[day]?.from && moment(workingDays[day]?.from, format)}
                          />
                          <span style={{ color: '#627b90' }}>Do:</span>
                          <TimePicker
                            onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                            style={{ opacity: `${Boolean(!workingDays[day]?.from) ? '.3' : '1'}` }}
                            disabledHours={() => disabledWorkingHours(day, true)}
                            disabledMinutes={(hour) => disabledWorkingMinutes(day, hour, 'to')}
                            bordered={false}
                            disabled={Boolean(!workingDays[day]?.from)}
                            showNow={false}
                            minuteStep={15}
                            format={format}
                            onSelect={(time) => setupHours(time, day, 'to')}
                            onChange={(time) => setupHours(time, day, 'to')}
                            value={workingDays[day]?.to && moment(workingDays[day]?.to, format)}
                          />
                        </div>
                      ))}
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      lg={{ span: 9, offset: 0 }}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <h2 style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Izaberite neradne dane</h2>
                      <Form.Item>
                        <DayPicker
                          disabledDays={disabledCalendarDays}
                          selectedDays={selectedDays.map((day) => new Date(day.date))}
                          onDayClick={handleDayClick}
                          firstDayOfWeek={1}
                          className='non-working-days-day-picker'
                        />
                        {hasNonWorkingDays.length ? <h1>Neradni dani</h1> : ''}
                        <div style={{ maxHeight: '220px', overflowY: 'auto' }}>
                          {selectedDays?.map((day, i) => (
                            <div key={i}>
                              {new Date().getTime() < new Date(day.date).setHours('22', '0', '0') && (
                                <>
                                  <p style={{ color: '#627b90', margin: 0, marginTop: '.3rem' }}>
                                    {moment(day.date).locale('sr').format('DD. MMMM YYYY.').toUpperCase()}
                                  </p>
                                  <span style={{ color: '#627b90' }}>Od:</span>
                                  <TimePicker
                                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                                    disabledHours={() => disableHours(day, false)}
                                    disabledMinutes={(hour) => disableMinutes(hour, day, 'from')}
                                    bordered={false}
                                    showNow={false}
                                    minuteStep={15}
                                    format={format}
                                    allowClear={false}
                                    onSelect={(time) => setupTime(time, day.date, 'from')}
                                    value={
                                      selectedDays?.find((selectedDay) => selectedDay.date === day.date).from &&
                                      moment(
                                        selectedDays?.find((selectedDay) => selectedDay.date === day.date).from,
                                        format,
                                      )
                                    }
                                  />
                                  <span style={{ color: '#627b90' }}>Do:</span>
                                  <TimePicker
                                    onClick={() => document.querySelector('.ant-picker-footer')?.remove()}
                                    disabledHours={() => disableHours(day, true)}
                                    disabledMinutes={(hour) => disableMinutes(hour, day, 'to')}
                                    bordered={false}
                                    showNow={false}
                                    minuteStep={15}
                                    format={format}
                                    allowClear={false}
                                    onSelect={(time) => setupTime(time, day.date, 'to')}
                                    value={
                                      selectedDays?.find((selectedDay) => selectedDay.date === day.date).to &&
                                      moment(
                                        selectedDays?.find((selectedDay) => selectedDay.date === day.date).to,
                                        format,
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                      {allSingleDays.length === 0 ?
                        <span
                          className='action-button border-dark'
                          style={{ cursor: 'pointer' }}
                          onClick={() => { setSingleDayOpen(true); setSingleDayId(null); }}
                        >
                          Postavi jednokratne dane
                        </span>
                        :
                        <span
                          className='action-button border-dark'
                          style={{ cursor: 'pointer' }}
                          onClick={() => { setSingleDayOpen(true); setSingleDayId(allSingleDays[0]._id); }}
                        >
                          Jednokratni radni dani
                        </span>
                      }
                      {ordinationSingleDays.length ?
                        <span
                          className='action-button border-dark'
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenOrdinationSingleDays(true)}
                        >
                          Ordinacijski jednokratni dani
                        </span> : null
                      }
                    </div>
                  </Row>
                  <Row className='doctor-working-hours-buttons'>
                    <Col style={{ display: 'flex' }}>
                      <button className='action-button border-dark' htmltype='submit'>
                        <img src='/images/save.svg' alt='save' />
                        <span>{workingDays?.createdAt ? 'Ažuriraj' : 'Potvrdi'}</span>
                      </button>
                      {workingDays?.createdAt && (
                        <Popconfirm
                          title='Da li stvarno želite da obrišete radno vreme?'
                          onConfirm={beforeDelete}
                          okText='Da'
                          cancelText='Ne'
                        >
                          <button className='action-button delete'>
                            <img src='/images/delete.svg' alt='delete' />
                            <span>Obriši</span>
                          </button>
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <ServiceModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isEdit={isEdit}
        submitService={submitService}
        form={form}
        isCreatedByDoctor={isCreatedByDoctor}
      />
      <DeleteWorkDayModal
        removeWorkDay={removeWorkDay}
        setRemoveWorkDay={setRemoveWorkDay}
        updateWorkingDays={updateWorkingDays}
        deleteDayAppointments={deleteDayAppointments}
        deleteAllAppointments={deleteAllAppointments}
      />
      <RemoveWorkDayModal
        appointments={appointments}
        removeAppointments={removeAppointments}
        setRemoveAppointments={setRemoveAppointments}
        deleteAppointments={deleteAppointments}
      />
      <SingleDayModal
        requestedBy={ordination ? 'ordination' : 'doctor'}
        singleDayOpen={singleDayOpen}
        setSingleDayOpen={setSingleDayOpen}
        doctor={doctorId}
        ordination={ordination}
        singleDayId={singleDayId}
        setSingleDayId={setSingleDayId}
        allSingleDays={allSingleDays}
        getAllSingleDays={getAllSingleDays}
        workingDays={workingDays}
        allWorkingDays={allWorkingDays}
        ordinationWorkDay={ordinationWorkDay}
        appointmentDuration={appointmentDuration}
      />
      <OrdinationSingleDays
        doctor={doctor}
        openOrdinationSingleDays={openOrdinationSingleDays}
        setOpenOrdinationSingleDays={setOpenOrdinationSingleDays}
        ordinationSingleDays={ordinationSingleDays}
        workingDays={workingDays}
        ordination={ordination}
        notificationEvents={notificationEvents}
        appointmentDuration={appointmentDuration}
      />
    </div>
  );
};

export default WorkHours;
