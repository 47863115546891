import React from 'react';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const BannersTable = (props) => {
  const columns = [
    {
      key: '1',
      title: 'Naziv',
      dataIndex: 'title',
    },
    {
      key: '2',
      title: 'Opis',
      dataIndex: 'name'
    },
    {
      key: '3',
      title: 'Izmena',
      render: (record) => {
        return (
          <EditOutlined onClick={() => { props.openModal(record); }} />
        );
      }
    },
  ];

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={props.banners}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>

  );
};

export default BannersTable;
