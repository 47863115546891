import React, { useContext } from 'react';
import { Table, Input, Button, notification } from 'antd';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import $ from 'jquery';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const NotificationTable = ({ fetchNotifications, sidebarContext, data, columnKeys, columnKeysTranslated }) => {
  const user = useContext(UserContext);
  let searchInput;

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${user?.data?.token}` },
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder='Pretraži'
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 195,
              marginBottom: 8,
              display: 'block',
              border: '1px solid #4dc1be',
              borderRadius: 20,
              paddingLeft: 10,
            }}
          />
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 15 }}
          >
            Pretraži
          </Button>
          <Button type='primary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <svg fill={filtered ? 'red' : 'white'} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" > <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" /></svg>,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    $(document).ready(function () {
      $('.ant-table-filter-trigger.active').parent().parent().attr('style', 'background-color: #4dc1be !important');
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const accept = async (id, message) => {
    const type = message.split(' ')[0].toLowerCase();
    try {
      const { data } = await axios.put(`${SERVER_URL}/notification/${id}?answer=yes&type=${type}`, null, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      await sidebarContext.getNumberOfUnreadNotifications();
      fetchNotifications();
    } catch (error) {
      console.log(error.message);
    }
  };

  const decline = async (id, message) => {
    const type = message.split(' ')[0].toLowerCase();
    try {
      const { data } = await axios.put(`${SERVER_URL}/notification/${id}?answer=no&type=${type}`, null, headers);
      notification.info({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5
      });
      await sidebarContext.getNumberOfUnreadNotifications();
      fetchNotifications();
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  if (user.data.userType === 'admin') {
    columns?.push({
      title: 'Postupak',
      render: (record) => {
        if (record.resolved === 'PENDING') {
          return (
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <CheckOutlined onClick={() => accept(record._id, record.message)} title='Prihvati zahtev' />
              </div>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <CloseOutlined onClick={() => decline(record._id, record.message)} title='Odbij zahtev' />
              </div>
            </div>
          );
        }
      },
    });

    columns.push({
      title: 'Status',
      render: (item) => (
        <>
          {item.resolved === 'PENDING' && <span style={{ color: 'yellow' }}>Čekanje</span>}
          {item.resolved === 'ACCEPTED' && <span style={{ color: 'green' }}>Prihvaćen</span>}
          {item.resolved === 'REJECTED' && <span style={{ color: 'crimson' }}>Odbijen</span>}
        </>
      ),
    });
  }
  return (
    <div>
      <Table
        rowClassName={(record, index) => `scroll-row-${index}`}
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <span>
              <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
              <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema poruka</p>
            </span>
          ),
        }}
      />
    </div>
  );
};

export default NotificationTable;
